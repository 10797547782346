import { Box, Card, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import { useState } from "react";

export default function ItensLote({
  setShowAddProdutoLote,
  produtosLote,
  handleAdicionar,
}) {
  const [qtds, setQtds] = useState(() => produtosLote.map(() => 0));

  const handleQtdChange = (index, value) => {
    const newQtds = [...qtds];
    newQtds[index] = value;
    setQtds(newQtds);
  };

  const columns = [
    {
      Header: "Lote",
      accessor: "lote",
      align: "left",
    },
    {
      Header: "Produto",
      accessor: "produto",
      align: "left",
    },
    {
      Header: "Quantidade Disponível",
      accessor: "quantidade",
      align: "left",
    },
    {
      Header: "Quantidade Comprada",
      accessor: "quantidadeComprada",
      align: "left",
    },
    {
      Header: "Num. Pedido de Compra",
      accessor: "pedidoDeCompra",
      align: "left",
    },
    {
      Header: "Adicionar",
      accessor: "action",
    },
  ];

  const rows =
    produtosLote && Array.isArray(produtosLote)
      ? produtosLote.map((produto, index) => ({
          lote: produto.lote,
          produto: produto.produto.descricao,
          quantidade: produto.quantidade,
          quantidadeComprada: produto.quantidadeComprada,
          pedidoDeCompra: produto.pedidoDeCompra.numPedido,
          action: (
            <div>
              <TextField
                label="Quantidade"
                variant="outlined"
                type="number"
                size="small"
                value={qtds[index]}
                onChange={(e) => {
                  handleQtdChange(index, Number(e.target.value));
                }}
                sx={{ mr: 2, width: 80 }}
                InputProps={{
                  inputProps: { min: 0, max: produto.quantidade },
                }}
                error={qtds[index] > produto.quantidade || qtds[index] < 0}
              />

              <MDButton
                variant="contained"
                color="info"
                disabled={qtds[index] > produto.quantidade || qtds[index] <= 0}
                onClick={() => handleAdicionar(produto, qtds[index])}
              >
                Adicionar
              </MDButton>
            </div>
          ),
        }))
      : [];

  return (
    <Card>
      <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <MDTypography variant="h6" color="white" mt={1}>
          Listagem de Lotes
        </MDTypography>

        <MDButton
          variant="contained"
          type="button"
          onClick={() => {
            setShowAddProdutoLote(false);
          }}
        >
          Voltar
        </MDButton>
      </MDBox>
      <MDBox pt={3} mx={2} py={3} px={2}>
        <Box
          sx={{
            display: "flex",
          }}
        >
          {rows?.length > 0 && (
            <div style={{ width: "100%" }}>
              <DataTable
                table={{ columns, rows }}
                isSorted={false}
                disablePagination={true}
                noEndBorder
              />
            </div>
          )}

          {rows?.length === 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: 5,
              }}
            >
              Nenhum item de lote disponível
            </div>
          )}
        </Box>
      </MDBox>
    </Card>
  );
}
