const TIPO_DESCONTO = {
  PERCENTUAL: "P",
  VALOR: "V",
  NENHUM: "N",
};

export const findTipoDesconto = (tipoDesconto) => {
  switch (tipoDesconto) {
    case TIPO_DESCONTO.PERCENTUAL:
      return "Percentual";
    case TIPO_DESCONTO.VALOR:
      return "Valor";
    case TIPO_DESCONTO.NENHUM:
      return "Nenhum";
    default:
      return "";
  }
};
