import * as yup from "yup";

export const yupSchema = yup.object().shape({
  warehouse: yup.string().required("Esse campo é obrigatório"),
  responsavel: yup.string().required("Esse campo é obrigatório"),
  usuario: yup.string().required("Esse campo é obrigatório"),
  dataDeAplicacao: yup.string().required("Esse campo é obrigatório"),
  centroDeCusto: yup.string().required("Esse campo é obrigatório"),
  finalidade: yup.string().required("Esse campo é obrigatório"),
  produtos: yup.array(),
});

export const initialValues = {
  warehouse: "",
  responsavel: "",
  usuario: "",
  dataDeAplicacao: "",
  centroDeCusto: "",
  finalidade: "Aração",
  produtos: [],
};

export const finalidade = [
  "Aração",
  "Preparo do Solo",
  "Sulcador",
  "Gradear",
  "Adubação",
  "Manutenção de maquinário",
  "Manutenção preventiva de maquinário",
];
