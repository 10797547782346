import HttpService from "./http.service";

class WeatherService {
  get = async () => {
    const weatherEndpoint = `/weather`;
    return await HttpService.get(`${weatherEndpoint}`);
  };
}

export default new WeatherService();
