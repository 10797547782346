const TIPO_CONTA = {
  ENTRADA: "E",
  SAIDA: "S",
};

export const findTipoConta = (tipoConta) => {
  switch (tipoConta) {
    case TIPO_CONTA.ENTRADA:
      return "Entrada";
    case TIPO_CONTA.SAIDA:
      return "Saída";
    default:
      return "";
  }
};
