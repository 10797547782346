import HttpService from "./http.service";

class CepService {
  get = async (cep) => {
    try {
      const fetchCep = await this.getCep(cep, "v2");

      if (fetchCep.message) {
        throw new Error(fetchCep.message || "CEP não encontrado");
      }

      return fetchCep;
    } catch (error) {
      return await this.getCep(cep, "v1");
    }
  };

  getCep = async (cep, version) => {
    const cepEndpoint = `/cep/${version}`;
    return await HttpService.get(`${cepEndpoint}/${cep}`);
  };
}

export default new CepService();
