import HttpService from "./http.service";

class AplicacaoService {
  list = async (sortStr = null, filterStr = null) => {
    const aplicacaoEndpoint = `/aplicacao?limit=-1${sortStr ? sortStr : ""}${
      filterStr ? filterStr : ""
    }`;
    return await HttpService.get(aplicacaoEndpoint);
  };

  paginate = async (
    page = 0,
    limit = 25,
    sortStr = null,
    filterStr = null,
    warehouseId
  ) => {
    const aplicacaoEndpoint = `/aplicacao/warehouse/${warehouseId}?page=${page}&limit=${limit}${
      sortStr ? sortStr : ""
    }${filterStr ? filterStr : ""}`;
    return await HttpService.get(aplicacaoEndpoint);
  };

  listForDashboard = async (warehouseId) => {
    const aplicacaoEndpoint = `/aplicacao/dashboard/${warehouseId}`;
    return await HttpService.get(aplicacaoEndpoint);
  };

  get = async (id) => {
    const aplicacaoEndpoint = "/aplicacao";
    return await HttpService.get(`${aplicacaoEndpoint}/${id}`);
  };

  filter = async (data) => {
    const dataWithoutUndefined = Object.keys(data).reduce((object, key) => {
      if (data[key] !== undefined && data[key] !== "" && data[key] !== null) {
        object[key] = data[key];
      }
      return object;
    }, {});

    const query = new URLSearchParams(dataWithoutUndefined).toString();
    const aplicacaoEndpoint = `/aplicacao/filter?${query}`;
    return await HttpService.get(aplicacaoEndpoint, data);
  };

  create = async (data) => {
    const aplicacaoEndpoint = "/aplicacao";
    return await HttpService.post(aplicacaoEndpoint, data);
  };

  update = async (id, data) => {
    const aplicacaoEndpoint = "/aplicacao";
    return await HttpService.put(`${aplicacaoEndpoint}/${id}`, data);
  };

  delete = async (id) => {
    const aplicacaoEndpoint = "/aplicacao";
    return await HttpService.delete(`${aplicacaoEndpoint}/${id}`);
  };
}

export default new AplicacaoService();
