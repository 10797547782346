import * as yup from "yup";

export const STATUS_PEDIDO = {
  AGUARDANDO: "A",
  PARC_ENTREGUE: "P",
  ENTREGUE: "E",
  CANCELADO: "C",
  DEVOLVIDO: "D",
};

export const findStatusPedido = (status) => {
  switch (status) {
    case STATUS_PEDIDO.AGUARDANDO:
      return "Aguardando";
    case STATUS_PEDIDO.PARC_ENTREGUE:
      return "Parcialmente entregue";
    case STATUS_PEDIDO.ENTREGUE:
      return "Entregue";
    case STATUS_PEDIDO.CANCELADO:
      return "Cancelado";
    case STATUS_PEDIDO.DEVOLVIDO:
      return "Devolvido";
    default:
      return "";
  }
};

export const MOEDAS = {
  BRL: ["R$", "Real Brasileiro", "BRL"],
  USD: ["US$", "Dólar Americano", "USD"],
  EUR: ["€", "Euro", "EUR"],
};

export const TIPO_FRETE = {
  FOB: "F",
  CIF: "C",
  TERCEIRO: "T",
  OUTRO: "O",
};

export const findTipoFrete = (tipoFrete) => {
  switch (tipoFrete) {
    case TIPO_FRETE.FOB:
      return "FOB";
    case TIPO_FRETE.CIF:
      return "CIF";
    case TIPO_FRETE.TERCEIRO:
      return "Terceiro";
    case TIPO_FRETE.OUTRO:
      return "Outro";
    default:
      return "";
  }
};

export const yupSchema = yup.object().shape({
  contas: yup.array(),
  contato: yup.string().required("Campo obrigatório"),
  data: yup.string(),
  dataDescarga: yup.string(),
  dataDocumento: yup.string().required("Campo obrigatório"),
  dataEntrega: yup.string().nullable(),
  dataLancamento: yup.string(),
  filial: yup.string().required("Campo obrigatório"),
  media: yup.number(),
  currency: yup.string().required("Campo obrigatório"),
  numPedido: yup.string().nullable(),
  pesoLiquido: yup.number(),
  produtos: yup.array().of(
    yup.object().shape({
      id: yup.string(),
      quantidade: yup.number(),
      valorUnitario: yup.number(),
      valorTotal: yup.number(),
      qtdRecebida: yup.number(),
      valorRecebido: yup.number(),
    })
  ),

  status: yup.string().required("Campo obrigatório"),
  supplier: yup.string(),
  user: yup.string(),
  valorFrete: yup.number(),
  valorTotal: yup.string().required("Campo obrigatório"),
  valorUnitario: yup.string(),
  productObject: yup.string(),
});

export const initialValues = {
  contas: [],
  contato: "",
  data: "",
  dataDescarga: "",
  dataDocumento: "",
  dataEntrega: "",
  dataLancamento: "",
  filial: "",
  media: 0,
  currency: "BRL",
  numPedido: null,
  pesoLiquido: 0,
  produtos: [],
  status: "A",
  supplier: "",
  user: "",
  valorFrete: 0,
  valorTotal: "",
  valorUnitario: "",
};
