import { Icon, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import Service from "services/purchasesWarehouse";
import { format } from "utils/formatDate";
import { findStatusPedido } from "./yupSchema";

export default function data(handleEdit, handleDelete, handleDetail, fromParent = false, selectParent = false, setLoading = () => {}) {
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [sortStr, setSortStr] = useState("&sortBy=numPedido&sortOrder=-1");
  const [filterStr, setFilterStr] = useState(null);

  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));

  const fetchData = async () => {
    setLoading(true);

    const { data, limit, total } = await Service.paginate(page, perPage, sortStr, filterStr);

    setPerPage(limit);
    setTotalRows(total);
    setRows(
      data.map((row) =>
        Object({
          ...row,
          id: row._id,
          numPedido: row.numPedido,
          supplier: row.supplier?.razaoSocial || row.supplier?.nomeFantasia,
          user: row.user?.nome || row.user?.name,
          valorTotal: Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(row?.valorTotal),
          dataLancamento: format(new Date(row.dataLancamento), "dd/MM/yyyy"),
          status: findStatusPedido(row.status),
          action: row,
        })
      )
    );

    setLoading(false);
  };

  const zerosBefore = (number, length) => {
    if (number === undefined || number == null) return number;

    const numberString = number.toString();
    const zeros = "0".repeat(length);
    return `${zeros}${numberString}`.slice(-length);
  };

  const action = (props) => {
    const row = props?.row?.action;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    if (fromParent) {
      return (
        <MDBox display="flex" gap={1}>
          <Tooltip title="Selecionar">
            <MDButton variant="gradient" color="primary" fullWidth type="submit" onClick={() => (selectParent ? selectParent(row) : null)} iconOnly>
              <Icon fontSize="small">check</Icon>
            </MDButton>
          </Tooltip>
        </MDBox>
      );
    }

    return (
      <MDBox display="flex" gap={1}>
        <Tooltip title="Editar" disabled={row.status !== "A" && row.status !== "P"} style={{ display: row.status !== "A" && row.status !== "P" ? "none" : "block" }}>
          <MDButton
            variant="gradient"
            color="info"
            fullWidth
            type="button"
            onClick={() => {
              handleEdit(row._id);
              handleClose();
            }}
            iconOnly
          >
            <Icon fontSize="small">edit</Icon>
          </MDButton>
        </Tooltip>
        <Tooltip title="Detalhes">
          <MDButton
            variant="gradient"
            color="success"
            fullWidth
            type="button"
            onClick={() => {
              handleDetail(row._id);
              handleClose();
            }}
            iconOnly
          >
            <Icon fontSize="small">visibility</Icon>
          </MDButton>
        </Tooltip>
        <Tooltip title="Excluir" disabled={row.status !== "A"} style={{ display: row.status !== "A" ? "none" : "block" }}>
          <MDButton
            variant="gradient"
            color="error"
            fullWidth
            type="button"
            onClick={() => {
              handleDelete(row);
              handleClose();
            }}
            iconOnly
          >
            <Icon fontSize="small">delete_forever</Icon>
          </MDButton>
        </Tooltip>
      </MDBox>
    );
  };

  const updateRows = (rows) => {
    setRows(
      rows.map((row) =>
        Object({
          ...row,
          id: row._id,
          numPedido: row.numPedido,
          supplier: row.supplier?.razaoSocial || row.supplier?.nomeFantasia,
          user: row.user?.nome || row.user?.name,
          valorTotal: Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(row?.valorTotal),
          dataLancamento: format(new Date(row.dataLancamento), "dd/MM/yyyy"),
          status: findStatusPedido(row.status),
          action: row,
        })
      )
    );
  };

  const updatePagination = ({ page: newPage, pageSize: newPerPage }) => {
    setPage(newPage);
    setPerPage(newPerPage);
  };

  const sortBy = (sortArr) => {
    if (sortArr.length === 0) {
      setSortStr("&sortBy=numPedido&sortOrder=-1");
      return;
    }

    const [filter] = sortArr;

    setSortStr(`&sortBy=${filter.field}&sortOrder=${filter.sort === "asc" ? 1 : "-1"}`);
  };

  const filterBy = ({ items }) => {
    if (items.length === 0) {
      setFilterStr(null);
      return;
    }

    const [filter] = items;

    if (filter.operator === "contains" && filter.value === undefined) {
      return;
    }

    setFilterStr(`&filterField=${filter.field}&filterOperator=${filter.operator}&filterValue=${filter.value}`);
  };

  useEffect(() => {
    fetchData();
  }, [page, perPage, sortStr, filterStr]);

  return {
    columns: [
      {
        headerName: "Nº Ped. Compra",
        field: "numPedido",
        flex: 0.12,
        minWidth: matchesMd ? undefined : 60,
      },
      {
        headerName: "Fornecedor",
        field: "supplier",
        flex: 0.2,
        minWidth: matchesMd ? undefined : 100,
      },
      {
        headerName: "Comprador",
        field: "user",
        flex: 0.1,
        minWidth: matchesMd ? undefined : 100,
      },
      {
        headerName: "Valor Total",
        field: "valorTotal",
        flex: 0.1,
        minWidth: matchesMd ? undefined : 100,
      },
      {
        headerName: "Data de Lançamento",
        field: "dataLancamento",
        flex: 0.1,
        minWidth: matchesMd ? undefined : 100,
      },
      {
        headerName: "Status",
        field: "status",
        flex: 0.1,
        minWidth: matchesMd ? undefined : 100,
      },
      { headerName: "Ações", field: "action", flex: 0.1, renderCell: action },
    ],

    rows,
    fetchData,
    updateRows,
    updatePagination,
    sortBy,
    filterBy,
    pageObject: {
      totalRows,
      perPage,
      page,
    },
    sortStr,
    filterStr,
  };
}
