import * as yup from "yup";

export const yupSchema = yup.object().shape({
  descricao: yup.string().required("Esse campo é obrigatório"),
  responsavel: yup.string().required("Esse campo é obrigatório"),
  cep: yup.string(),
  uf: yup.string().required("Esse campo é obrigatório"),
  endereco: yup.string(),
  cidade: yup.string().required("Esse campo é obrigatório"),
  celular: yup.string().required("Esse campo é obrigatório"),
});

export const initialValues = {
  descricao: "",
  responsavel: "",
  cep: "",
  uf: "",
  endereco: "",
  cidade: "",
  celular: "",
};
