// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { DataGrid } from "@mui/x-data-grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Data
import MDButton from "components/MDButton";
import { useState } from "react";
import tableData from "./components/tableData";

import { ptBR_DataGrid } from "utils/datagrid";

export default function Recebidos({ fromParent = false, selectParent, setShowAddProduto, grupoProduto, filterByWarehouse }) {
  const [loading, setLoading] = useState(false);

  const { columns, rows, fetchData, updatePagination, sortBy, filterBy, pageObject } = tableData(fromParent, selectParent, setShowAddProduto, setLoading, filterByWarehouse);

  return (
    <DashboardLayout fromParent={fromParent}>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" sx={{ display: "flex", justifyContent: "space-between" }}>
                <MDTypography variant="h6" color="white" mt={1}>
                  Recebidos
                </MDTypography>
                <div>
                  {fromParent && setShowAddProduto && (
                    <MDButton variant="contained" type="button" onClick={() => setShowAddProduto(false)} sx={{ mr: 2 }}>
                      Voltar
                    </MDButton>
                  )}
                </div>
              </MDBox>
              <MDBox pt={3}>
                <Card sx={{ padding: 2 }}>
                  <DataGrid
                    rows={grupoProduto ? rows.filter((row) => row.productGroup === grupoProduto) : rows}
                    columns={columns}
                    rowCount={pageObject.totalRows}
                    pageSizeOptions={[50, 100]}
                    paginationModel={{
                      pageSize: pageObject.perPage,
                      page: pageObject.page,
                    }}
                    paginationMode="server"
                    onPaginationModelChange={updatePagination}
                    sortingMode="server"
                    onSortModelChange={sortBy}
                    filterMode="server"
                    onFilterModelChange={filterBy}
                    rowSelection={false}
                    loading={loading}
                    localeText={ptBR_DataGrid}
                    sx={{
                      ".MuiDataGrid-virtualScroller": {
                        minHeight: "100px",
                      },
                    }}
                  />
                </Card>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
