import * as yup from "yup";

export const yupSchema = yup.object().shape({
  warehouse: yup.string().required("Esse campo é obrigatório"),
  responsavel: yup.string().required("Esse campo é obrigatório"),
  codCautela: yup.string(),
  usuario: yup.string().required("Esse campo é obrigatório"),
  dataDeRetirada: yup.string().required("Esse campo é obrigatório"),
  produtos: yup.array(),
});

export const initialValues = {
  warehouse: "",
  responsavel: "",
  codCautela: "",
  usuario: "",
  dataDeRetirada: "",
  produtos: [],
};
