import { debounce, Icon, TextField, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";
import Service from "services/stock-dashboard";

export default function Destaques({ rows, fetchData, setLoading }) {
  const handleDelete = async (row) => {
    try {
      setLoading(true);
      await Service.delete(row._id);
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  const action = (props) => {
    const row = props?.row?.action;

    return (
      <MDBox display="flex" gap={1}>
        <Tooltip title="Remover">
          <MDButton variant="gradient" color="error" fullWidth onClick={() => handleDelete(row)} iconOnly>
            <Icon fontSize="small">delete_forever</Icon>
          </MDButton>
        </Tooltip>
      </MDBox>
    );
  };

  const handleChange = debounce(async (e, id) => {
    const value = e.target.value;

    const request = {
      ...rows.find((row) => row.id === id),
      [e.target.name]: value,
    };
    try {
      await Service.update(id, request);
      fetchData();
    } catch (error) {
      console.log(error);
    }
  }, 1000);

  const minQuantityField = (props) => {
    const row = props?.row?.minQuantity;
    const id = props?.row?.id;

    return (
      <TextField
        type="number"
        placeholder={row}
        name="minQuantity"
        onChange={(e) => handleChange(e, id)}
        sx={{
          mx: "5px",
          "& .MuiInputBase-input::placeholder": {
            color: "black",
            opacity: 1,
          },
        }}
      />
    );
  };

  const maxQuantityField = (props) => {
    const row = props?.row?.maxQuantity;
    const id = props?.row?.id;

    return (
      <TextField
        type="number"
        placeholder={row}
        name="maxQuantity"
        onChange={(e) => handleChange(e, id)}
        sx={{
          mx: "5px",
          "& .MuiInputBase-input::placeholder": {
            color: "black",
            opacity: 1,
          },
        }}
      />
    );
  };

  const contactField = (props) => {
    const row = props?.row?.contact;
    const id = props?.row?.id;

    return (
      <ReactInputMask mask="(99) 99999-9999" maskChar={null} name="contact" onChange={(e) => handleChange(e, id)}>
        {() => (
          <TextField
            type="text"
            placeholder={row}
            name="contact"
            sx={{
              mx: "5px",
              "& .MuiInputBase-input::placeholder": {
                color: "black",
                opacity: 1,
              },
            }}
          />
        )}
      </ReactInputMask>
    );
  };

  const columns = [
    {
      headerName: "Produto",
      field: "produto",
      flex: 1,
    },
    {
      headerName: "Unidade de Medida",
      field: "unidadeMedida",
      flex: 1,
    },
    {
      headerName: "QTD Mínima",
      field: "minQuantity",
      flex: 1,
      renderCell: minQuantityField,
    },
    {
      headerName: "QTD Máxima",
      field: "maxQuantity",
      flex: 1,
      renderCell: maxQuantityField,
    },
    {
      headerName: "Contato",
      field: "contact",
      flex: 1,
      renderCell: contactField,
    },
    {
      headerName: "Ação",
      field: "action",
      flex: 1,
      renderCell: action,
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        gridColumn: "span 2",
        alignItems: "center",
        width: "100%",
        margin: 5,
      }}
    >
      {rows?.length > 0 ? (
        <DataGrid rows={rows} columns={columns} pageSize={5} rowsPerPageOptions={[5]} disableSelectionOnClick style={{ width: "100%", margin: 0 }} />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            margin: 5,
          }}
        >
          Nenhum item adicionado
        </div>
      )}
    </div>
  );
}
