import * as yup from "yup";

export const yupSchema = yup.object().shape({
  warehouse: yup.string().required("Esse campo é obrigatório"),
  responsavel: yup.string().required("Esse campo é obrigatório"),
  usuario: yup.string().required("Esse campo é obrigatório"),
  dataDeDevolucao: yup.string().required("Esse campo é obrigatório"),
  motivo: yup.string().required("Esse campo é obrigatório"),
  produtos: yup.array(),
  observacao: yup.string(),
});

export const initialValues = {
  warehouse: "",
  responsavel: "",
  usuario: "",
  dataDeDevolucao: "",
  motivo: "",
  produtos: [],
  observacao: "",
};

export const motivos = [
  "Quantidade reservada superior ao necessário",
  "Equívoco",
  "Inexistência da necessidade de uso",
];
