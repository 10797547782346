import HttpService from "./http.service";

class DevolucaoService {
  list = async (sortStr = null, filterStr = null) => {
    const devolucaoEndpoint = `/devolucao?limit=-1${sortStr ? sortStr : ""}${
      filterStr ? filterStr : ""
    }`;
    return await HttpService.get(devolucaoEndpoint);
  };

  paginate = async (
    page = 0,
    limit = 25,
    sortStr = null,
    filterStr = null,
    warehouseId
  ) => {
    const devolucaoEndpoint = `/devolucao/warehouse/${warehouseId}?page=${page}&limit=${limit}${
      sortStr ? sortStr : ""
    }${filterStr ? filterStr : ""}`;
    return await HttpService.get(devolucaoEndpoint);
  };

  listForDashboard = async (warehouseId) => {
    const devolucaoEndpoint = `/devolucao/dashboard/${warehouseId}`;
    return await HttpService.get(devolucaoEndpoint);
  };

  get = async (id) => {
    const devolucaoEndpoint = "/devolucao";
    return await HttpService.get(`${devolucaoEndpoint}/${id}`);
  };

  filter = async (data) => {
    const dataWithoutUndefined = Object.keys(data).reduce((object, key) => {
      if (data[key] !== undefined && data[key] !== "" && data[key] !== null) {
        object[key] = data[key];
      }
      return object;
    }, {});

    const query = new URLSearchParams(dataWithoutUndefined).toString();
    const devolucaoEndpoint = `/devolucao/filter?${query}`;
    return await HttpService.get(devolucaoEndpoint, data);
  };

  create = async (data) => {
    const devolucaoEndpoint = "/devolucao";
    return await HttpService.post(devolucaoEndpoint, data);
  };

  update = async (id, data) => {
    const devolucaoEndpoint = "/devolucao";
    return await HttpService.put(`${devolucaoEndpoint}/${id}`, data);
  };

  delete = async (id) => {
    const devolucaoEndpoint = "/devolucao";
    return await HttpService.delete(`${devolucaoEndpoint}/${id}`);
  };
}

export default new DevolucaoService();
