import { useContext, useEffect, useState } from "react";

// react-router components
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

// @mui material components
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import { ThemeProvider } from "@mui/material/styles";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";

// Material Dashboard 2 React themes
import theme from "assets/theme";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";

// RTL plugins

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import {
  setMiniSidenav,
  setOpenConfigurator,
  useMaterialUIController,
} from "context";

// Images
import brandDark from "assets/images/logo-ct-dark.png";
import brandWhite from "assets/images/logo-ct.png";
import brandWhitePlus from "assets/images/primeplus-white.png";

import ForgotPassword from "auth/forgot-password";
import Login from "auth/login";
import ResetPassword from "auth/reset-password";
import { AuthContext } from "context";
import ProtectedRoute from "examples/ProtectedRoute";
import UserManagement from "layouts/user-management";
import UserProfile from "layouts/user-profile";
import { setupAxiosInterceptors } from "./services/interceptor";
import authService from "services/auth-service";

export default function App() {
  const authContext = useContext(AuthContext);

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  const [isDemo, setIsDemo] = useState(false);

  useEffect(() => {
    setIsDemo(process.env.REACT_APP_IS_DEMO === "true");
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    // if (miniSidenav && !onMouseEnter) {
    //   setMiniSidenav(dispatch, false);
    //   setOnMouseEnter(true);
    // }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    // if (onMouseEnter) {
    //   setMiniSidenav(dispatch, true);
    //   setOnMouseEnter(false);
    // }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  // if the token expired or other errors it logs out and goes to the login page
  const navigate = useNavigate();
  setupAxiosInterceptors(() => {
    authContext.logout();
    navigate("/auth/login");
  });

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    const token = localStorage.getItem("@primefrutas/token");

    if (token) {
      const checkToken = async () => {
        try {
          await authService.validateToken();
        } catch (error) {
          console.log(error);
          authContext.logout();
          navigate("/auth/login");
        }
      };

      checkToken();
    }
  }, []);

  const flattenRoutes = (routes) => {
    let flattenedRoutes = [];

    routes.forEach((route) => {
      flattenedRoutes.push(route);

      if (route.children) {
        flattenedRoutes = flattenedRoutes.concat(flattenRoutes(route.children));
      }
    });

    return flattenedRoutes;
  };

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route && route.type !== "auth") {
        return (
          <Route
            exact
            path={route.route}
            element={
              <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                {route.component}
              </ProtectedRoute>
            }
            key={route.key}
          />
        );
      }
      return null;
    });

  return (
    <>
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        <style type="text/css">
          {`
          .MuiDataGrid-menu, .MuiDataGrid-panel {
            z-index: 99999;
          }
          `}
        </style>
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={
                process.env.REACT_APP_IS_PRIME_PLUS
                  ? brandWhitePlus
                  : brandWhite
              }
              brandName="Prime Frutas Admin"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
              disableTextLogo={true}
            />
          </>
        )}

        <Routes>
          <Route path="/auth/login" element={<Login />} />
          <Route path="/auth/forgot-password" element={<ForgotPassword />} />
          <Route path="/auth/reset-password" element={<ResetPassword />} />
          <Route
            exact
            path="user-profile"
            element={
              <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                <UserProfile />
              </ProtectedRoute>
            }
            key="user-profile"
          />
          <Route
            exact
            path="user-management"
            element={
              <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                <UserManagement />
              </ProtectedRoute>
            }
            key="user-management"
          />
          {getRoutes(flattenRoutes(routes))}
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </ThemeProvider>
    </>
  );
}
