// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Data
import { useEffect, useState } from "react";

import {
  Box,
  Button,
  CardContent,
  CardHeader,
  CircularProgress,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import Service from "services/stock";
import ServiceDestaque from "services/stock-dashboard";
import AplicacaoService from "services/aplicacao";
import DevolucaoService from "services/devolucao";
import TransitorioService from "services/warehousesStock";
import WarehouseService from "services/warehouses";

import ConfigurarDashboard from "./components/configDashboard";

export default function AlmoxarifadoEstoque() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataDestaque, setDataDestaque] = useState([]);
  const [dataTransitorio, setDataTransitorio] = useState([]);
  const [devolucoes, setDevolucoes] = useState([]);
  const [aplicacoes, setAplicacoes] = useState([]);
  const [configDashboard, setConfigDashboard] = useState(false);

  const [warehouseId, setWarehouseId] = useState({
    id: "",
    nome: "",
  });
  const [warehouses, setWarehouses] = useState([]);

  const fetchData = async () => {
    setLoading(true);

    const { data } = await Service.listByWarehouse(warehouseId?.id);
    const { data: dataDestaque } = await ServiceDestaque.listByWarehouse(
      warehouseId?.id
    );
    const { data: dataTransitorio } = await TransitorioService.listForDashboard(
      warehouseId?.id
    );

    const { data: aplicacoes } = await AplicacaoService.listForDashboard(
      warehouseId?.id
    );

    const { data: devolucoes } = await DevolucaoService.listForDashboard(
      warehouseId?.id
    );

    const dataFiltered = data
      .filter((item) => item.productObject?.itemDeEstoque === true)
      .map((item) => {
        const itemTransitorio = dataTransitorio?.find(
          (i) => i.produto === item.produto
        );
        if (itemTransitorio) {
          item.qtdRecebida -= itemTransitorio?.qtdRetirada;
        }

        const itemDevolvido = devolucoes?.find(
          (i) => i.produto === item.produto
        );

        if (itemDevolvido) {
          item.qtdRecebida += itemDevolvido?.qtdDevolvida || 0;
          itemTransitorio.qtdRetirada -= itemDevolvido?.qtdDevolvida || 0;
        }

        return item;
      });

    dataTransitorio.map((item) => {
      const itemAplicado = aplicacoes?.find((i) => i.produto === item.produto);
      const itemDevolvido = devolucoes?.find((i) => i.produto === item.produto);

      if (itemAplicado) {
        item.qtdRetirada -= itemDevolvido?.qtdDevolvida || 0;
        item.qtdRetirada -= itemAplicado?.qtdAplicada || 0;
      }
      return item;
    });

    setDataTransitorio(dataTransitorio);
    setDataDestaque(dataDestaque);
    setDevolucoes(devolucoes);
    setAplicacoes(aplicacoes);
    setData(dataFiltered);
    setLoading(false);
  };

  useEffect(() => {
    warehouseId?.id !== "" && fetchData();
  }, [warehouseId]);

  useEffect(() => {
    const fetchWarehouse = async () => {
      const warehouses = await WarehouseService.list();
      setWarehouses(warehouses);
      warehouses &&
        setWarehouseId({
          id: warehouses?.data[0]?._id,
          nome: warehouses?.data[0]?.descricao,
        });
    };

    fetchWarehouse();
  }, []);

  const calculateTotal = () => {
    let total = 0;
    data.map((item) => {
      total += item.valorRecebido;
    });

    dataTransitorio?.map((item) => {
      total -= item.valorRetirado;
    });

    devolucoes?.map((item) => {
      const valorUnitario = item.valorRetirado / item.qtdDisponivel;
      total += valorUnitario * item.qtdDevolvida;
    });

    return total;
  };

  const calculateProgress = (idProduto, minQuantity, maxQuantity) => {
    const item = data.find((item) => item.produto === idProduto);
    const qtdRecebida = item?.qtdRecebida || 0;
    const result = (qtdRecebida / (minQuantity + maxQuantity)) * 50;
    return Math.max(0, Math.min(50, result));
  };

  return (
    <DashboardLayout>
      {configDashboard && (
        <ConfigurarDashboard
          setConfigDashboard={setConfigDashboard}
          warehouse={warehouseId}
          fetchDataParent={fetchData}
        />
      )}
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  Almoxarifado - Estoque
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "top",
                    justifyContent: "space-between",
                    margin: "1rem",
                  }}
                >
                  <TextField
                    select
                    label="Selecione o Depósito"
                    onChange={(e) =>
                      setWarehouseId({
                        id: e.target.value,
                        nome: e.target.options[e.target.selectedIndex].text,
                      })
                    }
                    value={warehouseId.id}
                    SelectProps={{
                      native: true,
                    }}
                    sx={{
                      width: "400px",
                    }}
                  >
                    {warehouses?.data?.length > 0 ? (
                      warehouses?.data?.map((option, k) => (
                        <option key={k} value={option._id}>
                          {option.descricao}
                        </option>
                      ))
                    ) : (
                      <option value={""}></option>
                    )}
                  </TextField>
                  <Button
                    variant="contained"
                    sx={{
                      color: "#fff",
                    }}
                    onClick={() => setConfigDashboard(true)}
                  >
                    Configurar Dashboard
                  </Button>
                </Stack>
                <Stack sx={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                  <Stack sx={{ display: "flex", flexDirection: "column" }}>
                    {loading ? (
                      <Skeleton
                        variant="rectangular"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          borderRadius: "8px",
                          margin: "1rem",
                          width: "auto",
                          height: "200px",
                          backgroundColor: "#d7e0e1",
                        }}
                      />
                    ) : (
                      <Card
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          borderRadius: "8px",
                          margin: "1rem",
                          width: "auto",
                          height: "200px",
                          backgroundColor: "#d7e0e1",
                        }}
                      >
                        <CardHeader title="VALOR TOTAL EM ESTOQUE" />
                        <CardContent
                          sx={{
                            color: "#058a3a",
                            fontWeight: "bold",
                            fontSize: "3.5rem",
                            textAlign: "right",
                            ":before": {
                              content: '"R$"',
                              fontSize: "1.5rem",
                              padding: "0.5rem",
                            },
                          }}
                        >
                          {calculateTotal()?.toLocaleString("pt-BR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </CardContent>
                      </Card>
                    )}
                    <Stack
                      sx={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
                    >
                      {!loading &&
                        dataDestaque?.map((item, k) => (
                          <Card
                            key={k}
                            sx={{
                              position: "relative",
                              display: "flex",
                              flexDirection: "column",
                              borderRadius: "8px",
                              margin: "1rem",
                              width: "auto",
                              height: "200px",
                              paddingInline: "1rem",
                              backgroundColor: "#d7e0e1",
                            }}
                          >
                            <Tooltip title={item?.produto?.descricao}>
                              <CardHeader
                                sx={{
                                  textAlign: "center",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                                title={item?.produto?.descricao}
                              />
                            </Tooltip>
                            <CardContent
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                width: "100%",
                                height: "100%",
                              }}
                            >
                              <CircularProgress
                                variant="determinate"
                                value={calculateProgress(
                                  item.produto.descricao,
                                  item.minQuantity,
                                  item.maxQuantity
                                )}
                                sx={{
                                  width: "160px !important",
                                  height: "auto !important",
                                  top: "30%",
                                  position: "absolute",
                                  opacity: 1,
                                  rotate: "-90deg",
                                  color: "#058a3a",
                                }}
                              />
                              <CircularProgress
                                variant="determinate"
                                value={50}
                                sx={{
                                  width: "160px !important",
                                  height: "auto !important",
                                  position: "absolute",
                                  top: "30%",
                                  opacity: 0.5,
                                  rotate: "-90deg",
                                  color: "#058a3a",
                                }}
                              />
                              <Stack
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  gap: "60px",
                                  alignItems: "center",
                                  width: "100%",
                                  height: "100%",
                                  color: "#344767",
                                  fontWeight: "bold",
                                  "p:nth-child(1), p:nth-child(3)": {
                                    transform: "translateY(100%)",
                                  },
                                }}
                              >
                                <p>{item.minQuantity}</p>
                                <p style={{}}>
                                  {data?.find(
                                    (i) => i.produto === item.produto.descricao
                                  )?.qtdRecebida || 0}
                                </p>
                                <p>{item.maxQuantity}</p>
                              </Stack>
                            </CardContent>
                          </Card>
                        ))}
                    </Stack>
                  </Stack>
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: "auto",
                    }}
                  >
                    {loading ? (
                      <Skeleton
                        variant="rectangular"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          borderRadius: "8px",
                          margin: "1rem",
                          width: "auto",
                          height: "200px",
                          backgroundColor: "#edd7d7",
                        }}
                      />
                    ) : (
                      <Card
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          borderRadius: "8px",
                          margin: "1rem",
                          width: "auto",
                          height: "auto",
                          backgroundColor: "#edd7d7",
                          overflowY: "auto",
                        }}
                      >
                        <CardHeader title="MATERIAIS EM ESTOQUE" />
                        <CardContent>
                          {
                            <Stack
                              sx={{
                                display: "flex",
                              }}
                            >
                              {
                                <Stack
                                  sx={{
                                    display: "flex",
                                  }}
                                >
                                  {data?.map((item, k) => (
                                    <Stack
                                      key={k}
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      <MDTypography variant="body2">
                                        {item.produto}
                                      </MDTypography>
                                      <Box
                                        sx={{
                                          flexGrow: 1,
                                          borderBottom: "1px solid",
                                          mx: 1,
                                        }}
                                      />
                                      <MDTypography
                                        variant="body2"
                                        sx={{
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {item.qtdRecebida +
                                          " " +
                                          item.unidadeMedida}
                                      </MDTypography>
                                    </Stack>
                                  ))}
                                </Stack>
                              }
                            </Stack>
                          }
                        </CardContent>
                      </Card>
                    )}
                    {loading ? (
                      <Skeleton
                        variant="rectangular"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          borderRadius: "8px",
                          margin: "1rem",
                          width: "auto",
                          height: "200px",

                          backgroundColor: "#edead7",
                          columnSpan: 2,
                          overflowY: "auto",
                        }}
                      />
                    ) : (
                      <Card
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          borderRadius: "8px",
                          margin: "1rem",
                          width: "auto",
                          height: "auto",
                          backgroundColor: "#edead7",
                          columnSpan: 2,
                          overflowY: "auto",
                        }}
                      >
                        <CardHeader title="MATERIAIS EM ESTOQUE TRANSITÓRIO" />
                        <CardContent>
                          {
                            <Stack
                              sx={{
                                display: "flex",
                              }}
                            >
                              {
                                <Stack
                                  sx={{
                                    display: "flex",
                                  }}
                                >
                                  {dataTransitorio?.map((item, k) => (
                                    <Stack
                                      key={k}
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      <MDTypography variant="body2">
                                        {item.produto}
                                      </MDTypography>
                                      <Box
                                        sx={{
                                          flexGrow: 1,
                                          borderBottom: "1px solid",
                                          mx: 1,
                                        }}
                                      />
                                      <MDTypography
                                        variant="body2"
                                        sx={{
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {item.qtdRetirada +
                                          " " +
                                          item.unidadeMedida}
                                      </MDTypography>
                                    </Stack>
                                  ))}
                                </Stack>
                              }
                            </Stack>
                          }
                        </CardContent>
                      </Card>
                    )}
                  </Stack>
                </Stack>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
