import { Alert, Box, Button, Card, Grid, Icon, TextField } from "@mui/material";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Service from "services/stock-transactions";
import UsersService from "services/users";
import { initialValues, yupSchema } from "./yupSchema";
import CurrencyInput from "react-currency-input-field";
import AdicionarProduto from "layouts/produtos";
import AdicionarGrupoConta from "layouts/grupos-contas";
import { commaToDot } from "utils/numberComma";

export default function AdicionarConta({ setShowAddState, fetchData }) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [showAddProduto, setShowAddProduto] = useState(false);
  const [showAddCentroDeCustoInput, setShowAddCentroDeCustoInput] =
    useState(false);
  const [showAddCentroDeCustoOutput, setShowAddCentroDeCustoOutput] =
    useState(false);
  const [alertKey, setAlertKey] = useState(0);
  const [dataList, setDataList] = useState({
    produtos: [],
    users: [],
  });
  const [childData, setChildData] = useState({
    product: null,
  });

  const changeCurrency = (value, name) => {
    formik.setFieldValue(name, value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const users = await UsersService.getOnlyNames();

        setDataList({
          users,
        });
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yupSchema,
    onSubmit: async (values) => {
      setLoading(true);

      try {
        await Service.create({
          ...values,
          unityPrice: commaToDot(values.unityPrice),
        });
        setChildData({
          product: null,
        });
        formik.resetForm();
        formik.setFieldValue("type", "");
        setSuccess(true);
      } catch (error) {
        console.log(error);
        setSuccess(false);
      }
      setAlertKey(alertKey + 1);
      setLoading(false);
    },
  });

  useEffect(() => {
    const closeOnEscapePressed = (e) => {
      if (e.key === "Escape") {
        fetchData();
        setSuccess(null);
        setShowAddState(false);
      }
    };
    window.addEventListener("keydown", closeOnEscapePressed);
    return () => window.removeEventListener("keydown", closeOnEscapePressed);
  }, []);

  return (
    <>
      <MDBox
        pt={"30px"}
        sx={{
          position: "absolute",
          zIndex: 9999,
          width: "100vw",
          minHeight: "100vh",
          top: 0,
          right: 0,
        }}
      >
        <MDBox
          pt={"30px"}
          sx={{
            position: "fixed",
            zIndex: -1,
            width: "100vw",
            height: "110%",
            backgroundColor: "rgba(0,0,0,0.5)",
            backdropFilter: "blur(5px)",
            top: 0,
            right: 0,
          }}
        />
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={8} mx={"auto"}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  Cadastrar Transação
                </MDTypography>
                <MDButton
                  variant="contained"
                  type="button"
                  onClick={() => {
                    fetchData();
                    setSuccess(null);
                    setShowAddState(false);
                  }}
                >
                  Voltar
                </MDButton>
              </MDBox>
              <MDBox pt={3} mx={2} py={3} px={2}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 2,
                    mb: 2,
                  }}
                  component="form"
                  onSubmit={formik.handleSubmit}
                >
                  <TextField
                    id="description"
                    name="description"
                    label="Descrição"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                  />

                  <TextField
                    id="type"
                    name="type"
                    label="Tipo de Transação"
                    value={formik.values.type}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.type && Boolean(formik.errors.type)}
                    helperText={formik.touched.type && formik.errors.type}
                    SelectProps={{
                      native: true,
                    }}
                    select
                  >
                    <option value=""></option>
                    <option value="input">Entrada</option>
                    <option value="output">Saída</option>
                  </TextField>

                  <Alert
                    icon={false}
                    severity="success"
                    action={
                      <MDButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        iconOnly
                        onClick={() => setShowAddProduto(true)}
                      >
                        <Icon fontSize="small">edit</Icon>
                      </MDButton>
                    }
                    sx={{
                      border: formik.errors.product ? "1px solid red" : "",
                    }}
                  >
                    <b>Produto: </b>
                    {childData.product?.descricao}
                  </Alert>

                  <TextField
                    id="quantity"
                    name="quantity"
                    label="Quantidade"
                    type="number"
                    value={formik.values.quantity}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.quantity && Boolean(formik.errors.quantity)
                    }
                    helperText={
                      formik.touched.quantity && formik.errors.quantity
                    }
                  />

                  <CurrencyInput
                    id="unityPrice"
                    name="unityPrice"
                    label="Preço Unitário"
                    value={formik.values.unityPrice}
                    onValueChange={changeCurrency}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.unityPrice &&
                      Boolean(formik.errors.unityPrice)
                    }
                    helperText={
                      formik.touched.unityPrice && formik.errors.unityPrice
                    }
                    prefix="R$ "
                    decimalsLimit={2}
                    decimalSeparator=","
                    groupSeparator="."
                    disableGroupSeparators={true}
                    intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                    customInput={TextField}
                  />

                  <TextField
                    id="requester"
                    name="requester"
                    label="Solicitante"
                    value={formik.values.requester?.name}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      formik.handleBlur(e);
                    }}
                    error={
                      formik.touched.requester &&
                      Boolean(formik.errors.requester)
                    }
                    helperText={
                      formik.touched.requester && formik.errors.requester
                    }
                    select
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option value="" />
                    {dataList?.users?.map((option) => (
                      <option key={option._id} value={option._id}>
                        {option.name}
                      </option>
                    ))}
                  </TextField>

                  <Alert
                    icon={false}
                    severity="success"
                    action={
                      <MDButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        iconOnly
                        onClick={() => setShowAddCentroDeCustoOutput(true)}
                      >
                        <Icon fontSize="small">edit</Icon>
                      </MDButton>
                    }
                    sx={{
                      border: formik.errors.accountGroupOutput
                        ? "1px solid red"
                        : "",
                    }}
                  >
                    <b>Centro de Custo de Saída: </b>
                    {childData.centroDeCustoOutput?.descricao}
                  </Alert>

                  <Alert
                    icon={false}
                    severity="success"
                    action={
                      <MDButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        iconOnly
                        onClick={() => setShowAddCentroDeCustoInput(true)}
                      >
                        <Icon fontSize="small">edit</Icon>
                      </MDButton>
                    }
                    sx={{
                      border: formik.errors.accountGroupInput
                        ? "1px solid red"
                        : "",
                    }}
                  >
                    <b>Centro de Custo de Entrada: </b>
                    {childData.centroDeCustoInput?.descricao}
                  </Alert>

                  <TextField
                    id="observacoes"
                    name="observacoes"
                    label="Observações"
                    value={formik.values.observacoes}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.observacoes &&
                      Boolean(formik.errors.observacoes)
                    }
                    helperText={
                      formik.touched.observacoes && formik.errors.observacoes
                    }
                    multiline
                    rows={4}
                    fullWidth
                  />

                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      gridColumn: "span 2",
                      justifySelf: "center",
                      width: "100%",
                      color: "white !important",
                    }}
                  >
                    {loading ? "Carregando..." : "Salvar"}
                  </Button>
                </Box>
                {success !== null && (
                  <Box>
                    <MDAlert
                      color={success ? "success" : "error"}
                      mt={4}
                      dismissible
                      key={alertKey}
                    >
                      <MDTypography variant="body2" color="white">
                        {success
                          ? "Transação cadastrada com sucesso!"
                          : "Ocorreu um erro ao cadastrar a transação."}
                      </MDTypography>
                    </MDAlert>
                  </Box>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>

        {/* forms alternativos */}
        {showAddProduto && (
          <MDBox
            pt={"30px"}
            pb={"5%"}
            px={"5%"}
            sx={{
              position: "absolute",
              zIndex: 9999,
              width: "100vw",
              minHeight: "100vh",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.5)",
              backdropFilter: "blur(5px)",
              top: 0,
              right: 0,
              overflow: "auto",
            }}
          >
            <AdicionarProduto
              setShowAddProduto={setShowAddProduto}
              selectParent={(data) => {
                setChildData({
                  ...childData,
                  product: data,
                });
                setShowAddProduto(false);
                formik.setFieldValue("product", data._id);
              }}
              disableForm={true}
              fromParent={true}
              onlyAlmoxarifado={true}
            />
          </MDBox>
        )}

        {showAddCentroDeCustoInput && (
          <MDBox
            pt={"30px"}
            pb={"5%"}
            px={"5%"}
            sx={{
              position: "absolute",
              zIndex: 9999,
              width: "100vw",
              minHeight: "100vh",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.5)",
              backdropFilter: "blur(5px)",
              top: 0,
              right: 0,
              overflow: "auto",
            }}
          >
            <AdicionarGrupoConta
              setShowAddCentroCusto={setShowAddCentroDeCustoInput}
              selectParent={(data) => {
                setChildData({
                  ...childData,
                  centroDeCustoInput: data,
                });
                setShowAddCentroDeCustoInput(false);
                formik.setFieldValue("accountGroupInput", data._id);
              }}
              disableForm={true}
              fromParent={true}
            />
          </MDBox>
        )}

        {showAddCentroDeCustoOutput && (
          <MDBox
            pt={"30px"}
            pb={"5%"}
            px={"5%"}
            sx={{
              position: "absolute",
              zIndex: 9999,
              width: "100vw",
              minHeight: "100vh",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.5)",
              backdropFilter: "blur(5px)",
              top: 0,
              right: 0,
              overflow: "auto",
            }}
          >
            <AdicionarGrupoConta
              setShowAddCentroCusto={setShowAddCentroDeCustoOutput}
              selectParent={(data) => {
                setChildData({
                  ...childData,
                  centroDeCustoOutput: data,
                });
                setShowAddCentroDeCustoOutput(false);
                formik.setFieldValue("accountGroupOutput", data._id);
              }}
              disableForm={true}
              fromParent={true}
            />
          </MDBox>
        )}
      </MDBox>
    </>
  );
}
