const TIPO_PESSOA = {
  FISICA: "F",
  JURIDICA: "J",
};

export const findTipoPessoa = (tipoPessoa) => {
  switch (tipoPessoa) {
    case TIPO_PESSOA.FISICA:
      return "Física";
    case TIPO_PESSOA.JURIDICA:
      return "Jurídica";
    default:
      return "";
  }
};
