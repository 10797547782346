import * as yup from "yup";

export const yupSchema = yup.object().shape({
  nome: yup.string().required("Esse campo é obrigatório"),
  cep: yup.string().required("Esse campo é obrigatório"),
  logradouro: yup.string().required("Esse campo é obrigatório"),
  numero: yup.string(),
  bairro: yup.string().required("Esse campo é obrigatório"),
  cidade: yup.string().required("Esse campo é obrigatório"),
  uf: yup.string().required("Esse campo é obrigatório"),
  telefone: yup.string(),
  celular: yup.string(),
  email: yup
    .string()
    .required("Esse campo é obrigatório")
    .email("Email inválido"),
  observacoes: yup.string(),
});

export const initialValues = {
  nome: "",
  cep: "",
  logradouro: "",
  numero: "",
  bairro: "",
  cidade: "",
  uf: "",
  telefone: "",
  celular: "",
  email: "",
  observacoes: "",
};
