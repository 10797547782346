import * as yup from "yup";

export const ACCOUNT_TYPES = {
  ENTRADA: "E",
  SAIDA: "S",
};

export const yupSchema = yup.object().shape({
  nome: yup.string().required("Esse campo é obrigatório"),
  tipoConta: yup
    .string()
    .required("Esse campo é obrigatório")
    .oneOf(Object.values(ACCOUNT_TYPES), "Tipo de conta inválido"),
  accountGroup: yup.string().required("Esse campo é obrigatório"),
  accountSubGroup: yup.string(),
});

export const initialValues = {
  nome: "",
  tipoConta: "",
  accountGroup: "",
  accountSubGroup: "",
};
