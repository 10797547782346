import HttpService from "./http.service";

class AccountService {
  list = async (sortStr = null, filterStr = null) => {
    const accountsEndpoint = `/accounts?limit=-1${sortStr ? sortStr : ""}${
      filterStr ? filterStr : ""
    }`;
    return await HttpService.get(accountsEndpoint);
  };

  paginate = async (page = 0, limit = 25, sortStr = null, filterStr = null) => {
    const accountsEndpoint = `/accounts?page=${page}&limit=${limit}${
      sortStr ? sortStr : ""
    }${filterStr ? filterStr : ""}`;
    return await HttpService.get(accountsEndpoint);
  };

  get = async (id) => {
    const accountsEndpoint = "/accounts";
    return await HttpService.get(`${accountsEndpoint}/${id}`);
  };

  create = async (data) => {
    const accountsEndpoint = "/accounts";
    return await HttpService.post(accountsEndpoint, data);
  };

  update = async (id, data) => {
    const accountsEndpoint = "/accounts";
    return await HttpService.put(`${accountsEndpoint}/${id}`, data);
  };

  delete = async (id) => {
    const accountsEndpoint = "/accounts";
    return await HttpService.delete(`${accountsEndpoint}/${id}`);
  };
}

export default new AccountService();
