import { Box, Button, Card, Grid, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { format, set } from "date-fns";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { useFormik } from "formik";
import { useState } from "react";
import Service from "services/payables";
import { dotToComma } from "utils/numberComma";

const initialValues = {
  xml: "",
};

export default function ImportarNotaFiscal() {
  const [loading, setLoading] = useState(false);
  const [xml, setXml] = useState(null);
  const [importedData, setImportedData] = useState(null);

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async () => {
      setLoading(true);

      try {
        const { imported } = await Service.createPayableByNfe(xml);
        setImportedData(imported);
        alert("Nota fiscal importada com sucesso!");
        formik.resetForm();
        setXml(null);
      } catch (error) {
        console.log(error);

        if (error.message) {
          alert(error.message);
        } else {
          alert("Erro ao importar nota fiscal!");
        }
      }

      setLoading(false);
    },
  });

  return (
    <DashboardLayout>
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  Importar Nota Fiscal
                </MDTypography>
              </MDBox>
              <MDBox pt={3} mx={2} py={3} px={2}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 2,
                    mb: 2,
                  }}
                  component="form"
                  onSubmit={formik.handleSubmit}
                >
                  <TextField
                    type="file"
                    id="xml"
                    name="xml"
                    label="Arquivo XML"
                    variant="outlined"
                    value={formik.values.xml}
                    onChange={async (e) => {
                      setXml(e.target.files[0]);
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.xml && Boolean(formik.errors.xml)}
                    helperText={formik.touched.xml && formik.errors.xml}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      accept: ".xml",
                    }}
                    required
                  />

                  <Button
                    type="submit"
                    variant="contained"
                    disabled={loading}
                    sx={{
                      gridColumn: "span 2",
                      justifySelf: "center",
                      width: "100%",
                      color: "white !important",
                    }}
                  >
                    {loading ? "Carregando..." : "Importar Arquivo"}
                  </Button>
                </Box>
              </MDBox>
            </Card>

            {importedData?.payable && (
              <Card sx={{ mt: 5 }}>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <MDTypography variant="h6" color="white" mt={1}>
                    Contas a Pagar
                  </MDTypography>
                </MDBox>
                <MDBox pt={3} mx={2} py={3} px={2}>
                  <MDBox
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)",
                      gap: 2,
                      mb: 2,
                    }}
                  >
                    <MDTypography variant="h6" color="text">
                      Descrição: {importedData?.payable?.descricao}
                    </MDTypography>
                    <MDTypography variant="h6" color="text">
                      Valor: R$ {dotToComma(importedData?.payable?.valor)}
                    </MDTypography>
                    <MDTypography variant="h6" color="text">
                      Observações: {importedData?.payable?.observacoes}
                    </MDTypography>
                    <MDTypography variant="h6" color="text">
                      Data do Pedido:{" "}
                      {importedData?.payable?.dataPedido &&
                        format(
                          new Date(importedData?.payable?.dataPedido),
                          "dd/MM/yyyy"
                        )}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </Card>
            )}

            {importedData?.supplier && (
              <Card sx={{ mt: 5 }}>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <MDTypography variant="h6" color="white" mt={1}>
                    Fornecedor
                  </MDTypography>
                </MDBox>
                <MDBox pt={3} mx={2} py={3} px={2}>
                  <MDBox
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)",
                      gap: 2,
                      mb: 2,
                    }}
                  >
                    <MDTypography variant="h6" color="text">
                      Razão Social: {importedData?.supplier?.razaoSocial}
                    </MDTypography>
                    <MDTypography variant="h6" color="text">
                      CNPJ: {importedData?.supplier?.cpfCnpj}
                    </MDTypography>
                    <MDTypography variant="h6" color="text">
                      Logradouro: {importedData?.supplier?.logradouro}
                    </MDTypography>
                    <MDTypography variant="h6" color="text">
                      Número: {importedData?.supplier?.numero}
                    </MDTypography>
                    <MDTypography variant="h6" color="text">
                      Bairro: {importedData?.supplier?.bairro}
                    </MDTypography>
                    <MDTypography variant="h6" color="text">
                      Cidade: {importedData?.supplier?.cidade}
                    </MDTypography>
                    <MDTypography variant="h6" color="text">
                      UF: {importedData?.supplier?.uf}
                    </MDTypography>
                    <MDTypography variant="h6" color="text">
                      CEP: {importedData?.supplier?.cep}
                    </MDTypography>
                    <MDTypography variant="h6" color="text">
                      Telefone: {importedData?.supplier?.telefone}
                    </MDTypography>
                    <MDTypography variant="h6" color="text">
                      E-mail: {importedData?.supplier?.email}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </Card>
            )}

            {importedData?.products && importedData?.products?.length > 0 && (
              <Card sx={{ mt: 5 }}>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <MDTypography variant="h6" color="white" mt={1}>
                    Produtos
                  </MDTypography>
                </MDBox>
                <MDBox pt={3} mx={2} py={3} px={2}>
                  <MDBox
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)",
                      gap: 2,
                      mb: 2,
                    }}
                  >
                    {importedData?.products?.map((product) => (
                      <div>
                        <MDTypography variant="h6" color="text">
                          Descrição: {product?.descricao}
                        </MDTypography>
                        <MDTypography variant="h6" color="text">
                          Código Interno: {product?.codigoInterno}
                        </MDTypography>
                        <MDTypography variant="h6" color="text">
                          NCM: {product?.ncm}
                        </MDTypography>
                        <MDTypography variant="h6" color="text">
                          Unidade:{product?.unidade}
                        </MDTypography>
                        <MDTypography variant="h6" color="text">
                          Preço de Compra: R$ {dotToComma(product?.precoCompra)}
                        </MDTypography>
                      </div>
                    ))}
                  </MDBox>
                </MDBox>
              </Card>
            )}

            {importedData?.stock && importedData?.stock?.length > 0 && (
              <Card sx={{ mt: 5 }}>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <MDTypography variant="h6" color="white" mt={1}>
                    Almoxarifado
                  </MDTypography>
                </MDBox>
                <MDBox pt={3} mx={2} py={3} px={2}>
                  <MDBox
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)",
                      gap: 2,
                      mb: 2,
                    }}
                  >
                    {importedData?.stock?.map((product) => (
                      <div>
                        <MDTypography variant="h6" color="text">
                          Descrição: {product?.productDescription}
                        </MDTypography>
                        <MDTypography variant="h6" color="text">
                          Quantidade: +{product?.quantity}
                        </MDTypography>
                      </div>
                    ))}
                  </MDBox>
                </MDBox>
              </Card>
            )}
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
