import HttpService from "./http.service";

class ReportsService {
  genesisReport = async ({ startDate, endDate, accountGroup, supplierId }) => {
    const reportsEndpoint = `/reports/genesis-report?${
      startDate && `startDate=${startDate}`
    }&${endDate && `endDate=${endDate}`}&${
      accountGroup && `accountGroup=${accountGroup}`
    }&${supplierId && `supplierId=${supplierId}`}`;

    return await HttpService.get(reportsEndpoint);
  };

  saldoDiario = async (dia = null) => {
    const reportsEndpoint = `/reports/saldo-diario${dia ? `?dia=${dia}` : ""}`;
    return await HttpService.get(reportsEndpoint);
  };

  saldoEntreDatas = async (dataInicio = null, dataFim = null) => {
    const reportsEndpoint = `/reports/saldo-entre-datas?${
      dataInicio ? `dataInicio=${dataInicio}` : ""
    }&${dataFim ? `dataFim=${dataFim}` : ""}`;
    return await HttpService.get(reportsEndpoint);
  };

  carregamentoDia = async (dia = null) => {
    const reportsEndpoint = `/reports/carregamento-dia${
      dia ? `?dia=${dia}` : ""
    }`;
    return await HttpService.get(reportsEndpoint);
  };
}
export default new ReportsService();
