import HttpService from "./http.service";

class DashboardService {
  get = async () => {
    const dashboardEndpoint = `/dashboard`;
    return await HttpService.get(`${dashboardEndpoint}`);
  };

  getPurchasesLastMonths = async () => {
    const dashboardEndpoint = `/dashboard/purchases-last-months`;
    return await HttpService.get(`${dashboardEndpoint}`);
  };
}

export default new DashboardService();
