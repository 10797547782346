import { Box, Grid, Card, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Service from "services/customers";
import { yupSchema, initialValues, TIPO_PESSOA } from "./yupSchema";
import { findTipoPessoa } from "utils/tipoPessoa";
import { format } from "utils/formatDate";
import InputMask from "react-input-mask";
import CurrencyInput from "react-currency-input-field";
import UsersService from "services/users";
import PaymentMethodService from "services/payment-methods";
import { PRAZO_PAGAMENTO } from "layouts/pedidos-compra/components/contas/yupSchema";
import { findPrazoPagamento } from "layouts/pedidos-compra/components/contas/yupSchema";
import LabelForm from "layouts/pedidos-compra/components/LabelForm";

export default function DetalharCliente({
  setShowDetailState,
  currentIdAction,
  fetchData,
}) {
  const [loading, setLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [vendedores, setVendedores] = useState([]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yupSchema,
  });

  const changeCurrency = (value, name) => {
    formik.setFieldValue(name, value);
  };

  useEffect(() => {
    const fetchList = async () => {
      setLoading(true);
      const fields = await Service.get(currentIdAction);

      for (var key in formik.values) {
        formik.setFieldValue(key, fields[key]);

        if (key === "dtNascimento" || key.includes("data")) {
          formik.setFieldValue(
            key,
            fields[key] !== undefined && fields[key] !== null
              ? format(new Date(fields[key]), "yyyy-MM-dd")
              : ""
          );
        }
      }

      setLoading(false);
    };

    fetchList();
  }, [currentIdAction]);

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      const { data: paymentMethods } = await PaymentMethodService.list();
      setPaymentMethods(paymentMethods);
    };

    fetchPaymentMethods();

    const fetchUsers = async () => {
      const users = await UsersService.getOnlyNames();
      setVendedores(users);
    };

    fetchUsers();

    const closeOnEscapePressed = (e) => {
      if (e.key === "Escape") {
        fetchData();
        setShowDetailState(false);
      }
    };
    window.addEventListener("keydown", closeOnEscapePressed);
    return () => window.removeEventListener("keydown", closeOnEscapePressed);
  }, []);

  return (
    <>
      <MDBox
        pt={"30px"}
        sx={{
          position: "absolute",
          zIndex: 9999,
          width: "100vw",
          minHeight: "auto",
          height: "120vh",
          backgroundColor: "rgba(0,0,0,0.5)",
          backdropFilter: "blur(5px)",
          top: 0,
          right: 0,
        }}
      >
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={8} mx={"auto"}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  Detalhes
                </MDTypography>
                <MDButton
                  variant="contained"
                  type="button"
                  onClick={() => {
                    fetchData();
                    setShowDetailState(false);
                  }}
                >
                  Fechar
                </MDButton>
              </MDBox>
              <MDBox pt={3} mx={2} py={3} px={2}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 2,
                    mb: 2,
                  }}
                  component="form"
                  onSubmit={formik.handleSubmit}
                >
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="tipoPessoa"
                    name="tipoPessoa"
                    label="Tipo de Pessoa"
                    value={findTipoPessoa(formik.values.tipoPessoa)}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="cpfCnpj"
                    name="cpfCnpj"
                    label={
                      formik.values.tipoPessoa === TIPO_PESSOA.PESSOA_FISICA
                        ? "CPF"
                        : "CNPJ"
                    }
                    value={formik.values.cpfCnpj}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="inscricaoEstadual"
                    name="inscricaoEstadual"
                    label="Inscrição Estadual"
                    value={formik.values.inscricaoEstadual}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="inscricaoMunicipal"
                    name="inscricaoMunicipal"
                    label="Inscrição Municipal"
                    value={formik.values.inscricaoMunicipal}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="razaoSocial"
                    name="razaoSocial"
                    label="Razão Social"
                    value={formik.values.razaoSocial}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="nomeFantasia"
                    name="nomeFantasia"
                    label="Nome Fantasia"
                    value={formik.values.nomeFantasia}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />

                  <TextField
                    id="dataNascimento"
                    name="dataNascimento"
                    label="Data de Nascimento"
                    type="date"
                    value={formik.values.dataNascimento}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.dataNascimento &&
                      Boolean(formik.errors.dataNascimento)
                    }
                    helperText={
                      formik.touched.dataNascimento &&
                      formik.errors.dataNascimento
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <InputMask
                    mask="99.999-999"
                    value={formik.values.cep}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      formik.handleBlur(e);
                      pesquisaCep();
                    }}
                  >
                    {(inputProps) => (
                      <TextField
                        id="cep"
                        name="cep"
                        label="CEP"
                        error={formik.touched.cep && Boolean(formik.errors.cep)}
                        helperText={formik.touched.cep && formik.errors.cep}
                        {...inputProps}
                      />
                    )}
                  </InputMask>

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="logradouro"
                    name="logradouro"
                    label="Logradouro"
                    value={formik.values.logradouro}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="numero"
                    name="numero"
                    label="Número"
                    value={formik.values.numero}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="complemento"
                    name="complemento"
                    label="Complemento"
                    value={formik.values.complemento}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="bairro"
                    name="bairro"
                    label="Complemento"
                    value={formik.values.bairro}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />

                  <TextField
                    id="codigoIbge"
                    name="codigoIbge"
                    label="Código IBGE"
                    type="number"
                    value={formik.values.codigoIbge}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.codigoIbge &&
                      Boolean(formik.errors.codigoIbge)
                    }
                    helperText={
                      formik.touched.codigoIbge && formik.errors.codigoIbge
                    }
                  />

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="cidade"
                    name="cidade"
                    label="Cidade"
                    value={formik.values.cidade}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="uf"
                    name="uf"
                    label="UF"
                    value={formik.values.uf}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />

                  <InputMask
                    mask={
                      formik.values.telefone?.length > 14
                        ? "(99) 99999-9999"
                        : "(99) 9999-99999"
                    }
                    maskChar=""
                    value={formik.values.telefone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    {(inputProps) => (
                      <TextField
                        id="telefone"
                        name="telefone"
                        label="Telefone"
                        error={
                          formik.touched.telefone &&
                          Boolean(formik.errors.telefone)
                        }
                        helperText={
                          formik.touched.telefone && formik.errors.telefone
                        }
                        {...inputProps}
                      />
                    )}
                  </InputMask>

                  <InputMask
                    mask={
                      formik.values.celular?.length > 14
                        ? "(99) 99999-9999"
                        : "(99) 9999-99999"
                    }
                    maskChar=""
                    value={formik.values.celular}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    {(inputProps) => (
                      <TextField
                        id="celular"
                        name="celular"
                        label="Celular"
                        value={formik.values.celular}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.celular &&
                          Boolean(formik.errors.celular)
                        }
                        helperText={
                          formik.touched.celular && formik.errors.celular
                        }
                        {...inputProps}
                      />
                    )}
                  </InputMask>

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="email"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />

                  <CurrencyInput
                    id="limiteCredito"
                    name="limiteCredito"
                    label="Limite de Crédito"
                    value={formik.values.limiteCredito}
                    onValueChange={changeCurrency}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.limiteCredito &&
                      Boolean(formik.errors.limiteCredito)
                    }
                    helperText={
                      formik.touched.limiteCredito &&
                      formik.errors.limiteCredito
                    }
                    prefix="R$ "
                    decimalsLimit={2}
                    decimalSeparator=","
                    groupSeparator="."
                    disableGroupSeparators={true}
                    intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                    customInput={TextField}
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    id="paymentMethod"
                    name="paymentMethod"
                    label="Método de Pagamento"
                    value={formik.values.paymentMethod}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.paymentMethod &&
                      Boolean(formik.errors.paymentMethod)
                    }
                    helperText={
                      formik.touched.paymentMethod &&
                      formik.errors.paymentMethod
                    }
                    select
                    SelectProps={{
                      native: true,
                    }}
                    disabled
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <option value="" />
                    {paymentMethods.map((option) => (
                      <option key={option._id} value={option._id}>
                        {option.descricao}
                      </option>
                    ))}
                  </TextField>

                  <TextField
                    id="prazoPagamento"
                    name="prazoPagamento"
                    label="Prazo de Pagamento"
                    value={formik.values.prazoPagamento}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.prazoPagamento &&
                      Boolean(formik.errors.prazoPagamento)
                    }
                    helperText={
                      formik.touched.prazoPagamento &&
                      formik.errors.prazoPagamento
                    }
                    select
                    SelectProps={{
                      native: true,
                    }}
                    disabled
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <option value=""></option>
                    {Object.values(PRAZO_PAGAMENTO).map((prazo) => (
                      <option key={prazo} value={prazo}>
                        {findPrazoPagamento(prazo)}
                      </option>
                    ))}
                  </TextField>

                  <Grid item sx={{ lineHeight: "14px" }}>
                    <LabelForm label="Vendedores">
                      {vendedores
                        .filter((vendedor) =>
                          formik.values.vendedores.includes(vendedor._id)
                        )
                        .map((vendedor) => (
                          <b>{vendedor.name}, </b>
                        ))}
                    </LabelForm>
                  </Grid>
                <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="ativo"
                    name="ativo"
                    label="Status"
                    value={formik.values.ativo ? "Ativo" : "Inativo"}
                  />
                 <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="ativo_obs"
                    name="ativo_obs"
                    label="Observação de Status"
                    value={formik.values.ativo_obs}
                    multiline
                    rows={4}
                    sx={{
                      gridColumn: "span 2",
                      justifySelf: "center",
                      width: "100%",
                    }}
                  />
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="observacao"
                    name="observacao"
                    label="Observação"
                    value={formik.values.observacao}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    multiline
                    rows={4}
                    sx={{
                      gridColumn: "span 2",
                      justifySelf: "center",
                      width: "100%",
                    }}
                  />
                </Box>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
