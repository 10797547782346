import { useContext, useEffect } from "react";

// react-router-dom components
import { NavLink, useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

// Images
import logoAlpha from "assets/images/logo-alpha.png";
import logoAlphaIcon from "assets/images/favicon.png";
import logoAlphaPlus from "assets/images/primeplus-white.png";
import logoAlphaIconPlus from "assets/images/faviconprimeplus.png";

// Material Dashboard 2 React context
import {
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  useMaterialUIController,
} from "context";
import LogoutIcon from "@mui/icons-material/Logout";

import AuthService from "services/auth-service";
import PermissionService from "services/permission";
import { AuthContext } from "context";
import { useState } from "react";
import MDButton from "components/MDButton";

import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";
import zIndex from "@mui/material/styles/zIndex";

function Sidenav({
  color,
  brand,
  brandName,
  routes,
  disableTextLogo,
  isMini,
  ...rest
}) {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    sidenavColor,
  } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");
  const authContext = useContext(AuthContext);
  const [openCategory, setOpenCategory] = useState("");
  const [allRoutes, setAllRoutes] = useState(routes);
  const [loading, setLoading] = useState(false);

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  const handleLogOut = async () => {
    const response = await AuthService.logout();
    authContext.logout();
  };

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : transparentSidenav
      );
      setWhiteSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : whiteSidenav
      );
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem("@primefrutas/token");

    if (routes !== undefined && token) {
      PermissionService.listMy().then((data) => {
        const mrt = data
          .map((item) => item.area)
          .concat([
            "logout",
            "cadastros",
            "produtos",
            "dashboard",
            "financeiro",
            "pedidos",
            "relatorios",
            "rh",
            "comercial",
            "logistica",
            "almoxarifado",
          ]);

        const newRoutes = disableRoutes(routes, mrt);
        setAllRoutes(newRoutes);
        setLoading(false);
      });
    }
  }, [routes]);

  const disableRoutes = (routes, routesEnabled) => {
    routes.map((route) => {
      route.enabled = routesEnabled.includes(route.key);

      if (route.children && route.children.length > 0) {
        route.children = disableRoutes(route.children, routesEnabled);

        if (route.children.filter((item) => item.enabled).length === 0) {
          route.enabled = false;
        }
      }

      return route;
    });

    return routes;
  };

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const RenderRoutes = ({ routes }) => {
    if (routes === undefined || routes.length === 0) return null;

    return routes.map(
      ({
        type,
        name,
        icon,
        title,
        noCollapse,
        key,
        href,
        route,
        agrupador,
        children,
      }) => {
        let returnValue;

        if (type === "collapse") {
          returnValue = href ? (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavCollapse
                name={name}
                icon={icon}
                active={key === collapseName}
                noCollapse={noCollapse}
              />
            </Link>
          ) : (
            <Collapse in={openCategory === agrupador || children?.length > 0}>
              <NavLink key={key} to={route}>
                <SidenavCollapse
                  name={name}
                  icon={icon}
                  active={key === collapseName}
                />
              </NavLink>
              <RenderRoutes routes={children} />
            </Collapse>
          );
        } else if (type === "title") {
          returnValue = (
            <MDTypography
              key={key}
              color={textColor}
              display="block"
              variant="caption"
              fontWeight="bold"
              textTransform="uppercase"
              pl={3}
              mt={2}
              mb={1}
              ml={1}
            >
              {title}
            </MDTypography>
          );
        } else if (type === "divider") {
          returnValue = (
            <Divider
              key={key}
              light={
                (!darkMode && !whiteSidenav && !transparentSidenav) ||
                (darkMode && !transparentSidenav && whiteSidenav)
              }
            />
          );
        }
        return returnValue;
      }
    );
  };

  const renderExampleRoutes = routes.map(
    ({ type, name, icon, title, noCollapse, key, href, route }) => {
      let returnValue;

      if (type === "examples") {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              name={name}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </Link>
        ) : (
          <NavLink key={key} to={route}>
            <SidenavCollapse
              name={name}
              icon={icon}
              active={key === collapseName}
            />
          </NavLink>
        );
      }
      return returnValue;
    }
  );

  const Menu = ({ routes }) => {
    return routes
      .filter((route) => route.enabled || route.enabled === undefined)
      .map(({ name, icon, children, route, type, key, padded }, k) => {
        const isTheLastItem = k === routes.length - 1;

        if (type === "collapse") {
          if (children?.length === 0 || children === undefined) {
            return (
              <NavLink key={key} to={route}>
                <SidenavCollapse
                  name={name}
                  icon={icon}
                  active={k === collapseName}
                  sx={{
                    paddingLeft: padded ? 36 : 0,
                    paddingBlock: padded ? 0 : undefined,
                  }}
                />
              </NavLink>
            );
          } else {
            const [active, setActive] = useState(false);

            const activeRoute = children.find(
              ({ route }) => route === location.pathname
            );

            const activeRouteChildren = children.find(
              ({ children }) =>
                children?.find(({ route }) => route === location.pathname) !==
                undefined
            );

            useEffect(() => {
              if (
                activeRoute !== undefined ||
                activeRouteChildren !== undefined
              )
                setActive(true);
            }, [activeRoute, activeRouteChildren]);

            return (
              <>
                <SidenavCollapse
                  name={name}
                  icon={icon}
                  variant="agrupador"
                  active={active}
                  key={key + Date.now() + 1}
                  onClick={() => setActive(!active)}
                />

                {active && (
                  <div
                    key={key + Date.now()}
                    style={{ background: "rgba(255, 255, 255, 0.1)" }}
                  >
                    <Menu key={key + Date.now()} routes={children} />
                  </div>
                )}

                {!isTheLastItem && (
                  <Divider
                    key={k}
                    light={
                      (!darkMode && !whiteSidenav && !transparentSidenav) ||
                      (darkMode && !transparentSidenav && whiteSidenav)
                    }
                  />
                )}
              </>
            );
          }
        }
      });
  };

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  return (
    <>
      {isMini ? null : (
        <MDBox
          sx={{
            position: "fixed",
            top: miniSidenav ? 25 : 24,
            left: miniSidenav ? 45 : 24,
            zIndex: 9999,
          }}
        >
          <MDButton
            variant="gradient"
            color="info"
            iconOnly
            sx={{
              ...navbarIconButton,
              padding: "0",
            }}
            onClick={handleMiniSidenav}
          >
            <Icon
              sx={{
                color: "white !important",
              }}
            >
              {miniSidenav ? "menu" : "menu_open"}
            </Icon>
          </MDButton>
        </MDBox>
      )}

      <SidenavRoot
        {...rest}
        variant="permanent"
        ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
      >
        {miniSidenav && (
          <Divider
            light={!darkMode && !whiteSidenav && !transparentSidenav}
            sx={{
              position: "absolute",
              top: 40,
              left: 0,
              width: "100%",
              zIndex: 98,
            }}
          />
        )}

        <MDBox pt={1} pb={1} px={4} textAlign="center">
          <MDBox
            display={{ xs: "block", xl: "none" }}
            position="absolute"
            top={0}
            right={0}
            p={1.625}
            onClick={closeSidenav}
            sx={{ cursor: "pointer" }}
          >
            <MDTypography variant="h6" color="secondary">
              <Icon sx={{ fontWeight: "bold", color: "#fff" }}>close</Icon>
            </MDTypography>
          </MDBox>
          <MDBox component={NavLink} to="/" display="flex" alignItems="center">
            {brand && disableTextLogo && (
              <MDBox
                component="img"
                src={
                  miniSidenav
                    ? process.env.REACT_APP_IS_PRIME_PLUS
                      ? logoAlphaIconPlus
                      : logoAlphaIcon
                    : process.env.REACT_APP_IS_PRIME_PLUS
                    ? logoAlphaPlus
                    : logoAlpha
                }
                alt="logo"
                width={"80%"}
                sx={{
                  margin: "0 auto",
                  marginTop: miniSidenav ? 8 : 0,
                  filter:
                    "brightness( 1000% ) contrast( 100% ) saturate( 100% ) blur( 0px ) hue-rotate( 0deg )",
                }}
              />
            )}

            {brand && !disableTextLogo && (
              <MDBox component="img" src={brand} alt="Brand" width="2rem" />
            )}
            {!disableTextLogo && (
              <MDBox
                width={!brandName && "100%"}
                sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
              >
                <MDTypography
                  component="h6"
                  variant="button"
                  fontWeight="medium"
                  color={textColor}
                >
                  {brandName}
                </MDTypography>
              </MDBox>
            )}
          </MDBox>
        </MDBox>
        <Divider
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
        <List>
          <MDBox display="flex flex-col" alignItems="center">
            {renderExampleRoutes}
          </MDBox>
          <Divider
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />

          {!loading && <Menu routes={allRoutes} />}
          <a onClick={handleLogOut}>
            <SidenavCollapse name={"Logout"} icon={<LogoutIcon />} />
          </a>
        </List>
      </SidenavRoot>
    </>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
