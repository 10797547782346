import { Box, Button, Card, Grid, MenuItem, TextField } from "@mui/material";
import MDButton from "components/MDButton";
import { useFormik } from "formik";
import Service from "services/users";
import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import { yupSchema, initialValues, ROLES, findRole } from "./yupSchema";
import cepService from "services/cep.service";
import InputMask from "react-input-mask";

export default function AdicionarFuncionario({
  setShowAddState,
  fetchData,
  fromParent = false,
  selectParent = null,
  setShowAddFuncionario = null,
}) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [alertKey, setAlertKey] = useState(0);
  const [imgBase64, setImgBase64] = useState(null);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yupSchema,
    onSubmit: async (values) => {
      setLoading(true);

      try {
        const created = await Service.create(values);
        if (fromParent) {
          selectParent(created);
          setShowAddFuncionario(false);
        } else {
          formik.resetForm();
          setSuccess(true);
        }
      } catch (error) {
        console.log(error);
        setSuccess(false);
      }
      setAlertKey(alertKey + 1);
      setLoading(false);
    },
  });

  const pesquisaCep = async () => {
    const cep = formik.values.cep.replace(/[^0-9]/g, "");

    if (cep.length !== 8) return;

    const fetchCep = await cepService.get(cep);

    const mapping = {
      state: "uf",
      city: "cidade",
      neighborhood: "bairro",
      street: "logradouro",
    };

    Object.entries(mapping).forEach(([fetchKey, formikKey]) => {
      if (fetchCep[fetchKey] !== undefined) {
        formik.setFieldValue(formikKey, fetchCep[fetchKey]);
      }
    });
  };

  useEffect(() => {
    const closeOnEscapePressed = (e) => {
      if (e.key === "Escape") {
        fetchData();
        setSuccess(null);
        setShowAddState(false);
      }
    };
    window.addEventListener("keydown", closeOnEscapePressed);
    return () => window.removeEventListener("keydown", closeOnEscapePressed);
  }, []);

  return (
    <>
      <MDBox
        pt={"30px"}
        sx={{
          position: "absolute",
          zIndex: 9999,
          width: "100vw",
          minHeight: "100vh",
          top: 0,
          right: 0,
        }}
      >
        <MDBox
          pt={"30px"}
          sx={{
            position: "fixed",
            zIndex: -1,
            width: "100vw",
            height: "110%",
            backgroundColor: "rgba(0,0,0,0.5)",
            backdropFilter: "blur(5px)",
            top: 0,
            right: 0,
          }}
        />
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={8} mx={"auto"}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  Cadastrar Funcionário
                </MDTypography>
                <MDButton
                  variant="contained"
                  type="button"
                  onClick={() => {
                    fetchData();
                    setSuccess(null);
                    setShowAddState(false);
                  }}
                >
                  Voltar
                </MDButton>
              </MDBox>
              <MDBox pt={3} mx={2} py={3} px={2}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 2,
                    mb: 2,
                  }}
                  component="form"
                  onSubmit={formik.handleSubmit}
                >
                  <TextField
                    id="name"
                    name="name"
                    label="Nome"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />

                  <TextField
                    id="email"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />

                  <TextField
                    id="role"
                    name="role"
                    label="Acesso"
                    value={formik.values.role}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.role && Boolean(formik.errors.role)}
                    helperText={formik.touched.role && formik.errors.role}
                    select
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option aria-label="Selecione" value="" />
                    {Object.values(ROLES).map((role) => (
                      <option key={role} value={role}>
                        {findRole(role)}
                      </option>
                    ))}
                  </TextField>

                  <InputMask
                    mask={"999.999.999-99"}
                    value={formik.values.cpfCnpj}
                    onChange={formik.handleChange}
                  >
                    {(inputProps) => (
                      <TextField
                        id="cpf"
                        name="cpf"
                        label="CPF"
                        error={formik.touched.cpf && Boolean(formik.errors.cpf)}
                        helperText={formik.touched.cpf && formik.errors.cpf}
                        {...inputProps}
                      />
                    )}
                  </InputMask>

                  <TextField
                    id="dataNascimento"
                    name="dataNascimento"
                    label="Data de Nascimento"
                    type="date"
                    value={formik.values.dataNascimento}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.dataNascimento &&
                      Boolean(formik.errors.dataNascimento)
                    }
                    helperText={
                      formik.touched.dataNascimento &&
                      formik.errors.dataNascimento
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    id="logradouro"
                    name="logradouro"
                    label="Logradouro"
                    value={formik.values.logradouro}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.logradouro &&
                      Boolean(formik.errors.logradouro)
                    }
                    helperText={
                      formik.touched.logradouro && formik.errors.logradouro
                    }
                  />

                  <TextField
                    id="numero"
                    name="numero"
                    label="Número"
                    value={formik.values.numero}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.numero && Boolean(formik.errors.numero)
                    }
                    helperText={formik.touched.numero && formik.errors.numero}
                  />

                  <InputMask
                    mask="99.999-999"
                    value={formik.values.cep}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      formik.handleBlur(e);
                      pesquisaCep();
                    }}
                  >
                    {(inputProps) => (
                      <TextField
                        id="cep"
                        name="cep"
                        label="CEP"
                        error={formik.touched.cep && Boolean(formik.errors.cep)}
                        helperText={formik.touched.cep && formik.errors.cep}
                        {...inputProps}
                      />
                    )}
                  </InputMask>

                  <TextField
                    id="complemento"
                    name="complemento"
                    label="Complemento"
                    value={formik.values.complemento}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.complemento &&
                      Boolean(formik.errors.complemento)
                    }
                    helperText={
                      formik.touched.complemento && formik.errors.complemento
                    }
                  />

                  <TextField
                    id="bairro"
                    name="bairro"
                    label="Bairro"
                    value={formik.values.bairro}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.bairro && Boolean(formik.errors.bairro)
                    }
                    helperText={formik.touched.bairro && formik.errors.bairro}
                  />

                  <TextField
                    select
                    id="uf"
                    name="uf"
                    label="UF"
                    value={formik.values.uf}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.uf && Boolean(formik.errors.uf)}
                    helperText={formik.touched.uf && formik.errors.uf}
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option value=""></option>
                    <option value="AC">AC - Acre</option>
                    <option value="AL">AL - Alagoas</option>
                    <option value="AP">AP - Amapá</option>
                    <option value="AM">AM - Amazonas</option>
                    <option value="BA">BA - Bahia</option>
                    <option value="CE">CE - Ceará</option>
                    <option value="DF">DF - Distrito Federal</option>
                    <option value="ES">ES - Espírito Santo</option>
                    <option value="GO">GO - Goiás</option>
                    <option value="MA">MA - Maranhão</option>
                    <option value="MT">MT - Mato Grosso</option>
                    <option value="MS">MS - Mato Grosso do Sul</option>
                    <option value="MG">MG - Minas Gerais</option>
                    <option value="PA">PA - Pará</option>
                    <option value="PB">PB - Paraíba</option>
                    <option value="PR">PR - Paraná</option>
                    <option value="PE">PE - Pernambuco</option>
                    <option value="PI">PI - Piauí</option>
                    <option value="RJ">RJ - Rio de Janeiro</option>
                    <option value="RN">RN - Rio Grande do Norte</option>
                    <option value="RS">RS - Rio Grande do Sul</option>
                    <option value="RO">RO - Rondônia</option>
                    <option value="RR">RR - Roraima</option>
                    <option value="SC">SC - Santa Catarina</option>
                    <option value="SP">SP - São Paulo</option>
                    <option value="SE">SE - Sergipe</option>
                    <option value="TO">TO - Tocantins</option>
                  </TextField>

                  <InputMask
                    mask={
                      formik.values.telefone?.length > 14
                        ? "(99) 99999-9999"
                        : "(99) 9999-99999"
                    }
                    maskChar=""
                    value={formik.values.telefone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    {(inputProps) => (
                      <TextField
                        id="telefone"
                        name="telefone"
                        label="Telefone"
                        error={
                          formik.touched.telefone &&
                          Boolean(formik.errors.telefone)
                        }
                        helperText={
                          formik.touched.telefone && formik.errors.telefone
                        }
                        {...inputProps}
                      />
                    )}
                  </InputMask>

                  <InputMask
                    mask={
                      formik.values.celular?.length > 14
                        ? "(99) 99999-9999"
                        : "(99) 9999-99999"
                    }
                    maskChar=""
                    value={formik.values.celular}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    {(inputProps) => (
                      <TextField
                        id="celular"
                        name="celular"
                        label="Celular"
                        value={formik.values.celular}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.celular &&
                          Boolean(formik.errors.celular)
                        }
                        helperText={
                          formik.touched.celular && formik.errors.celular
                        }
                        {...inputProps}
                      />
                    )}
                  </InputMask>

                  <TextField
                    id="comissao"
                    name="comissao"
                    label="Comissão"
                    type="number"
                    value={formik.values.comissao}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.comissao && Boolean(formik.errors.comissao)
                    }
                    helperText={
                      formik.touched.comissao && formik.errors.comissao
                    }
                  />

                  <TextField
                    id="observacao"
                    name="observacao"
                    label="Observação"
                    value={formik.values.observacao}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.observacao &&
                      Boolean(formik.errors.observacao)
                    }
                    helperText={
                      formik.touched.observacao && formik.errors.observacao
                    }
                    multiline
                    rows={4}
                    sx={{
                      gridColumn: "span 2",
                      justifySelf: "center",
                      width: "100%",
                    }}
                  />

                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      gridColumn: "span 2",
                      justifySelf: "center",
                      width: "100%",
                      color: "white !important",
                    }}
                  >
                    {loading ? "Carregando..." : "Salvar"}
                  </Button>
                </Box>
                {success !== null && (
                  <Box>
                    <MDAlert
                      color={success ? "success" : "error"}
                      mt={4}
                      dismissible
                      key={alertKey}
                    >
                      <MDTypography variant="body2" color="white">
                        {success
                          ? "Funcionário cadastrado com sucesso!"
                          : "Ocorreu um erro ao cadastrar o funcionário."}
                      </MDTypography>
                    </MDAlert>
                  </Box>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
