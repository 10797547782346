import { Alert, Box, Button, Card, Grid, Icon, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useFormik } from "formik";
import AdicionarCliente from "layouts/clientes";
import AdicionarFornecedor from "layouts/fornecedores";
import AdicionarCentroCusto from "layouts/grupos-contas";
import { useState } from "react";
import { BILLING_STATUS, findBillingStatus } from "./components/yupSchema";
import { useEffect } from "react";
import CurrencyInput from "react-currency-input-field";

const initialValues = {
  numPedido: "",
  startDate: "",
  endDate: "",
  customer: null,
  supplier: null,
  accountGroup: null,
  status: "",
  vencendoHoje: null,
};

export default function Filter({
  Service,
  resetRows,
  updateRows,
  setIsFiltered,
  setShowFilterState,
  isContaReceber = false,
  setCurrentFilter = () => {},
}) {
  const [loading, setLoading] = useState(false);

  // forms
  const [showAddFornecedor, setShowAddFornecedor] = useState(false);
  const [showAddCliente, setShowAddCliente] = useState(false);
  const [showAddCentroCusto, setShowAddCentroCusto] = useState(false);
  const [childData, setChildData] = useState({});

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      setLoading(true);

      try {
        if (!Service) {
          setLoading(false);
          return;
        }

        setCurrentFilter({
          ...values,
          customer: values.customer?._id || values.customer,
          supplier: values.supplier?._id || values.supplier,
        });

        const rows = await Service.filter({
          ...values,
          customer: values.customer?._id || values.customer,
          supplier: values.supplier?._id || values.supplier,
        });

        formik.resetForm();

        updateRows(rows);
        setIsFiltered(true);
        setLoading(false);
        setShowFilterState(false);
      } catch (error) {
        console.log(error);
      }

      setLoading(false);
    },
  });

  const selectParent = (parent, value) => {
    formik.setFieldValue(parent, value);
  };

  const changeCurrency = (value, name) => {
    formik.setFieldValue(name, value);
  };

  const addZero = (e) => {
    const { name, value } = e.target;
    if (value === "") {
      formik.setFieldValue(name, "0,00");
    } else {
      const removeRS = value.replace("R$", "").trim();
      const removeDots = removeRS.replace(/\./g, "");

      if (value.indexOf(",") === -1) {
        formik.setFieldValue(name, `${removeDots},00`);
      }
    }
  };

  useEffect(() => {
    const closeOnEscapePressed = (e) => {
      if (e.key === "Escape") {
        setShowFilterState(false);
      }
    };
    window.addEventListener("keydown", closeOnEscapePressed);
    return () => window.removeEventListener("keydown", closeOnEscapePressed);
  }, []);

  useEffect(() => {
    if (formik.values?.todasGenesis && formik.values.todasGenesis === "S") {
      setChildData({
        ...childData,
        accountGroup: null,
      });
      formik.setFieldValue("accountGroup", null);
    }
  }, [formik.values.todasGenesis]);

  return (
    <>
      <MDBox
        pt={"30px"}
        pb={"5%"}
        sx={{
          position: "absolute",
          zIndex: 9999,
          width: "100vw",
          minHeight: "100vh",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.5)",
          backdropFilter: "blur(5px)",
          top: 0,
          right: 0,
          overflow: "auto",
        }}
      >
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={6} mx={"auto"}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  Filtrar Contas à {isContaReceber ? "Receber" : "Pagar"}
                </MDTypography>

                <div>
                  <MDButton
                    variant="contained"
                    type="button"
                    onClick={() => {
                      resetRows();
                      setIsFiltered(false);
                      setShowFilterState(false);
                    }}
                    sx={{
                      mr: 2,
                    }}
                  >
                    Limpar Filtro
                  </MDButton>
                  <MDButton
                    variant="contained"
                    type="button"
                    onClick={() => {
                      setShowFilterState(false);
                    }}
                  >
                    Voltar
                  </MDButton>
                </div>
              </MDBox>
              <MDBox pt={3} mx={2} py={3} px={2}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 2,
                    mb: 2,
                  }}
                  component="form"
                  onSubmit={formik.handleSubmit}
                >
                  <TextField
                    id="numPedido"
                    name="numPedido"
                    label="Número do Pedido"
                    type="number"
                    value={formik.values.numPedido}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={loading}
                    error={
                      formik.touched.numPedido &&
                      Boolean(formik.errors.numPedido)
                    }
                    helperText={
                      formik.touched.numPedido && formik.errors.numPedido
                    }
                    sx={{
                      width: "100%",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    id="status"
                    name="status"
                    label="Status"
                    value={formik.values.status}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={loading}
                    error={
                      formik.touched.status && Boolean(formik.errors.status)
                    }
                    helperText={formik.touched.status && formik.errors.status}
                    sx={{
                      width: "100%",
                    }}
                    select
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option aria-label="Selecione" value="" />
                    {Object.values(BILLING_STATUS).map((opcao) => (
                      <option key={opcao} value={opcao}>
                        {findBillingStatus(opcao)}
                      </option>
                    ))}
                  </TextField>

                  <TextField
                    id="startDate"
                    name="startDate"
                    label="Data Inicial do Pedido"
                    type="date"
                    value={formik.values.startDate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={loading}
                    error={
                      formik.touched.startDate &&
                      Boolean(formik.errors.startDate)
                    }
                    helperText={
                      formik.touched.startDate && formik.errors.startDate
                    }
                    sx={{
                      width: "100%",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    id="endDate"
                    name="endDate"
                    label="Data Final do Pedido"
                    type="date"
                    value={formik.values.endDate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={loading}
                    error={
                      formik.touched.endDate && Boolean(formik.errors.endDate)
                    }
                    helperText={formik.touched.endDate && formik.errors.endDate}
                    sx={{
                      width: "100%",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    id="startDatePayment"
                    name="startDatePayment"
                    label="Data Inicial do Pagamento"
                    type="date"
                    value={formik.values.startDatePayment}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={loading}
                    error={
                      formik.touched.startDatePayment &&
                      Boolean(formik.errors.startDatePayment)
                    }
                    helperText={
                      formik.touched.startDatePayment &&
                      formik.errors.startDatePayment
                    }
                    sx={{
                      width: "100%",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    id="endDatePayment"
                    name="endDatePayment"
                    label="Data Final do Pagamento"
                    type="date"
                    value={formik.values.endDatePayment}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={loading}
                    error={
                      formik.touched.endDatePayment &&
                      Boolean(formik.errors.endDatePayment)
                    }
                    helperText={
                      formik.touched.endDatePayment &&
                      formik.errors.endDatePayment
                    }
                    sx={{
                      width: "100%",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    id="startDateExpires"
                    name="startDateExpires"
                    label="Data Inicial do Vencimento"
                    type="date"
                    value={formik.values.startDateExpires}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={loading}
                    error={
                      formik.touched.startDateExpires &&
                      Boolean(formik.errors.startDateExpires)
                    }
                    helperText={
                      formik.touched.startDateExpires &&
                      formik.errors.startDateExpires
                    }
                    sx={{
                      width: "100%",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    id="endDateExpires"
                    name="endDateExpires"
                    label="Data Final do Vencimento"
                    type="date"
                    value={formik.values.endDateExpires}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={loading}
                    error={
                      formik.touched.endDateExpires &&
                      Boolean(formik.errors.endDateExpires)
                    }
                    helperText={
                      formik.touched.endDateExpires &&
                      formik.errors.endDateExpires
                    }
                    sx={{
                      width: "100%",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <CurrencyInput
                    id="valor"
                    name="valor"
                    label="Valor"
                    value={formik.values.valor}
                    onBlur={formik.handleBlur}
                    disabled={loading}
                    decimalsLimit={2}
                    onValueChange={changeCurrency}
                    onBlurCapture={addZero}
                    customInput={TextField}
                    error={formik.touched.valor && Boolean(formik.errors.valor)}
                    helperText={formik.touched.valor && formik.errors.valor}
                    sx={{
                      width: "100%",
                    }}
                    prefix="R$ "
                    intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                  />

                  <TextField
                    id="vencendoHoje"
                    name="vencendoHoje"
                    label="Vencendo Hoje"
                    value={childData.vencendoHoje}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={loading}
                    error={
                      formik.touched.vencendoHoje &&
                      Boolean(formik.errors.vencendoHoje)
                    }
                    helperText={
                      formik.touched.vencendoHoje && formik.errors.vencendoHoje
                    }
                    sx={{
                      width: "100%",
                    }}
                    select
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option aria-label="Selecione" value="" />
                    <option value={"S"}>Sim</option>
                    <option value={"N"}>Não</option>
                  </TextField>

                  <Alert
                    icon={false}
                    severity="success"
                    action={
                      <MDButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        iconOnly
                        onClick={() => setShowAddCentroCusto(true)}
                      >
                        <Icon fontSize="small">edit</Icon>
                      </MDButton>
                    }
                  >
                    <b>Centro de Custo: </b>
                    {childData.accountGroup?.descricao}
                  </Alert>

                  {isContaReceber && (
                    <Alert
                      icon={false}
                      severity="success"
                      action={
                        <MDButton
                          variant="gradient"
                          color="info"
                          fullWidth
                          iconOnly
                          onClick={() => setShowAddCliente(true)}
                        >
                          <Icon fontSize="small">edit</Icon>
                        </MDButton>
                      }
                    >
                      <b>Cliente: </b>
                      {childData.customer?.razaoSocial ||
                        childData.customer?.nomeFantasia}
                    </Alert>
                  )}

                  {!isContaReceber && (
                    <Alert
                      icon={false}
                      severity="success"
                      action={
                        <MDButton
                          variant="gradient"
                          color="info"
                          fullWidth
                          iconOnly
                          onClick={() => setShowAddFornecedor(true)}
                        >
                          <Icon fontSize="small">edit</Icon>
                        </MDButton>
                      }
                    >
                      <b>Fornecedor: </b>
                      {childData.supplier?.razaoSocial ||
                        childData.supplier?.nomeFantasia}
                    </Alert>
                  )}

                  {!isContaReceber && (
                    <TextField
                      id="fromNfe"
                      name="fromNfe"
                      label="Importado de Nota Fiscal"
                      value={formik.values.fromNfe}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={loading}
                      error={
                        formik.touched.fromNfe && Boolean(formik.errors.fromNfe)
                      }
                      helperText={
                        formik.touched.fromNfe && formik.errors.fromNfe
                      }
                      sx={{
                        width: "100%",
                      }}
                      select
                      SelectProps={{
                        native: true,
                      }}
                    >
                      <option aria-label="Selecione" value="" />
                      <option value={"S"}>Sim</option>
                      <option value={"N"}>Não</option>
                    </TextField>
                  )}

                  {!isContaReceber && (
                    <TextField
                      id="todasGenesis"
                      name="todasGenesis"
                      label="Todas as Genesis"
                      value={formik.values.todasGenesis}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={loading}
                      error={
                        formik.touched.todasGenesis &&
                        Boolean(formik.errors.todasGenesis)
                      }
                      helperText={
                        formik.touched.todasGenesis &&
                        formik.errors.todasGenesis
                      }
                      sx={{
                        width: "100%",
                      }}
                      select
                      SelectProps={{
                        native: true,
                      }}
                    >
                      <option aria-label="Selecione" value="" />
                      <option value={"S"}>Sim</option>
                      <option value={"N"}>Não</option>
                    </TextField>
                  )}

                  <Button
                    type="submit"
                    variant="contained"
                    disabled={loading}
                    sx={{
                      gridColumn: "span 2",
                      justifySelf: "center",
                      width: "100%",
                      color: "white !important",
                    }}
                  >
                    {loading ? "Carregando..." : "Filtrar"}
                  </Button>
                </Box>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {/* forms */}
      {showAddFornecedor && (
        <MDBox
          pt={"30px"}
          pb={"5%"}
          px={"5%"}
          sx={{
            position: "absolute",
            zIndex: 9999,
            width: "100vw",
            minHeight: "100vh",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            backdropFilter: "blur(5px)",
            top: 0,
            right: 0,
            overflow: "auto",
          }}
        >
          <AdicionarFornecedor
            fromParent={true}
            selectParent={(value) => {
              setChildData((prevState) =>
                Object({ ...prevState, supplier: value })
              );
              selectParent("supplier", value?._id);
            }}
            setShowAddFornecedor={setShowAddFornecedor}
          />
        </MDBox>
      )}

      {showAddCentroCusto && (
        <MDBox
          pt={"30px"}
          pb={"5%"}
          px={"5%"}
          sx={{
            position: "absolute",
            zIndex: 9999,
            width: "100vw",
            minHeight: "100vh",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            backdropFilter: "blur(5px)",
            top: 0,
            right: 0,
            overflow: "auto",
          }}
        >
          <AdicionarCentroCusto
            fromParent={true}
            selectParent={(value) => {
              setChildData((prevState) =>
                Object({ ...prevState, accountGroup: value })
              );
              selectParent("accountGroup", value?._id);
            }}
            setShowAddCentroCusto={setShowAddCentroCusto}
          />
        </MDBox>
      )}

      {showAddCliente && (
        <MDBox
          pt={"30px"}
          pb={"5%"}
          px={"5%"}
          sx={{
            position: "absolute",
            zIndex: 9999,
            width: "100vw",
            minHeight: "100vh",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            backdropFilter: "blur(5px)",
            top: 0,
            right: 0,
            overflow: "auto",
          }}
        >
          <AdicionarCliente
            fromParent={true}
            selectParent={(value) => {
              setChildData((prevState) =>
                Object({ ...prevState, customer: value })
              );
              selectParent("customer", value?._id);
            }}
            setShowAddCliente={setShowAddCliente}
          />
        </MDBox>
      )}
    </>
  );
}
