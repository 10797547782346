import { Box, Grid, Card, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Service from "services/warehouses";
import AccountSubgroupService from "services/sub-group-accounts";
import AccountGroupsService from "services/account-groups";
import { yupSchema, initialValues } from "./yupSchema";
import { format } from "utils/formatDate";

export default function DetalharDeposito({ setShowDetailState, currentIdAction, fetchData }) {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yupSchema,
  });

  useEffect(() => {
    const fetchList = async () => {
      setLoading(true);
      const fields = await Service.get(currentIdAction);

      for (var key in formik.values) {
        formik.setFieldValue(key, fields[key]);
      }
      setLoading(false);
    };

    fetchList();
  }, [currentIdAction]);

  useEffect(() => {
    const closeOnEscapePressed = (e) => {
      if (e.key === "Escape") {
        fetchData();
        setShowDetailState(false);
      }
    };
    window.addEventListener("keydown", closeOnEscapePressed);
    return () => window.removeEventListener("keydown", closeOnEscapePressed);
  }, []);

  return (
    <>
      <MDBox
        pt={"30px"}
        sx={{
          position: "absolute",
          zIndex: 9999,
          width: "100vw",
          minHeight: "100vh",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.5)",
          backdropFilter: "blur(5px)",
          top: 0,
          right: 0,
        }}
      >
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={8} mx={"auto"}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  Detalhes
                </MDTypography>
                <MDButton
                  variant="contained"
                  type="button"
                  onClick={() => {
                    fetchData();
                    setShowDetailState(false);
                  }}
                >
                  Fechar
                </MDButton>
              </MDBox>
              <MDBox pt={3} mx={2} py={3} px={2}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 2,
                    mb: 2,
                  }}
                  component="form"
                  onSubmit={formik.handleSubmit}
                >
                  <TextField
                    id="descricao"
                    name="descricao"
                    label="Nome do Depósito"
                    value={formik.values.descricao}
                    InputProps={{
                      readOnly: true,
                    }}
                  />

                  <TextField
                    id="responsavel"
                    name="responsavel"
                    label="Responsável pelo Depósito"
                    value={formik.values.responsavel}
                    InputProps={{
                      readOnly: true,
                    }}
                  />

                  <TextField
                    id="cep"
                    name="cep"
                    label="CEP"
                    value={formik.values.cep}
                    InputProps={{
                      readOnly: true,
                    }}
                  />

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="uf"
                    name="uf"
                    label="UF"
                    value={formik.values.uf}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />

                  <TextField
                    id="endereco"
                    name="endereco"
                    label="Endereço"
                    value={formik.values.endereco}
                    InputProps={{
                      readOnly: true,
                    }}
                  />

                  <TextField
                    id="cidade"
                    name="cidade"
                    label="Cidade"
                    value={formik.values.cidade}
                    InputProps={{
                      readOnly: true,
                    }}
                  />

                  <TextField
                    id="celular"
                    name="celular"
                    label="Contato"
                    value={formik.values.celular}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Box>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
