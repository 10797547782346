import React, { memo } from "react";
import { Paper, Typography, Box } from "@mui/material";
import { Droppable } from "react-beautiful-dnd";
import Task from "./Task";

const Column = memo(({ column, tasks }) => {
  return (
    <Paper elevation={3} sx={{ p: 2, width: 300, minHeight: 500, mx: 1 }}>
      <Typography variant="h6">
        {column.title} ({tasks.length})
      </Typography>

      <Droppable droppableId={column.id}>
        {(provided) => (
          <Box
            {...provided.droppableProps}
            ref={provided.innerRef}
            sx={{
              minHeight: "inherit",
              mt: 2,
              maxHeight: 500,
              overflowY: "auto",
            }}
          >
            {tasks.map((task, index) => (
              <Task key={task.id} task={task} index={index} />
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </Paper>
  );
});

export default Column;
