import { Alert, Box, Button, Card, Grid, Icon, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useFormik } from "formik";
import AdicionarCliente from "layouts/clientes";
import AdicionarFornecedor from "layouts/fornecedores";
import AdicionarFuncionario from "layouts/funcionarios";
import { useEffect, useState } from "react";
import { STATUS_PEDIDO, findStatusPedido } from "./yupSchema";

const initialValues = {
  numPedido: "",
  startDate: "",
  endDate: "",
  customer: null,
  supplier: null,
  user: null,
  status: "",
  aguardandoFechamento: null,
  aguardandoAcerto: null,
};

export default function Filter({
  Service,
  resetRows,
  updateRows,
  setIsFiltered,
  setShowFilterState,
  isPedidoVenda = false,
  setCurrentFilter = () => {},
}) {
  const [loading, setLoading] = useState(false);

  // forms
  const [showAddFornecedor, setShowAddFornecedor] = useState(false);
  const [showAddFuncionario, setShowAddFuncionario] = useState(false);
  const [showAddCliente, setShowAddCliente] = useState(false);

  const [childData, setChildData] = useState({});

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      setLoading(true);

      try {
        if (!Service) {
          setLoading(false);
          return;
        }

        setCurrentFilter({
          ...values,
          customer: values.customer?._id || values.customer,
          supplier: values.supplier?._id || values.supplier,
          user: values.user?._id || values.user,
        });

        const rows = await Service.filter({
          ...values,
          customer: values.customer?._id || values.customer,
          supplier: values.supplier?._id || values.supplier,
          user: values.user?._id || values.user,
        });

        formik.resetForm();

        updateRows(rows);
        setIsFiltered(true);
        setLoading(false);
        setShowFilterState(false);
      } catch (error) {
        console.log(error);
      }

      setLoading(false);
    },
  });

  const selectParent = (parent, value) => {
    formik.setFieldValue(parent, value);
  };

  useEffect(() => {
    const closeOnEscapePressed = (e) => {
      if (e.key === "Escape") {
        if (showAddFornecedor) setShowAddFornecedor(false);
        if (showAddCliente) setShowAddCliente(false);
        if (showAddFuncionario) setShowAddFuncionario(false);

        if (!showAddFornecedor && !showAddCliente && !showAddFuncionario) {
          setShowFilterState(false);
        }
      }
    };
    window.addEventListener("keydown", closeOnEscapePressed);
    return () => window.removeEventListener("keydown", closeOnEscapePressed);
  }, [showAddFornecedor, showAddCliente, showAddFuncionario]);

  return (
    <>
      <MDBox
        pt={"30px"}
        pb={"5%"}
        sx={{
          position: "absolute",
          zIndex: 9999,
          width: "100vw",
          minHeight: "100vh",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.5)",
          backdropFilter: "blur(5px)",
          top: 0,
          right: 0,
          overflow: "auto",
        }}
      >
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={6} mx={"auto"}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  Filtrar Pedidos de {isPedidoVenda ? "Venda" : "Compra"}
                </MDTypography>

                <div>
                  <MDButton
                    variant="contained"
                    type="button"
                    onClick={() => {
                      resetRows();
                      setIsFiltered(false);
                      setShowFilterState(false);
                    }}
                    sx={{
                      mr: 2,
                    }}
                  >
                    Limpar Filtro
                  </MDButton>
                  <MDButton
                    variant="contained"
                    type="button"
                    onClick={() => {
                      setShowFilterState(false);
                    }}
                  >
                    Voltar
                  </MDButton>
                </div>
              </MDBox>
              <MDBox pt={3} mx={2} py={3} px={2}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 2,
                    mb: 2,
                  }}
                  component="form"
                  onSubmit={formik.handleSubmit}
                >
                  <TextField
                    id="numPedido"
                    name="numPedido"
                    label="Número do Pedido"
                    type="number"
                    value={formik.values.numPedido}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={loading}
                    error={
                      formik.touched.numPedido &&
                      Boolean(formik.errors.numPedido)
                    }
                    helperText={
                      formik.touched.numPedido && formik.errors.numPedido
                    }
                    sx={{
                      width: "100%",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    id="status"
                    name="status"
                    label="Status"
                    value={formik.values.status}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={loading}
                    error={
                      formik.touched.status && Boolean(formik.errors.status)
                    }
                    helperText={formik.touched.status && formik.errors.status}
                    sx={{
                      width: "100%",
                    }}
                    select
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option aria-label="Selecione" value="" />
                    {Object.values(STATUS_PEDIDO).map((opcao) => (
                      <option key={opcao} value={opcao}>
                        {findStatusPedido(opcao)}
                      </option>
                    ))}
                  </TextField>

                  <TextField
                    id="startDate"
                    name="startDate"
                    label="Data Inicial"
                    type="date"
                    value={formik.values.startDate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={loading}
                    error={
                      formik.touched.startDate &&
                      Boolean(formik.errors.startDate)
                    }
                    helperText={
                      formik.touched.startDate && formik.errors.startDate
                    }
                    sx={{
                      width: "100%",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    id="endDate"
                    name="endDate"
                    label="Data Final"
                    type="date"
                    value={formik.values.endDate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={loading}
                    error={
                      formik.touched.endDate && Boolean(formik.errors.endDate)
                    }
                    helperText={formik.touched.endDate && formik.errors.endDate}
                    sx={{
                      width: "100%",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    id="aguardandoFechamento"
                    name="aguardandoFechamento"
                    label="Aguardando Fechamento"
                    value={formik.values.aguardandoFechamento}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={loading}
                    error={
                      formik.touched.aguardandoFechamento &&
                      Boolean(formik.errors.aguardandoFechamento)
                    }
                    helperText={
                      formik.touched.aguardandoFechamento &&
                      formik.errors.aguardandoFechamento
                    }
                    sx={{
                      width: "100%",
                    }}
                    select
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option aria-label="Selecione" value="" />
                    <option value={"S"}>Sim</option>
                    <option value={"N"}>Não</option>
                  </TextField>

                  {!isPedidoVenda && (
                    <TextField
                      id="aguardandoAcerto"
                      name="aguardandoAcerto"
                      label="Aguardando Acerto"
                      value={formik.values.aguardandoAcerto}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={loading}
                      error={
                        formik.touched.aguardandoAcerto &&
                        Boolean(formik.errors.aguardandoAcerto)
                      }
                      helperText={
                        formik.touched.aguardandoAcerto &&
                        formik.errors.aguardandoAcerto
                      }
                      sx={{
                        width: "100%",
                      }}
                      select
                      SelectProps={{
                        native: true,
                      }}
                    >
                      <option aria-label="Selecione" value="" />
                      <option value={"S"}>Sim</option>
                      <option value={"N"}>Não</option>
                    </TextField>
                  )}

                  {isPedidoVenda && (
                    <Alert
                      icon={false}
                      severity="success"
                      action={
                        <MDButton
                          variant="gradient"
                          color="info"
                          fullWidth
                          iconOnly
                          onClick={() => setShowAddCliente(true)}
                        >
                          <Icon fontSize="small">edit</Icon>
                        </MDButton>
                      }
                    >
                      <b>Cliente: </b>
                      {childData.customer?.razaoSocial ||
                        childData.customer?.nomeFantasia}
                    </Alert>
                  )}

                  {!isPedidoVenda && (
                    <Alert
                      icon={false}
                      severity="success"
                      action={
                        <MDButton
                          variant="gradient"
                          color="info"
                          fullWidth
                          iconOnly
                          onClick={() => setShowAddFornecedor(true)}
                        >
                          <Icon fontSize="small">edit</Icon>
                        </MDButton>
                      }
                    >
                      <b>Fornecedor: </b>
                      {childData.supplier?.razaoSocial ||
                        childData.supplier?.nomeFantasia}
                    </Alert>
                  )}

                  <Alert
                    icon={false}
                    severity="success"
                    action={
                      <MDButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        iconOnly
                        onClick={() => setShowAddFuncionario(true)}
                      >
                        <Icon fontSize="small">edit</Icon>
                      </MDButton>
                    }
                  >
                    <b>{isPedidoVenda ? "Vendedor" : "Comprador"}: </b>
                    {childData.user?.name}
                  </Alert>

                  <Button
                    type="submit"
                    variant="contained"
                    disabled={loading}
                    sx={{
                      gridColumn: "span 2",
                      justifySelf: "center",
                      width: "100%",
                      color: "white !important",
                    }}
                  >
                    {loading ? "Carregando..." : "Filtrar"}
                  </Button>
                </Box>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {/* forms */}
      {showAddFornecedor && (
        <MDBox
          pt={"30px"}
          pb={"5%"}
          px={"5%"}
          sx={{
            position: "absolute",
            zIndex: 9999,
            width: "100vw",
            minHeight: "100vh",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            backdropFilter: "blur(5px)",
            top: 0,
            right: 0,
            overflow: "auto",
          }}
        >
          <AdicionarFornecedor
            fromParent={true}
            selectParent={(value) => {
              setChildData((prevState) =>
                Object({ ...prevState, supplier: value })
              );
              selectParent("supplier", value?._id);
            }}
            setShowAddFornecedor={setShowAddFornecedor}
          />
        </MDBox>
      )}

      {showAddCliente && (
        <MDBox
          pt={"30px"}
          pb={"5%"}
          px={"5%"}
          sx={{
            position: "absolute",
            zIndex: 9999,
            width: "100vw",
            minHeight: "100vh",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            backdropFilter: "blur(5px)",
            top: 0,
            right: 0,
            overflow: "auto",
          }}
        >
          <AdicionarCliente
            fromParent={true}
            selectParent={(value) => {
              setChildData((prevState) =>
                Object({ ...prevState, customer: value })
              );
              selectParent("customer", value?._id);
            }}
            setShowAddCliente={setShowAddCliente}
          />
        </MDBox>
      )}

      {showAddFuncionario && (
        <MDBox
          pt={"30px"}
          pb={"5%"}
          px={"5%"}
          sx={{
            position: "absolute",
            zIndex: 9999,
            width: "100vw",
            minHeight: "100vh",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            backdropFilter: "blur(5px)",
            top: 0,
            right: 0,
            overflow: "auto",
          }}
        >
          <AdicionarFuncionario
            fromParent={true}
            selectParent={(value) => {
              setChildData((prevState) =>
                Object({ ...prevState, user: value })
              );
              selectParent("user", value?._id);
            }}
            setShowAddFuncionario={setShowAddFuncionario}
          />
        </MDBox>
      )}
    </>
  );
}
