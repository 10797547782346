import { Icon, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import Service from "services/products";

export default function data(handleEdit, handleDelete, handleDetail, fromParent = false, selectParent = null, setShowAddProduto = null, setLoading = () => {}, onlyAlmoxarifado = false) {
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [sortStr, setSortStr] = useState(null);
  const [filterStr, setFilterStr] = useState(null);

  const fetchData = async () => {
    setLoading(true);

    const { data, limit, total } = await Service.paginate(
      page,
      perPage,
      sortStr,
      onlyAlmoxarifado ? "&filterField=productGroup&filterValue=653bece726a3aebb3a534746&filterOperator=equals" : filterStr
    );

    setPerPage(limit);
    setTotalRows(total);

    setRows(
      data.map((row) =>
        Object({
          id: row._id,
          action: row,
          codigoInterno: row.codigoInterno,
          referencia: row.referencia,
          descricao: row.descricao,
          precoCompra: Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(row.precoCompra),
          precoVenda: Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(row.precoVenda),
          productGroup: row.productGroup?.descricao,
        })
      )
    );

    setLoading(false);
  };

  const action = (props) => {
    const row = props?.row?.action;

    return (
      <MDBox display="flex" gap={1}>
        {fromParent && (
          <MDButton
            variant="gradient"
            color="primary"
            fullWidth
            type="submit"
            onClick={() => {
              selectParent(row);
              setShowAddProduto(false);
            }}
            iconOnly
          >
            <Icon fontSize="small">check</Icon>
          </MDButton>
        )}

        <Tooltip title="Editar">
          <MDButton variant="gradient" color="info" fullWidth type="submit" onClick={() => handleEdit(row._id)} iconOnly>
            <Icon fontSize="small">edit</Icon>
          </MDButton>
        </Tooltip>

        <Tooltip title="Detalhes">
          <MDButton variant="gradient" color="success" fullWidth type="submit" onClick={() => handleDetail(row._id)} iconOnly>
            <Icon fontSize="small">visibility</Icon>
          </MDButton>
        </Tooltip>

        <Tooltip title="Remover">
          <MDButton variant="gradient" color="error" fullWidth type="submit" onClick={() => handleDelete(row)} iconOnly>
            <Icon fontSize="small">delete_forever</Icon>
          </MDButton>
        </Tooltip>
      </MDBox>
    );
  };

  const updatePagination = ({ page: newPage, pageSize: newPerPage }) => {
    setPage(newPage);
    setPerPage(newPerPage);
  };

  const sortBy = (sortArr) => {
    if (sortArr.length === 0) {
      setSortStr(null);
      return;
    }

    const [filter] = sortArr;

    setSortStr(`&sortBy=${filter.field}&sortOrder=${filter.sort === "asc" ? 1 : "-1"}`);
  };

  const filterBy = ({ items }) => {
    if (items.length === 0) {
      setFilterStr(null);
      return;
    }

    const [filter] = items;

    if (filter.operator === "contains" && filter.value === undefined) {
      return;
    }

    setFilterStr(`&filterField=${filter.field}&filterOperator=${filter.operator}&filterValue=${filter.value}`);
  };

  useEffect(() => {
    fetchData();
  }, [page, perPage, sortStr, filterStr]);

  return {
    columns: [
      { headerName: "Código Interno", field: "codigoInterno", flex: 0.14 },
      // { headerName: "Referência", field: "referencia", flex: 0.14 },
      { headerName: "Descrição", field: "descricao", flex: 0.14 },
      // { headerName: "Preço de Compra", field: "precoCompra", flex: 0.14 },
      // { headerName: "Preço de Venda", field: "precoVenda", flex: 0.14 },
      { headerName: "Grupo de Produto", field: "productGroup", flex: 0.14 },
      { headerName: "Ações", field: "action", flex: 0.14, renderCell: action },
    ],

    rows,
    fetchData,
    updatePagination,
    sortBy,
    filterBy,
    pageObject: {
      totalRows,
      perPage,
      page,
    },
  };
}
