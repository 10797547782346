import { Box, Card, Grid, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useEffect, useState } from "react";
import tableData from "./tableData";

import { DataGrid } from "@mui/x-data-grid";
import ProductService from "services/products";
import PermissionService from "services/permission";
import { ptBR_DataGrid } from "utils/datagrid";
import MDDialog from "components/MDDialog";
import MDButton from "components/MDButton";
import { AREAS } from "layouts/permissoes/components/yupSchema";

export default function LotesProdutos() {
  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const [currentEdit, setCurrentEdit] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [currentDescarte, setCurrentDescarte] = useState(null);

  const handleEdit = (obj) => {
    setCurrentEdit(obj);
    setCurrentDescarte(obj.quantidadeDescartada);
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
    setCurrentDescarte(0);
    setCurrentEdit(null);
  };

  const confirmUpdate = async () => {
    setLoading(true);

    try {
      const objUpdate = {
        quantidade: currentEdit.quantidade,
        quantidadeDescartada: currentDescarte,
      };

      await ProductService.updateBatch(currentEdit.id, objUpdate);
      await fetchData();
      closeModal();
    } catch (error) {
      alert("Erro ao atualizar lote");
      await fetchData();
      closeModal();
    }

    setLoading(false);
  };

  const {
    columns,
    rows,
    fetchData,
    updatePagination,
    sortBy,
    filterBy,
    pageObject,
  } = tableData(handleEdit, isAdmin, setLoading);

  useEffect(() => {
    const fetchIsAdmin = async () => {
      const permissions = await PermissionService.listMy();
      const permissionsFiltered = permissions.filter(
        (permission) => permission.area === AREAS.PRODUCT
      );

      setIsAdmin(permissionsFiltered.shift()?.editaAdmin === "1");
    };

    fetchIsAdmin();
  }, []);

  return (
    <DashboardLayout>
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  Lotes de Produtos (Prime Plus)
                </MDTypography>
              </MDBox>

              <MDBox pt={3} mx={2} py={3} px={2}>
                <Box
                  sx={{
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 2,
                    mb: 2,
                  }}
                >
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    rowCount={pageObject.totalRows}
                    pageSizeOptions={[50, 100]}
                    paginationModel={{
                      pageSize: pageObject.perPage,
                      page: pageObject.page,
                    }}
                    paginationMode="server"
                    onPaginationModelChange={updatePagination}
                    sortingMode="server"
                    onSortModelChange={sortBy}
                    filterMode="server"
                    onFilterModelChange={filterBy}
                    rowSelection={false}
                    loading={loading}
                    localeText={ptBR_DataGrid}
                    sx={{
                      ".MuiDataGrid-virtualScroller": {
                        minHeight: "100px",
                      },
                    }}
                  />
                </Box>
              </MDBox>
            </Card>
          </Grid>
        </Grid>

        <MDDialog
          open={openModal}
          handleClose={() => closeModal()}
          title={`Informe a quantidade de descarte para o lote ${
            currentEdit?.lote || ""
          }`}
          actions={
            <>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                type="submit"
                onClick={() => confirmUpdate()}
                disabled={
                  currentDescarte <= 0 ||
                  currentDescarte < currentEdit?.quantidadeDescartada ||
                  loading
                }
              >
                Confirmar
              </MDButton>
              <MDButton
                variant="gradient"
                color="error"
                fullWidth
                type="submit"
                onClick={() => closeModal()}
              >
                Cancelar
              </MDButton>
            </>
          }
        >
          <TextField
            id="descarte"
            name="descarte"
            label="Descarte"
            type="number"
            value={currentDescarte}
            onChange={(e) => setCurrentDescarte(e.target.value)}
            error={currentDescarte < 0}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ mt: 1, width: "100%" }}
            InputProps={{
              inputProps: {
                min: currentEdit?.quantidadeDescartada || 0,
                max: currentEdit?.quantidade || 0,
              },
            }}
          />
        </MDDialog>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}
