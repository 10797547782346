import { Icon, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { findCompany } from "layouts/pedidos-venda/components/yupSchema";
import { useEffect, useState } from "react";
import Service from "services/last-nfe-numbers";

export default function data(handleEdit, setLoading = () => {}) {
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [sortStr, setSortStr] = useState(null);
  const [filterStr, setFilterStr] = useState(null);

  const fetchData = async () => {
    setLoading(true);

    const { data } = await Service.list();

    setRows(
      data.map((row) =>
        Object({
          id: row._id,
          action: row,
          number: row.number,
          company: findCompany(row.company),
        })
      )
    );

    setLoading(false);
  };

  const action = (props) => {
    const row = props?.row?.action;

    return (
      <MDBox display="flex" gap={1}>
        <Tooltip title="Editar">
          <MDButton variant="gradient" color="info" fullWidth type="submit" onClick={() => handleEdit(row._id)} iconOnly>
            <Icon fontSize="small">edit</Icon>
          </MDButton>
        </Tooltip>
      </MDBox>
    );
  };

  const updatePagination = ({ page: newPage, pageSize: newPerPage }) => {
    setPage(newPage);
    setPerPage(newPerPage);
  };

  const sortBy = (sortArr) => {
    if (sortArr.length === 0) {
      setSortStr(null);
      return;
    }

    const [filter] = sortArr;

    setSortStr(`&sortBy=${filter.field}&sortOrder=${filter.sort === "asc" ? 1 : "-1"}`);
  };

  const filterBy = ({ items }) => {
    if (items.length === 0) {
      setFilterStr(null);
      return;
    }

    const [filter] = items;

    if (filter.operator === "contains" && filter.value === undefined) {
      return;
    }

    setFilterStr(`&filterField=${filter.field}&filterOperator=${filter.operator}&filterValue=${filter.value}`);
  };

  useEffect(() => {
    fetchData();
  }, [page, perPage, sortStr, filterStr]);

  return {
    columns: [
      { headerName: "Empresa", field: "company", flex: 0.14 },
      { headerName: "Número", field: "number", flex: 0.14 },
      { headerName: "Ações", field: "action", flex: 0.14, renderCell: action },
    ],

    rows,
    fetchData,
    updatePagination,
    sortBy,
    filterBy,
    pageObject: {
      totalRows,
      perPage,
      page,
    },
  };
}
