import HttpService from "./http.service";

class CustomersService {
  list = async (sortStr = null, filterStr = null) => {
    const customersEndpoint = `/customers?limit=-1${sortStr ? sortStr : ""}${
      filterStr ? filterStr : ""
    }`;
    return await HttpService.get(customersEndpoint);
  };

  filter = async (data) => {
    const dataWithoutUndefined = Object.keys(data).reduce((object, key) => {
      if (data[key] !== undefined && data[key] !== "" && data[key] !== null) {
        object[key] = data[key];
      }
      return object;
    }, {});
    const query = new URLSearchParams(dataWithoutUndefined).toString();
    const customersEndpoint = `/customers/filter?${query}`;
    return await HttpService.get(customersEndpoint, data);
  };

  listAllUfs = async () => {
    const customersEndpoint = `/customers/ufs`;
    return await HttpService.get(customersEndpoint);
  };

  listAllCitiesByUf = async (uf) => {
    const customersEndpoint = `/customers/cities/${uf}`;
    return await HttpService.get(customersEndpoint);
  };

  paginate = async (page = 0, limit = 25, sortStr = null, filterStr = null) => {
    const customersEndpoint = `/customers?page=${page}&limit=${limit}${
      sortStr ? sortStr : ""
    }${filterStr ? filterStr : ""}`;
    return await HttpService.get(customersEndpoint);
  };

  get = async (id) => {
    const customersEndpoint = "/customers";
    return await HttpService.get(`${customersEndpoint}/${id}`);
  };

  create = async (data) => {
    const customersEndpoint = "/customers";
    return await HttpService.post(customersEndpoint, data);
  };

  update = async (id, data) => {
    const customersEndpoint = "/customers";
    return await HttpService.put(`${customersEndpoint}/${id}`, data);
  };

  toggleStatus = async (id, data) => {
    const customersEndpoint = "/customers/toggle-status";
    return await HttpService.put(`${customersEndpoint}/${id}`, data);
  };


  delete = async (id) => {
    const customersEndpoint = "/customers";
    return await HttpService.delete(`${customersEndpoint}/${id}`);
  };
}

export default new CustomersService();
