import {
  Box,
  Button,
  Grid,
  Card,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Service from "services/customers";
import { yupSchema, initialValues, TIPO_PESSOA } from "./yupSchema";
import InputMask from "react-input-mask";
import cnpjService from "services/cnpj.service";
import cepService from "services/cep.service";
import UsersService from "services/users";
import { format } from "utils/formatDate";
import PaymentMethodService from "services/payment-methods";
import { PRAZO_PAGAMENTO } from "layouts/pedidos-compra/components/contas/yupSchema";
import { findPrazoPagamento } from "layouts/pedidos-compra/components/contas/yupSchema";
import CurrencyInput from "react-currency-input-field";
import { commaToDot } from "utils/numberComma";

export default function EditarCliente({
  setShowEditState,
  currentIdAction,
  fetchData,
}) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [alertKey, setAlertKey] = useState(0);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [vendedores, setVendedores] = useState([]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yupSchema,
    onSubmit: async (values) => {
      setLoading(true);

      values.limiteCredito = commaToDot(values.limiteCredito);

      try {
        await Service.update(currentIdAction, values);
        setSuccess(true);
      } catch (error) {
        console.log(error);
        setSuccess(false);
      }
      setAlertKey(alertKey + 1);
      setLoading(false);
    },
  });

  const pesquisaCnpj = async () => {
    const cnpj = formik.values.cpfCnpj.replace(/\D/g, "");

    if (cnpj.length !== 14) return;

    const fetchCnpj = await cnpjService.get(cnpj);

    const mapping = {
      cep: "cep",
      uf: "uf",
      municipio: "cidade",
      complemento: "complemento",
      bairro: "bairro",
      logradouro: "logradouro",
      numero: "numero",
      data_inicio_atividade: "dtNascimento",
      nome_fantasia: "nomeFantasia",
      razao_social: "razaoSocial",
      email: "email",
      ddd_telefone_1: "telefone",
    };

    Object.entries(mapping).forEach(([fetchKey, formikKey]) => {
      if (fetchCnpj[fetchKey] !== undefined) {
        formik.setFieldValue(formikKey, fetchCnpj[fetchKey]);
      }
    });
  };

  const pesquisaCep = async () => {
    const cep = formik.values.cep.replace(/[^0-9]/g, "");

    if (cep.length !== 8) return;

    const fetchCep = await cepService.get(cep);

    const mapping = {
      state: "uf",
      city: "cidade",
      neighborhood: "bairro",
      street: "logradouro",
    };

    Object.entries(mapping).forEach(([fetchKey, formikKey]) => {
      if (fetchCep[fetchKey] !== undefined) {
        formik.setFieldValue(formikKey, fetchCep[fetchKey]);
      }
    });
  };

  const changeCurrency = (value, name) => {
    formik.setFieldValue(name, value);
  };

  useEffect(() => {
    const fetchList = async () => {
      setLoading(true);
      const fields = await Service.get(currentIdAction);

      for (var key in formik.values) {
        if (key === "dtNascimento" || key.includes("data")) {
          formik.setFieldValue(
            key,
            fields[key] !== undefined && fields[key] !== null
              ? format(new Date(fields[key]), "yyyy-MM-dd")
              : ""
          );
        } else if (key === "paymentMethod") {
          if (fields[key] !== undefined && fields[key] !== null) {
            formik.setFieldValue(key, fields[key]._id || fields[key]);
          }
        } else formik.setFieldValue(key, fields[key]);
      }

      setLoading(false);
    };

    fetchList();
  }, [currentIdAction]);

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      const { data: paymentMethods } = await PaymentMethodService.list();
      setPaymentMethods(paymentMethods);
    };

    fetchPaymentMethods();

    const fetchUsers = async () => {
      const users = await UsersService.getOnlyNames();
      setVendedores(users);
    };

    fetchUsers();

    const closeOnEscapePressed = (e) => {
      if (e.key === "Escape") {
        fetchUsers();
        setSuccess(null);
        setShowEditState(false);
      }
    };
    window.addEventListener("keydown", closeOnEscapePressed);
    return () => window.removeEventListener("keydown", closeOnEscapePressed);
  }, []);

  return (
    <>
      <MDBox
        pt={"30px"}
        sx={{
          position: "absolute",
          zIndex: 1200,
          width: "100vw",
          minHeight: "auto",
          height: "125vh",
          backgroundColor: "rgba(0,0,0,0.5)",
          backdropFilter: "blur(5px)",
          top: 0,
          right: 0,
        }}
      >
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={8} mx={"auto"}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  Editar Cliente
                </MDTypography>
                <MDButton
                  variant="contained"
                  type="button"
                  onClick={() => {
                    fetchData();
                    setSuccess(null);
                    setShowEditState(false);
                  }}
                >
                  Voltar
                </MDButton>
              </MDBox>
              <MDBox pt={3} mx={2} py={3} px={2}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 2,
                    mb: 2,
                  }}
                  component="form"
                  onSubmit={formik.handleSubmit}
                >
                  <TextField
                    select
                    id="tipoPessoa"
                    name="tipoPessoa"
                    label="Tipo de Pessoa"
                    value={formik.values.tipoPessoa}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.tipoPessoa &&
                      Boolean(formik.errors.tipoPessoa)
                    }
                    helperText={
                      formik.touched.tipoPessoa && formik.errors.tipoPessoa
                    }
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option aria-label="Selecione" value="" />
                    <option value={TIPO_PESSOA.PESSOA_FISICA}>
                      Pessoa Física
                    </option>
                    <option value={TIPO_PESSOA.PESSOA_JURIDICA}>
                      Pessoa Jurídica
                    </option>
                  </TextField>

                  <InputMask
                    mask={
                      formik.values.tipoPessoa === TIPO_PESSOA.PESSOA_FISICA
                        ? "999.999.999-99"
                        : "99.999.999/9999-99"
                    }
                    value={formik.values.cpfCnpj}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      formik.handleBlur(e);

                      if (
                        formik.values.tipoPessoa === TIPO_PESSOA.PESSOA_JURIDICA
                      ) {
                        pesquisaCnpj();
                      }
                    }}
                  >
                    {(inputProps) => (
                      <TextField
                        id="cpfCnpj"
                        name="cpfCnpj"
                        label={
                          formik.values.tipoPessoa === TIPO_PESSOA.PESSOA_FISICA
                            ? "CPF"
                            : "CNPJ"
                        }
                        error={
                          formik.touched.cpfCnpj &&
                          Boolean(formik.errors.cpfCnpj)
                        }
                        helperText={
                          formik.touched.cpfCnpj && formik.errors.cpfCnpj
                        }
                        {...inputProps}
                      />
                    )}
                  </InputMask>

                  <TextField
                    id="inscricaoEstadual"
                    name="inscricaoEstadual"
                    label="Inscrição Estadual"
                    value={formik.values.inscricaoEstadual}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.inscricaoEstadual &&
                      Boolean(formik.errors.inscricaoEstadual)
                    }
                    helperText={
                      formik.touched.inscricaoEstadual &&
                      formik.errors.inscricaoEstadual
                    }
                  />

                  <TextField
                    id="inscricaoMunicipal"
                    name="inscricaoMunicipal"
                    label="Inscrição Municipal"
                    value={formik.values.inscricaoMunicipal}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.inscricaoMunicipal &&
                      Boolean(formik.errors.inscricaoMunicipal)
                    }
                    helperText={
                      formik.touched.inscricaoMunicipal &&
                      formik.errors.inscricaoMunicipal
                    }
                  />

                  <TextField
                    id="razaoSocial"
                    name="razaoSocial"
                    label="Razão Social"
                    value={formik.values.razaoSocial}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.razaoSocial &&
                      Boolean(formik.errors.razaoSocial)
                    }
                    helperText={
                      formik.touched.razaoSocial && formik.errors.razaoSocial
                    }
                  />

                  <TextField
                    id="nomeFantasia"
                    name="nomeFantasia"
                    label="Nome Fantasia"
                    value={formik.values.nomeFantasia}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.nomeFantasia &&
                      Boolean(formik.errors.nomeFantasia)
                    }
                    helperText={
                      formik.touched.nomeFantasia && formik.errors.nomeFantasia
                    }
                  />

                  <TextField
                    id="dataNascimento"
                    name="dataNascimento"
                    label="Data de Nascimento"
                    type="date"
                    value={formik.values.dataNascimento}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.dataNascimento &&
                      Boolean(formik.errors.dataNascimento)
                    }
                    helperText={
                      formik.touched.dataNascimento &&
                      formik.errors.dataNascimento
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <InputMask
                    mask="99.999-999"
                    value={formik.values.cep}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      formik.handleBlur(e);
                      pesquisaCep();
                    }}
                  >
                    {(inputProps) => (
                      <TextField
                        id="cep"
                        name="cep"
                        label="CEP"
                        error={formik.touched.cep && Boolean(formik.errors.cep)}
                        helperText={formik.touched.cep && formik.errors.cep}
                        {...inputProps}
                      />
                    )}
                  </InputMask>

                  <TextField
                    id="logradouro"
                    name="logradouro"
                    label="Logradouro"
                    value={formik.values.logradouro}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.logradouro &&
                      Boolean(formik.errors.logradouro)
                    }
                    helperText={
                      formik.touched.logradouro && formik.errors.logradouro
                    }
                    inputProps={{
                      maxLength: 60,
                    }}
                  />

                  <TextField
                    id="numero"
                    name="numero"
                    label="Número"
                    value={formik.values.numero}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.numero && Boolean(formik.errors.numero)
                    }
                    helperText={formik.touched.numero && formik.errors.numero}
                  />

                  <TextField
                    id="complemento"
                    name="complemento"
                    label="Complemento"
                    value={formik.values.complemento}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.complemento &&
                      Boolean(formik.errors.complemento)
                    }
                    helperText={
                      formik.touched.complemento && formik.errors.complemento
                    }
                  />

                  <TextField
                    id="bairro"
                    name="bairro"
                    label="Complemento"
                    value={formik.values.bairro}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.bairro && Boolean(formik.errors.bairro)
                    }
                    helperText={formik.touched.bairro && formik.errors.bairro}
                  />

                  <div>
                    <TextField
                      id="codigoIbge"
                      name="codigoIbge"
                      label="Código IBGE (Município)"
                      type="number"
                      value={formik.values.codigoIbge}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.codigoIbge &&
                        Boolean(formik.errors.codigoIbge)
                      }
                      helperText={
                        formik.touched.codigoIbge && formik.errors.codigoIbge
                      }
                      sx={{ width: "100%" }}
                    />
                    <a
                      href="https://www.ibge.gov.br/explica/codigos-dos-municipios.php"
                      target="_blank"
                      style={{
                        fontSize: "0.85rem",
                        color: "rgb(0, 48, 21)",
                        fontWeight: "400",
                      }}
                    >
                      Lista de códigos IBGE
                    </a>
                  </div>

                  <TextField
                    id="cidade"
                    name="cidade"
                    label="Cidade"
                    value={formik.values.cidade}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.cidade && Boolean(formik.errors.cidade)
                    }
                    helperText={formik.touched.cidade && formik.errors.cidade}
                  />

                  <TextField
                    select
                    id="uf"
                    name="uf"
                    label="UF"
                    value={formik.values.uf}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.uf && Boolean(formik.errors.uf)}
                    helperText={formik.touched.uf && formik.errors.uf}
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option value=""></option>
                    <option value="AC">AC - Acre</option>
                    <option value="AL">AL - Alagoas</option>
                    <option value="AP">AP - Amapá</option>
                    <option value="AM">AM - Amazonas</option>
                    <option value="BA">BA - Bahia</option>
                    <option value="CE">CE - Ceará</option>
                    <option value="DF">DF - Distrito Federal</option>
                    <option value="ES">ES - Espírito Santo</option>
                    <option value="GO">GO - Goiás</option>
                    <option value="MA">MA - Maranhão</option>
                    <option value="MT">MT - Mato Grosso</option>
                    <option value="MS">MS - Mato Grosso do Sul</option>
                    <option value="MG">MG - Minas Gerais</option>
                    <option value="PA">PA - Pará</option>
                    <option value="PB">PB - Paraíba</option>
                    <option value="PR">PR - Paraná</option>
                    <option value="PE">PE - Pernambuco</option>
                    <option value="PI">PI - Piauí</option>
                    <option value="RJ">RJ - Rio de Janeiro</option>
                    <option value="RN">RN - Rio Grande do Norte</option>
                    <option value="RS">RS - Rio Grande do Sul</option>
                    <option value="RO">RO - Rondônia</option>
                    <option value="RR">RR - Roraima</option>
                    <option value="SC">SC - Santa Catarina</option>
                    <option value="SP">SP - São Paulo</option>
                    <option value="SE">SE - Sergipe</option>
                    <option value="TO">TO - Tocantins</option>
                  </TextField>

                  <InputMask
                    mask={
                      formik.values.telefone?.length > 14
                        ? "(99) 99999-9999"
                        : "(99) 9999-99999"
                    }
                    maskChar=""
                    value={formik.values.telefone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    {(inputProps) => (
                      <TextField
                        id="telefone"
                        name="telefone"
                        label="Telefone"
                        error={
                          formik.touched.telefone &&
                          Boolean(formik.errors.telefone)
                        }
                        helperText={
                          formik.touched.telefone && formik.errors.telefone
                        }
                        {...inputProps}
                      />
                    )}
                  </InputMask>

                  <InputMask
                    mask={
                      formik.values.celular?.length > 14
                        ? "(99) 99999-9999"
                        : "(99) 9999-99999"
                    }
                    maskChar=""
                    value={formik.values.celular}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    {(inputProps) => (
                      <TextField
                        id="celular"
                        name="celular"
                        label="Celular"
                        value={formik.values.celular}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.celular &&
                          Boolean(formik.errors.celular)
                        }
                        helperText={
                          formik.touched.celular && formik.errors.celular
                        }
                        {...inputProps}
                      />
                    )}
                  </InputMask>

                  <TextField
                    id="email"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />

                  <CurrencyInput
                    id="limiteCredito"
                    name="limiteCredito"
                    label="Limite de Crédito"
                    value={formik.values.limiteCredito}
                    onValueChange={changeCurrency}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.limiteCredito &&
                      Boolean(formik.errors.limiteCredito)
                    }
                    helperText={
                      formik.touched.limiteCredito &&
                      formik.errors.limiteCredito
                    }
                    prefix="R$ "
                    decimalsLimit={2}
                    decimalSeparator=","
                    groupSeparator="."
                    disableGroupSeparators={true}
                    intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                    customInput={TextField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    id="paymentMethod"
                    name="paymentMethod"
                    label="Método de Pagamento"
                    value={formik.values.paymentMethod}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.paymentMethod &&
                      Boolean(formik.errors.paymentMethod)
                    }
                    helperText={
                      formik.touched.paymentMethod &&
                      formik.errors.paymentMethod
                    }
                    select
                    SelectProps={{
                      native: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <option value="" />
                    {paymentMethods.map((option) => (
                      <option key={option._id} value={option._id}>
                        {option.descricao}
                      </option>
                    ))}
                  </TextField>

                  <TextField
                    id="prazoPagamento"
                    name="prazoPagamento"
                    label="Prazo de Pagamento"
                    value={formik.values.prazoPagamento}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.prazoPagamento &&
                      Boolean(formik.errors.prazoPagamento)
                    }
                    helperText={
                      formik.touched.prazoPagamento &&
                      formik.errors.prazoPagamento
                    }
                    select
                    SelectProps={{
                      native: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <option value=""></option>
                    {Object.values(PRAZO_PAGAMENTO).map((prazo) => (
                      <option key={prazo} value={prazo}>
                        {findPrazoPagamento(prazo)}
                      </option>
                    ))}
                  </TextField>

                  <FormControl>
                    <InputLabel id="vendedores">Vendedores</InputLabel>
                    <Select
                      labelId="vendedores"
                      id="vendedores"
                      multiple
                      value={formik.values.vendedores}
                      onChange={(e) => {
                        formik.setFieldValue("vendedores", e.target.value);
                      }}
                      input={<OutlinedInput label="Vendedores" />}
                      sx={{ width: "100%", height: "44px" }}
                    >
                      {vendedores.map((vendedor) => (
                        <MenuItem key={vendedor._id} value={vendedor._id}>
                          {vendedor.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <TextField
                    id="observacao"
                    name="observacao"
                    label="Observação"
                    value={formik.values.observacao}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.observacao &&
                      Boolean(formik.errors.observacao)
                    }
                    helperText={
                      formik.touched.observacao && formik.errors.observacao
                    }
                    multiline
                    rows={4}
                    sx={{
                      gridColumn: "span 2",
                      justifySelf: "center",
                      width: "100%",
                    }}
                  />

                  <Button
                    type="submit"
                    variant="contained"
                    disabled={loading}
                    sx={{
                      gridColumn: "span 2",
                      justifySelf: "center",
                      width: "100%",
                      color: "white !important",
                    }}
                  >
                    {loading ? "Carregando..." : "Salvar"}
                  </Button>
                </Box>

                {success !== null && (
                  <Box>
                    <MDAlert
                      color={success ? "success" : "error"}
                      mt={4}
                      dismissible
                      key={alertKey}
                    >
                      <MDTypography variant="body2" color="white">
                        {success
                          ? "Cliente atualizado com sucesso!"
                          : "Ocorreu um erro ao atualizar o cliente."}
                      </MDTypography>
                    </MDAlert>
                  </Box>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
