import HttpService from "./http.service";

class AccountGroupService {
  list = async (sortStr = null, filterStr = null) => {
    const accountGroupEndpoint = `/account-groups?limit=-1${
      sortStr ? sortStr : ""
    }${filterStr ? filterStr : ""}`;
    return await HttpService.get(accountGroupEndpoint);
  };

  paginate = async (page = 0, limit = 25, sortStr = null, filterStr = null) => {
    const accountGroupEndpoint = `/account-groups?page=${page}&limit=${limit}${
      sortStr ? sortStr : ""
    }${filterStr ? filterStr : ""}`;
    return await HttpService.get(accountGroupEndpoint);
  };

  get = async (id) => {
    const accountGroupEndpoint = "/account-groups";
    return await HttpService.get(`${accountGroupEndpoint}/${id}`);
  };

  create = async (data) => {
    const accountGroupEndpoint = "/account-groups";
    return await HttpService.post(accountGroupEndpoint, data);
  };

  update = async (id, data) => {
    const accountGroupEndpoint = "/account-groups";
    return await HttpService.put(`${accountGroupEndpoint}/${id}`, data);
  };

  delete = async (id) => {
    const accountGroupEndpoint = "/account-groups";
    return await HttpService.delete(`${accountGroupEndpoint}/${id}`);
  };
}

export default new AccountGroupService();
