// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { DataGrid } from "@mui/x-data-grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Data
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import MDDialog from "components/MDDialog";

import AdicionarDado from "./components/addData";
import EditarDado from "./components/editData";
import tableData from "./components/tableData";
import DetalharDado from "./components/detailData";

import Service from "services/receivables";

import { useLocation } from "react-router-dom";
import { ptBR_DataGrid } from "utils/datagrid";
import Filter from "../payables/filter";
import { Icon } from "@mui/material";
import { set } from "date-fns";

import { exportToExcel } from "react-json-to-excel";
import { format } from "utils/formatDate";

export default function ContasAReceber() {
  const [showAddState, setShowAddState] = useState(false);
  const [showEditState, setShowEditState] = useState(false);
  const [showFilterState, setShowFilterState] = useState(false);
  const [showDetailState, setShowDetailState] = useState(false);
  const [dialogRemove, setDialogRemove] = useState(false);
  const [dialogText, setDialogText] = useState("");
  const [currentIdAction, setCurrentIdAction] = useState(null);
  const [loading, setLoading] = useState(false);

  const [staticPagination, setStaticPagination] = useState({
    page: 0,
    pageSize: 50,
  });

  const [isFiltered, setIsFiltered] = useState(false);
  const [currentFilter, setCurrentFilter] = useState({});

  const location = useLocation();

  const handleDetail = (id) => {
    setCurrentIdAction(id);
    setShowDetailState(true);
  };

  const handleEdit = (id) => {
    setCurrentIdAction(id);
    setShowEditState(true);
    window.scrollTo(0, 0);
  };

  const handleDelete = (obj) => {
    setDialogRemove(true);
    setDialogText(obj.descricao);
    setCurrentIdAction(obj._id);
  };

  const cancelDelete = () => {
    setDialogRemove(false);
    setDialogText("");
    setCurrentIdAction(null);
  };

  const confirmDelete = async () => {
    await Service.delete(currentIdAction);
    setDialogRemove(false);
    fetchData();
  };

  const resetRows = () => {
    updateRows([]);
    setLoading();
    setIsFiltered(false);
    setCurrentFilter({});
    fetchData();
  };

  const doCurrentFilter = async () => {
    if (JSON.stringify(currentFilter) !== "{}") {
      setLoading(true);
      const data = await Service.filter(currentFilter);
      updateRows(data);
      setLoading(false);
    } else {
      resetRows();
    }
  };

  const {
    columns,
    rows,
    fetchData,
    updateRows,
    updatePagination,
    sortBy,
    filterBy,
    pageObject,
    sortStr,
    filterStr,
  } = tableData(
    handleEdit,
    handleDelete,
    handleDetail,
    setLoading,
    currentFilter
  );

  const cleanRawRows = (lines) => {
    const linesToExport = lines.map((line) => ({
      "N. P": line.numPedido,
      Descrição: line.descricao,
      "Centro de Custo": line.accountGroup?.descricao,
      "Natureza de Lançamento": line.accountSubGroup?.nome,
      Cliente: line.customer?.razaoSocial || line.customer?.nomeFantasia,
      "Data do Pedido":
        line.dataPedido !== undefined && line.dataPedido !== null
          ? format(new Date(line.dataPedido), "dd/MM/yyyy")
          : "",
      "Data do Vencimento":
        line.dataVencimento !== undefined && line.dataVencimento !== null
          ? format(new Date(line.dataVencimento), "dd/MM/yyyy")
          : "",
      "Data do Pagamento":
        line.dataPagamento !== undefined && line.dataPagamento !== null
          ? format(new Date(line.dataPagamento), "dd/MM/yyyy")
          : "",
      Valor: line.valor,
    }));

    return linesToExport;
  };

  const doExportExcel = async () => {
    setLoading(true);

    if (isFiltered) {
      const data = await Service.filter({
        ...currentFilter,
        customer: currentFilter.customer?._id || currentFilter.customer,
        supplier: currentFilter.supplier?._id || currentFilter.supplier,
      });

      exportToExcel(cleanRawRows(data), "contas_pagar_" + new Date().getTime());
      setLoading(false);
    } else {
      const { data } = await Service.paginate(0, -1, sortStr, filterStr);
      exportToExcel(cleanRawRows(data), "contas_pagar_" + new Date().getTime());
      setLoading(false);
    }
  };

  const doExportExcelPage = async () => {
    setLoading(true);

    if (isFiltered) {
      const oldData = await Service.filter({
        ...currentFilter,
        customer: currentFilter.customer?._id || currentFilter.customer,
        supplier: currentFilter.supplier?._id || currentFilter.supplier,
      });

      const data = paginateArray(
        oldData,
        staticPagination.pageSize,
        staticPagination.page
      );

      exportToExcel(
        cleanRawRows(data),
        "contas_receber_" + new Date().getTime()
      );
      setLoading(false);
    } else {
      const { data } = await Service.paginate(
        pageObject.page,
        pageObject.perPage,
        sortStr,
        filterStr
      );
      exportToExcel(
        cleanRawRows(data),
        "contas_receber_" + new Date().getTime()
      );
      setLoading(false);
    }
  };

  const paginateArray = (array, perPage, page) => {
    const start = page * perPage;
    const end = start + perPage;
    return array.slice(start, end);
  };

  useEffect(() => {
    if (location.state && location.state.id) {
      setCurrentIdAction(location.state.id);
      setShowEditState(true);
    }

    if (location.state && location.state.contasReceberVencendoHoje) {
      setCurrentFilter({
        vencendoHoje: "S",
      });

      const doFilterLocation = async () => {
        const newRows = await Service.filter({
          vencendoHoje: "S",
        });

        updateRows(newRows);
        setIsFiltered(true);
      };

      setTimeout(() => {
        doFilterLocation();
      }, 3000);
    }

    if (location.state && location.state.contasReceberVencidasSempre) {
      setCurrentFilter({
        vencidasSempre: "S",
      });

      const doFilterLocation = async () => {
        const newRows = await Service.filter({
          vencidasSempre: "S",
        });

        updateRows(newRows);
        setIsFiltered(true);
      };

      setTimeout(() => {
        doFilterLocation();
      }, 3000);
    }
  }, [location, loading]);

  return (
    <DashboardLayout>
      <MDBox pt={3} pb={3}>
        {showEditState && (
          <EditarDado
            setShowEditState={setShowEditState}
            currentIdAction={currentIdAction}
            fetchData={isFiltered ? doCurrentFilter : fetchData}
          />
        )}
        {showAddState && (
          <AdicionarDado
            setShowAddState={setShowAddState}
            fetchData={isFiltered ? doCurrentFilter : fetchData}
          />
        )}
        {showFilterState && (
          <Filter
            Service={Service}
            resetRows={resetRows}
            updateRows={updateRows}
            setIsFiltered={setIsFiltered}
            setShowFilterState={setShowFilterState}
            setCurrentFilter={setCurrentFilter}
            currentFilter={currentFilter}
            isContaReceber={true}
          />
        )}
        {showDetailState && (
          <DetalharDado
            setShowDetailState={setShowDetailState}
            currentIdAction={currentIdAction}
            fetchData={isFiltered ? doCurrentFilter : fetchData}
          />
        )}
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  Contas a receber
                </MDTypography>
                <div>
                  <MDButton
                    variant="contained"
                    type="button"
                    onClick={() => setShowFilterState(true)}
                    sx={{ mr: 1 }}
                  >
                    Filtrar
                    {isFiltered && (
                      <>
                        {" "}
                        <Icon fontSize="small">filter_alt</Icon>
                      </>
                    )}
                  </MDButton>

                  <MDButton
                    variant="contained"
                    type="button"
                    onClick={() => setShowAddState(true)}
                  >
                    Adicionar
                  </MDButton>
                </div>
              </MDBox>
              <MDBox pt={3}>
                <Card sx={{ padding: 2 }}>
                  {isFiltered && "Filtrando Registros"}

                  {isFiltered ? (
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      pageSizeOptions={[50, 100]}
                      rowSelection={false}
                      loading={loading}
                      localeText={ptBR_DataGrid}
                      sx={{
                        ".MuiDataGrid-virtualScroller": {
                          minHeight: "100px",
                        },
                      }}
                      onPaginationModelChange={(model) =>
                        setStaticPagination(model)
                      }
                    />
                  ) : (
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      rowCount={pageObject.totalRows}
                      pageSizeOptions={[50, 100]}
                      paginationModel={{
                        pageSize: pageObject.perPage,
                        page: pageObject.page,
                      }}
                      paginationMode="server"
                      onPaginationModelChange={updatePagination}
                      sortingMode="server"
                      onSortModelChange={sortBy}
                      filterMode="server"
                      onFilterModelChange={filterBy}
                      rowSelection={false}
                      loading={loading}
                      localeText={ptBR_DataGrid}
                      sx={{
                        ".MuiDataGrid-virtualScroller": {
                          minHeight: "100px",
                        },
                      }}
                    />
                  )}
                </Card>
              </MDBox>
            </Card>

            <MDBox
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: 2,
              }}
            >
              <MDButton
                variant="gradient"
                color="success"
                onClick={doExportExcelPage}
                sx={{ mr: 1 }}
              >
                Exportar Página Para Excel{" "}
                <Icon fontSize="small">download</Icon>
              </MDButton>

              <MDButton
                variant="gradient"
                color="success"
                onClick={doExportExcel}
              >
                Exportar Tudo Para Excel <Icon fontSize="small">download</Icon>
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>

        <MDDialog
          open={dialogRemove}
          handleClose={() => setDialogRemove(false)}
          title={"Tem certeza que deseja remover?"}
          actions={
            <>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                type="submit"
                onClick={() => confirmDelete()}
              >
                Confirmar
              </MDButton>
              <MDButton
                variant="gradient"
                color="error"
                fullWidth
                type="submit"
                onClick={() => cancelDelete()}
              >
                Cancelar
              </MDButton>
            </>
          }
        >
          {dialogText}
        </MDDialog>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
