export const commaToDot = (value) => {
  if (value === undefined || value === null || value.replace === undefined)
    return value;
  return value.replace(",", ".");
};

export const dotToComma = (value) => {
  if (value === undefined || value === null || value.replace === undefined)
    return value;
  return String(value).replace(".", ",");
};
