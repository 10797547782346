import { Box, Button, Grid, Card, TextField } from "@mui/material";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useFormik } from "formik";
import { useState } from "react";
import AccountSubgroupService from "services/sub-group-accounts";
import AccountGroupsService from "services/account-groups";
import PaymentMethodService from "services/payment-methods";
import SupplierService from "services/suppliers";
import CustomerService from "services/customers";
import AccountService from "services/accounts";
import { yupSchema, initialValues, BILLING_STATUS, findBillingStatus, PRAZO_PAGAMENTO, findPrazoPagamento, PARCELAS_PRAZO_PAGAMENTO, DIAS_PRAZO_PAGAMENTO } from "./yupSchema";
import { useEffect } from "react";
import CurrencyInput from "react-currency-input-field";
import { format } from "utils/formatDate";
import { commaToDot } from "utils/numberComma";
import DataTable from "examples/Tables/DataTable";

const colunasParcelas = [
  {
    Header: "Valor",
    accessor: "valor",
    align: "center",
  },
  {
    Header: "Data de Vencimento",
    accessor: "dataVencimento",
    align: "center",
  },
  {
    Header: "Descrição",
    accessor: "descricao",
    align: "center",
  },
  {
    Header: "Dias",
    accessor: "dias",
    align: "center",
  },
];

export default function AdicionarContaAPagar({
  setShowAddState,
  handleAdd,
  isReceber = false,
  rows = [],
  cliente = null,
  valorTotal = null,
  currEdit = null,
  handleEditCurrent = null,
  parentDataPedido = null,
  isEdit = false,
  numPedido = "",
}) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [alertKey, setAlertKey] = useState(0);
  const [dataList, setDataList] = useState({
    accountSubgroup: [],
    accountGroups: [],
    paymentMethods: [],
    suppliers: [],
    accounts: [],
  });

  const [disableDataVencimento, setDisableDataVencimento] = useState(false);
  const [hideDataVencimento, setHideDataVencimento] = useState(false);
  const [parcelas, setParcelas] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data: accountGroups } = await AccountGroupsService.list();
        const { data: accountSubgroup } = await AccountSubgroupService.list();
        const { data: paymentMethods } = await PaymentMethodService.list();
        const { data: suppliers } = await SupplierService.list();
        const { data: customers } = await CustomerService.list();
        const { data: accounts } = await AccountService.list();

        setDataList({
          accountSubgroup,
          accountGroups,
          paymentMethods,
          suppliers,
          customers,
          accounts,
        });
      } catch (error) {
        console.log(error);
      }

      setLoading(false);
    };

    fetchData();

    formik.setFieldValue("status", BILLING_STATUS.ABERTO);

    if (parentDataPedido) {
      formik.setFieldValue("dataPedido", parentDataPedido);
    } else {
      formik.setFieldValue("dataPedido", format(new Date(), "yyyy-MM-dd"));
    }
    setDisableDataVencimento(false);

    if (isReceber) {
      formik.setFieldValue("accountGroup", "6526c91d8017b394afadcb6a");
      formik.setFieldValue("accountSubGroup", "6526ccebe69d93e3e92bb845");
    } else {
      formik.setFieldValue("accountGroup", "6526c91d8017b394afadcb6a");
      formik.setFieldValue("accountSubGroup", "65b006220c5276d036a7117b");
    }
  }, []);

  useEffect(() => {
    if (currEdit) {
      formik.setFieldValue("descricao", currEdit.descricao);
      formik.setFieldValue("supplier", currEdit.supplier._id);
      formik.setFieldValue("paymentMethod", currEdit.paymentMethod);
      formik.setFieldValue("prazoPagamento", currEdit.prazoPagamento);
      formik.setFieldValue("dataVencimento", format(currEdit.dataVencimento, "yyyy-MM-dd"));
      formik.setFieldValue("valor", currEdit.valor);
      formik.setFieldValue("status", currEdit.status);
      formik.setFieldValue("account", currEdit.account);
      formik.setFieldValue("observacoes", currEdit.observacoes);
    }
  }, [currEdit]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yupSchema,
    onSubmit: async (values) => {
      setLoading(true);

      try {
        if (currEdit !== null && handleEditCurrent !== null) {
          handleEditCurrent({
            ...currEdit,
            ...values,
          });
        } else {
          handleAdd({ ...values, idx: Date.now() });
        }
      } catch (error) {
        console.log(error);
        setSuccess(false);
      }

      setAlertKey(alertKey + 1);

      setLoading(false);
    },
  });

  useEffect(() => {
    updatePaymentAndPrazo(formik.values?.supplier);
  }, [formik.values?.supplier]);

  const changeCurrency = (value, name) => {
    formik.setFieldValue(name, value);
  };

  const addZero = (e) => {
    const { name, value } = e.target;
    if (value === "") {
      formik.setFieldValue(name, "0,00");
    } else {
      const removeRS = value.replace("R$", "").trim();
      const removeDots = removeRS.replace(/\./g, "");

      if (value.indexOf(",") === -1) {
        formik.setFieldValue(name, `${removeDots},00`);
      }
    }
  };

  const handleCustomerChange = (e) => {
    formik.handleChange(e);
    updatePaymentAndPrazo(e.target.value);
  };

  const updatePaymentAndPrazo = (customerId) => {
    if (!customerId) return;

    const customer = dataList.customers.find((cs) => cs._id == customerId);

    if (customer && customer.paymentMethod) {
      formik.setFieldValue("paymentMethod", customer.paymentMethod);
    }

    if (customer && customer.prazoPagamento) {
      formik.setFieldValue("prazoPagamento", customer.prazoPagamento);
    }
  };

  const calculaParcelas = () => {
    const parcs = [];

    const valorParcela = commaToDot(formik.values.valor) / PARCELAS_PRAZO_PAGAMENTO[formik.values.prazoPagamento];
    const diasParcela = DIAS_PRAZO_PAGAMENTO[formik.values.prazoPagamento];

    for (let i = 0; i < PARCELAS_PRAZO_PAGAMENTO[formik.values.prazoPagamento]; i++) {
      const parcela = {
        valor: Number(valorParcela).toFixed(2),
        dataVencimento: hojePlusDias(diasParcela[i]),
        descricao: `PARCELA ${i + 1}/${PARCELAS_PRAZO_PAGAMENTO[formik.values.prazoPagamento]}`,
        dias: diasParcela[i],
      };

      parcs.push(parcela);
    }

    setParcelas(parcs);
  };

  const hojePlusDias = (dias) => {
    const hoje = new Date();
    hoje.setDate(hoje.getDate() + dias);
    return hoje;
  };

  const formataParcelas = (parc) => {
    return parc.map((p) => ({
      ...p,
      valor: new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(p.valor),
      dataVencimento: format(p.dataVencimento, "dd/MM/yyyy"),
    }));
  };

  useEffect(() => {
    if (rows?.length === 0 && valorTotal) {
      formik.setFieldValue("valor", valorTotal);
      formik.setFieldValue("descricao", "PARCELA 01/01");
    }

    if (cliente && dataList.suppliers?.length > 0) {
      formik.setFieldValue("supplier", cliente);
    }
  }, [rows, cliente, valorTotal, dataList]);

  useEffect(() => {
    setHideDataVencimento(false);
    setParcelas([]);

    switch (formik.values.prazoPagamento) {
      case PRAZO_PAGAMENTO.A_VISTA:
        if (rows?.length === 0) {
          formik.setFieldValue("descricao", "PARCELA 01/01");
        }

        if (parentDataPedido) {
          formik.setFieldValue("dataVencimento", parentDataPedido);
        } else {
          formik.setFieldValue("dataVencimento", format(new Date(), "yyyy-MM-dd"));
        }

        setDisableDataVencimento(true);
        break;

      case PRAZO_PAGAMENTO.A_PRAZO:
        if (rows?.length === 0) {
          formik.setFieldValue("descricao", "PARCELA 01/01");
        }

        if (parentDataPedido) {
          formik.setFieldValue("dataVencimento", parentDataPedido);
        } else {
          formik.setFieldValue("dataVencimento", format(new Date(), "yyyy-MM-dd"));
        }

        setDisableDataVencimento(false);
        break;

      case PRAZO_PAGAMENTO.SETE:
        if (rows?.length === 0) {
          formik.setFieldValue("descricao", "PARCELA 01/01");
        }
        const seteDias = parentDataPedido ? new Date(parentDataPedido) : new Date();
        seteDias.setDate(seteDias.getDate() + 7);
        formik.setFieldValue("dataVencimento", format(seteDias, "yyyy-MM-dd"));
        setDisableDataVencimento(true);
        break;

      case PRAZO_PAGAMENTO.DEZ:
        if (rows?.length === 0) {
          formik.setFieldValue("descricao", "PARCELA 01/01");
        }
        const dezDias = parentDataPedido ? new Date(parentDataPedido) : new Date();
        dezDias.setDate(dezDias.getDate() + 10);
        formik.setFieldValue("dataVencimento", format(dezDias, "yyyy-MM-dd"));
        setDisableDataVencimento(true);
        break;

      case PRAZO_PAGAMENTO.QUINZE:
        if (rows?.length === 0) {
          formik.setFieldValue("descricao", "PARCELA 01/01");
        }
        const quinzeDias = parentDataPedido ? new Date(parentDataPedido) : new Date();
        quinzeDias.setDate(quinzeDias.getDate() + 15);
        formik.setFieldValue("dataVencimento", format(quinzeDias, "yyyy-MM-dd"));
        setDisableDataVencimento(true);
        break;

      case PRAZO_PAGAMENTO.VINTE_UM:
        if (rows?.length === 0) {
          formik.setFieldValue("descricao", "PARCELA 01/01");
        }
        const vinteUmDias = parentDataPedido ? new Date(parentDataPedido) : new Date();
        vinteUmDias.setDate(vinteUmDias.getDate() + 21);
        formik.setFieldValue("dataVencimento", format(vinteUmDias, "yyyy-MM-dd"));
        setDisableDataVencimento(true);
        break;

      case PRAZO_PAGAMENTO.TRINTA:
        if (rows?.length === 0) {
          formik.setFieldValue("descricao", "PARCELA 01/01");
        }
        const trintaDias = parentDataPedido ? new Date(parentDataPedido) : new Date();
        trintaDias.setDate(trintaDias.getDate() + 30);
        formik.setFieldValue("dataVencimento", format(trintaDias, "yyyy-MM-dd"));
        setDisableDataVencimento(true);
        break;

      case PRAZO_PAGAMENTO.TRINTA_CINCO:
        if (rows?.length === 0) {
          formik.setFieldValue("descricao", "PARCELA 01/01");
        }
        const trintaCincoDias = parentDataPedido ? new Date(parentDataPedido) : new Date();
        trintaCincoDias.setDate(trintaCincoDias.getDate() + 35);
        formik.setFieldValue("dataVencimento", format(trintaCincoDias, "yyyy-MM-dd"));
        setDisableDataVencimento(true);
        break;

      case PRAZO_PAGAMENTO.QUARENTA:
        if (rows?.length === 0) {
          formik.setFieldValue("descricao", "PARCELA 01/01");
        }
        const quarentaDias = parentDataPedido ? new Date(parentDataPedido) : new Date();
        quarentaDias.setDate(quarentaDias.getDate() + 40);
        formik.setFieldValue("dataVencimento", format(quarentaDias, "yyyy-MM-dd"));
        setDisableDataVencimento(true);
        break;

      case PRAZO_PAGAMENTO.SESSENTA:
        if (rows?.length === 0) {
          formik.setFieldValue("descricao", "PARCELA 01/01");
        }
        const sessentaDias = parentDataPedido ? new Date(parentDataPedido) : new Date();
        sessentaDias.setDate(sessentaDias.getDate() + 60);
        formik.setFieldValue("dataVencimento", format(sessentaDias, "yyyy-MM-dd"));
        setDisableDataVencimento(true);
        break;

      case PRAZO_PAGAMENTO.NOVENTA:
        if (rows?.length === 0) {
          formik.setFieldValue("descricao", "PARCELA 01/01");
        }
        const noventaDias = parentDataPedido ? new Date(parentDataPedido) : new Date();
        noventaDias.setDate(noventaDias.getDate() + 90);
        formik.setFieldValue("dataVencimento", format(noventaDias, "yyyy-MM-dd"));
        setDisableDataVencimento(true);
        break;

      case PRAZO_PAGAMENTO.CENTO_VINTE:
        if (rows?.length === 0) {
          formik.setFieldValue("descricao", "PARCELA 01/01");
        }
        const centoVinteDias = parentDataPedido ? new Date(parentDataPedido) : new Date();
        centoVinteDias.setDate(centoVinteDias.getDate() + 120);
        formik.setFieldValue("dataVencimento", format(centoVinteDias, "yyyy-MM-dd"));
        setDisableDataVencimento(true);
        break;

      case PRAZO_PAGAMENTO.TRINTA_QUARENTA_CINCO:
        calculaParcelas();
        setHideDataVencimento(true);
        formik.setFieldValue("descricao", `PARCELADO ` + findPrazoPagamento(PRAZO_PAGAMENTO.TRINTA_QUARENTA_CINCO));
        const trintaQuarentaCincoDias = parentDataPedido ? new Date(parentDataPedido) : new Date();
        trintaQuarentaCincoDias.setDate(trintaQuarentaCincoDias.getDate() + 45);
        formik.setFieldValue("dataVencimento", format(trintaQuarentaCincoDias, "yyyy-MM-dd"));
        setDisableDataVencimento(true);
        break;

      case PRAZO_PAGAMENTO.TRINTA_SESSENTA:
        calculaParcelas();
        setHideDataVencimento(true);
        formik.setFieldValue("descricao", `PARCELADO ` + findPrazoPagamento(PRAZO_PAGAMENTO.TRINTA_SESSENTA));
        const trintaSessentaDias = parentDataPedido ? new Date(parentDataPedido) : new Date();
        trintaSessentaDias.setDate(trintaSessentaDias.getDate() + 60);
        formik.setFieldValue("dataVencimento", format(trintaSessentaDias, "yyyy-MM-dd"));
        setDisableDataVencimento(true);
        break;

      case PRAZO_PAGAMENTO.TRINTA_QUARENTA_CINCO_SESSENTA:
        calculaParcelas();
        setHideDataVencimento(true);
        formik.setFieldValue("descricao", `PARCELADO ` + findPrazoPagamento(PRAZO_PAGAMENTO.TRINTA_QUARENTA_CINCO_SESSENTA));
        const trintaQuarentaCincoSessentaDias = parentDataPedido ? new Date(parentDataPedido) : new Date();
        trintaQuarentaCincoSessentaDias.setDate(trintaQuarentaCincoSessentaDias.getDate() + 60);
        formik.setFieldValue("dataVencimento", format(trintaQuarentaCincoSessentaDias, "yyyy-MM-dd"));
        setDisableDataVencimento(true);
        break;

      case PRAZO_PAGAMENTO.TRINTA_SESSENTA_NOVENTA:
        calculaParcelas();
        setHideDataVencimento(true);
        formik.setFieldValue("descricao", `PARCELADO ` + findPrazoPagamento(PRAZO_PAGAMENTO.TRINTA_SESSENTA_NOVENTA));
        const trintaSessentaNoventaDias = parentDataPedido ? new Date(parentDataPedido) : new Date();
        trintaSessentaNoventaDias.setDate(trintaSessentaNoventaDias.getDate() + 90);
        formik.setFieldValue("dataVencimento", format(trintaSessentaNoventaDias, "yyyy-MM-dd"));
        setDisableDataVencimento(true);
        break;
    }
  }, [formik.values.prazoPagamento, formik.values.valor]);

  useEffect(() => {
    const closeOnEscapePressed = (e) => {
      if (e.key === "Escape") {
        setShowAddState(false);
        setSuccess(null);
      }
    };
    window.addEventListener("keydown", closeOnEscapePressed);
    return () => window.removeEventListener("keydown", closeOnEscapePressed);
  }, []);

  return (
    <>
      <MDBox
        pt={"30px"}
        pb={"5%"}
        px={"5%"}
        sx={{
          position: "absolute",
          zIndex: 9999,
          width: "100vw",
          minHeight: "100vh",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.5)",
          backdropFilter: "blur(5px)",
          top: 0,
          right: 0,
          overflow: "auto",
        }}
      >
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={8} mx={"auto"}>
            <Card>
              <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" sx={{ display: "flex", justifyContent: "space-between" }}>
                <MDTypography variant="h6" color="white" mt={1}>
                  Cadastrar Conta à {isReceber ? "Receber" : "Pagar"}{" "}
                </MDTypography>
                <MDButton
                  variant="contained"
                  type="button"
                  onClick={() => {
                    setSuccess(null);
                    setShowAddState(false);
                  }}
                >
                  Voltar
                </MDButton>
              </MDBox>
              <MDBox pt={3} mx={2} py={3} px={2}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 2,
                    mb: 2,
                  }}
                >
                  <TextField id="numPedido" name="numPedido" label="Número do Pedido" value={numPedido} onChange={formik.handleChange} disabled />
                  <TextField
                    id="partidaContabil"
                    name="partidaContabil"
                    label="Partida Contábil"
                    value={formik.values.partidaContabil}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.partidaContabil && Boolean(formik.errors.partidaContabil)}
                    helperText={formik.touched.partidaContabil && formik.errors.partidaContabil}
                    disabled={isEdit}
                  />

                  <TextField
                    id="descricao"
                    name="descricao"
                    label="Descrição"
                    value={formik.values.descricao}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.descricao && Boolean(formik.errors.descricao)}
                    helperText={formik.touched.descricao && formik.errors.descricao}
                    disabled={isEdit}
                  />

                  <TextField
                    id="supplier"
                    name="supplier"
                    label={isReceber ? "Cliente" : "Fornecedor"}
                    value={formik.values.supplier}
                    onChange={handleCustomerChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.supplier && Boolean(formik.errors.supplier)}
                    helperText={formik.touched.supplier && formik.errors.supplier}
                    select
                    SelectProps={{
                      native: true,
                    }}
                    disabled={isEdit}
                  >
                    <option value="" />
                    {!isReceber &&
                      dataList.suppliers?.map((option) => (
                        <option key={option._id} value={option._id}>
                          {option.razaoSocial || option.nomeFantasia}
                        </option>
                      ))}

                    {isReceber &&
                      dataList.customers?.map((option) => (
                        <option key={option._id} value={option._id}>
                          {option.razaoSocial || option.nomeFantasia}
                        </option>
                      ))}
                  </TextField>

                  <TextField
                    id="paymentMethod"
                    name="paymentMethod"
                    label={isReceber ? "Forma de Recebimento" : "Forma de Pagamento"}
                    value={formik.values.paymentMethod}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.paymentMethod && Boolean(formik.errors.paymentMethod)}
                    helperText={formik.touched.paymentMethod && formik.errors.paymentMethod}
                    select
                    SelectProps={{
                      native: true,
                    }}
                    disabled={isEdit}
                  >
                    <option value="" />
                    {dataList.paymentMethods.map((option) => (
                      <option key={option._id} value={option._id}>
                        {option.descricao}
                      </option>
                    ))}
                  </TextField>

                  <TextField
                    id="prazoPagamento"
                    name="prazoPagamento"
                    label="Prazo de Pagamento"
                    value={formik.values.prazoPagamento}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.prazoPagamento && Boolean(formik.errors.prazoPagamento)}
                    helperText={formik.touched.prazoPagamento && formik.errors.prazoPagamento}
                    select
                    SelectProps={{
                      native: true,
                    }}
                    disabled={isEdit}
                  >
                    <option value=""></option>
                    {Object.values(PRAZO_PAGAMENTO).map((prazo) => (
                      <option key={prazo} value={prazo}>
                        {findPrazoPagamento(prazo)}
                      </option>
                    ))}
                  </TextField>

                  <TextField
                    id="dataVencimento"
                    name="dataVencimento"
                    label="Data de Vencimento"
                    type="date"
                    disabled={disableDataVencimento || isEdit}
                    value={formik.values.dataVencimento}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.dataVencimento && Boolean(formik.errors.dataVencimento)}
                    helperText={formik.touched.dataVencimento && formik.errors.dataVencimento}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ display: hideDataVencimento ? "none" : "" }}
                  />

                  <CurrencyInput
                    id="valor"
                    name="valor"
                    label="Valor"
                    value={formik.values.valor}
                    onBlur={formik.handleBlur}
                    disabled={isEdit}
                    decimalsLimit={2}
                    onValueChange={changeCurrency}
                    onBlurCapture={addZero}
                    customInput={TextField}
                    error={formik.touched.valor && Boolean(formik.errors.valor)}
                    helperText={formik.touched.valor && formik.errors.valor}
                    prefix="R$ "
                    intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                  />

                  <TextField
                    id="status"
                    name="status"
                    label="Status"
                    value={formik.values.status}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.status && Boolean(formik.errors.status)}
                    helperText={formik.touched.status && formik.errors.status}
                    select
                    SelectProps={{
                      native: true,
                    }}
                    disabled={isEdit}
                  >
                    <option value=""></option>
                    {Object.values(BILLING_STATUS).map((status) => (
                      <option key={status} value={status}>
                        {findBillingStatus(status)}
                      </option>
                    ))}
                  </TextField>

                  <TextField
                    id="account"
                    name="account"
                    label="Conta"
                    value={formik.values.account}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.account && Boolean(formik.errors.account)}
                    helperText={formik.touched.account && formik.errors.account}
                    select
                    SelectProps={{
                      native: true,
                    }}
                    disabled={isEdit}
                  >
                    <option value="" />
                    {dataList.accounts.map((option) => (
                      <option key={option._id} value={option._id}>
                        {option.nome}
                      </option>
                    ))}
                  </TextField>

                  <TextField
                    id="accountGroup"
                    name="accountGroup"
                    label="Centro de Custo"
                    value={formik.values.accountGroup}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.accountGroup && Boolean(formik.errors.accountGroup)}
                    helperText={formik.touched.accountGroup && formik.errors.accountGroup}
                    select
                    SelectProps={{
                      native: true,
                    }}
                    InputLabelProps={{
                      shrink: formik.values.accountGroup?.length > 0 ? true : false,
                    }}
                    disabled={isEdit}
                  >
                    <option value="" />
                    {dataList.accountGroups.map((option) => (
                      <option key={option._id} value={option._id}>
                        {option.descricao}
                      </option>
                    ))}
                  </TextField>

                  <TextField
                    id="accountSubGroup"
                    name="accountSubGroup"
                    label="Natureza de Lançamento"
                    value={formik.values.accountSubGroup}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.accountSubGroup && Boolean(formik.errors.accountSubGroup)}
                    helperText={formik.touched.accountSubGroup && formik.errors.accountSubGroup}
                    select
                    SelectProps={{
                      native: true,
                    }}
                    InputLabelProps={{
                      shrink: formik.values.accountSubGroup?.length > 0 ? true : false,
                    }}
                    disabled={isEdit}
                  >
                    <option value="" />
                    {dataList.accountSubgroup.map((option) => (
                      <option key={option._id} value={option._id}>
                        {option.nome}
                      </option>
                    ))}
                  </TextField>

                  <TextField
                    id="observacoes"
                    name="observacoes"
                    label="Observações"
                    value={formik.values.observacoes}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.observacoes && Boolean(formik.errors.observacoes)}
                    helperText={formik.touched.observacoes && formik.errors.observacoes}
                    multiline
                    rows={4}
                    sx={{
                      gridColumn: "span 2",
                      justifySelf: "center",
                      width: "100%",
                    }}
                    disabled={isEdit}
                  />

                  {parcelas?.length > 0 && (
                    <div
                      style={{
                        gridColumn: "span 2",
                        justifySelf: "center",
                        width: "100%",
                      }}
                    >
                      <MDTypography
                        variant="h6"
                        color="gray"
                        sx={{
                          textAlign: "center",
                          marginTop: "1rem",
                        }}
                      >
                        Parcelas
                      </MDTypography>

                      <DataTable
                        table={{
                          columns: colunasParcelas,
                          rows: formataParcelas(parcelas),
                        }}
                        isSorted={false}
                        entriesPerPage={{
                          defaultValue: 99,
                        }}
                        noEndBorder
                        disablePagination
                      />
                    </div>
                  )}

                  <Button
                    type="submit"
                    variant="contained"
                    disabled={loading}
                    onClick={formik.handleSubmit}
                    sx={{
                      gridColumn: "span 2",
                      justifySelf: "center",
                      width: "100%",
                      color: "white !important",
                      display: isEdit ? "none" : "block",
                    }}
                  >
                    {loading ? "Carregando..." : "Salvar"}
                  </Button>
                </Box>
                {success !== null && (
                  <Box>
                    <MDAlert color={success ? "success" : "error"} mt={4} dismissible key={alertKey}>
                      <MDTypography variant="body2" color="white">
                        {success ? `Conta à ${isReceber ? "Receber" : "Pagar"}  cadastrada com sucesso!` : `Ocorreu um erro ao cadastrar a conta a ${isReceber ? "receber" : "pagar"}.`}
                      </MDTypography>
                    </MDAlert>
                  </Box>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
