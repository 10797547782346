const maskPhone = (value) => {
  if (value === undefined) return value;

  const cleanValue = value.replace(/\D/g, "");
  const isElevenDigits = cleanValue.length === 11;
  const isTenDigits = cleanValue.length === 10;

  if (isElevenDigits) {
    return cleanValue.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
  }

  if (isTenDigits) {
    return cleanValue.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
  }

  return value;
};

export default maskPhone;
