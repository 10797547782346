import { Box, Grid, Card, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Service from "services/users";
import { yupSchema, initialValues } from "./yupSchema";
import { format } from "utils/formatDate";
import InputMask from "react-input-mask";

export default function DetalharFuncionario({
  setShowDetailState,
  currentIdAction,
  fetchData,
}) {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yupSchema,
  });

  useEffect(() => {
    const fetchList = async () => {
      setLoading(true);
      const fields = await Service.get(currentIdAction);

      for (var key in formik.values) {
        formik.setFieldValue(key, fields[key]);

        if (key.includes("data")) {
          formik.setFieldValue(
            key,
            fields[key] !== undefined && fields[key] !== null
              ? format(new Date(fields[key]), "yyyy-MM-dd")
              : ""
          );
        }
      }

      setLoading(false);
    };

    fetchList();
  }, [currentIdAction]);

  useEffect(() => {
    const closeOnEscapePressed = (e) => {
      if (e.key === "Escape") {
        fetchData();
        setShowDetailState(false);
      }
    };
    window.addEventListener("keydown", closeOnEscapePressed);
    return () => window.removeEventListener("keydown", closeOnEscapePressed);
  }, []);

  return (
    <>
      <MDBox
        pt={"30px"}
        sx={{
          position: "absolute",
          zIndex: 9999,
          width: "100vw",
          minHeight: "100vh",
          top: 0,
          right: 0,
        }}
      >
        <MDBox
          pt={"30px"}
          sx={{
            position: "fixed",
            zIndex: -1,
            width: "100vw",
            height: "110%",
            backgroundColor: "rgba(0,0,0,0.5)",
            backdropFilter: "blur(5px)",
            top: 0,
            right: 0,
          }}
        />
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={8} mx={"auto"}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  Detalhes
                </MDTypography>
                <MDButton
                  variant="contained"
                  type="button"
                  onClick={() => {
                    fetchData();
                    setShowDetailState(false);
                  }}
                >
                  Fechar
                </MDButton>
              </MDBox>
              <MDBox pt={3} mx={2} py={3} px={2}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 2,
                    mb: 2,
                  }}
                  component="form"
                  onSubmit={formik.handleSubmit}
                >
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="name"
                    name="name"
                    label="Nome"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="email"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="role"
                    name="role"
                    label="Acesso"
                    value={formik.values.role}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.role && Boolean(formik.errors.role)}
                    helperText={formik.touched.role && formik.errors.role}
                  />

                  <InputMask
                    mask={"999.999.999-99"}
                    value={formik.values.cpfCnpj}
                    onChange={formik.handleChange}
                  >
                    {(inputProps) => (
                      <TextField
                        id="cpf"
                        name="cpf"
                        label="CPF"
                        error={formik.touched.cpf && Boolean(formik.errors.cpf)}
                        helperText={formik.touched.cpf && formik.errors.cpf}
                        {...inputProps}
                      />
                    )}
                  </InputMask>

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="dataNascimento"
                    name="dataNascimento"
                    label="Data de Nascimento"
                    type="date"
                    value={formik.values.dataNascimento}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="logradouro"
                    name="logradouro"
                    label="Logradouro"
                    value={formik.values.logradouro}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.logradouro &&
                      Boolean(formik.errors.logradouro)
                    }
                    helperText={
                      formik.touched.logradouro && formik.errors.logradouro
                    }
                  />

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="numero"
                    name="numero"
                    label="Número"
                    value={formik.values.numero}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.numero && Boolean(formik.errors.numero)
                    }
                    helperText={formik.touched.numero && formik.errors.numero}
                  />

                  <InputMask
                    mask="99.999-999"
                    value={formik.values.cep}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      formik.handleBlur(e);
                      pesquisaCep();
                    }}
                  >
                    {(inputProps) => (
                      <TextField
                        id="cep"
                        name="cep"
                        label="CEP"
                        error={formik.touched.cep && Boolean(formik.errors.cep)}
                        helperText={formik.touched.cep && formik.errors.cep}
                        {...inputProps}
                      />
                    )}
                  </InputMask>

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="complemento"
                    name="complemento"
                    label="Complemento"
                    value={formik.values.complemento}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.complemento &&
                      Boolean(formik.errors.complemento)
                    }
                    helperText={
                      formik.touched.complemento && formik.errors.complemento
                    }
                  />

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="bairro"
                    name="bairro"
                    label="Bairro"
                    value={formik.values.bairro}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.bairro && Boolean(formik.errors.bairro)
                    }
                    helperText={formik.touched.bairro && formik.errors.bairro}
                  />

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="uf"
                    name="uf"
                    label="UF"
                    value={formik.values.uf}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.uf && Boolean(formik.errors.uf)}
                    helperText={formik.touched.uf && formik.errors.uf}
                  />

                  <InputMask
                    mask={
                      formik.values.telefone?.length > 14
                        ? "(99) 99999-9999"
                        : "(99) 9999-99999"
                    }
                    maskChar=""
                    value={formik.values.telefone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    {(inputProps) => (
                      <TextField
                        id="telefone"
                        name="telefone"
                        label="Telefone"
                        error={
                          formik.touched.telefone &&
                          Boolean(formik.errors.telefone)
                        }
                        helperText={
                          formik.touched.telefone && formik.errors.telefone
                        }
                        {...inputProps}
                      />
                    )}
                  </InputMask>

                  <InputMask
                    mask={
                      formik.values.celular?.length > 14
                        ? "(99) 99999-9999"
                        : "(99) 9999-99999"
                    }
                    maskChar=""
                    value={formik.values.celular}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    {(inputProps) => (
                      <TextField
                        id="celular"
                        name="celular"
                        label="Celular"
                        value={formik.values.celular}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.celular &&
                          Boolean(formik.errors.celular)
                        }
                        helperText={
                          formik.touched.celular && formik.errors.celular
                        }
                        {...inputProps}
                      />
                    )}
                  </InputMask>

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="comissao"
                    name="comissao"
                    label="Comissão"
                    type="number"
                    value={formik.values.comissao}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.comissao && Boolean(formik.errors.comissao)
                    }
                    helperText={
                      formik.touched.comissao && formik.errors.comissao
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="observacao"
                    name="observacao"
                    label="Observação"
                    value={formik.values.observacao}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.observacao &&
                      Boolean(formik.errors.observacao)
                    }
                    helperText={
                      formik.touched.observacao && formik.errors.observacao
                    }
                    multiline
                    rows={4}
                    sx={{
                      gridColumn: "span 2",
                      justifySelf: "center",
                      width: "100%",
                    }}
                  />
                </Box>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
