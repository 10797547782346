import { Box, Button, Card, Grid, TextField, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { format } from "utils/formatDate";
import ptBR from "date-fns/locale/pt-BR";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import ReportsService from "services/reports";
import { dotToComma } from "utils/numberComma";
registerLocale("ptBR", ptBR);

import { DataGrid } from "@mui/x-data-grid";
import "react-datepicker/dist/react-datepicker.css";
import { exportToExcel } from "react-json-to-excel";
import { ptBR_DataGrid } from "utils/datagrid";
import { commaToDot } from "utils/numberComma";

export default function CarregamentoDia() {
  const [dia, setDia] = useState(new Date());
  const [extrato, setExtrato] = useState([]);
  const [loading, setLoading] = useState(true);

  async function fetchExtrato(changeDia = null) {
    setLoading(true);

    try {
      const data = await ReportsService.carregamentoDia(
        changeDia ? changeDia : dia.toISOString()
      );

      setExtrato(data);
      setSaldoDiario(data);
      setLoading(false);
    } catch (error) {
      console.error("Erro ao buscar extrato:", error);
      setLoading(false);
    }
  }

  const handleDateChange = async (date) => {
    setDia(date);
    if (!loading) fetchExtrato(date.toISOString());
  };

  const printReport = (report) => {
    exportToExcel(report, "carregamento_dia_" + new Date().getTime(), true);
  };

  const cleanRows = (rows) => {
    return rows.map((row) => {
      const purchase = row.purchase;

      const totalFrete =
        Number(commaToDot(purchase.carregamento)) +
        Number(commaToDot(purchase.valorFrete)) +
        Number(commaToDot(purchase.valorIcms));

      const totalPagarFrete =
        totalFrete - Number(commaToDot(purchase.adiantamentoFrete));

      let totalFinal = totalPagarFrete + 0;
      let totalQtdProdutos = 0;

      purchase.products?.forEach((produto) => {
        totalFinal += produto.price * produto.quantity;
        totalQtdProdutos += produto.quantity;
      });

      let custoUnitario = totalFinal > 0 ? totalFinal / totalQtdProdutos : 0;

      return {
        Data: format(new Date(row.data), "dd/MM/yyyy"),
        Pedido: row.numPedido,
        "Peso Líquido": row.pesoLiquido,
        Destino: row.customer?.razaoSocial,
        "Vl. Venda": dotToComma(custoUnitario.toFixed(2)),
        Motorista: row.motorista,
        Caminhão: row.caminhao,
        Fornecedor: row.purchase?.supplier?.razaoSocial,
        "Vl. Compra": row.vlCompra,
      };
    });
  };

  useEffect(() => {
    fetchExtrato();
  }, []);

  return (
    <DashboardLayout>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  Carregamento do Dia
                </MDTypography>
              </MDBox>
              <MDBox pt={3} mx={2} py={3} px={2}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "2fr 1fr",
                  }}
                >
                  <Typography variant="h4" gutterBottom>
                    Carregamento do dia: ({format(dia, "dd/MM/yyyy")})
                  </Typography>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginBottom: 8,
                    }}
                  >
                    <DatePicker
                      selected={dia}
                      onChange={handleDateChange} //only when value has changed
                      locale="ptBR"
                      dateFormat="dd/MM/yyyy"
                      customInput={<TextField style={{ paddingLeft: 4 }} />}
                    />
                  </div>
                </Box>
                {loading && (
                  <Typography variant="body1">Carregando...</Typography>
                )}
                {!loading && (
                  <DataGrid
                    rows={extrato}
                    // empty
                    localeText={ptBR_DataGrid}
                    sx={{
                      ".MuiDataGrid-virtualScroller": {
                        minHeight: "100px",
                      },
                    }}
                    columns={[
                      {
                        field: "data",
                        headerName: "Data",
                        flex: 1,
                        renderCell: (params) => (
                          <Typography>
                            {format(new Date(params.row.data), "dd/MM/yyyy")}
                          </Typography>
                        ),
                      },
                      {
                        field: "numPedido",
                        headerName: "Pedido",
                        flex: 1,
                      },
                      {
                        field: "pesoLiquido",
                        headerName: "Peso Líquido",
                        flex: 1,
                      },
                      {
                        field: "customer",
                        headerName: "Destino",
                        flex: 1,
                        renderCell: (params) => (
                          <Typography>
                            {params.row.customer?.razaoSocial}
                          </Typography>
                        ),
                      },
                      {
                        field: "vlVenda",
                        headerName: "Vl. Venda",
                        flex: 1,
                        renderCell: (params) => {
                          const purchase = params.row.purchase;

                          const totalFrete =
                            Number(commaToDot(purchase.carregamento)) +
                            Number(commaToDot(purchase.valorFrete)) +
                            Number(commaToDot(purchase.valorIcms));

                          const totalPagarFrete =
                            totalFrete -
                            Number(commaToDot(purchase.adiantamentoFrete));

                          let totalFinal = totalPagarFrete + 0;
                          let totalQtdProdutos = 0;

                          purchase.products?.forEach((produto) => {
                            totalFinal += produto.price * produto.quantity;
                            totalQtdProdutos += produto.quantity;
                          });

                          let custoUnitario =
                            totalFinal > 0 ? totalFinal / totalQtdProdutos : 0;

                          return (
                            <Typography>
                              R$ {dotToComma(custoUnitario.toFixed(2))}
                            </Typography>
                          );
                        },
                      },
                      {
                        field: "motorista",
                        headerName: "Motorista",
                        flex: 1,
                      },
                      {
                        field: "caminhao",
                        headerName: "Caminhão",
                        flex: 1,
                      },
                      {
                        field: "supplier",
                        headerName: "Fornecedor",
                        flex: 1,
                        renderCell: (params) => (
                          <Typography>
                            {params.row.purchase?.supplier?.razaoSocial}
                          </Typography>
                        ),
                      },
                      {
                        field: "vlCompra",
                        headerName: "Vl. Compra",
                        flex: 1,
                      },
                    ]}
                    pageSize={5}
                    componentsProps={{
                      cell: {
                        style: {
                          fontWeight: "bold",
                        },
                      },
                    }}
                    getRowId={(row) => row?._id}
                  />
                )}
              </MDBox>
            </Card>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                p: 2,
              }}
            >
              <Button
                variant="contained"
                onClick={() =>
                  printReport([
                    {
                      sheetName: "Carregamento do Dia",
                      details: cleanRows(extrato),
                    },
                  ])
                }
                sx={{
                  color: "white !important",
                }}
              >
                Exportar Relatório Para Excel
              </Button>
            </Box>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
