import HttpService from "./http.service";

class StockDashboardService {
  listByWarehouse = async (warehouseId) => {
    const stockDashboardEndpoint = `/stock-dashboard/warehouse/${warehouseId}`;
    return await HttpService.get(stockDashboardEndpoint);
  };

  create = async (data) => {
    const stockDashboardEndpoint = "/stock-dashboard";
    return await HttpService.post(stockDashboardEndpoint, data);
  };

  update = async (id, data) => {
    const stockDashboardEndpoint = "/stock-dashboard";
    return await HttpService.put(`${stockDashboardEndpoint}/${id}`, data);
  };

  delete = async (id) => {
    const stockDashboardEndpoint = "/stock-dashboard";
    return await HttpService.delete(`${stockDashboardEndpoint}/${id}`);
  };
}

export default new StockDashboardService();
