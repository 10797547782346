import HttpService from "./http.service";

class PayablesService {
  list = async (sortStr = null, filterStr = null) => {
    const payablesEndpoint = `/payables?limit=-1${sortStr ? sortStr : ""}${
      filterStr ? filterStr : ""
    }`;
    return await HttpService.get(payablesEndpoint);
  };

  paginate = async (page = 0, limit = 25, sortStr = null, filterStr = null) => {
    const payablesEndpoint = `/payables?page=${page}&limit=${limit}${
      sortStr ? sortStr : ""
    }${filterStr ? filterStr : ""}`;
    return await HttpService.get(payablesEndpoint);
  };

  get = async (id) => {
    const payablesEndpoint = "/payables";
    return await HttpService.get(`${payablesEndpoint}/${id}`);
  };

  getlastMonths = async () => {
    const payablesEndpoint = "/payables/last-months";
    return await HttpService.get(payablesEndpoint);
  };

  filter = async (data) => {
    const dataWithoutUndefined = Object.keys(data).reduce((object, key) => {
      if (data[key] !== undefined && data[key] !== "" && data[key] !== null) {
        object[key] = data[key];
      }
      return object;
    }, {});
    const query = new URLSearchParams(dataWithoutUndefined).toString();
    const payablesEndpoint = `/payables/filter?${query}`;
    return await HttpService.get(payablesEndpoint, data);
  };

  agreementWithSuppliers = async (data) => {
    const dataWithoutUndefined = Object.keys(data).reduce((object, key) => {
      if (data[key] !== undefined && data[key] !== "" && data[key] !== null) {
        object[key] = data[key];
      }
      return object;
    }, {});
    const query = new URLSearchParams(dataWithoutUndefined).toString();
    const payablesEndpoint = `/payables/agreement-suppliers?${query}`;
    return await HttpService.get(payablesEndpoint, data);
  };

  averageTicket = async (data) => {
    const dataWithoutUndefined = Object.keys(data).reduce((object, key) => {
      if (data[key] !== undefined && data[key] !== "" && data[key] !== null) {
        object[key] = data[key];
      }
      return object;
    }, {});
    const query = new URLSearchParams(dataWithoutUndefined).toString();
    const payablesEndpoint = `/payables/average-ticket?${query}`;
    return await HttpService.get(payablesEndpoint, data);
  };

  notPaid = async (data) => {
    const dataWithoutUndefined = Object.keys(data).reduce((object, key) => {
      if (data[key] !== undefined && data[key] !== "" && data[key] !== null) {
        object[key] = data[key];
      }
      return object;
    }, {});
    const query = new URLSearchParams(dataWithoutUndefined).toString();
    const payablesEndpoint = `/payables/not-paid?${query}`;
    return await HttpService.get(payablesEndpoint, data);
  };

  createPayableByNfe = async (data) => {
    const form = new FormData();
    form.append("file", data);

    const payablesEndpoint = "/payables/import-nfe";
    return await HttpService.postFile(payablesEndpoint, form);
  };

  create = async (data) => {
    const payablesEndpoint = "/payables";
    return await HttpService.post(payablesEndpoint, data);
  };

  update = async (id, data) => {
    const payablesEndpoint = "/payables";
    return await HttpService.put(`${payablesEndpoint}/${id}`, data);
  };

  delete = async (id) => {
    const payablesEndpoint = "/payables";
    return await HttpService.delete(`${payablesEndpoint}/${id}`);
  };
}

export default new PayablesService();
