import { Icon, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import Service from "services/products";

export default function data(
  handleEdit,
  isAdmin = false,
  setLoading = () => {}
) {
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [sortStr, setSortStr] = useState(null);
  const [filterStr, setFilterStr] = useState(null);

  const fetchData = async () => {
    setLoading(true);

    const { data, limit, total } = await Service.listBatchesPaginate(
      page,
      perPage,
      sortStr,
      filterStr
    );

    setPerPage(limit);
    setTotalRows(total);

    setRows(
      data.map((row) =>
        Object({
          id: row._id,
          action: row,
          lote: row.lote,
          produto: row.produto.descricao,
          quantidade: row.quantidade,
          quantidadeComprada: row.quantidadeComprada,
          quantidadeDescartada: row.quantidadeDescartada || 0,
          pedidoDeCompra: row.pedidoDeCompra.numPedido,
        })
      )
    );

    setLoading(false);
  };

  const action = (props) => {
    const row = props?.row?.action;

    return (
      <MDBox display="flex" gap={1}>
        <Tooltip
          title={
            row.quantidadeDescartada > 0 && !isAdmin
              ? "Descarte já adicionado, contate a administração."
              : "Adicionar Descarte"
          }
        >
          <MDButton
            variant="gradient"
            color={row.quantidadeDescartada > 0 && !isAdmin ? "error" : "info"}
            fullWidth
            type="submit"
            onClick={() =>
              row.quantidadeDescartada > 0 && !isAdmin
                ? undefined
                : handleEdit(row)
            }
            iconOnly
          >
            <Icon fontSize="small">add</Icon>
          </MDButton>
        </Tooltip>
      </MDBox>
    );
  };

  const updatePagination = ({ page: newPage, pageSize: newPerPage }) => {
    setPage(newPage);
    setPerPage(newPerPage);
  };

  const sortBy = (sortArr) => {
    if (sortArr.length === 0) {
      setSortStr(null);
      return;
    }

    const [filter] = sortArr;

    setSortStr(
      `&sortBy=${filter.field}&sortOrder=${filter.sort === "asc" ? 1 : "-1"}`
    );
  };

  const filterBy = ({ items }) => {
    if (items.length === 0) {
      setFilterStr(null);
      return;
    }

    const [filter] = items;

    if (filter.operator === "contains" && filter.value === undefined) {
      return;
    }

    setFilterStr(
      `&filterField=${filter.field}&filterOperator=${filter.operator}&filterValue=${filter.value}`
    );
  };

  useEffect(() => {
    fetchData();
  }, [page, perPage, sortStr, filterStr]);

  return {
    columns: [
      { headerName: "Lote", field: "lote", flex: 0.2 },
      { headerName: "Produto", field: "produto", flex: 0.2 },
      { headerName: "Quantidade Disponível", field: "quantidade", flex: 0.2 },
      {
        headerName: "Quantidade Descartada",
        field: "quantidadeDescartada",
        flex: 0.2,
      },
      {
        headerName: "Quantidade Comprada",
        field: "quantidadeComprada",
        flex: 0.2,
      },
      {
        headerName: "Num. Pedido de Compra",
        field: "pedidoDeCompra",
        flex: 0.2,
      },
      { headerName: "Ações", field: "action", flex: 0.2, renderCell: action },
    ],

    rows,
    fetchData,
    updatePagination,
    sortBy,
    filterBy,
    pageObject: {
      totalRows,
      perPage,
      page,
    },
  };
}
