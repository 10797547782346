import { Alert, Box, Button, Card, Grid, TextField, Icon } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { useFormik } from "formik";
import AdicionarGrupoConta from "layouts/grupos-contas";
import { useState } from "react";
import { exportToExcel } from "react-json-to-excel";
import Service from "services/stock-transactions";
import { ptBR_DataGrid } from "utils/datagrid";

const initialValues = {
  startDate: "",
  endDate: "",
  accountGroupOutput: "",
  accountGroupInput: "",
  type: "",
};

export default function AlmoxarifadoReport() {
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState([]);

  const [childData, setChildData] = useState({
    centroDeCustoOutput: null,
    centroDeCustoInput: null,
  });

  const [showAddCentroDeCustoOutput, setShowAddCentroDeCustoOutput] =
    useState(false);
  const [showAddCentroDeCustoInput, setShowAddCentroDeCustoInput] =
    useState(false);

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      setLoading(true);

      try {
        const data = await Service.getReport({
          ...values,
        });

        const transactions = parseTransactions(data);

        if (!transactions.length) {
          alert("Nenhum resultado encontrado");
        }

        setReport(transactions);

        setLoading(false);
      } catch (error) {
        console.log(error);
      }

      setLoading(false);
    },
  });

  const parseTransactions = (data) => {
    return data.map((transaction) => {
      return {
        Produto: transaction.product?.descricao,
        "Preço Unitário": new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(transaction.unityPrice),
        Quantidade: transaction.quantity,
        Tipo: transaction.type === "input" ? "Entrada" : "Saída",
        "Centro de Custo de Entrada": transaction.accountGroupInput?.descricao,
        "Centro de Custo de Saída": transaction.accountGroupOutput?.descricao,
        "Descrição da Transação": transaction.description,
        Data: new Date(transaction.date).toLocaleDateString(),
      };
    });
  };

  const doExportExcel = () => {
    if (report.length > 0) {
      exportToExcel(report, "prime_" + new Date().getTime());
    }
  };

  return (
    <DashboardLayout>
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  Relatório de Movimentações do Almoxarifado
                </MDTypography>
              </MDBox>
              <MDBox pt={3} mx={2} py={3} px={2}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 2,
                    mb: 2,
                  }}
                  component="form"
                  onSubmit={formik.handleSubmit}
                >
                  <TextField
                    id="startDate"
                    name="startDate"
                    label="Data Inicial"
                    type="date"
                    value={formik.values.startDate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={loading}
                    error={
                      formik.touched.startDate &&
                      Boolean(formik.errors.startDate)
                    }
                    helperText={
                      formik.touched.startDate && formik.errors.startDate
                    }
                    sx={{
                      width: "100%",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    id="endDate"
                    name="endDate"
                    label="Data Final"
                    type="date"
                    value={formik.values.endDate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={loading}
                    error={
                      formik.touched.endDate && Boolean(formik.errors.endDate)
                    }
                    helperText={formik.touched.endDate && formik.errors.endDate}
                    sx={{
                      width: "100%",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <Alert
                    icon={false}
                    severity="success"
                    action={
                      <MDButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        iconOnly
                        onClick={() => setShowAddCentroDeCustoOutput(true)}
                      >
                        <Icon fontSize="small">edit</Icon>
                      </MDButton>
                    }
                    sx={{
                      border: formik.errors.accountGroupOutput
                        ? "1px solid red"
                        : "",
                    }}
                  >
                    <b>Centro de Custo de Saída: </b>
                    {childData.centroDeCustoOutput?.descricao}
                  </Alert>

                  <Alert
                    icon={false}
                    severity="success"
                    action={
                      <MDButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        iconOnly
                        onClick={() => setShowAddCentroDeCustoInput(true)}
                      >
                        <Icon fontSize="small">edit</Icon>
                      </MDButton>
                    }
                    sx={{
                      border: formik.errors.accountGroupInput
                        ? "1px solid red"
                        : "",
                    }}
                  >
                    <b>Centro de Custo de Entrada: </b>
                    {childData.centroDeCustoInput?.descricao}
                  </Alert>

                  <TextField
                    id="type"
                    name="type"
                    label="Tipo de Transação"
                    value={formik.values.type}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.type && Boolean(formik.errors.type)}
                    helperText={formik.touched.type && formik.errors.type}
                    SelectProps={{
                      native: true,
                    }}
                    select
                  >
                    <option value=""></option>
                    <option value="input">Entrada</option>
                    <option value="output">Saída</option>
                  </TextField>

                  <Button
                    type="submit"
                    variant="contained"
                    disabled={loading}
                    sx={{
                      gridColumn: "span 2",
                      justifySelf: "center",
                      width: "100%",
                      color: "white !important",
                    }}
                  >
                    {loading ? "Carregando..." : "Gerar Relatório"}
                  </Button>
                </Box>
              </MDBox>
            </Card>

            {report.length > 0 && (
              <>
                <Card sx={{ marginTop: 4 }}>
                  <DataGrid
                    initialState={{
                      pagination: { paginationModel: { pageSize: 100 } },
                    }}
                    pageSizeOptions={[100, 200]}
                    rows={report.map((row, index) =>
                      Object({ ...row, id: index })
                    )}
                    columns={Object.keys(report[0]).map((key, idx) =>
                      Object({
                        headerName: key,
                        field: key,
                        flex: 1,
                        idx: idx,
                      })
                    )}
                    rowCount={report.length}
                    rowSelection={false}
                    localeText={ptBR_DataGrid}
                    sx={{
                      ".MuiDataGrid-virtualScroller": {
                        minHeight: "100px",
                      },
                    }}
                  />
                </Card>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    p: 2,
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() => doExportExcel()}
                    sx={{
                      color: "white !important",
                    }}
                  >
                    Exportar para Excel
                  </Button>
                </Box>
              </>
            )}
          </Grid>
        </Grid>

        {showAddCentroDeCustoInput && (
          <MDBox
            pt={"30px"}
            pb={"5%"}
            px={"5%"}
            sx={{
              position: "absolute",
              zIndex: 9999,
              width: "100vw",
              minHeight: "100vh",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.5)",
              backdropFilter: "blur(5px)",
              top: 0,
              right: 0,
              overflow: "auto",
            }}
          >
            <AdicionarGrupoConta
              setShowAddCentroCusto={setShowAddCentroDeCustoInput}
              selectParent={(data) => {
                setChildData({
                  ...childData,
                  centroDeCustoInput: data,
                });
                setShowAddCentroDeCustoInput(false);
                formik.setFieldValue("accountGroupInput", data._id);
              }}
              disableForm={true}
              fromParent={true}
            />
          </MDBox>
        )}

        {/* forms alternativos */}

        {showAddCentroDeCustoOutput && (
          <MDBox
            pt={"30px"}
            pb={"5%"}
            px={"5%"}
            sx={{
              position: "absolute",
              zIndex: 9999,
              width: "100vw",
              minHeight: "100vh",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.5)",
              backdropFilter: "blur(5px)",
              top: 0,
              right: 0,
              overflow: "auto",
            }}
          >
            <AdicionarGrupoConta
              setShowAddCentroCusto={setShowAddCentroDeCustoOutput}
              selectParent={(data) => {
                setChildData({
                  ...childData,
                  centroDeCustoOutput: data,
                });
                setShowAddCentroDeCustoOutput(false);
                formik.setFieldValue("accountGroupOutput", data._id);
              }}
              disableForm={true}
              fromParent={true}
            />
          </MDBox>
        )}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
