import * as yup from "yup";

export const TIPO_PESSOA = {
  PESSOA_FISICA: "F",
  PESSOA_JURIDICA: "J",
};

export const yupSchema = yup.object().shape({
  tipoPessoa: yup
    .string()
    .oneOf(Object.values(TIPO_PESSOA))
    .required("Esse campo é obrigatório"),
  cpfCnpj: yup.string().required("Esse campo é obrigatório"),
  inscricaoEstadual: yup.string(),
  inscricaoMunicipal: yup.string(),
  razaoSocial: yup.string(),
  nomeFantasia: yup.string(),
  cep: yup.string(),
  logradouro: yup.string().max(60, "Máximo de 60 caracteres"),
  numero: yup.string(),
  complemento: yup.string(),
  bairro: yup.string(),
  cidade: yup.string(),
  uf: yup.string(),
  telefone: yup.string(),
  celular: yup.string(),
  observacao: yup.string(),
  ativo: yup.boolean(),
  ativo_obs: yup.string(),
});

export const initialValues = {
  tipoPessoa: "",
  cpfCnpj: "",
  inscricaoEstadual: "",
  inscricaoMunicipal: "",
  razaoSocial: "",
  nomeFantasia: "",
  dtNascimento: null,
  cep: "",
  logradouro: "",
  numero: "",
  complemento: "",
  bairro: "",
  codigoIbge: "",
  cidade: "",
  uf: "",
  telefone: "",
  celular: "",
  email: "",
  limiteCredito: 0,
  observacao: "",
  prazoPagamento: null,
  paymentMethod: null,
  vendedores: [],
  ativo: true,
  ativo_obs: "",
};
