// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { DataGrid } from "@mui/x-data-grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Data
import MDButton from "components/MDButton";
import { useState } from "react";

import MDDialog from "components/MDDialog";

import AdicionarDado from "./components/addData";
import DetalharDado from "./components/detailData";
import EditarDado from "./components/editData";
import tableData from "./components/tableData";

import Service from "services/batchOfSettlements";
import { ptBR_DataGrid } from "utils/datagrid";

export default function LotesBaixa() {
  const [showAddState, setShowAddState] = useState(false);
  const [showEditState, setShowEditState] = useState(false);
  const [showDetailState, setShowDetailState] = useState(false);
  const [dialogRemove, setDialogRemove] = useState(false);
  const [dialogText, setDialogText] = useState("");
  const [currentIdAction, setCurrentIdAction] = useState(null);
  const [loading, setLoading] = useState(false);

  const [motivoExclusao, setMotivoExclusao] = useState("");

  const handleDetail = (id) => {
    setCurrentIdAction(id);
    setShowDetailState(true);
  };

  const handleEdit = (id) => {
    setCurrentIdAction(id);
    setShowEditState(true);
    window.scrollTo(0, 0);
  };

  const handleDelete = (obj) => {
    setDialogRemove(true);
    setDialogText(obj.nome + "\n" + obj.descricao);
    setCurrentIdAction(obj._id);
  };

  const cancelDelete = () => {
    setDialogRemove(false);
    setDialogText("");
    setMotivoExclusao("");
    setCurrentIdAction(null);
  };

  const confirmDelete = async () => {
    setLoading(true);
    await Service.delete(currentIdAction, { motivoExclusao: motivoExclusao });
    setDialogRemove(false);
    fetchData();
  };

  const {
    columns,
    rows,
    fetchData,
    updatePagination,
    sortBy,
    filterBy,
    pageObject,
  } = tableData(handleEdit, handleDelete, handleDetail, setLoading);

  return (
    <DashboardLayout>
      <MDBox pt={3} pb={3}>
        {showEditState && (
          <EditarDado
            setShowEditState={setShowEditState}
            currentIdAction={currentIdAction}
            fetchData={fetchData}
          />
        )}
        {showAddState && (
          <AdicionarDado
            setShowAddState={setShowAddState}
            fetchData={fetchData}
          />
        )}
        {showDetailState && (
          <DetalharDado
            setShowDetailState={setShowDetailState}
            currentIdAction={currentIdAction}
            fetchData={fetchData}
          />
        )}
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  Lotes de Baixa
                </MDTypography>
                <div>
                  <MDButton
                    variant="contained"
                    type="button"
                    onClick={() => setShowAddState(true)}
                  >
                    Adicionar
                  </MDButton>
                </div>
              </MDBox>
              <MDBox pt={3}>
                <Card sx={{ padding: 2 }}>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    rowCount={pageObject.totalRows}
                    pageSizeOptions={[50, 100]}
                    paginationModel={{
                      pageSize: pageObject.perPage,
                      page: pageObject.page,
                    }}
                    paginationMode="server"
                    onPaginationModelChange={updatePagination}
                    sortingMode="server"
                    onSortModelChange={sortBy}
                    filterMode="server"
                    onFilterModelChange={filterBy}
                    rowSelection={false}
                    loading={loading}
                    localeText={ptBR_DataGrid}
                    sx={{
                      ".MuiDataGrid-virtualScroller": {
                        minHeight: "100px",
                      },
                    }}
                  />
                </Card>
              </MDBox>
            </Card>
          </Grid>
        </Grid>

        <MDDialog
          open={dialogRemove}
          handleClose={() => setDialogRemove(false)}
          title={"Tem certeza que deseja remover?"}
          actions={
            <>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                type="submit"
                onClick={() => confirmDelete()}
                disabled={motivoExclusao === "" || loading}
              >
                Confirmar
              </MDButton>
              <MDButton
                variant="gradient"
                color="error"
                fullWidth
                type="submit"
                onClick={() => cancelDelete()}
              >
                Cancelar
              </MDButton>
            </>
          }
        >
          {dialogText.split("\n").map((item, key) => {
            return (
              <span key={key}>
                {item}
                <br />
              </span>
            );
          })}

          <MDBox>
            <MDTypography variant="h6" color="error">
              Motivo da exclusão
            </MDTypography>
            <MDBox>
              <textarea
                value={motivoExclusao}
                onChange={(e) => setMotivoExclusao(e.target.value)}
                style={{ width: "100%", height: "100px" }}
                disabled={loading}
              />
            </MDBox>
          </MDBox>
        </MDDialog>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
