import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
} from "@mui/material";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import ProductGroupService from "services/product-groups";
import Service from "services/products";
import {
  administrationMethodList,
  initialValues,
  itemAdministrationList,
  itemGroupList,
  umGroupList,
  unidadeList,
  yupSchema,
} from "./yupSchema";

export default function AdicionarProduto({
  setShowAddState,
  fetchData,
  disableForm = false,
}) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [productGroupsList, setProductGroupsList] = useState([]);
  const [alertKey, setAlertKey] = useState(0);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yupSchema,
    onSubmit: async (values) => {
      setLoading(true);

      try {
        await Service.create(values);
        formik.resetForm();
        setSuccess(true);
      } catch (error) {
        console.log(error);
        setSuccess(false);
      }

      setAlertKey(alertKey + 1);

      setLoading(false);
    },
  });

  useEffect(() => {
    const fetchProductGroups = async () => {
      setLoading(true);
      try {
        const { data } = await ProductGroupService.list();
        setProductGroupsList(data);
      } catch (error) {
        console.log(error);
      }

      setLoading(false);
    };

    fetchProductGroups();
  }, []);

  useEffect(() => {
    const closeOnEscapePressed = (e) => {
      if (e.key === "Escape") {
        fetchData();
        setShowAddState(false);
        setSuccess(null);
      }
    };
    window.addEventListener("keydown", closeOnEscapePressed);
    return () => window.removeEventListener("keydown", closeOnEscapePressed);
  }, []);

  useEffect(() => {
    const findProductGroup = productGroupsList.find(
      (group) => group._id === formik.values.productGroup
    );

    if (findProductGroup) {
      formik.setFieldValue(
        "isAlmoxarifado",

        findProductGroup.descricao === "ALMOXARIFADO"
      );
    }

    setSuccess(null);
  }, [formik.values.productGroup]);

  const touchAllFields = () => {
    for (var key in formik.errors) {
      formik.setFieldTouched(key, true);
    }
  };

  return (
    <>
      <MDBox
        pt={"30px"}
        sx={{
          position: "absolute",
          zIndex: 9999,
          width: "100vw",
          minHeight: "100vh",
          top: 0,
          right: 0,
        }}
      >
        <MDBox
          pt={"30px"}
          sx={{
            position: "fixed",
            zIndex: -1,
            width: "100vw",
            height: "110%",
            backgroundColor: "rgba(0,0,0,0.5)",
            backdropFilter: "blur(5px)",
            top: 0,
            right: 0,
          }}
        />
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={8} mx={"auto"}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  Cadastrar Produto
                </MDTypography>
                <MDButton
                  variant="contained"
                  type="button"
                  onClick={() => {
                    fetchData();
                    setSuccess(null);
                    setShowAddState(false);
                  }}
                >
                  Voltar
                </MDButton>
              </MDBox>
              <MDBox pt={3} mx={2} py={3} px={2}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 2,
                    mb: 2,
                  }}
                  component={disableForm ? "div" : "form"}
                >
                  <TextField
                    id="productGroup"
                    name="productGroup"
                    label="Grupo de Produto"
                    value={formik?.values?.productGroup}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.productGroup &&
                      Boolean(formik.errors.productGroup)
                    }
                    helperText={
                      formik.touched.productGroup && formik.errors.productGroup
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    select
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option value="" />
                    {productGroupsList.map((option) => (
                      <option key={option._id} value={option._id}>
                        {option.descricao}
                      </option>
                    ))}
                  </TextField>

                  <TextField
                    id="referencia"
                    name="referencia"
                    label="Referência"
                    value={formik.values.referencia}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.referencia &&
                      Boolean(formik.errors.referencia)
                    }
                    helperText={
                      formik.touched.referencia && formik.errors.referencia
                    }
                  />

                  <TextField
                    id="nomeEstrangeiro"
                    name="nomeEstrangeiro"
                    label="Nome Estrangeiro"
                    value={formik.values.nomeEstrangeiro}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.nomeEstrangeiro &&
                      Boolean(formik.errors.nomeEstrangeiro)
                    }
                    helperText={
                      formik.touched.nomeEstrangeiro &&
                      formik.errors.nomeEstrangeiro
                    }
                  />

                  <TextField
                    id="codigoInterno"
                    name="codigoInterno"
                    label="Código Interno"
                    value={formik.values.codigoInterno}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.codigoInterno &&
                      Boolean(formik.errors.codigoInterno)
                    }
                    helperText={
                      formik.touched.codigoInterno &&
                      formik.errors.codigoInterno
                    }
                  />

                  <TextField
                    id="codigoDeBarras"
                    name="codigoDeBarras"
                    label="Código de Barras"
                    value={formik.values.codigoDeBarras}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.codigoDeBarras &&
                      Boolean(formik.errors.codigoDeBarras)
                    }
                    helperText={
                      formik.touched.codigoDeBarras &&
                      formik.errors.codigoDeBarras
                    }
                  />

                  <TextField
                    id="ncm"
                    name="ncm"
                    label="NCM"
                    value={formik.values.ncm}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.ncm && Boolean(formik.errors.ncm)}
                    helperText={formik.touched.ncm && formik.errors.ncm}
                  />

                  <TextField
                    id="descricao"
                    name="descricao"
                    label="Descrição"
                    value={formik.values.descricao}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.descricao &&
                      Boolean(formik.errors.descricao)
                    }
                    helperText={
                      formik.touched.descricao && formik.errors.descricao
                    }
                  />

                  <TextField
                    id="localizacao"
                    name="localizacao"
                    label="Localização"
                    value={formik.values.localizacao}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.localizacao &&
                      Boolean(formik.errors.localizacao)
                    }
                    helperText={
                      formik.touched.localizacao && formik.errors.localizacao
                    }
                  />
                  <TextField
                    id="precoCompra"
                    name="precoCompra"
                    label="Preço de Compra"
                    type="number"
                    value={formik.values.precoCompra}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.precoCompra &&
                      Boolean(formik.errors.precoCompra)
                    }
                    helperText={
                      formik.touched.precoCompra && formik.errors.precoCompra
                    }
                    style={{
                      display: !formik.values.isAlmoxarifado ? "flex" : "none",
                    }}
                  />

                  <TextField
                    id="margemLucro"
                    name="margemLucro"
                    label="Margem de Lucro"
                    type="number"
                    value={formik.values.margemLucro}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.margemLucro &&
                      Boolean(formik.errors.margemLucro)
                    }
                    helperText={
                      formik.touched.margemLucro && formik.errors.margemLucro
                    }
                    style={{
                      display: !formik.values.isAlmoxarifado ? "flex" : "none",
                    }}
                  />

                  <TextField
                    id="precoVenda"
                    name="precoVenda"
                    label="Preço de Venda"
                    type="number"
                    value={formik.values.precoVenda}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.precoVenda &&
                      Boolean(formik.errors.precoVenda)
                    }
                    helperText={
                      formik.touched.precoVenda && formik.errors.precoVenda
                    }
                    style={{
                      display: !formik.values.isAlmoxarifado ? "flex" : "none",
                    }}
                  />

                  <TextField
                    id="itemGroup"
                    name="itemGroup"
                    label="Grupo de Itens"
                    value={formik.values.itemGroup}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.itemGroup &&
                      Boolean(formik.errors.itemGroup)
                    }
                    helperText={
                      formik.touched.itemGroup && formik.errors.itemGroup
                    }
                    select
                    SelectProps={{
                      native: true,
                    }}
                    style={{
                      display: formik.values.isAlmoxarifado ? "" : "none",
                    }}
                  >
                    <option value="" />
                    {itemGroupList.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </TextField>

                  <TextField
                    id="umGroup"
                    name="umGroup"
                    label="Grupo de UM"
                    value={formik.values.umGroup}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.umGroup && Boolean(formik.errors.umGroup)
                    }
                    helperText={formik.touched.umGroup && formik.errors.umGroup}
                    select
                    SelectProps={{
                      native: true,
                    }}
                    style={{
                      display: formik.values.isAlmoxarifado ? "flex" : "none",
                    }}
                  >
                    <option value="" />
                    {umGroupList.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </TextField>

                  <TextField
                    id="itemAdministration"
                    name="itemAdministration"
                    label="Forma de administrar o item"
                    value={formik.values.itemAdministration}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.itemAdministration &&
                      Boolean(formik.errors.itemAdministration)
                    }
                    helperText={
                      formik.touched.itemAdministration &&
                      formik.errors.itemAdministration
                    }
                    select
                    SelectProps={{
                      native: true,
                    }}
                    style={{
                      display: formik.values.isAlmoxarifado ? "" : "none",
                    }}
                  >
                    <option value="" />
                    {itemAdministrationList.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </TextField>

                  <TextField
                    id="administrationMethod"
                    name="administrationMethod"
                    label="Método de administração"
                    value={formik.values.administrationMethod}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.administrationMethod &&
                      Boolean(formik.errors.administrationMethod)
                    }
                    helperText={
                      formik.touched.administrationMethod &&
                      formik.errors.administrationMethod
                    }
                    select
                    SelectProps={{
                      native: true,
                    }}
                    style={{
                      display: formik.values.isAlmoxarifado ? "flex" : "none",
                    }}
                  >
                    <option value="" />
                    {administrationMethodList.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </TextField>

                  <TextField
                    id="manufacturer"
                    name="manufacturer"
                    label="Fabricante"
                    value={formik.values.manufacturer}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.manufacturer &&
                      Boolean(formik.errors.manufacturer)
                    }
                    helperText={
                      formik.touched.manufacturer && formik.errors.manufacturer
                    }
                    style={{
                      display: formik.values.isAlmoxarifado ? "flex" : "none",
                    }}
                  />

                  <TextField
                    id="unidade"
                    name="unidade"
                    label="Unidade de preço"
                    value={formik.values.unidade}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.unidade && Boolean(formik.errors.unidade)
                    }
                    helperText={formik.touched.unidade && formik.errors.unidade}
                    select
                    SelectProps={{
                      native: true,
                    }}
                    style={{
                      display: formik.values.isAlmoxarifado ? "flex" : "none",
                    }}
                  >
                    <option value="" />
                    {unidadeList.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </TextField>

                  <FormGroup
                    sx={{
                      display: formik.values.isAlmoxarifado ? "flex" : "none",
                      flexDirection: "row",
                    }}
                  >
                    <FormControlLabel
                      control={<Checkbox />}
                      name="itemDeEstoque"
                      id="itemDeEstoque"
                      label="Item de estoque"
                      value={formik.values.itemDeEstoque}
                      checked={formik.values.itemDeEstoque}
                      onChange={formik.handleChange}
                    />

                    <FormControlLabel
                      control={<Checkbox />}
                      name="itemDeVenda"
                      id="itemDeVenda"
                      label="Item de venda"
                      value={formik.values.itemDeVenda}
                      checked={formik.values.itemDeVenda}
                      onChange={formik.handleChange}
                    />

                    <FormControlLabel
                      control={<Checkbox />}
                      name="itemDeCompra"
                      id="itemDeCompra"
                      label="Item de compra"
                      value={formik.values.itemDeCompra}
                      checked={formik.values.itemDeCompra}
                      onChange={formik.handleChange}
                    />
                  </FormGroup>

                  <TextField
                    id="observacao"
                    name="observacao"
                    label="Observação"
                    value={formik.values.observacao}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.observacao &&
                      Boolean(formik.errors.observacao)
                    }
                    helperText={
                      formik.touched.observacao && formik.errors.observacao
                    }
                    multiline
                    rows={4}
                    sx={{
                      gridColumn: "span 2",
                      justifySelf: "center",
                      width: "100%",
                    }}
                  />

                  <Button
                    type={disableForm ? "button" : "submit"}
                    variant="contained"
                    sx={{
                      gridColumn: "span 2",
                      justifySelf: "center",
                      width: "100%",
                      color: "white !important",
                    }}
                    onClick={(e) => {
                      if (!disableForm) {
                        formik.handleSubmit(e);
                        touchAllFields();
                      }
                    }}
                  >
                    {loading ? "Carregando..." : "Salvar"}
                  </Button>
                </Box>

                {success !== null && (
                  <Box>
                    <MDAlert
                      color={success ? "success" : "error"}
                      mt={4}
                      dismissible
                      key={alertKey}
                    >
                      <MDTypography variant="body2" color="white">
                        {success
                          ? "Produto cadastrado com sucesso!"
                          : "Ocorreu um erro ao cadastrar o produto."}
                      </MDTypography>
                    </MDAlert>
                  </Box>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
