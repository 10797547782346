import { Icon, TextField, Tooltip } from "@mui/material";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import useDebounce from "hooks/useDebounce";
import { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { dotToComma } from "utils/numberComma";
import { commaToDot } from "utils/numberComma";

const QTD = ({ formik, produto, limitarAlteracao = false }) => {
  const [qtd, setQtd] = useState(produto?.qtd);
  const [qtdInicial, setQtdInicial] = useState(produto?.qtd);
  const [debouncedQtd, setDebouncedQtd] = useDebounce(qtd, 1000);

  const formatNumber = (value) => {
    return dotToComma(Number(value).toFixed(2));
  };

  useEffect(() => {
    if (limitarAlteracao) {
      if (
        (qtdInicial > 0 && debouncedQtd > qtdInicial * 1.15) ||
        (qtdInicial > 0 && debouncedQtd < qtdInicial * 0.85)
      ) {
        alert(
          "A quantidade do produto não pode ser maior ou menor que 15% do valor inicial, favor verificar com a Administração da Empresa!" +
            `Quantidate tem que ser Entre: ${formatNumber(
              qtdInicial * 0.85
            )} - ${formatNumber(qtdInicial * 1.15)}`
        );
        setQtd(qtdInicial);
      }
    }
  }, [debouncedQtd]);

  useEffect(() => {
    const produtos = formik.values?.produtos?.map((item) => {
      if (item?._id === produto?._id) {
        return {
          ...item,
          qtd,
        };
      }
      return item;
    });

    formik.setFieldValue("produtos", produtos);
    setDebouncedQtd(qtd);
  }, [qtd]);

  const plus = () => {
    setQtd(Number(qtd) + 1);
  };

  const minus = () => {
    if (qtd > 0) {
      setQtd(Number(qtd) - 1);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& > *": {
          margin: "0 5px",
        },
      }}
    >
      <Icon
        style={{
          color: "#81BF52",
          cursor: "pointer",
          display: produto.qtdDiminuirEstoque !== undefined ? "none" : "",
        }}
        fontSize="small"
        onClick={minus}
      >
        remove
      </Icon>

      <TextField
        type="number"
        min={0}
        value={qtd}
        onChange={(e) => setQtd(Number(e.target.value))}
        disabled={produto.qtdDiminuirEstoque !== undefined}
        onInput={(e) => {
          if (e.target.value.split(".")[1]?.length > 2) {
            e.target.value = parseFloat(e.target.value).toFixed(2);
          }
        }}
        sx={{
          mx: "5px",
        }}
      />

      <Icon
        style={{
          color: "#81BF52",
          cursor: "pointer",
          display: produto.qtdDiminuirEstoque !== undefined ? "none" : "",
        }}
        fontSize="small"
        onClick={plus}
      >
        add
      </Icon>
    </div>
  );
};

// simple select
const UnidadeMedida = ({ formik, produto, disabled = false }) => {
  const [unidadeMedida, setUnidadeMedida] = useState(produto?.unidadeMedida);

  useEffect(() => {
    const produtos = formik.values?.produtos?.map((item) => {
      if (item?._id === produto?._id) {
        return {
          ...item,
          unidadeMedida,
        };
      }
      return item;
    });

    formik.setFieldValue("produtos", produtos);
  }, [unidadeMedida]);

  return (
    <TextField
      id="unidadeMedida"
      name="unidadeMedida"
      value={unidadeMedida}
      onChange={(e) => setUnidadeMedida(e.target.value)}
      onBlur={formik.handleBlur}
      disabled={disabled}
      error={
        formik.touched.unidadeMedida && Boolean(formik.errors.unidadeMedida)
      }
      helperText={formik.touched.unidadeMedida && formik.errors.unidadeMedida}
      SelectProps={{
        native: true,
      }}
      sx={{
        width: "100%",
      }}
      select
      required
    >
      <option value="KG">KG</option>
      <option value="Unidade">Unidade</option>
      <option value="CX">Caixa</option>
    </TextField>
  );
};

const ValorUnitario = ({
  formik,
  produto,
  custoUnitario,
  disabled = false,
}) => {
  const [valorUnitario, setValorUnitario] = useState(produto?.valorUnitario);

  useEffect(() => {
    const produtos = formik.values?.produtos?.map((item) => {
      if (item?._id === produto?._id) {
        return {
          ...item,
          valorUnitario: commaToDot(valorUnitario),
        };
      }
      return item;
    });

    formik.setFieldValue("produtos", produtos);
  }, [valorUnitario]);

  const changeCurrency = (value, name) => {
    formik.setFieldValue(name, value);
  };

  const addZero = (e) => {
    const { name, value } = e.target;
    if (value === "") {
      formik.setFieldValue(name, "0,00");
    } else {
      const removeRS = value.replace("R$", "").trim();
      const removeDots = removeRS.replace(/\./g, "");

      if (value.indexOf(",") === -1) {
        setValorUnitario(`${removeDots},00`);
      }
    }
  };

  return (
    <CurrencyInput
      id="valorUnitario"
      name="valorUnitario"
      label="Valor Unitário"
      value={valorUnitario}
      onBlur={formik.handleBlur}
      decimalsLimit={2}
      disabled={disabled}
      onValueChange={(val) => {
        if (custoUnitario && custoUnitario > 0) {
          const valorAtualComparar = commaToDot(val);

          if (valorAtualComparar < custoUnitario) {
            formik.setFieldError(
              "valorUnitario",
              "Valor unitário não pode ser menor ou igual ao custo unitário!"
            );
          }
        }

        changeCurrency(val, "valorUnitario");
        setValorUnitario(val);
      }}
      onBlurCapture={addZero}
      customInput={TextField}
      error={
        formik.touched.valorUnitario && Boolean(formik.errors.valorUnitario)
      }
      helperText={formik.touched.valorUnitario && formik.errors.valorUnitario}
      sx={{
        width: "100%",
      }}
      prefix="R$ "
      intlConfig={{ locale: "pt-BR", currency: "BRL" }}
    />
  );
};

export default function Itens({
  formik,
  refAddItem,
  isVenda = false,
  isDetalhar = false,
  setShowAddProduto = () => {},
  setShowAddProdutoLote = () => {},
  setCurrentItem = () => {},
  currentItem = null,
  custoUnitario = null,
  disableEdit = false,
}) {
  const [rows, setRows] = useState([]);

  const columns = [
    {
      Header: "Código",
      accessor: "codigoInterno",
      align: "left",
    },
    {
      Header: "QTD",
      accessor: "qtd",
      align: "left",
    },
    {
      Header: "Unidade de Medida",
      accessor: "unidadeMedida",
      align: "left",
    },
    {
      Header: "Produto",
      accessor: "produto",
      align: "left",
    },
    {
      Header: "Valor Unitário",
      accessor: "valorUnitario",
    },
    { Header: isDetalhar ? "" : "Ação", accessor: "action", align: "center" },
  ];

  useEffect(() => {
    setRows(
      formik.values?.produtos?.map((produto) => ({
        codigoInterno: produto?.codigoInterno,
        qtd: isDetalhar ? (
          produto.qtd
        ) : (
          <QTD
            formik={formik}
            produto={produto}
            limitarAlteracao={disableEdit}
          />
        ),
        unidadeMedida: (
          <UnidadeMedida
            formik={formik}
            produto={produto}
            disabled={disableEdit}
          />
        ),
        produto: produto?.produto,
        valorUnitario: (
          <ValorUnitario
            formik={formik}
            produto={produto}
            custoUnitario={custoUnitario}
            disabled={disableEdit}
          />
        ),
        action: (
          <div
            style={{
              justifyContent: "center",
              display: isDetalhar ? "none" : "flex",
            }}
          >
            <Tooltip title="Remover">
              <MDButton
                variant="gradient"
                color="error"
                fullWidth
                type="submit"
                onClick={() => handleDelete(produto)}
                iconOnly
                disabled={disableEdit}
              >
                <Icon fontSize="small">delete_forever</Icon>
              </MDButton>
            </Tooltip>
          </div>
        ),
      }))
    );
  }, [formik.values]);

  const handleDelete = (produto) => {
    const produtos = formik.values?.produtos?.filter(
      (item) => item?._id !== produto?._id
    );

    formik.setFieldValue("produtos", produtos);
  };

  const handleAdd = (produto) => {
    // if product already exists, just increment the quantity
    const productExists = formik.values?.produtos?.find(
      (item) => item?._id === produto?._id
    );

    if (productExists) {
      const produtos = formik.values?.produtos?.map((item) => {
        if (item?._id === produto?._id) {
          return {
            ...item,
            qtd: item?.qtd + 1,
          };
        }
        return item;
      });

      formik.setFieldValue("produtos", produtos);
      setShowAddProduto(false);
      setShowAddProdutoLote(false);
      setRows(produtos);
    } else {
      const produtos = [
        ...formik.values?.produtos,
        {
          _id: produto?._id,
          codigoInterno: produto?.codigoInterno,
          qtd: 1,
          produto: produto?.descricao,
          valorUnitario: produto?.precoVenda,
          unidadeMedida: "KG",
        },
      ];
      setShowAddProduto(false);
      setShowAddProdutoLote(false);
      formik.setFieldValue("produtos", produtos);
      setRows(produtos);
    }
  };

  const scrollToTop = () => {
    refAddItem?.current?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (currentItem) {
      handleAdd(currentItem);
      setCurrentItem(null);
    }
  }, [currentItem]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {!isDetalhar && (!isVenda || !process.env.REACT_APP_IS_PRIME_PLUS) && (
          <MDButton
            variant="contained"
            color="info"
            type="button"
            disabled={disableEdit}
            onClick={() => {
              scrollToTop();
              setShowAddProduto(true);
            }}
          >
            Adicionar Item
          </MDButton>
        )}

        {!isDetalhar && isVenda && process.env.REACT_APP_IS_PRIME_PLUS && (
          <MDButton
            variant="contained"
            color="info"
            type="button"
            onClick={() => {
              scrollToTop();
              setShowAddProdutoLote(true);
            }}
          >
            Adicionar Item do Lote
          </MDButton>
        )}
      </div>

      {rows?.length > 0 && (
        <div style={{ marginTop: "-40px" }}>
          <DataTable
            table={{ columns, rows }}
            isSorted={false}
            disablePagination={true}
            noEndBorder
          />
        </div>
      )}

      {rows?.length === 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 5,
          }}
        >
          Nenhum item adicionado
        </div>
      )}
    </>
  );
}
