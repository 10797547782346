import HttpService from "./http.service";

class AgentService {
  list = async (sortStr = null, filterStr = null) => {
    const agentsEndpoint = `/agents?limit=-1${sortStr ? sortStr : ""}${
      filterStr ? filterStr : ""
    }`;
    return await HttpService.get(agentsEndpoint);
  };

  paginate = async (page = 0, limit = 25, sortStr = null, filterStr = null) => {
    const agentsEndpoint = `/agents?page=${page}&limit=${limit}${
      sortStr ? sortStr : ""
    }${filterStr ? filterStr : ""}`;
    return await HttpService.get(agentsEndpoint);
  };

  get = async (id) => {
    const agentsEndpoint = "/agents";
    return await HttpService.get(`${agentsEndpoint}/${id}`);
  };

  create = async (data) => {
    const agentsEndpoint = "/agents";
    return await HttpService.post(agentsEndpoint, data);
  };

  update = async (id, data) => {
    const agentsEndpoint = "/agents";
    return await HttpService.put(`${agentsEndpoint}/${id}`, data);
  };

  delete = async (id) => {
    const agentsEndpoint = "/agents";
    return await HttpService.delete(`${agentsEndpoint}/${id}`);
  };
}

export default new AgentService();
