import HttpService from "./http.service";

class SubAccountGroupService {
  list = async (sortStr = null, filterStr = null) => {
    const subAccountGroupEndpoint = `/sub-group-accounts?limit=-1${
      sortStr ? sortStr : ""
    }${filterStr ? filterStr : ""}`;
    return await HttpService.get(subAccountGroupEndpoint);
  };

  paginate = async (page = 0, limit = 25, sortStr = null, filterStr = null) => {
    const subAccountGroupEndpoint = `/sub-group-accounts?page=${page}&limit=${limit}${
      sortStr ? sortStr : ""
    }${filterStr ? filterStr : ""}`;
    return await HttpService.get(subAccountGroupEndpoint);
  };

  get = async (id) => {
    const subsubAccountGroupEndpoint = "/sub-group-accounts";
    return await HttpService.get(`${subsubAccountGroupEndpoint}/${id}`);
  };

  create = async (data) => {
    const subsubAccountGroupEndpoint = "/sub-group-accounts";
    return await HttpService.post(subsubAccountGroupEndpoint, data);
  };

  update = async (id, data) => {
    const subsubAccountGroupEndpoint = "/sub-group-accounts";
    return await HttpService.put(`${subsubAccountGroupEndpoint}/${id}`, data);
  };

  delete = async (id) => {
    const subsubAccountGroupEndpoint = "/sub-group-accounts";
    return await HttpService.delete(`${subsubAccountGroupEndpoint}/${id}`);
  };
}

export default new SubAccountGroupService();
