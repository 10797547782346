import { format as formatFns } from "date-fns";

const format = (date, pattern) => {
  if (!validateDate(date)) {
    return "";
  }

  let currDate = new Date(date);
  currDate.setHours(currDate.getHours() + 3);
  return formatFns(currDate, pattern);
};

const validateDate = (date) => {
  return date !== "Invalid Date" && !isNaN(date);
};

export { format };
