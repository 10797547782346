import { Box, Button, Card, Checkbox, Grid } from "@mui/material";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Service from "services/permission";
import { AREAS, AREAS_LIST, findRole, initialValues, yupSchema } from "./yupSchema";

export default function EditarPermissao({ setShowEditState, currentIdAction, fetchData }) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [alertKey, setAlertKey] = useState(0);
  const [permissions, setPermissions] = useState([]);
  const [areasWithPermissions, setAreasWithPermissions] = useState([]);

  const updateAreaWWithPermissions = (areaName, value) => {
    const newAreas = [...areasWithPermissions];

    for (let i = 0; i < newAreas.length; i++) {
      if (newAreas[i].value === areaName) {
        newAreas[i][value] = newAreas[i][value] === "1" ? "0" : "1";
        break;
      }
    }

    setAreasWithPermissions(newAreas);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yupSchema,
    onSubmit: async (values) => {
      setLoading(true);

      try {
        await Service.updateByRole(currentIdAction, areasWithPermissions);
        setSuccess(true);
      } catch (error) {
        console.log(error);
        setSuccess(false);
      }
      setAlertKey(alertKey + 1);
      setLoading(false);
    },
  });

  useEffect(() => {
    const fetchList = async () => {
      setLoading(true);
      const fields = await Service.getByRole(currentIdAction);
      setPermissions(fields);
      setLoading(false);
    };

    fetchList();
  }, [currentIdAction]);

  useEffect(() => {
    setAreasWithPermissions(
      AREAS_LIST.map((area) => {
        const permission = permissions.find(
          (permission) => permission.area === area.value
        );

        area.isReport = false;

        if (
          area.value === AREAS.REPORT ||
          area.value === AREAS.DASHBOARD_REPORTS
        ) {
          area.isReport = true;
        }

        return {
          cria: permission?.cria || "0",
          edita: permission?.edita || "0",
          exclui: permission?.exclui || "0",
          visualiza: permission?.visualiza || "0",
          editaAdmin: permission?.editaAdmin || "0",
          ...area,
        };
      })
    );
  }, [permissions]);

  useEffect(() => {
    const closeOnEscapePressed = (e) => {
      if (e.key === "Escape") {
        fetchData();
        setShowEditState(false);
        setSuccess(null);
      }
    };
    window.addEventListener("keydown", closeOnEscapePressed);
    return () => window.removeEventListener("keydown", closeOnEscapePressed);
  }, []);

  return (
    <>
      <MDBox
        pt={"30px"}
        sx={{
          position: "absolute",
          zIndex: 9999,
          width: "100vw",
          minHeight: "100vh",
          top: 0,
          right: 0,
        }}
      >
        <MDBox
          pt={"30px"}
          sx={{
            position: "fixed",
            zIndex: -1,
            width: "100vw",
            height: "110%",
            backgroundColor: "rgba(0,0,0,0.5)",
            backdropFilter: "blur(5px)",
            top: 0,
            right: 0,
          }}
        />
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={8} mx={"auto"}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  Editar Permissão - {findRole(currentIdAction)}
                </MDTypography>
                <MDButton
                  variant="contained"
                  type="button"
                  onClick={() => {
                    fetchData();
                    setSuccess(null);
                    setShowEditState(false);
                  }}
                >
                  Voltar
                </MDButton>
              </MDBox>
              <MDBox pt={3} mx={2} py={3} px={2}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 2,
                    mb: 2,
                  }}
                  component="form"
                  onSubmit={formik.handleSubmit}
                >
                  {!loading &&
                    areasWithPermissions.map((area) => (
                      <Box
                        key={area.label}
                        sx={{
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                          padding: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <MDTypography variant="h6" color="text">
                          {area.label} {area.area}
                        </MDTypography>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "repeat(4, 1fr)",
                            gap: 2,
                            mb: 2,
                          }}
                        >
                          {area.edita && !area.isReport && (
                            <Box>
                              <MDTypography variant="body2" color="text">
                                Editar
                              </MDTypography>
                              <Checkbox
                                name={area.label}
                                value={area.edita}
                                checked={area.edita == "1"}
                                onChange={(e) => {
                                  updateAreaWWithPermissions(
                                    area.value,
                                    "edita"
                                  );
                                }}
                              />
                            </Box>
                          )}

                          {area.editaAdmin && !area.isReport && (
                            <Box>
                              <MDTypography variant="body2" color="text">
                                Editar Admin
                              </MDTypography>
                              <Checkbox
                                name={area.label}
                                value={area.editaAdmin}
                                checked={area.editaAdmin == "1"}
                                onChange={(e) => {
                                  updateAreaWWithPermissions(
                                    area.value,
                                    "editaAdmin"
                                  );
                                }}
                              />
                            </Box>
                          )}

                          {area.cria && !area.isReport && (
                            <Box>
                              <MDTypography variant="body2" color="text">
                                Criar
                              </MDTypography>
                              <Checkbox
                                name={area.label}
                                value={area.cria}
                                checked={area.cria == "1"}
                                onChange={(e) => {
                                  updateAreaWWithPermissions(
                                    area.value,
                                    "cria"
                                  );
                                }}
                              />
                            </Box>
                          )}

                          {area.exclui && !area.isReport && (
                            <Box>
                              <MDTypography variant="body2" color="text">
                                Excluir
                              </MDTypography>
                              <Checkbox
                                name={area.label}
                                value={area.exclui}
                                checked={area.exclui == "1"}
                                onChange={(e) => {
                                  updateAreaWWithPermissions(
                                    area.value,
                                    "exclui"
                                  );
                                }}
                              />
                            </Box>
                          )}

                          {area.visualiza !== undefined && (
                            <Box>
                              <MDTypography variant="body2" color="text">
                                Visualizar
                              </MDTypography>
                              <Checkbox
                                name={area.label}
                                value={area.visualiza}
                                checked={area.visualiza == "1"}
                                onChange={(e) => {
                                  updateAreaWWithPermissions(
                                    area.value,
                                    "visualiza"
                                  );
                                }}
                              />
                            </Box>
                          )}
                        </Box>
                      </Box>
                    ))}

                  <Button
                    variant="contained"
                    disabled={loading}
                    sx={{
                      gridColumn: "span 2",
                      justifySelf: "center",
                      width: "100%",
                      color: "white !important",
                    }}
                    onClick={formik.handleSubmit}
                  >
                    {loading ? "Carregando..." : "Salvar"}
                  </Button>
                </Box>

                {success !== null && (
                  <Box>
                    <MDAlert
                      color={success ? "success" : "error"}
                      mt={4}
                      dismissible
                      key={alertKey}
                    >
                      <MDTypography variant="body2" color="white">
                        {success
                          ? "Permissão atualizada com sucesso!"
                          : "Ocorreu um erro ao atualizar a permissão."}
                      </MDTypography>
                    </MDAlert>
                  </Box>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
