import * as yup from "yup";

export const yupSchema = yup.object({
  codigoInterno: yup.string().required("Campo obrigatório"),
  codigoDeBarras: yup.string(),
  referencia: yup.string(),
  nomeEstrangeiro: yup.string(),
  descricao: yup.string().required("Campo obrigatório"),
  precoCompra: yup.number(),
  margemLucro: yup.number(),
  precoVenda: yup.number(),
  localizacao: yup.string(),
  observacao: yup.string(),
  itemGroup: yup.string(),
  umGroup: yup.string(),
  unidade: yup.string(),
  manufacturer: yup.string(),
  itemDeEstoque: yup.boolean(),
  itemDeVenda: yup.boolean(),
  itemDeCompra: yup.boolean(),
  itemAdministration: yup.string(),
  administrationMethod: yup.string(),
  productGroup: yup.string().required("Campo obrigatório"),
  isAlmoxarifado: yup.boolean(),
  itemGroup: yup.object().when("isAlmoxarifado", {
    is: true,
    then: () => yup.string().required("Campo obrigatório"),
    otherwise: () => yup.string().nullable(),
  }),
  umGroup: yup.object().when("isAlmoxarifado", {
    is: true,
    then: () => yup.string().required("Campo obrigatório"),
    otherwise: () => yup.string().nullable(),
  }),
  unidade: yup.object().when("isAlmoxarifado", {
    is: true,
    then: () => yup.string().required("Campo obrigatório"),
    otherwise: () => yup.string().nullable(),
  }),
  manufacturer: yup.object().when("isAlmoxarifado", {
    is: true,
    then: () => yup.string().required("Campo obrigatório"),
    otherwise: () => yup.string().nullable(),
  }),
  itemAdministration: yup.object().when("isAlmoxarifado", {
    is: true,
    then: () => yup.string().required("Campo obrigatório"),
    otherwise: () => yup.string().nullable(),
  }),
  administrationMethod: yup.object().when("isAlmoxarifado", {
    is: true,
    then: () => yup.string().required("Campo obrigatório"),
    otherwise: () => yup.string().nullable(),
  }),
  precoCompra: yup.object().when("isAlmoxarifado", {
    is: false,
    then: () => yup.string().required("Campo obrigatório"),
    otherwise: () => yup.string().nullable(),
  }),
  margemLucro: yup.object().when("isAlmoxarifado", {
    is: false,
    then: () => yup.string().required("Campo obrigatório"),
    otherwise: () => yup.string().nullable(),
  }),
  precoVenda: yup.object().when("isAlmoxarifado", {
    is: false,
    then: () => yup.string().required("Campo obrigatório"),
    otherwise: () => yup.string().nullable(),
  }),
});

export const initialValues = {
  codigoInterno: "",
  codigoDeBarras: "",
  referencia: "",
  nomeEstrangeiro: "",
  descricao: "",
  precoCompra: "",
  margemLucro: "",
  precoVenda: "",
  localizacao: "",
  observacao: "",
  itemGroup: "",
  umGroup: "",
  unidade: "",
  manufacturer: "",
  itemDeEstoque: false,
  itemDeVenda: false,
  itemDeCompra: false,
  itemAdministration: "",
  administrationMethod: "",
  productGroup: "",
  isAlmoxarifado: false,
  itemGroup: "",
  umGroup: "",
  unidade: "",
  manufacturer: "",
  itemAdministration: "",
  administrationMethod: "",
  ncm: "",
};

export const unidadeList = ["LT", "G", "KG", "TON", "M³", "UN", "CX", "MT"];
export const itemGroupList = [
  "Defensivo",
  "Inseticida",
  "Adubo",
  "Lubrificantes",
  "Combustível",
  "Peça",
];
export const itemAdministrationList = ["Nº de Série", "Lotes", "Nenhum"];
export const administrationMethodList = [
  "Em todas as transações",
  "Somente entrada",
  "Somente saída",
];
export const umGroupList = [
  "Litro LT",
  "Galão GL",
  "Metro Cúbico M³",
  "Grama G",
  "Kilo KG",
  "Tonelada TON",
  "Unidade UN",
  "Metro MT",
];
