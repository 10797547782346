import { useContext, useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayoutLanding from "layouts/authentication/components/BasicLayoutLanding";

import AuthService from "services/auth-service";
import { AuthContext } from "context";
import { FormGroup, Icon, IconButton } from "@mui/material";

function Login() {
  const authContext = useContext(AuthContext);

  const [user, setUser] = useState({});
  const [credentialsErros, setCredentialsError] = useState(null);
  const [typeInput, setTypeInput] = useState("password");
  const [rememberMe, setRememberMe] = useState(
    localStorage.getItem("@primefrutas/rememberMe") || false
  );

  const [inputs, setInputs] = useState({
    email: localStorage.getItem("@primefrutas/email") || "",
    password: "",
  });

  const [errors, setErrors] = useState({
    emailError: false,
    passwordError: false,
  });

  const addUserHandler = (newUser) => setUser(newUser);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    // check rememeber me?
    e.preventDefault();

    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (
      inputs.email.trim().length === 0 ||
      !inputs.email.trim().match(mailFormat)
    ) {
      setErrors({ ...errors, emailError: true });
      return;
    }

    if (inputs.password.trim().length < 6) {
      setErrors({ ...errors, passwordError: true });
      return;
    }

    const newUser = { email: inputs.email, password: inputs.password };
    addUserHandler(newUser);

    const myData = {
      data: {
        type: "token",
        attributes: { ...newUser },
      },
    };

    try {
      const response = await AuthService.login(myData);
      authContext.login(response.access_token, response.refresh_token);
    } catch (res) {
      if (res.hasOwnProperty("message")) {
        setCredentialsError(res.message);
      } else {
        setCredentialsError(res.errors[0].detail);
      }
    }

    return () => {
      setInputs({
        email: "",
        password: "",
      });

      setErrors({
        emailError: false,
        passwordError: false,
      });
    };
  };

  useEffect(() => {
    const checkIfHaveToken = () => {
      const token = localStorage.getItem("@primefrutas/token");

      if (token) {
        authContext.login(token);
      }
    };

    const toggleRememberMe = () => {
      const rememberMe = localStorage.getItem("@primefrutas/rememberMe");

      if (rememberMe) {
        setRememberMe(true);
      }
    };

    toggleRememberMe();
    checkIfHaveToken();
  }, []);

  useEffect(() => {
    if (rememberMe) {
      localStorage.setItem("@primefrutas/email", inputs.email);
      localStorage.setItem("@primefrutas/rememberMe", rememberMe);
    } else {
      localStorage.removeItem("@primefrutas/email");
      localStorage.removeItem("@primefrutas/rememberMe");
    }
  }, [inputs.email, rememberMe]);

  const isSafari = () => {
    return (
      /^((?!chrome|android).)*safari/i.test(navigator.userAgent) ||
      (!!navigator.vendor &&
        navigator.vendor.includes("Apple") &&
        !navigator.userAgent.includes("CriOS"))
    );
  };

  return (
    <BasicLayoutLanding bgColor="#2C441C">
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Autenticação
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox
            component="form"
            role="form"
            method="POST"
            onSubmit={submitHandler}
          >
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="E-mail"
                fullWidth
                value={inputs.email}
                name="email"
                onChange={changeHandler}
                error={errors.emailError}
              />
            </MDBox>
            <MDBox mb={2}>
              <FormGroup>
                <MDInput
                  type={typeInput}
                  label="Senha"
                  fullWidth
                  name="password"
                  value={inputs.password}
                  onChange={changeHandler}
                  error={errors.passwordError}
                />

                <IconButton
                  onClick={() =>
                    setTypeInput(typeInput === "password" ? "text" : "password")
                  }
                  size="large"
                  edge="end"
                  sx={{
                    position: "absolute",
                    right: 0,
                    top: "146px",
                    right: isSafari() ? "66px" : "36px",
                  }}
                >
                  {typeInput === "password" ? (
                    <Icon>visibility</Icon>
                  ) : (
                    <Icon>visibility_off</Icon>
                  )}
                </IconButton>
              </FormGroup>
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Lembrar-me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit">
                entrar
              </MDButton>
            </MDBox>
            {credentialsErros && (
              <MDTypography variant="caption" color="error" fontWeight="light">
                {credentialsErros}
              </MDTypography>
            )}
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Esqueceu sua senha? clique{" "}
                <MDTypography
                  component={Link}
                  to="/auth/forgot-password"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  aqui
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayoutLanding>
  );
}

export default Login;
