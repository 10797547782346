import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";


function DashboardVendas() {
  const url =
    "https://charts.mongodb.com/charts-primedb-dzfna/embed/dashboards?id=655d40a9-9ab1-4f40-8c61-82c84647f475&theme=light&autoRefresh=true&maxDataAge=3600&showTitleAndDesc=true&scalingWidth=scale&scalingHeight=fixed";

  return (
    <DashboardLayout>
      <iframe
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        style={{
          border: 0,
          width: "100%",
          height: "1025px",
        }}
        src={url}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default DashboardVendas;
