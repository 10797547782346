import { Box, Button, Card, Grid, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { TIPO_PESSOA } from "./components/yupSchema";
import InputMask from "react-input-mask";

const initialValues = {
  cidade: "",
  uf: "",
  tipoPessoa: "",
  cpfCnpj: "",
};

export default function Filter({
  Service,
  resetRows,
  updateRows,
  setIsFiltered,
  setShowFilterState,
  setCurrentFilter = () => {},
}) {
  const [loading, setLoading] = useState(false);
  const [ufs, setUfs] = useState([]);
  const [cidades, setCidades] = useState([]);

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      setLoading(true);

      try {
        if (!Service) {
          setLoading(false);
          return;
        }

        setCurrentFilter({
          ...values,
        });

        const rows = await Service.filter(values);

        formik.resetForm();

        updateRows(rows);
        setIsFiltered(true);
        setLoading(false);
        setShowFilterState(false);
      } catch (error) {
        console.log(error);
      }

      setLoading(false);
    },
  });

  useEffect(() => {
    if (formik.values.uf && formik.values.uf.length === 2) {
      const fetchCities = async () => {
        try {
          let cities = await Service.listAllCitiesByUf(formik.values.uf);
          cities = cities.filter((city) => city.length > 0);
          cities = [...new Set(cities)].sort();
          cities = cities.sort();
          setCidades(cities);
        } catch (error) {
          console.log(error);
        }
      };

      fetchCities();
    }
  }, [formik.values.uf]);

  useEffect(() => {
    const fetchUfs = async () => {
      try {
        let ufs = await Service.listAllUfs();
        ufs = ufs.filter((uf) => uf.length === 2);
        ufs = [...new Set(ufs.map((uf) => uf.toUpperCase()))].sort();
        setUfs(ufs);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUfs();
  }, []);

  return (
    <>
      <MDBox
        pt={"30px"}
        pb={"5%"}
        sx={{
          position: "absolute",
          zIndex: 9999,
          width: "100vw",
          minHeight: "100vh",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.5)",
          backdropFilter: "blur(5px)",
          top: 0,
          right: 0,
          overflow: "auto",
        }}
      >
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={6} mx={"auto"}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  Filtrar Clientes
                </MDTypography>

                <div>
                  <MDButton
                    variant="contained"
                    type="button"
                    onClick={() => {
                      resetRows();
                      setIsFiltered(false);
                      setShowFilterState(false);
                    }}
                    sx={{
                      mr: 2,
                    }}
                  >
                    Limpar Filtro
                  </MDButton>
                  <MDButton
                    variant="contained"
                    type="button"
                    onClick={() => {
                      setShowFilterState(false);
                    }}
                  >
                    Voltar
                  </MDButton>
                </div>
              </MDBox>

              <MDBox pt={3} mx={2} py={3} px={2}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 2,
                    mb: 2,
                  }}
                  component="form"
                  onSubmit={formik.handleSubmit}
                >
                  <InputMask
                    mask={
                      formik.values.tipoPessoa === TIPO_PESSOA.PESSOA_FISICA
                        ? "999.999.999-99"
                        : "99.999.999/9999-99"
                    }
                    value={formik.values.cpfCnpj}
                    onChange={formik.handleChange}
                  >
                    {(inputProps) => (
                      <TextField
                        id="cpfCnpj"
                        name="cpfCnpj"
                        label={
                          formik.values.tipoPessoa === TIPO_PESSOA.PESSOA_FISICA
                            ? "CPF"
                            : "CNPJ"
                        }
                        error={
                          formik.touched.cpfCnpj &&
                          Boolean(formik.errors.cpfCnpj)
                        }
                        helperText={
                          formik.touched.cpfCnpj && formik.errors.cpfCnpj
                        }
                        {...inputProps}
                      />
                    )}
                  </InputMask>

                  <TextField
                    id="uf"
                    name="uf"
                    label="UF"
                    select
                    variant="outlined"
                    value={formik.values.uf}
                    onChange={formik.handleChange}
                    fullWidth
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option value=""></option>
                    {ufs.map((uf) => (
                      <option key={uf} value={uf}>
                        {uf}
                      </option>
                    ))}
                  </TextField>

                  <TextField
                    id="cidade"
                    name="cidade"
                    label="Cidade"
                    select
                    variant="outlined"
                    value={formik.values.cidade}
                    onChange={formik.handleChange}
                    fullWidth
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option value=""></option>
                    {cidades.map((cidade) => (
                      <option key={cidade} value={cidade}>
                        {cidade}
                      </option>
                    ))}
                  </TextField>

                  <TextField
                    id="tipoPessoa"
                    name="tipoPessoa"
                    label="Tipo Pessoa"
                    select
                    variant="outlined"
                    value={formik.values.tipoPessoa}
                    onChange={formik.handleChange}
                    fullWidth
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option value=""></option>
                    {Object.entries(TIPO_PESSOA).map(([key, value]) => (
                      <option key={key} value={value}>
                        {key === "PESSOA_FISICA" ? "Física" : "Jurídica"}
                      </option>
                    ))}
                  </TextField>

                  <Button
                    type="submit"
                    variant="contained"
                    disabled={loading}
                    sx={{
                      gridColumn: "span 2",
                      justifySelf: "center",
                      width: "100%",
                      color: "white !important",
                    }}
                  >
                    {loading ? "Carregando..." : "Filtrar"}
                  </Button>
                </Box>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
