// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { DataGrid } from "@mui/x-data-grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Data
import MDButton from "components/MDButton";
import { useState } from "react";
import MDDialog from "components/MDDialog";
import AdicionarDado from "./components/addData";
import EditarDado from "./components/editData";
import tableData from "./components/tableData";
import DetalharDado from "./components/detailData";

import Service from "services/products";

import { ptBR_DataGrid } from "utils/datagrid";

export default function Produtos({ fromParent = false, selectParent = null, setShowAddProduto = null, disableForm = false, onlyAlmoxarifado = false, grupoProduto = null, filterByWarehouse = null }) {
  const [showAddState, setShowAddState] = useState(false);
  const [showEditState, setShowEditState] = useState(false);
  const [showDetailState, setShowDetailState] = useState(false);
  const [dialogRemove, setDialogRemove] = useState(false);
  const [dialogText, setDialogText] = useState("");
  const [currentIdAction, setCurrentIdAction] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleDetail = (id) => {
    setCurrentIdAction(id);
    setShowDetailState(true);
  };

  const handleEdit = (id) => {
    setCurrentIdAction(id);
    setShowEditState(true);
    window.scrollTo(0, 0);
  };

  const handleDelete = (obj) => {
    setDialogRemove(true);
    setDialogText(obj.descricao);
    setCurrentIdAction(obj._id);
  };

  const cancelDelete = () => {
    setDialogRemove(false);
    setDialogText("");
    setCurrentIdAction(null);
  };

  const confirmDelete = async () => {
    await Service.delete(currentIdAction);
    setDialogRemove(false);
    fetchData();
  };

  const { columns, rows, fetchData, updatePagination, sortBy, filterBy, pageObject } = tableData(
    handleEdit,
    handleDelete,
    handleDetail,
    fromParent,
    selectParent,
    setShowAddProduto,
    setLoading,
    onlyAlmoxarifado,
    filterByWarehouse
  );

  return (
    <DashboardLayout fromParent={fromParent}>
      <MDBox pt={6} pb={3}>
        {showEditState && <EditarDado setShowEditState={setShowEditState} currentIdAction={currentIdAction} fetchData={fetchData} fromParent={fromParent} />}
        {showAddState && (
          <AdicionarDado setShowAddState={setShowAddState} fetchData={fetchData} disableForm={disableForm} fromParent={fromParent} selectParent={selectParent} setShowAddProduto={setShowAddProduto} />
        )}
        {showDetailState && <DetalharDado setShowDetailState={setShowDetailState} currentIdAction={currentIdAction} fetchData={fetchData} fromParent={fromParent} />}
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" sx={{ display: "flex", justifyContent: "space-between" }}>
                <MDTypography variant="h6" color="white" mt={1}>
                  Produtos
                </MDTypography>
                <div>
                  {fromParent && setShowAddProduto && (
                    <MDButton variant="contained" type="button" onClick={() => setShowAddProduto(false)} sx={{ mr: 2 }}>
                      Voltar
                    </MDButton>
                  )}
                  <MDButton variant="contained" type="button" onClick={() => setShowAddState(true)}>
                    Adicionar
                  </MDButton>
                </div>
              </MDBox>
              <MDBox pt={3}>
                <Card sx={{ padding: 2 }}>
                  <DataGrid
                    rows={grupoProduto ? rows.filter((row) => row.productGroup === grupoProduto) : rows}
                    columns={columns}
                    rowCount={pageObject.totalRows}
                    pageSizeOptions={[50, 100]}
                    paginationModel={{
                      pageSize: pageObject.perPage,
                      page: pageObject.page,
                    }}
                    paginationMode="server"
                    onPaginationModelChange={updatePagination}
                    sortingMode="server"
                    onSortModelChange={sortBy}
                    filterMode="server"
                    onFilterModelChange={filterBy}
                    rowSelection={false}
                    loading={loading}
                    localeText={ptBR_DataGrid}
                    sx={{
                      ".MuiDataGrid-virtualScroller": {
                        minHeight: "100px",
                      },
                    }}
                  />
                </Card>
              </MDBox>
            </Card>
          </Grid>
        </Grid>

        <MDDialog
          open={dialogRemove}
          handleClose={() => setDialogRemove(false)}
          title={"Tem certeza que deseja remover?"}
          actions={
            <>
              <MDButton variant="gradient" color="info" fullWidth type="submit" onClick={() => confirmDelete()}>
                Confirmar
              </MDButton>
              <MDButton variant="gradient" color="error" fullWidth type="submit" onClick={() => cancelDelete()}>
                Cancelar
              </MDButton>
            </>
          }
        >
          {dialogText}
        </MDDialog>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
