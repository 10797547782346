import { Box } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import {
  findStatusPedido,
  STATUS_PEDIDO,
} from "layouts/pedidos-venda/components/yupSchema";
import { useCallback, useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import Service from "services/sales";
import Column from "./Column";

const initialData = {
  tasks: {},
  columns: {},
  columnOrder: [],
};

export const KanbanVenda = () => {
  const [data, setData] = useState(initialData);

  useEffect(() => {
    const fetchData = async () => {
      const pedidos = await Service.listSaleAgroupedByStatus();

      const newColumns = {};
      const newTasks = {};

      for (const status of Object.values(STATUS_PEDIDO)) {
        newColumns[status] = {
          id: status,
          title: findStatusPedido(status),
          taskIds:
            pedidos[status]?.map((pedido) => {
              const taskId = `${pedido.id}`;
              newTasks[taskId] = {
                id: taskId,
                content: `${pedido.numPedido}`,
                ...pedido,
              };
              return taskId;
            }) || [],
        };
      }

      setData({
        tasks: newTasks,
        columns: newColumns,
        columnOrder: Object.values(STATUS_PEDIDO),
      });
    };

    fetchData();
  }, []);

  const updateTaskStatus = useCallback(
    async (taskId, status) => {
      const task = data.tasks[taskId];
      const newTasks = {
        ...data.tasks,
        [taskId]: {
          ...task,
          status,
        },
      };

      const newColumn = {
        ...data.columns[status],
        taskIds: [...data.columns[status].taskIds, taskId],
      };

      setData((prevState) => ({
        ...prevState,
        tasks: newTasks,
        columns: {
          ...prevState.columns,
          [newColumn.id]: newColumn,
        },
      }));
    },
    [data]
  );

  const onDragEnd = useCallback(
    (result) => {
      const { destination, source, draggableId } = result;

      if (!destination) return;

      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }

      const start = data.columns[source.droppableId];
      const finish = data.columns[destination.droppableId];

      if (start === finish) {
        const newTaskIds = Array.from(start.taskIds);
        newTaskIds.splice(source.index, 1);
        newTaskIds.splice(destination.index, 0, draggableId);

        const newColumn = {
          ...start,
          taskIds: newTaskIds,
        };

        setData((prevState) => ({
          ...prevState,
          columns: {
            ...prevState.columns,
            [newColumn.id]: newColumn,
          },
        }));
        return;
      }

      const startTaskIds = Array.from(start.taskIds);
      startTaskIds.splice(source.index, 1);
      const newStart = {
        ...start,
        taskIds: startTaskIds,
      };

      const finishTaskIds = Array.from(finish.taskIds);
      finishTaskIds.splice(destination.index, 0, draggableId);
      const newFinish = {
        ...finish,
        taskIds: finishTaskIds,
      };

      setData((prevState) => ({
        ...prevState,
        columns: {
          ...prevState.columns,
          [newStart.id]: newStart,
          [newFinish.id]: newFinish,
        },
      }));

      updateTaskStatus(draggableId, finish.id);
    },
    [data]
  );

  return (
    <DashboardLayout>
      <MDBox pt={0} pb={3}>
        <MDBox
          py={3}
          px={2}
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <MDTypography variant="h6" color="white" mt={1}>
            Kanban - Pedidos de Venda
          </MDTypography>
        </MDBox>
      </MDBox>

      <MDBox
        sx={{
          display: "flex",
          overflowX: "auto",
        }}
      >
        <Box
          sx={{
            mt: 0,
            mb: 4,
            display: "flex",
          }}
        >
          <DragDropContext onDragEnd={onDragEnd}>
            {data.columnOrder.map((columnId) => {
              const column = data.columns[columnId];
              const tasks = column.taskIds.map((taskId) => data.tasks[taskId]);

              return (
                <MDBox key={column.id} sx={{ minWidth: 300 }}>
                  <Column column={column} tasks={tasks} />
                </MDBox>
              );
            })}
          </DragDropContext>
        </Box>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
};
