import { Tooltip } from "@mui/material";
import { format } from "utils/formatDate";
import { useEffect, useState } from "react";
import Service from "services/stock-transactions";

export default function data(setLoading = () => {}) {
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [sortStr, setSortStr] = useState("&sortBy=date&sortOrder=-1");
  const [filterStr, setFilterStr] = useState(null);

  const fetchData = async () => {
    setLoading(true);

    const { data, limit, total } = await Service.paginate(
      page,
      perPage,
      sortStr,
      filterStr
    );

    setPerPage(limit);
    setTotalRows(total);

    setRows(
      data.map((row) =>
        Object({
          id: row._id,
          nome: row.product?.descricao,
          quantity: row.quantity,
          date: format(new Date(row.date), "dd/MM/yyyy"),
          type: row.type === "input" ? "Entrada" : "Saída",
          unityPrice: new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(row.unityPrice),
          description: row.description,
          requester: row.requester?.name,
          observacoes: row.observacoes,
        })
      )
    );

    setLoading(false);
  };

  const updatePagination = ({ page: newPage, pageSize: newPerPage }) => {
    setPage(newPage);
    setPerPage(newPerPage);
  };

  const sortBy = (sortArr) => {
    if (sortArr.length === 0) {
      setSortStr(null);
      return;
    }

    const [filter] = sortArr;

    setSortStr(
      `&sortBy=${filter.field}&sortOrder=${filter.sort === "asc" ? 1 : "-1"}`
    );
  };

  const filterBy = ({ items }) => {
    if (items.length === 0) {
      setFilterStr(null);
      return;
    }

    const [filter] = items;

    if (filter.operator === "contains" && filter.value === undefined) {
      return;
    }

    setFilterStr(
      `&filterField=${filter.field}&filterOperator=${filter.operator}&filterValue=${filter.value}`
    );
  };

  useEffect(() => {
    fetchData();
  }, [page, perPage, sortStr, filterStr]);

  return {
    columns: [
      { headerName: "Data", field: "date", flex: 0.3 },
      { headerName: "Tipo", field: "type", flex: 0.3 },
      { headerName: "Nome", field: "nome", flex: 0.3 },
      { headerName: "Preço", field: "unityPrice", flex: 0.3 },
      { headerName: "Quantidade", field: "quantity", flex: 0.3 },
      { headerName: "Solicitante", field: "requester", flex: 0.3 },
      {
        headerName: "Descrição",
        field: "description",
        flex: 0.4,
        renderCell: ({ row }) => (
          <Tooltip title={row.description}>{row.description}</Tooltip>
        ),
      },
      {
        headerName: "Observações",
        field: "observacoes",
        flex: 0.4,
        renderCell: ({ row }) => (
          <Tooltip title={row.observacoes}>{row.observacoes}</Tooltip>
        ),
      },
    ],

    rows,
    fetchData,
    updatePagination,
    sortBy,
    filterBy,
    pageObject: {
      totalRows,
      perPage,
      page,
    },
  };
}
