import { Box, Grid, Card, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Service from "services/shipping-companies";
import { yupSchema, initialValues } from "./yupSchema";
import InputMask from "react-input-mask";

export default function DetalharTransportadora({
  setShowDetailState,
  currentIdAction,
  fetchData,
}) {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yupSchema,
  });

  useEffect(() => {
    const fetchList = async () => {
      setLoading(true);
      const fields = await Service.get(currentIdAction);

      for (var key in formik.values) {
        formik.setFieldValue(key, fields[key]);
      }

      setLoading(false);
    };

    fetchList();
  }, [currentIdAction]);

  useEffect(() => {
    const closeOnEscapePressed = (e) => {
      if (e.key === "Escape") {
        fetchData();
        setShowDetailState(false);
      }
    };
    window.addEventListener("keydown", closeOnEscapePressed);
    return () => window.removeEventListener("keydown", closeOnEscapePressed);
  }, []);

  return (
    <>
      <MDBox
        pt={"30px"}
        sx={{
          position: "absolute",
          zIndex: 9999,
          width: "100vw",
          minHeight: "auto",
          height: "110vh",
          backgroundColor: "rgba(0,0,0,0.5)",
          backdropFilter: "blur(5px)",
          top: 0,
          right: 0,
        }}
      >
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={8} mx={"auto"}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  Detalhes
                </MDTypography>
                <MDButton
                  variant="contained"
                  type="button"
                  onClick={() => {
                    fetchData();
                    setShowDetailState(false);
                  }}
                >
                  Fechar
                </MDButton>
              </MDBox>
              <MDBox pt={3} mx={2} py={3} px={2}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 2,
                    mb: 2,
                  }}
                  component="form"
                  onSubmit={formik.handleSubmit}
                >
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="razaoSocial"
                    name="razaoSocial"
                    label="Razão Social"
                    value={formik.values.razaoSocial}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.razaoSocial &&
                      Boolean(formik.errors.razaoSocial)
                    }
                    helperText={
                      formik.touched.razaoSocial && formik.errors.razaoSocial
                    }
                  />

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="nomeFantasia"
                    name="nomeFantasia"
                    label="Nome Fantasia"
                    value={formik.values.nomeFantasia}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.nomeFantasia &&
                      Boolean(formik.errors.nomeFantasia)
                    }
                    helperText={
                      formik.touched.nomeFantasia && formik.errors.nomeFantasia
                    }
                  />

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="nomeMotorista"
                    name="nomeMotorista"
                    label="Nome do Motorista"
                    value={formik.values.nomeMotorista}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.nomeMotorista &&
                      Boolean(formik.errors.nomeMotorista)
                    }
                    helperText={
                      formik.touched.nomeMotorista &&
                      formik.errors.nomeMotorista
                    }
                  />

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="veiculo"
                    name="veiculo"
                    label="Veículo"
                    value={formik.values.veiculo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.veiculo && Boolean(formik.errors.veiculo)
                    }
                    helperText={formik.touched.veiculo && formik.errors.veiculo}
                  />

                  <InputMask
                    mask={"999.999.999-99"}
                    value={formik.values.cpfMotorista}
                    onChange={formik.handleChange}
                  >
                    {(inputProps) => (
                      <TextField
                        id="cpfMotorista"
                        name="cpfMotorista"
                        label="CPF do Motorista"
                        error={
                          formik.touched.cpf &&
                          Boolean(formik.errors.cpfMotorista)
                        }
                        helperText={
                          formik.touched.cpf && formik.errors.cpfMotorista
                        }
                        {...inputProps}
                      />
                    )}
                  </InputMask>

                  <InputMask
                    mask={
                      formik.values.telefone?.length > 14
                        ? "(99) 99999-9999"
                        : "(99) 9999-99999"
                    }
                    maskChar=""
                    value={formik.values.telefone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    {(inputProps) => (
                      <TextField
                        id="telefone"
                        name="telefone"
                        label="Telefone"
                        error={
                          formik.touched.telefone &&
                          Boolean(formik.errors.telefone)
                        }
                        helperText={
                          formik.touched.telefone && formik.errors.telefone
                        }
                        {...inputProps}
                      />
                    )}
                  </InputMask>

                  <InputMask
                    mask={
                      formik.values.celular?.length > 14
                        ? "(99) 99999-9999"
                        : "(99) 9999-99999"
                    }
                    maskChar=""
                    value={formik.values.celular}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    {(inputProps) => (
                      <TextField
                        id="celular"
                        name="celular"
                        label="Celular"
                        value={formik.values.celular}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.celular &&
                          Boolean(formik.errors.celular)
                        }
                        helperText={
                          formik.touched.celular && formik.errors.celular
                        }
                        {...inputProps}
                      />
                    )}
                  </InputMask>

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="inscricaoEstadual"
                    name="inscricaoEstadual"
                    label="Inscrição Estadual"
                    value={formik.values.inscricaoEstadual}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.inscricaoEstadual &&
                      Boolean(formik.errors.inscricaoEstadual)
                    }
                    helperText={
                      formik.touched.inscricaoEstadual &&
                      formik.errors.inscricaoEstadual
                    }
                  />

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="inscricaoMunicipal"
                    name="inscricaoMunicipal"
                    label="Inscrição Municipal"
                    value={formik.values.inscricaoMunicipal}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.inscricaoMunicipal &&
                      Boolean(formik.errors.inscricaoMunicipal)
                    }
                    helperText={
                      formik.touched.inscricaoMunicipal &&
                      formik.errors.inscricaoMunicipal
                    }
                  />

                  <InputMask
                    mask="99.999-999"
                    value={formik.values.cep}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      formik.handleBlur(e);
                      pesquisaCep();
                    }}
                  >
                    {(inputProps) => (
                      <TextField
                        id="cep"
                        name="cep"
                        label="CEP"
                        error={formik.touched.cep && Boolean(formik.errors.cep)}
                        helperText={formik.touched.cep && formik.errors.cep}
                        {...inputProps}
                      />
                    )}
                  </InputMask>

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="logradouro"
                    name="logradouro"
                    label="Logradouro"
                    value={formik.values.logradouro}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.logradouro &&
                      Boolean(formik.errors.logradouro)
                    }
                    helperText={
                      formik.touched.logradouro && formik.errors.logradouro
                    }
                  />

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="numero"
                    name="numero"
                    label="Número"
                    value={formik.values.numero}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.numero && Boolean(formik.errors.numero)
                    }
                    helperText={formik.touched.numero && formik.errors.numero}
                  />

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="complemento"
                    name="complemento"
                    label="Complemento"
                    value={formik.values.complemento}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.complemento &&
                      Boolean(formik.errors.complemento)
                    }
                    helperText={
                      formik.touched.complemento && formik.errors.complemento
                    }
                  />

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="bairro"
                    name="bairro"
                    label="Bairro"
                    value={formik.values.bairro}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.bairro && Boolean(formik.errors.bairro)
                    }
                    helperText={formik.touched.bairro && formik.errors.bairro}
                  />

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="cidade"
                    name="cidade"
                    label="Cidade"
                    value={formik.values.cidade}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.cidade && Boolean(formik.errors.cidade)
                    }
                    helperText={formik.touched.cidade && formik.errors.cidade}
                  />

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="uf"
                    name="uf"
                    label="UF"
                    value={formik.values.uf}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.uf && Boolean(formik.errors.uf)}
                    helperText={formik.touched.uf && formik.errors.uf}
                  />

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="email"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="ativo"
                    name="ativo"
                    label="Status"
                    value={formik.values.ativo ? "Ativo" : "Inativo"}
                  />
                 <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="ativo_obs"
                    name="ativo_obs"
                    label="Observação de Status"
                    value={formik.values.ativo_obs}
                    multiline
                    rows={4}
                    sx={{
                      gridColumn: "span 2",
                      justifySelf: "center",
                      width: "100%",
                    }}
                  />
                </Box>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
