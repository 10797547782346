import { Box, Button, Grid, Card, TextField, Alert, Icon } from "@mui/material";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useFormik } from "formik";
import { useState } from "react";
import Service from "services/warehouses";
import { yupSchema, initialValues } from "./yupSchema";
import { useEffect } from "react";
import InputMask from "react-input-mask";
import cepService from "services/cep.service";
import AdicionarFuncionario from "layouts/funcionarios";

export default function AdicionarDeposito({ setShowAddState, fetchData }) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [alertKey, setAlertKey] = useState(0);
  const [showAddFuncionario, setShowAddFuncionario] = useState(false);
  const [childData, setChildData] = useState({});

  const selectParent = (parent, value) => {
    formik.setFieldValue(parent, value);
  };

  const pesquisaCep = async () => {
    const cep = formik.values.cep.replace(/[^0-9]/g, "");

    if (cep.length !== 8) return;

    const fetchCep = await cepService.get(cep);

    const mapping = {
      state: "uf",
      city: "cidade",
      street: "endereco",
    };

    Object.entries(mapping).forEach(([fetchKey, formikKey]) => {
      if (fetchCep[fetchKey] !== undefined) {
        formik.setFieldValue(formikKey, fetchCep[fetchKey]);
      }
    });
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yupSchema,
    onSubmit: async (values) => {
      setLoading(true);

      try {
        await Service.create(values);
        formik.resetForm();
        setSuccess(true);
      } catch (error) {
        console.log(error);
        setSuccess(false);
      }

      setAlertKey(alertKey + 1);

      setLoading(false);
    },
  });

  useEffect(() => {
    const closeOnEscapePressed = (e) => {
      if (e.key === "Escape") {
        if (showAddFuncionario) setShowAddFuncionario(false);

        if (!showAddFuncionario) {
          setShowAddState(false);
          fetchData();
        }

        setSuccess(null);
      }
    };
    window.addEventListener("keydown", closeOnEscapePressed);
    return () => window.removeEventListener("keydown", closeOnEscapePressed);
  }, [showAddFuncionario]);

  return (
    <>
      <MDBox
        pt={"30px"}
        sx={{
          position: "absolute",
          zIndex: 9999,
          width: "100vw",
          minHeight: "100vh",
          top: 0,
          right: 0,
        }}
      >
        <MDBox
          pt={"30px"}
          sx={{
            position: "fixed",
            zIndex: -1,
            width: "100vw",
            height: "110%",
            backgroundColor: "rgba(0,0,0,0.5)",
            backdropFilter: "blur(5px)",
            top: 0,
            right: 0,
          }}
        />
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={8} mx={"auto"}>
            <Card>
              <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" sx={{ display: "flex", justifyContent: "space-between" }}>
                <MDTypography variant="h6" color="white" mt={1}>
                  Cadastrar Depósito
                </MDTypography>
                <MDButton
                  variant="contained"
                  type="button"
                  onClick={() => {
                    fetchData();
                    setSuccess(null);
                    setShowAddState(false);
                  }}
                >
                  Voltar
                </MDButton>
              </MDBox>
              <MDBox pt={3} mx={2} py={3} px={2}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 2,
                    mb: 2,
                  }}
                  component="form"
                  onSubmit={formik.handleSubmit}
                >
                  <TextField
                    id="descricao"
                    name="descricao"
                    label="Nome do Depósito"
                    value={formik.values.descricao}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.descricao && Boolean(formik.errors.descricao)}
                    helperText={formik.touched.descricao && formik.errors.descricao}
                  />
                  <Grid item width="100%">
                    <Alert
                      icon={false}
                      severity="success"
                      action={
                        <MDButton variant="gradient" color="info" fullWidth iconOnly onClick={() => setShowAddFuncionario(true)}>
                          <Icon fontSize="small">edit</Icon>
                        </MDButton>
                      }
                      sx={{
                        border: formik.errors.responsavel ? "1px solid red" : "",
                      }}
                    >
                      <b>Responsável pelo Depósito: </b>
                      {childData.responsavel?.name}
                    </Alert>
                  </Grid>

                  <InputMask
                    mask="99.999-999"
                    value={formik.values.cep}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      formik.handleBlur(e);
                      pesquisaCep();
                    }}
                  >
                    {(inputProps) => (
                      <TextField id="cep" name="cep" label="CEP" error={formik.touched.cep && Boolean(formik.errors.cep)} helperText={formik.touched.cep && formik.errors.cep} {...inputProps} />
                    )}
                  </InputMask>

                  <TextField
                    select
                    id="uf"
                    name="uf"
                    label="UF"
                    value={formik.values.uf}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.uf && Boolean(formik.errors.uf)}
                    helperText={formik.touched.uf && formik.errors.uf}
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option value=""></option>
                    <option value="AC">AC - Acre</option>
                    <option value="AL">AL - Alagoas</option>
                    <option value="AP">AP - Amapá</option>
                    <option value="AM">AM - Amazonas</option>
                    <option value="BA">BA - Bahia</option>
                    <option value="CE">CE - Ceará</option>
                    <option value="DF">DF - Distrito Federal</option>
                    <option value="ES">ES - Espírito Santo</option>
                    <option value="GO">GO - Goiás</option>
                    <option value="MA">MA - Maranhão</option>
                    <option value="MT">MT - Mato Grosso</option>
                    <option value="MS">MS - Mato Grosso do Sul</option>
                    <option value="MG">MG - Minas Gerais</option>
                    <option value="PA">PA - Pará</option>
                    <option value="PB">PB - Paraíba</option>
                    <option value="PR">PR - Paraná</option>
                    <option value="PE">PE - Pernambuco</option>
                    <option value="PI">PI - Piauí</option>
                    <option value="RJ">RJ - Rio de Janeiro</option>
                    <option value="RN">RN - Rio Grande do Norte</option>
                    <option value="RS">RS - Rio Grande do Sul</option>
                    <option value="RO">RO - Rondônia</option>
                    <option value="RR">RR - Roraima</option>
                    <option value="SC">SC - Santa Catarina</option>
                    <option value="SP">SP - São Paulo</option>
                    <option value="SE">SE - Sergipe</option>
                    <option value="TO">TO - Tocantins</option>
                  </TextField>

                  <TextField
                    id="endereco"
                    name="endereco"
                    label="Endereço"
                    value={formik.values.endereco}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.endereco && Boolean(formik.errors.endereco)}
                    helperText={formik.touched.endereco && formik.errors.endereco}
                  />

                  <TextField
                    id="cidade"
                    name="cidade"
                    label="Cidade"
                    value={formik.values.cidade}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.cidade && Boolean(formik.errors.cidade)}
                    helperText={formik.touched.cidade && formik.errors.cidade}
                  />

                  <InputMask
                    mask={formik.values.celular?.length > 14 ? "(99) 99999-9999" : "(99) 9999-99999"}
                    maskChar=""
                    value={formik.values.celular}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    {(inputProps) => (
                      <TextField
                        id="celular"
                        name="celular"
                        label="Contato"
                        value={formik.values.celular}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.celular && Boolean(formik.errors.celular)}
                        helperText={formik.touched.celular && formik.errors.celular}
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={loading}
                    sx={{
                      gridColumn: "span 2",
                      justifySelf: "center",
                      width: "100%",
                      color: "white !important",
                    }}
                  >
                    {loading ? "Carregando..." : "Salvar"}
                  </Button>
                </Box>

                {success !== null && (
                  <Box>
                    <MDAlert color={success ? "success" : "error"} mt={4} dismissible key={alertKey}>
                      <MDTypography variant="body2" color="white">
                        {success ? "Depósito cadastrado com sucesso!" : "Ocorreu um erro ao cadastrar Depósito."}
                      </MDTypography>
                    </MDAlert>
                  </Box>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>

        {/* forms alternativos */}
        {showAddFuncionario && (
          <MDBox
            pt={"30px"}
            pb={"5%"}
            px={"5%"}
            sx={{
              position: "absolute",
              zIndex: 9999,
              width: "100vw",
              minHeight: "100vh",
              height: "100%",
              backdropFilter: "blur(5px)",
              top: 0,
              right: 0,
              overflow: "auto",
            }}
          >
            <AdicionarFuncionario
              fromParent={true}
              selectParent={(value) => {
                setChildData((prevState) => Object({ ...prevState, responsavel: value }));
                selectParent("responsavel", value?.name);
              }}
              setShowAddFuncionario={setShowAddFuncionario}
            />
          </MDBox>
        )}
      </MDBox>
    </>
  );
}
