import HttpService from "./http.service";

class StockService {
  list = async (sortStr = null, filterStr = null) => {
    const stockEndpoint = `/stock?limit=-1${sortStr ? sortStr : ""}${filterStr ? filterStr : ""}`;
    return await HttpService.get(stockEndpoint);
  };

  paginate = async (page = 0, limit = 25, sortStr = null, filterStr = null) => {
    const stockEndpoint = `/stock?page=${page}&limit=${limit}${sortStr ? sortStr : ""}${filterStr ? filterStr : ""}`;
    return await HttpService.get(stockEndpoint);
  };

  listByWarehouse = async (warehouseId, sortStr = null, filterStr = null) => {
    const stockEndpoint = `/stock/warehouse/${warehouseId}?limit=-1${sortStr ? sortStr : ""}${filterStr ? filterStr : ""}`;
    return await HttpService.get(stockEndpoint);
  };
}

export default new StockService();
