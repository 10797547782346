import * as yup from "yup";

export const yupSchema = yup.object({
  number: yup.number().required("Campo obrigatório"),
  company: yup.string().required("Campo obrigatório"),
});

export const initialValues = {
  number: 0,
  company: "",
};
