import HttpService from "./http.service";

class CommoditiesService {
  get = async () => {
    const commoditiesEndpoint = `/commodities`;
    return await HttpService.get(`${commoditiesEndpoint}`);
  };
}

export default new CommoditiesService();
