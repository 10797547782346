import HttpService from "./http.service";

class WarehousesService {
  list = async (sortStr = null, filterStr = null) => {
    const warehousesEndpoint = `/warehouses?limit=-1${sortStr ? sortStr : ""}${filterStr ? filterStr : ""}`;
    return await HttpService.get(warehousesEndpoint);
  };

  paginate = async (page = 0, limit = 25, sortStr = null, filterStr = null) => {
    const warehousesEndpoint = `/warehouses?page=${page}&limit=${limit}${sortStr ? sortStr : ""}${filterStr ? filterStr : ""}`;
    return await HttpService.get(warehousesEndpoint);
  };

  get = async (id) => {
    const warehousesEndpoint = "/warehouses";
    return await HttpService.get(`${warehousesEndpoint}/${id}`);
  };

  filter = async (data) => {
    const dataWithoutUndefined = Object.keys(data).reduce((object, key) => {
      if (data[key] !== undefined && data[key] !== "" && data[key] !== null) {
        object[key] = data[key];
      }
      return object;
    }, {});
    const query = new URLSearchParams(dataWithoutUndefined).toString();
    const warehousesEndpoint = `/warehouses/filter?${query}`;
    return await HttpService.get(warehousesEndpoint, data);
  };

  create = async (data) => {
    const warehousesEndpoint = "/warehouses";
    return await HttpService.post(warehousesEndpoint, data);
  };

  update = async (id, data) => {
    const warehousesEndpoint = "/warehouses";
    return await HttpService.put(`${warehousesEndpoint}/${id}`, data);
  };

  delete = async (id) => {
    const warehousesEndpoint = "/warehouses";
    return await HttpService.delete(`${warehousesEndpoint}/${id}`);
  };
}

export default new WarehousesService();
