import * as yup from "yup";

export const yupSchema = yup.object().shape({
  nome: yup.string().required("Esse campo é obrigatório"),
  accountGroup: yup.string().required("Esse campo é obrigatório"),
});

export const initialValues = {
  nome: "",
  accountGroup: "",
};
