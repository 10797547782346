import { Icon, MenuItem, Stack, TextField, Tooltip } from "@mui/material";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";

const QTDAplicada = ({ formik, produto }) => {
  const [qtdAplicada, setQtd] = useState(produto?.qtdAplicada || 0);

  useEffect(() => {
    const produtos = formik.values?.produtos?.map((item) => {
      if (item?._id === produto?._id) {
        return {
          ...item,
          qtdAplicada,
        };
      }
      return item;
    });

    formik.setFieldValue("produtos", produtos);
  }, [qtdAplicada]);

  const plus = () => {
    setQtd(Number(qtdAplicada) + 1);
  };

  const minus = () => {
    if (qtdAplicada > 0) {
      setQtd(Number(qtdAplicada) - 1);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& > *": {
          margin: "0 5px",
        },
      }}
    >
      <Icon
        style={{ color: "#81BF52", cursor: "pointer" }}
        fontSize="small"
        onClick={minus}
      >
        remove
      </Icon>

      <TextField
        type="number"
        min={0}
        value={qtdAplicada}
        onChange={(e) => setQtd(Number(e.target.value))}
        onInput={(e) => {
          if (e.target.value.split(".")[1]?.length > 2) {
            e.target.value = parseFloat(e.target.value).toFixed(2);
          }
        }}
        sx={{
          width: "100px",
        }}
      />

      <Icon
        style={{ color: "#81BF52", cursor: "pointer" }}
        fontSize="small"
        onClick={plus}
      >
        add
      </Icon>
    </div>
  );
};

const SelectParcela = ({ formik, produto, parcelas, isDetalhar }) => {
  const [parcela, setParcela] = useState(produto?.parcela || "");

  useEffect(() => {
    const produtos = formik.values?.produtos?.map((item) => {
      if (item?._id === produto?._id) {
        return {
          ...item,
          parcela,
        };
      }
      return item;
    });

    formik.setFieldValue("produtos", produtos);
  }, [parcela]);

  return parcelas?.length > 0 ? (
    <TextField
      select
      fullWidth
      label="Parcela"
      placeholder="Parcela"
      value={parcela}
      onChange={(e) => setParcela(e.target.value)}
      variant="outlined"
      sx={{ zIndex: 100000, width: "100px" }}
      SelectProps={{
        native: true,
      }}
      InputLabelProps={{ shrink: true }}
      disabled={isDetalhar}
    >
      <option value="" disabled>
        Selecione
      </option>
      {parcelas?.map((item) => (
        <option key={item.id} value={item.id}>
          {item?.parcela}
        </option>
      ))}
    </TextField>
  ) : (
    <p>N/A</p>
  );
};

export default function Itens({
  formik,
  isVenda = false,
  isDetalhar = false,
  setShowAddProduto = () => {},
  setShowAddProdutoLote = () => {},
  setCurrentItem = () => {},
  currentItem = null,
  parcelas,
}) {
  const [rows, setRows] = useState([]);
  const [tooltip, setTooltip] = useState(false);

  const columns = [
    {
      Header: "Cód. Cautela",
      accessor: "codCautela",
      align: "left",
    },
    {
      Header: "Produto",
      accessor: "produto",
      align: "left",
    },
    {
      Header: "Valor médio",
      accessor: "valorMedio",
      align: "left",
    },
    {
      Header: "Unidade de Medida",
      accessor: "unidadeMedida",
      align: "left",
    },
    {
      Header: "Lote / Série",
      accessor: "lote",
      align: "left",
    },
    {
      Header: "Validade",
      accessor: "dataVencimento",
      align: "left",
    },
    {
      Header: (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          width="100%"
          zIndex={1000}
        >
          <p>QTD disponível para aplicação</p>

          <Icon
            onMouseEnter={() => setTooltip(true)}
            onMouseLeave={() => setTooltip(false)}
            width="20px"
            color="alert"
            style={{
              cursor: "pointer",
            }}
          >
            info_outlined
          </Icon>
          <p
            style={{
              display: tooltip ? "block" : "none",
              position: "absolute",
              backgroundColor: "#111",
              color: "#fff",
              padding: "10px",
              borderRadius: "5px",
              width: "300px",
              top: "100%",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            A quantidade exibida é relativa ao estoque transitório do depósito.
            Todo o conteúdo apresentado foi previamente transacionado do estoque
            do depósito para um estoque transitório.
          </p>
        </Stack>
      ),

      accessor: "qtdDisponivel",
      align: "left",
    },
    {
      Header: "QTD aplicada",
      accessor: "qtdAplicada",
      align: "left",
    },
    {
      Header: "Parcela",
      accessor: "parcela",
      align: "left",
    },

    { Header: isDetalhar ? "" : "Ação", accessor: "action", align: "center" },
  ];

  useEffect(() => {
    formik.values?.produtos &&
      setRows(
        formik.values?.produtos?.map((produto) => ({
          produto: produto?.produto,
          valorMedio: Number(produto?.valorMedio).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          }),
          unidadeMedida: produto?.unidadeMedida,
          lote: produto?.lote,
          codCautela: produto?.codCautela,
          dataVencimento: produto?.dataVencimento,
          qtdDisponivel: produto?.qtdDisponivel,
          valorRetirado: produto?.valorRetirado,
          parcela: (
            <SelectParcela
              formik={formik}
              parcelas={parcelas}
              produto={produto}
              isDetalhar={isDetalhar}
            />
          ),
          qtdAplicada: isDetalhar ? (
            produto.qtdAplicada
          ) : (
            <QTDAplicada formik={formik} produto={produto} />
          ),

          action: (
            <div
              style={{
                justifyContent: "center",
                display: isDetalhar ? "none" : "flex",
              }}
            >
              <Tooltip title="Remover">
                <MDButton
                  variant="gradient"
                  color="error"
                  fullWidth
                  type="submit"
                  onClick={() => handleDelete(produto)}
                  iconOnly
                >
                  <Icon fontSize="small">delete_forever</Icon>
                </MDButton>
              </Tooltip>
            </div>
          ),
        }))
      );
  }, [formik.values]);

  const handleDelete = (produto) => {
    const produtos = formik.values?.produtos?.filter(
      (item) => item?._id !== produto?._id
    );

    formik.setFieldValue("produtos", produtos);
  };

  const handleAdd = (produto) => {
    const productExists = formik.values?.produtos?.find(
      (item) => item?._id === produto?._id
    );
    if (productExists) {
      return;
    } else {
      const produtos = [
        ...(formik.values?.produtos || []),
        {
          _id: produto?._id,
          produto: produto?.produto,
          valorMedio: produto?.valorMedio,
          unidadeMedida: produto?.unidadeMedida,
          lote: produto?.lote,
          codCautela: produto?.codCautela,
          dataVencimento: new Date(
            produto?.dataVencimento
          ).toLocaleDateString(),
          qtdDisponivel: produto?.qtdRetirada,
          qtdAplicada: produto?.qtdAplicada,
          parcela: produto?.parcela,
          valorRetirado: produto?.valorRetirado,
        },
      ];
      setShowAddProduto(false);
      setShowAddProdutoLote(false);
      formik.setFieldValue("produtos", produtos);
      setRows(produtos);
    }
  };

  useEffect(() => {
    if (currentItem) {
      handleAdd(currentItem);
      setCurrentItem(null);
    }
  }, [currentItem]);

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {!isDetalhar && (!isVenda || !process.env.REACT_APP_IS_PRIME_PLUS) && (
          <MDButton
            variant="contained"
            color="info"
            type="button"
            onClick={() => {
              setShowAddProduto(true);
            }}
          >
            Adicionar Item
          </MDButton>
        )}
      </div>

      {rows?.length > 0 && (
        <div style={{ marginTop: "-40px" }}>
          <DataTable
            table={{ columns, rows }}
            isSorted={false}
            disablePagination={true}
            noEndBorder
          />
        </div>
      )}

      {rows?.length === 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 5,
          }}
        >
          Nenhum item adicionado
        </div>
      )}
    </>
  );
}
