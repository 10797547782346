import Itens from "layouts/pedidos-compra/components/itens";

export default function ItensVenda({
  formik,
  refAddItem,
  isVenda = false,
  isDetalhar = false,
  setShowAddProduto = () => {},
  setShowAddProdutoLote = () => {},
  setCurrentItem = () => {},
  currentItem = null,
  custoUnitario = null,
  disableEdit = false,
}) {
  return (
    <Itens
      formik={formik}
      refAddItem={refAddItem}
      setShowAddProduto={setShowAddProduto}
      isDetalhar={isDetalhar}
      currentItem={currentItem}
      custoUnitario={custoUnitario}
      isVenda={isVenda}
      setShowAddProdutoLote={setShowAddProdutoLote}
      disableEdit={disableEdit}
    />
  );
}
