import {
  Box,
  Button,
  Card,
  Grid,
  Icon,
  TextField,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { exportToExcel } from "react-json-to-excel";
import MDButton from "components/MDButton";
import { format } from "utils/formatDate";
import ptBR from "date-fns/locale/pt-BR";
import EditarContaAPagar from "layouts/payables/components/editData";
import { findBillingStatus } from "layouts/payables/components/yupSchema";
import EditarContaAReceber from "layouts/receivables/components/editData";
import { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import ReportsService from "services/reports";
import { dotToComma } from "utils/numberComma";
registerLocale("ptBR", ptBR);

import { DataGrid } from "@mui/x-data-grid";
import "react-datepicker/dist/react-datepicker.css";
import { ptBR_DataGrid } from "utils/datagrid";

export default function SaldoDiario() {
  const [dia, setDia] = useState(new Date());
  const [dia2, setDia2] = useState(new Date());
  const [saldoDiario, setSaldoDiario] = useState(null);
  const [extrato, setExtrato] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filtroAtual, setFiltroAtual] = useState("Todos");

  const [currentIdPayable, setCurrentIdPayable] = useState(null);
  const [currentIdReceivable, setCurrentIdReceivable] = useState(null);

  async function fetchExtrato(changeDia = null, changeDia2 = null) {
    setLoading(true);

    try {
      const data = await ReportsService.saldoEntreDatas(changeDia, changeDia2);

      // Combina os pagamentos e recebimentos em uma única lista
      const combinedList = [
        ...data.pagamentosDoIntervalo.map((pagamento) => ({
          ...pagamento,
          type: "Pagamento",
        })),
        ...data.recebimentosDoIntervalo.map((recebimento) => ({
          ...recebimento,
          type: "Recebimento",
        })),
      ];

      // Ordena a lista combinada por data
      const sortedList = combinedList
        .sort((a, b) => new Date(a.dataPedido) - new Date(b.dataPedido))
        .map((item) => ({
          ...item,
          valor: dotToComma(item.valor.toFixed(2)),
          supplier: item.supplier
            ? item.supplier?.razaoSocial || item.supplier?.nomeFantasia
            : item.customer?.razaoSocial || item.customer?.nomeFantasia,
          status: findBillingStatus(item.status),
          accountGroup: item.accountGroup?.descricao,
          accountSubGroup: item.accountSubGroup?.nome,
          dataVencimento: item.dataVencimento
            ? format(new Date(item.dataVencimento), "dd/MM/yyyy")
            : "",
        }));

      setExtrato(sortedList);
      setSaldoDiario(data);
      setLoading(false);
    } catch (error) {
      console.error("Erro ao buscar extrato:", error);
      setLoading(false);
    }
  }

  const handleDateChange = async (date) => {
    setDia(date);
    if (!loading) fetchExtrato(date.toISOString(), dia2.toISOString());
  };

  const handleDate2Change = async (date) => {
    setDia2(date);
    if (!loading) fetchExtrato(dia.toISOString(), date.toISOString());
  };

  const printAgreements = (agreements) => {
    exportToExcel(agreements, "extrato_periodos_" + new Date().getTime(), true);
  };

  useEffect(() => {
    fetchExtrato();
  }, []);

  return (
    <DashboardLayout>
      <MDBox pt={6} pb={3}>
        {currentIdPayable && (
          <EditarContaAPagar
            setShowEditState={() => setCurrentIdPayable(null)}
            currentIdAction={currentIdPayable}
            fetchData={() => fetchExtrato(dia.toISOString())}
          />
        )}

        {currentIdReceivable && (
          <EditarContaAReceber
            setShowEditState={() => setCurrentIdReceivable(null)}
            currentIdAction={currentIdReceivable}
            fetchData={() => fetchExtrato(dia.toISOString())}
          />
        )}

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  Saldo entre Datas
                </MDTypography>
              </MDBox>
              <MDBox pt={3} mx={2} py={3} px={2}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "2fr 1fr",
                  }}
                >
                  <Typography variant="h4" gutterBottom>
                    Saldo do Periodo ({format(dia, "dd/MM/yyyy")}
                    {" - "}
                    {format(dia2, "dd/MM/yyyy")})
                  </Typography>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <TextField
                      id="filtroAtual"
                      name="filtroAtual"
                      label="Tipo"
                      value={filtroAtual}
                      onChange={(e) => setFiltroAtual(e.target.value)}
                      select
                      SelectProps={{
                        native: true,
                      }}
                    >
                      <option value="Todos">Todos</option>
                      <option value="Pagamento">Pagamentos</option>
                      <option value="Recebimento">Recebimentos</option>
                    </TextField>

                    <DatePicker
                      selected={dia}
                      onChange={handleDateChange} //only when value has changed
                      locale="ptBR"
                      dateFormat="dd/MM/yyyy"
                      customInput={<TextField style={{ paddingLeft: 4 }} />}
                    />
                    <div
                      style={{
                        display: "flex",
                        margin: 8,
                        marginTop: 6,
                      }}
                    >
                      a
                    </div>
                    <DatePicker
                      selected={dia2}
                      onChange={handleDate2Change} //only when value has changed
                      locale="ptBR"
                      dateFormat="dd/MM/yyyy"
                      customInput={<TextField style={{ paddingLeft: 4 }} />}
                    />
                  </div>
                </Box>
                {loading && (
                  <Typography variant="body1">Carregando...</Typography>
                )}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    mt: 2,
                  }}
                >
                  <Typography variant="h6">
                    Saldo Anterior: R${" "}
                    {dotToComma(
                      Number(saldoDiario?.saldoAnterior).toFixed(2) || 0
                    )}
                  </Typography>
                </Box>
                {!loading && (
                  <DataGrid
                    rows={extrato.filter((value) =>
                      filtroAtual !== "Todos"
                        ? value.type === filtroAtual
                        : 1 > 0
                    )}
                    // empty
                    localeText={ptBR_DataGrid}
                    sx={{
                      ".MuiDataGrid-virtualScroller": {
                        minHeight: "100px",
                      },
                    }}
                    columns={[
                      {
                        field: "dataVencimento",
                        headerName: "Vencimento",
                        flex: 1,
                      },
                      {
                        field: "supplier",
                        headerName: "Cliente / Fornecedor",
                        flex: 1,
                      },
                      {
                        field: "accountGroup",
                        headerName: "Centro de Custo",
                        flex: 1,
                      },
                      {
                        field: "accountSubGroup",
                        headerName: "Natureza de Lançamento",
                        flex: 1,
                      },
                      { field: "descricao", headerName: "Descrição", flex: 1 },
                      { field: "type", headerName: "Tipo", flex: 1 },
                      { field: "status", headerName: "Status", flex: 1 },
                      {
                        field: "valor",
                        headerName: "Valor",
                        flex: 1,
                        align: "right",
                        renderCell: (params) => (
                          <Typography
                            style={{
                              color:
                                params.row.type === "Pagamento"
                                  ? "red"
                                  : "green",
                            }}
                          >{`${params.row.type === "Pagamento" ? "-" : "+"}R$${
                            params.row.valor
                          }`}</Typography>
                        ),
                      },
                      {
                        field: "actions",
                        headerName: "",
                        flex: 1,
                        renderCell: (params) => (
                          <MDButton
                            variant="gradient"
                            color="info"
                            fullWidth
                            type="submit"
                            onClick={
                              params.row.type === "Pagamento"
                                ? () => setCurrentIdPayable(params.row?._id)
                                : () => setCurrentIdReceivable(params.row?._id)
                            }
                            iconOnly
                          >
                            <Icon fontSize="small">edit</Icon>
                          </MDButton>
                        ),
                      },
                    ]}
                    pageSize={5}
                    componentsProps={{
                      cell: {
                        style: {
                          fontWeight: "bold",
                        },
                      },
                    }}
                    getRowId={(row) => row?._id}
                  />
                )}

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    mt: 2,
                  }}
                >
                  <Typography variant="h6">
                    Total do Periodo: R${" "}
                    {dotToComma(
                      Number(saldoDiario?.saldoDiario).toFixed(2) || 0
                    )}
                  </Typography>

                  <Typography variant="h6">
                    Total: R${" "}
                    {dotToComma(
                      Number(
                        saldoDiario?.saldoAnterior + saldoDiario?.saldoDiario
                      ).toFixed(2) || 0
                    )}
                  </Typography>
                </Box>
              </MDBox>
            </Card>

            {extrato.length > 0 && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    p: 2,
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() =>
                      printAgreements([
                        {
                          sheetName: "Extrato do Periodo",
                          details: extrato
                            .filter((value) =>
                              filtroAtual !== "Todos"
                                ? value.type === filtroAtual
                                : 1 > 0
                            )
                            .map((row) =>
                              Object({
                                Vencimento: row.dataVencimento,
                                "Cliente/Fornecedor": row.supplier,
                                "Centro de Custo": row.accountGroup,
                                "Natureza de Lançamento": row.accountSubGroup,
                                Descrição: row.descricao,
                                Tipo: row.type,
                                Status: row.status,
                                Valor: row.valor,
                              })
                            ),
                        },
                      ])
                    }
                    sx={{
                      color: "white !important",
                    }}
                  >
                    Exportar para Excel
                  </Button>
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
