import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import Service from "services/payables";
import { format } from "utils/formatDate";
import { Icon, Tooltip } from "@mui/material";

export default function data(handleEdit, handleDelete, handleDetail, setLoading = () => {}) {
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [sortStr, setSortStr] = useState(null);
  const [filterStr, setFilterStr] = useState(null);

  const fetchData = async () => {
    setLoading(true);

    const { data, limit, total } = await Service.paginate(page, perPage, sortStr, filterStr);

    setPerPage(limit);
    setTotalRows(total);

    setRows(
      data.map((row) =>
        Object({
          id: row._id,
          action: row,
          partidaContabil: row.partidaContabil || "-",
          numPedido: row.purchase?.numPedido || row?.purchaseWarehouse?.numPedido || "-",
          descricao: row.descricao,
          accountGroup: row.accountGroup?.descricao,
          supplier: row.supplier?.razaoSocial || row.supplier?.nomeFantasia,
          dataPedido: row.dataPedido !== undefined && row.dataPedido !== null ? format(new Date(row.dataPedido), "dd/MM/yyyy") : "",
          dataVencimento: row.dataVencimento !== undefined && row.dataVencimento !== null ? format(new Date(row.dataVencimento), "dd/MM/yyyy") : "",
          dataPagamento: row.dataPagamento !== undefined && row.dataPagamento !== null ? format(new Date(row.dataPagamento), "dd/MM/yyyy") : "",
          valor: Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(row?.valor),
        })
      )
    );

    setLoading(false);
  };

  const action = (props) => {
    const row = props?.row?.action;

    return (
      <MDBox display="flex" gap={1}>
        <Tooltip title="Editar">
          <MDButton variant="gradient" color="info" fullWidth type="submit" onClick={() => handleEdit(row._id)} iconOnly>
            <Icon fontSize="small">edit</Icon>
          </MDButton>
        </Tooltip>

        <Tooltip title="Detalhes">
          <MDButton variant="gradient" color="success" fullWidth type="submit" onClick={() => handleDetail(row._id)} iconOnly>
            <Icon fontSize="small">visibility</Icon>
          </MDButton>
        </Tooltip>

        <Tooltip title="Remover">
          <MDButton variant="gradient" color="error" fullWidth type="submit" onClick={() => handleDelete(row)} iconOnly>
            <Icon fontSize="small">delete_forever</Icon>
          </MDButton>
        </Tooltip>
      </MDBox>
    );
  };

  const updateRows = (rows) => {
    setRows(
      rows.map((row) =>
        Object({
          id: row._id,
          action: row,
          partidaContabil: row.partidaContabil,
          numPedido: row.purchase?.numPedido,
          descricao: row.descricao,
          accountGroup: row.accountGroup?.descricao,
          supplier: row.supplier?.razaoSocial || row.supplier?.nomeFantasia,
          dataPedido: row.dataPedido !== undefined && row.dataPedido !== null ? format(new Date(row.dataPedido), "dd/MM/yyyy") : "",
          dataVencimento: row.dataVencimento !== undefined && row.dataVencimento !== null ? format(new Date(row.dataVencimento), "dd/MM/yyyy") : "",
          dataPagamento: row.dataPagamento !== undefined && row.dataPagamento !== null ? format(new Date(row.dataPagamento), "dd/MM/yyyy") : "",
          valor: Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(row?.valor),
        })
      )
    );
  };

  const updatePagination = ({ page: newPage, pageSize: newPerPage }) => {
    setPage(newPage);
    setPerPage(newPerPage);
  };

  const sortBy = (sortArr) => {
    if (sortArr.length === 0) {
      setSortStr(null);
      return;
    }

    const [filter] = sortArr;

    setSortStr(`&sortBy=${filter.field}&sortOrder=${filter.sort === "asc" ? 1 : "-1"}`);
  };

  const filterBy = ({ items }) => {
    if (items.length === 0) {
      setFilterStr(null);
      return;
    }

    const [filter] = items;

    if (filter.operator === "contains" && filter.value === undefined) {
      return;
    }

    setFilterStr(`&filterField=${filter.field}&filterOperator=${filter.operator}&filterValue=${filter.value}`);
  };

  useEffect(() => {
    fetchData();
  }, [page, perPage, sortStr, filterStr]);

  return {
    columns: [
      { headerName: "N. P", field: "numPedido", flex: 0.14 },
      { headerName: "Partida Contábil", field: "partidaContabil", flex: 0.14 },
      { headerName: "Descrição", field: "descricao", flex: 0.14 },
      { headerName: "Centro de Custo", field: "accountGroup", flex: 0.14 },
      { headerName: "Fornecedor", field: "supplier", flex: 0.14 },
      { headerName: "Data do Pedido", field: "dataPedido", flex: 0.14 },
      { headerName: "Data do Vencimento", field: "dataVencimento", flex: 0.14 },
      { headerName: "Data do Pagamento", field: "dataPagamento", flex: 0.14 },
      { headerName: "Valor", field: "valor", flex: 0.14 },
      { headerName: "Ações", field: "action", flex: 0.14, renderCell: action },
    ],

    rows,
    fetchData,
    updateRows,
    updatePagination,
    sortBy,
    filterBy,
    pageObject: {
      totalRows,
      perPage,
      page,
    },
    sortStr,
    filterStr,
  };
}
