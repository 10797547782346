import * as yup from "yup";

export const TIPO_DESCONTO = {
  PERCENTUAL: "P",
  VALOR: "V",
  NENHUM: "N",
};

export const yupSchema = yup.object().shape({
  descricao: yup.string().required("Campo descrição é obrigatório"),
  tipoDesconto: yup
    .string()
    .required("Campo tipoDesconto é obrigatório")
    .oneOf(Object.values(TIPO_DESCONTO), "Tipo de desconto inválido"),
  valorDesconto: yup.number(),
});

export const initialValues = {
  descricao: "",
  tipoDesconto: "",
  valorDesconto: 0,
};
