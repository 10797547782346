import * as yup from "yup";

export const ACCOUNT_TYPES = {
  ENTRADA: "input",
  SAIDA: "output",
};

export const yupSchema = yup.object().shape({
  unityPrice: yup.string().required("Esse campo é obrigatório"),
  product: yup.string().required("Esse campo é obrigatório"),
  description: yup.string().required("Esse campo é obrigatório"),
  quantity: yup.number().min(1, "Esse campo é obrigatório (mínimo 1)"),
  type: yup.string().required("Esse campo é obrigatório").oneOf(Object.values(ACCOUNT_TYPES), "Tipo de conta inválido"),
});

export const yupSchemaDashboard = yup.object().shape({
  warehouse: yup.string().required("Esse campo é obrigatório"),
  produto: yup.mixed().required("Esse campo é obrigatório"),
  minQuantity: yup.number().required("Esse campo é obrigatório"),
  maxQuantity: yup.number().required("Esse campo é obrigatório"),
  contact: yup.string().required("Esse campo é obrigatório"),
});

export const initialValues = {
  unityPrice: 0,
  product: null,
  accountGroupInput: null,
  accountGroupOutput: null,
  description: "",
  quantity: 0,
  type: null,
  observacoes: "",
};

export const initialValuesDashboard = {
  warehouse: "",
  produto: "",
  minQuantity: 0,
  maxQuantity: 0,
  contact: "",
};
