import * as yup from "yup";

export const ROLES = {
  ADMIN: "admin",
  BUYER: "comprador",
  SELLER: "vendedor",
  FINANCIAL: "financeiro",
  SUPPORT: "suporte",
};

export const findRole = (role) => {
  switch (role) {
    case ROLES.ADMIN:
      return "Administrador";
    case ROLES.BUYER:
      return "Comprador";
    case ROLES.SELLER:
      return "Vendedor";
    case ROLES.FINANCIAL:
      return "Financeiro";
    case ROLES.SUPPORT:
      return "Suporte";
    default:
      return "";
  }
};

export const yupSchema = yup.object().shape({
  name: yup.string().required("Campo nome é obrigatório"),
  email: yup
    .string()
    .required("Campo email é obrigatório")
    .email("Email inválido"),
  role: yup
    .string()
    .required("Campo role é obrigatório")
    .oneOf(Object.values(ROLES), "Função inválida")
    .default(ROLES.BUYER),
  cpf: yup.string().required("Campo CPF é obrigatório"),
  dataNascimento: yup.date().nullable(),
  logradouro: yup.string(),
  numero: yup.string(),
  cep: yup.string(),
  complemento: yup.string(),
  bairro: yup.string(),
  uf: yup.string(),
  telefone: yup.string(),
  celular: yup.string(),
  comissao: yup.number().min(0).max(100),
  observacao: yup.string(),
});

export const initialValues = {
  name: "",
  email: "",
  role: ROLES.BUYER,
  cpf: "",
  dataNascimento: null,
  logradouro: "",
  numero: "",
  cep: "",
  complemento: "",
  bairro: "",
  uf: "",
  telefone: "",
  celular: "",
  comissao: 0,
  observacao: "",
};
