import HttpService from "./http.service";

class UsersService {
  list = async (sortStr = null, filterStr = null) => {
    const usersEndpoint = `/users?limit=-1${sortStr ? sortStr : ""}${
      filterStr ? filterStr : ""
    }`;
    return await HttpService.get(usersEndpoint);
  };

  paginate = async (page = 0, limit = 25, sortStr = null, filterStr = null) => {
    const usersEndpoint = `/users?page=${page}&limit=${limit}${
      sortStr ? sortStr : ""
    }${filterStr ? filterStr : ""}`;
    return await HttpService.get(usersEndpoint);
  };

  get = async (id) => {
    const usersEndpoint = "/users";
    return await HttpService.get(`${usersEndpoint}/${id}`);
  };

  getOnlyNames = async () => {
    const usersEndpoint = "/users-names";
    return await HttpService.get(`${usersEndpoint}`);
  };

  create = async (data) => {
    const usersEndpoint = "/users";
    return await HttpService.post(usersEndpoint, data);
  };

  update = async (id, data) => {
    const usersEndpoint = "/users";
    return await HttpService.put(`${usersEndpoint}/${id}`, data);
  };

  delete = async (id) => {
    const usersEndpoint = "/users";
    return await HttpService.delete(`${usersEndpoint}/${id}`);
  };
}

export default new UsersService();
