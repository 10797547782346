// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { DataGrid } from "@mui/x-data-grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { ptBR_DataGrid } from "utils/datagrid";

// Data
import MDButton from "components/MDButton";
import MDDialog from "components/MDDialog";
import { useEffect, useState } from "react";

import AdicionarDado from "./components/addData";
import DetalhesDado from "./components/detailData";
import EditarDado from "./components/editData";
import tableData from "./components/tableData";

import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Service from "services/purchasesWarehouse";

import { Icon, Menu, MenuItem, Tooltip } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Filter from "./components/filter";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function PedidosCompra({ fromParent = false, setShowImportState = null, selectParent = null }) {
  const [showAddState, setShowAddState] = useState(false);
  const [showEditState, setShowEditState] = useState(false);
  const [showDetailState, setShowDetailState] = useState(false);
  const [showFilterState, setShowFilterState] = useState(false);
  const [dialogRemove, setDialogRemove] = useState(false);
  const [dialogText, setDialogText] = useState("");
  const [currentIdAction, setCurrentIdAction] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);

  const [isFiltered, setIsFiltered] = useState(false);
  const [currentFilter, setCurrentFilter] = useState({});

  const location = useLocation();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));

  const handleDetail = async (id) => {
    setCurrentIdAction(id);
    setShowDetailState(true);
    window.scrollTo(0, 0);
  };

  const handleEdit = (id) => {
    setCurrentIdAction(id);
    setShowEditState(true);
    window.scrollTo(0, 0);
  };

  const handleDelete = (obj) => {
    setDialogRemove(true);
    setDialogText(obj.numPedido);
    setCurrentIdAction(obj._id);
  };

  const cancelDelete = () => {
    setDialogRemove(false);
    setDialogText("");
    setCurrentIdAction(null);
  };

  const confirmDelete = async () => {
    await Service.delete(currentIdAction);
    setDialogRemove(false);
    fetchData();
  };

  const { columns, rows, fetchData, updateRows, updatePagination, sortBy, filterBy, pageObject, sortStr, filterStr } = tableData(
    handleEdit,
    handleDelete,
    handleDetail,
    fromParent,
    selectParent,
    setLoadingTable
  );

  const resetRows = () => {
    updateRows([]);
    setLoading();
    setIsFiltered(false);
    setCurrentFilter({});
    fetchData();
  };

  const doCurrentFilter = async () => {
    if (JSON.stringify(currentFilter) !== "{}") {
      setLoading(true);
      setLoadingTable(true);
      const data = await Service.filter(currentFilter);
      updateRows(data);
      setLoading(false);
      setLoadingTable(false);
    } else {
      resetRows();
    }
  };

  useEffect(() => {
    if (location.state && location.state.id) {
      setCurrentIdAction(location.state.id);
      setShowEditState(true);
    }

    if (location.state && location.state.pedidosAguardandoAcerto) {
      setCurrentFilter({
        aguardandoAcerto: "S",
      });

      const doFilterLocation = async () => {
        const newRows = await Service.filter({
          aguardandoAcerto: "S",
        });

        updateRows(newRows);
        setIsFiltered(true);
      };

      setTimeout(() => {
        doFilterLocation();
      }, 3000);
    }

    // pedidosCompraAguardandoFechamento
    if (location.state && location.state.pedidosCompraAguardandoFechamento) {
      setLoadingTable(true);
      setCurrentFilter({
        aguardandoFechamento: "S",
      });

      const doFilterLocation = async () => {
        const newRows = await Service.filter({
          aguardandoFechamento: "S",
        });

        updateRows(newRows);
        setIsFiltered(true);
        setLoadingTable(false);
      };

      setTimeout(() => {
        doFilterLocation();
      }, 3000);
    }
  }, [location, loading]);

  return (
    <DashboardLayout fromParent={fromParent}>
      {!fromParent && <DashboardNavbar />}
      <MDBox pt={6} pb={3}>
        {showEditState && <EditarDado setShowEditState={setShowEditState} currentIdAction={currentIdAction} fetchData={isFiltered ? doCurrentFilter : fetchData} />}
        {showAddState && <AdicionarDado setShowAddState={setShowAddState} fetchData={isFiltered ? doCurrentFilter : fetchData} />}
        {showDetailState && <DetalhesDado setShowDetailState={setShowDetailState} currentIdAction={currentIdAction} fetchData={isFiltered ? doCurrentFilter : fetchData} />}

        {showFilterState && (
          <Filter Service={Service} resetRows={resetRows} updateRows={updateRows} setIsFiltered={setIsFiltered} setShowFilterState={setShowFilterState} setCurrentFilter={setCurrentFilter} />
        )}
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" sx={{ display: "flex", justifyContent: "space-between" }}>
                <MDTypography variant="h6" color="white" mt={1}>
                  Pedidos de Compra
                </MDTypography>

                <div>
                  {!matchesSm && (
                    <>
                      <Tooltip title="Ações">
                        <MDButton
                          variant="contained"
                          fullWidth
                          type="button"
                          id="basic-button"
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={handleClick}
                          iconOnly
                          display={{ xs: "block", md: "hidden" }}
                        >
                          <Icon fontSize="small">more_vert</Icon>
                        </MDButton>
                      </Tooltip>

                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        {fromParent && setShowImportState && <MenuItem onClick={() => setShowImportState(false)}>Voltar</MenuItem>}

                        {/* <MenuItem
                          onClick={() => {
                            setShowFilterState(true);
                          }}
                        >
                          Filtrar
                          {isFiltered && (
                            <>
                              {" "}
                              <Icon fontSize="small">filter_alt</Icon>
                            </>
                          )}
                        </MenuItem> */}

                        <MenuItem onClick={() => setShowAddState(true)} disabled={loading}>
                          Adicionar
                        </MenuItem>
                      </Menu>
                    </>
                  )}

                  {fromParent && setShowImportState && (
                    <MDButton
                      variant="contained"
                      type="button"
                      onClick={() => setShowImportState(false)}
                      sx={{
                        mr: 2,
                      }}
                    >
                      Voltar
                    </MDButton>
                  )}

                  {matchesSm && (
                    <>
                      {/* {!fromParent && !setShowImportState && (
                        <MDButton
                          variant="contained"
                          type="button"
                          onClick={() => setShowFilterState(true)}
                          sx={{
                            mr: 1,
                          }}
                        >
                          Filtrar
                          {isFiltered && (
                            <>
                              {" "}
                              <Icon fontSize="small">filter_alt</Icon>
                            </>
                          )}
                        </MDButton>
                      )} */}

                      <MDButton variant="contained" type="button" onClick={() => setShowAddState(true)} disabled={loading}>
                        Adicionar
                      </MDButton>
                    </>
                  )}
                </div>
              </MDBox>
              <MDBox pt={3}>
                <Card sx={{ padding: 2 }}>
                  {isFiltered && "Filtrando Registros"}

                  {isFiltered ? (
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      pageSizeOptions={[50, 100]}
                      rowSelection={false}
                      loading={loading}
                      localeText={ptBR_DataGrid}
                      sx={{
                        ".MuiDataGrid-virtualScroller": {
                          minHeight: "100px",
                        },
                      }}
                    />
                  ) : (
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      rowCount={pageObject.totalRows}
                      pageSizeOptions={[50, 100]}
                      paginationModel={{
                        pageSize: pageObject.perPage,
                        page: pageObject.page,
                      }}
                      paginationMode="server"
                      onPaginationModelChange={updatePagination}
                      sortingMode="server"
                      onSortModelChange={sortBy}
                      filterMode="server"
                      onFilterModelChange={filterBy}
                      rowSelection={false}
                      loading={loadingTable}
                      localeText={ptBR_DataGrid}
                      sx={{
                        ".MuiDataGrid-virtualScroller": {
                          minHeight: "100px",
                        },
                      }}
                    />
                  )}
                </Card>
              </MDBox>
            </Card>
          </Grid>
        </Grid>

        {loading && (
          <MDBox
            pt={"30px"}
            pb={"5%"}
            sx={{
              position: "absolute",
              zIndex: 9999,
              width: "100vw",
              minHeight: "100vh",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.5)",
              backdropFilter: "blur(5px)",
              top: 0,
              right: 0,
              overflow: "auto",
            }}
          >
            <MDTypography
              variant="h4"
              sx={{
                color: "white !important",
                textAlign: "center",
                fontWeight: "bold",
                marginTop: "20%",
              }}
            >
              Carregando...
            </MDTypography>
          </MDBox>
        )}

        <MDDialog
          open={dialogRemove}
          handleClose={() => setDialogRemove(false)}
          title={"Tem certeza que deseja remover?"}
          actions={
            <>
              <MDButton variant="gradient" color="info" fullWidth type="submit" onClick={() => confirmDelete()}>
                Confirmar
              </MDButton>
              <MDButton variant="gradient" color="error" fullWidth type="submit" onClick={() => cancelDelete()}>
                Cancelar
              </MDButton>
            </>
          }
        >
          {dialogText}
        </MDDialog>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
