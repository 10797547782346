import { Box, Button, Card, Grid, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { format } from "utils/formatDate";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { useFormik } from "formik";
import { findTipoFrete } from "layouts/pedidos-compra/components/yupSchema";
import { findStatusPedido } from "layouts/pedidos-compra/components/yupSchema";
import { useState } from "react";
import { exportToExcel } from "react-json-to-excel";
import Service from "services/reports";
import { dotToComma } from "utils/numberComma";

const initialValues = {
  startDate: "",
  endDate: "",
  genesis: null,
};

const accountGroups = [
  { id: "6526c91d8017b394afadcb6c", name: "GENESIS 1" },
  { id: "6526c91d8017b394afadcb6d", name: "GENESIS 1" },
  { id: "6526c91d8017b394afadcb6e", name: "GENESIS 2" },
  { id: "6526c91d8017b394afadcb6f", name: "GENESIS 2" },
  { id: "6526c91d8017b394afadcb70", name: "GENESIS 3" },
  { id: "6526c91d8017b394afadcb71", name: "GENESIS 3" },
  { id: "6526c91d8017b394afadcb72", name: "GENESIS 4" },
  { id: "6526c91d8017b394afadcb73", name: "GENESIS 4" },
  { id: "6526c91d8017b394afadcb74", name: "GENESIS 5" },
  { id: "6526c91d8017b394afadcb75", name: "GENESIS 5" },
  { id: "6526c91d8017b394afadcb76", name: "GENESIS 6" },
  { id: "6526c91d8017b394afadcb77", name: "GENESIS 6" },
  { id: "6526c91d8017b394afadcb78", name: "GENESIS 7" },
  { id: "6526c91d8017b394afadcb79", name: "GENESIS 8" },
  { id: "6526c91d8017b394afadcb7a", name: "GENESIS 9" },
];

const suppliers = [
  { id: "6528655bfd8f87f3fe891141", name: "GENESIS 1" },
  { id: "6528655bfd8f87f3fe891142", name: "GENESIS 10" },
  { id: "6528655bfd8f87f3fe891143", name: "GENESIS 11" },
  { id: "6528655bfd8f87f3fe891144", name: "GENESIS 12" },
  { id: "6528655bfd8f87f3fe891145", name: "GENESIS 13" },
  { id: "6528655bfd8f87f3fe891146", name: "GENESIS 2" },
  { id: "6528655bfd8f87f3fe891147", name: "GENESIS 3" },
  { id: "6528655bfd8f87f3fe891148", name: "GENESIS 4" },
  { id: "6528655bfd8f87f3fe891149", name: "GENESIS 5" },
  { id: "6528655bfd8f87f3fe89114a", name: "GENESIS 6" },
  { id: "6528655bfd8f87f3fe89114b", name: "GENESIS 7" },
  { id: "6528655bfd8f87f3fe89114c", name: "GENESIS 8" },
  { id: "6528655bfd8f87f3fe89114d", name: "GENESIS 9" },
  { id: "654a75ac04cc418b06320228", name: "GENESIS 15" },
  { id: "654a7e0104cc418b0632048e", name: "GENESIS 15" },
  { id: "654a7e1204cc418b06320497", name: "GENESIS 12" },
  { id: "654a7e2a04cc418b063204a4", name: "GENESIS 11" },
  { id: "654a7e3b04cc418b063204b1", name: "GENESIS 13" },
  { id: "654a7e5004cc418b063204be", name: "GENESIS 14" },
  { id: "654a7f2904cc418b06320572", name: "GENESIS 15" },
];

export default function GenesisReport() {
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState([]);
  const [originalData, setOriginalData] = useState({});

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      setLoading(true);

      try {
        const rows = await Service.genesisReport({
          ...values,
          supplier: values.supplier?._id || values.supplier,
        });

        const parsedReport = await parseReport(rows);
        setOriginalData(rows);

        if (!parsedReport.length) {
          alert("Nenhum resultado encontrado");
        }

        setReport(parsedReport);

        setLoading(false);
      } catch (error) {
        console.log(error);
      }

      setLoading(false);
    },
  });

  const parseReport = async (rows) => {
    if (!rows || JSON.stringify(rows) === "{}") return [];

    const data = {
      "Total de Pedidos": rows.totalPedidos,
      "Total de Produtividade": maskTonelada(rows.totalProdutividade),
      "Total por Area": maskTonelada(rows.totalPorArea),
      "Total por Hectare": maskTonelada(rows.totalPorHa),
      "Total a Pagar": maskMoney(rows.totalPagar),
      "Total a Pagar por Area": maskMoney(rows.totalPagarPorArea),
      "Total a Pagar por Hectare": maskMoney(rows.totalPagarPorHa),
      "Total a Receber": maskMoney(rows.totalReceber),
      "Total a Receber por Area": maskMoney(rows.totalReceberPorArea),
      "Total a Receber por Hectare": maskMoney(rows.totalReceberPorHa),
      Lucrabilidade: maskMoney(rows.lucrabilidade),
      "Lucrabilidade por Area": maskMoney(rows.lucrabilidadePorArea),
    };

    return [data];
  };

  const printReport = (report) => {
    exportToExcel(report, "genesis_report_" + new Date().getTime(), true);
  };

  const maskTonelada = (value) => {
    return value ? value.toFixed(2).replace(".", ",") + " T" : value;
  };

  const maskMoney = (value) => {
    return value
      ? new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(value)
      : value;
  };

  const productsToString = (products) => {
    return products
      .map((product) =>
        `${product.product.descricao} - ${product.quantity} ${
          product.measure || ""
        }`.trim()
      )
      .join(", ");
  };

  const cleanRawRows = (lines) => {
    const linesToExport = lines.map((line) => ({
      "No. Pedido": line.numPedido,
      Data: line.data ? format(new Date(line.data), "dd/MM/yyyy") : "",
      Status: findStatusPedido(line.status),
      "Produto x Quantidade": productsToString(line.products),
      "Valor Total dos Produtos": dotToComma(line.valorTotal),
      Fornecedor:
        line.supplier?.nomeFantasia || line.supplier?.razaoSocial || "",
      Comprador: line.user?.name || "",
      Transportadora: line.shippingCompany?.nome || "",
      Agenciador: line.agent?.nome || "",
      "Peso Bruto": line.pesoBruto,
      Tara: line.tara,
      "Peso Liquido": line.pesoLiquido,
      "Qtd. Frutas": line.qtdFrutas,
      Media: line.media,
      "Preço por Tonelada": dotToComma(line.precoTonelada),
      "Data de Descarga": line.dataDescarga
        ? format(new Date(line.dataDescarga), "dd/MM/yyyy")
        : "",
      "Valor do Frete": dotToComma(line.valorFrete),
      "Valor do ICMS": dotToComma(line.valorIcms),
      Carregamento: dotToComma(line.carregamento),
      "Adiantamento do Frete": dotToComma(line.adiantamentoFrete),
      "Total a Pagar do Frete": dotToComma(line.totalPagarFrete),
      Motorista: line.motorista,
      "Tipo de Frete": findTipoFrete(line.tipoFrete),
      Caminhão: line.caminhao,
      Telefone: line.telefone,
      "Valor Final": dotToComma(line.totalFinal),
      Observações: line.observacoes || "",
    }));

    return linesToExport;
  };

  const cleanPayables = (payables) => {
    return payables.map((payable) => ({
      "Total a Pagar": dotToComma(payable.totalValor),
      Gênesis: payable.accountGroup,
    }));
  };

  return (
    <DashboardLayout>
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  Relatório de Produtividade (Gênesis)
                </MDTypography>
              </MDBox>
              <MDBox pt={3} mx={2} py={3} px={2}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 2,
                    mb: 2,
                  }}
                  component="form"
                  onSubmit={formik.handleSubmit}
                >
                  <TextField
                    id="startDate"
                    name="startDate"
                    label="Data Inicial"
                    type="date"
                    value={formik.values.startDate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={loading}
                    error={
                      formik.touched.startDate &&
                      Boolean(formik.errors.startDate)
                    }
                    helperText={
                      formik.touched.startDate && formik.errors.startDate
                    }
                    sx={{
                      width: "100%",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    id="endDate"
                    name="endDate"
                    label="Data Final"
                    type="date"
                    value={formik.values.endDate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={loading}
                    error={
                      formik.touched.endDate && Boolean(formik.errors.endDate)
                    }
                    helperText={formik.touched.endDate && formik.errors.endDate}
                    sx={{
                      width: "100%",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  {/* <TextField
                    select
                    id="genesis"
                    name="genesis"
                    label="Gênesis"
                    value={formik.values.genesis}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.genesis && Boolean(formik.errors.genesis)
                    }
                    helperText={formik.touched.genesis && formik.errors.genesis}
                    SelectProps={{
                      native: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <option value="">Todas</option>
                    <option value="GENESIS 1">GENESIS 1</option>
                    <option value="GENESIS 2">GENESIS 2</option>
                    <option value="GENESIS 3">GENESIS 3</option>
                    <option value="GENESIS 4">GENESIS 4</option>
                    <option value="GENESIS 5">GENESIS 5</option>
                    <option value="GENESIS 6">GENESIS 6</option>
                    <option value="GENESIS 7">GENESIS 7</option>
                    <option value="GENESIS 8">GENESIS 8</option>
                    <option value="GENESIS 9">GENESIS 9</option>
                    <option value="GENESIS 10">GENESIS 10</option>
                    <option value="GENESIS 11">GENESIS 11</option>
                    <option value="GENESIS 12">GENESIS 12</option>
                    <option value="GENESIS 13">GENESIS 13</option>
                    <option value="GENESIS 14">GENESIS 14</option>
                    <option value="GENESIS 15">GENESIS 15</option>
                  </TextField> */}

                  <Button
                    type="submit"
                    variant="contained"
                    disabled={loading}
                    sx={{
                      gridColumn: "span 2",
                      justifySelf: "center",
                      width: "100%",
                      color: "white !important",
                    }}
                  >
                    {loading ? "Carregando..." : "Gerar Relatório"}
                  </Button>
                </Box>
              </MDBox>
            </Card>

            {JSON.stringify(originalData) !== "{}" && (
              <>
                <Card sx={{ marginTop: 4 }}>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <MDTypography variant="h6" color="white" mt={1}>
                      Resultado
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3} mx={2} py={3} px={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <MDTypography variant="h6" color="info">
                          Total de Pedidos
                        </MDTypography>

                        <MDTypography variant="h6" color="secondary">
                          {originalData.totalPedidos}
                        </MDTypography>
                      </Grid>

                      <Grid item xs={6}>
                        <MDTypography variant="h6" color="info">
                          Total de Produtividade
                        </MDTypography>

                        <MDTypography variant="h6" color="secondary">
                          {maskTonelada(originalData.totalProdutividade)}
                        </MDTypography>
                      </Grid>

                      <Grid item xs={6}>
                        <MDTypography variant="h6" color="info">
                          Total por Area
                        </MDTypography>

                        <MDTypography variant="h6" color="secondary">
                          {maskTonelada(originalData.totalPorArea)}
                        </MDTypography>
                      </Grid>

                      <Grid item xs={6}>
                        <MDTypography variant="h6" color="info">
                          Total por Hectare
                        </MDTypography>

                        <MDTypography variant="h6" color="secondary">
                          {maskTonelada(originalData.totalPorHa)}
                        </MDTypography>
                      </Grid>

                      <Grid item xs={6}>
                        <MDTypography variant="h6" color="info">
                          Total a Pagar
                        </MDTypography>

                        <MDTypography variant="h6" color="secondary">
                          {maskMoney(originalData.totalPagar)}
                        </MDTypography>
                      </Grid>

                      <Grid item xs={6}>
                        <MDTypography variant="h6" color="info">
                          Total a Pagar por Area
                        </MDTypography>

                        <MDTypography variant="h6" color="secondary">
                          {maskMoney(originalData.totalPagarPorArea)}
                        </MDTypography>
                      </Grid>

                      <Grid item xs={6}>
                        <MDTypography variant="h6" color="info">
                          Total a Pagar por Hectare
                        </MDTypography>

                        <MDTypography variant="h6" color="secondary">
                          {maskMoney(originalData.totalPagarPorHa)}
                        </MDTypography>
                      </Grid>

                      <Grid item xs={6}>
                        <MDTypography variant="h6" color="info">
                          Total a Receber
                        </MDTypography>

                        <MDTypography variant="h6" color="secondary">
                          {maskMoney(originalData.totalReceber)}
                        </MDTypography>
                      </Grid>

                      <Grid item xs={6}>
                        <MDTypography variant="h6" color="info">
                          Total a Receber por Area
                        </MDTypography>

                        <MDTypography variant="h6" color="secondary">
                          {maskMoney(originalData.totalReceberPorArea)}
                        </MDTypography>
                      </Grid>

                      <Grid item xs={6}>
                        <MDTypography variant="h6" color="info">
                          Total a Receber por Hectare
                        </MDTypography>

                        <MDTypography variant="h6" color="secondary">
                          {maskMoney(originalData.totalReceberPorHa)}
                        </MDTypography>
                      </Grid>

                      <Grid item xs={6}>
                        <MDTypography variant="h6" color="info">
                          Lucrabilidade
                        </MDTypography>

                        <MDTypography variant="h6" color="secondary">
                          {maskMoney(originalData.lucrabilidade)}
                        </MDTypography>
                      </Grid>

                      <Grid item xs={6}>
                        <MDTypography variant="h6" color="info">
                          Lucrabilidade por Area
                        </MDTypography>

                        <MDTypography variant="h6" color="secondary">
                          {maskMoney(originalData.lucrabilidadePorArea)}
                        </MDTypography>
                      </Grid>
                    </Grid>
                  </MDBox>
                </Card>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    p: 2,
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() =>
                      printReport([
                        {
                          sheetName: "Produtividade",
                          details: report,
                        },
                        {
                          sheetName: "Contas a Pagar",
                          details: cleanPayables(originalData.payables),
                        },
                        {
                          sheetName: "Pedidos",
                          details: cleanRawRows(originalData.purchases),
                        },
                      ])
                    }
                    sx={{
                      color: "white !important",
                    }}
                  >
                    Exportar Relatório Para Excel
                  </Button>
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}
