import {
  Alert,
  Box,
  Button,
  Card,
  Grid,
  Icon,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useFormik } from "formik";
import AdicionarProduto from "layouts/recebidos";
import { useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";
import { initialValuesDashboard, yupSchemaDashboard } from "./yupSchema";
import Destaques from "./destaqueDashboard";
import Service from "services/stock-dashboard";

const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#81BF52",
    },
    "& .MuiTab-root": {
      color: "#425d73 !important",
    },
    "& .MuiTab-root.Mui-selected": {
      backgroundColor: "#81bf52ab",
      color: "#FFFFFF !important",
    },
  },
});
export default function ConfigurarDashboard({
  setConfigDashboard,
  warehouse,
  fetchDataParent,
}) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [showAddProduto, setShowAddProduto] = useState(false);
  const [alertKey, setAlertKey] = useState(0);
  const [rows, setRows] = useState([]);
  const [childData, setChildData] = useState();

  const classes = useStyles();

  const fetchData = async () => {
    setLoading(true);
    const { data } = await Service.listByWarehouse(warehouse?.id);
    setRows(
      data.map((row) =>
        Object({
          id: row._id,
          action: row,
          produto: row.produto.descricao,
          unidadeMedida: row.produto.unidade,
          minQuantity: row.minQuantity,
          maxQuantity: row.maxQuantity,
          contact: row.contact,
        })
      )
    );
    setLoading(false);
  };
  const formik = useFormik({
    initialValues: {
      ...initialValuesDashboard,
      warehouse: warehouse?.nome,
    },
    validationSchema: yupSchemaDashboard,
    onSubmit: async (values) => {
      setLoading(true);

      values.warehouse = warehouse.id;

      try {
        await Service.create({
          ...values,
        });
        setChildData({
          produto: null,
        });
        fetchData();
        setSuccess(true);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setSuccess(false);
      }
      setAlertKey(alertKey + 1);
      setLoading(false);
    },
  });

  useEffect(() => {
    const closeOnEscapePressed = (e) => {
      if (e.key === "Escape") {
        setSuccess(null);
        setConfigDashboard(false);
      }
    };
    window.addEventListener("keydown", closeOnEscapePressed);
    return () => window.removeEventListener("keydown", closeOnEscapePressed);
  }, []);

  return (
    <>
      <MDBox
        pt={"30px"}
        sx={{
          position: "absolute",
          zIndex: 9999,
          width: "100vw",
          minHeight: "100vh",
          top: 0,
          right: 0,
        }}
      >
        <MDBox
          pt={"30px"}
          sx={{
            position: "fixed",
            zIndex: -1,
            width: "100vw",
            height: "110%",
            backgroundColor: "rgba(0,0,0,0.5)",
            backdropFilter: "blur(5px)",
            top: 0,
            right: 0,
          }}
        />
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={11} mx={"auto"}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  Configurar Dashboard
                </MDTypography>
                <MDButton
                  variant="contained"
                  type="button"
                  onClick={() => {
                    fetchDataParent();
                    setSuccess(null);
                    setConfigDashboard(false);
                  }}
                >
                  Voltar
                </MDButton>
              </MDBox>
              <MDBox pt={3} mx={2} py={3} px={2}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 2,
                    mb: 2,
                  }}
                  component="form"
                  onSubmit={formik.handleSubmit}
                >
                  <TextField
                    label="Depósito"
                    variant="outlined"
                    type="text"
                    name="warehouse"
                    value={formik.values.warehouse}
                    onChange={formik.handleChange}
                    fullWidth
                    disabled
                  />

                  <Alert
                    icon={false}
                    severity="success"
                    action={
                      <MDButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        iconOnly
                        onClick={() => setShowAddProduto(true)}
                      >
                        <Icon fontSize="small">edit</Icon>
                      </MDButton>
                    }
                    sx={{
                      border: formik.errors.accountGroupOutput
                        ? "1px solid red"
                        : "",
                    }}
                  >
                    <b>Produto a ser destacado: </b>
                    {childData?.produto?.produto}
                  </Alert>

                  <TextField
                    label="Quantidade mínima"
                    variant="outlined"
                    type="number"
                    name="minQuantity"
                    value={formik.values.minQuantity}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.minQuantity &&
                      Boolean(formik.errors.minQuantity)
                    }
                    helperText={
                      formik.touched.minQuantity && formik.errors.minQuantity
                    }
                    fullWidth
                  />
                  <TextField
                    label="Quantidade máxima"
                    variant="outlined"
                    type="number"
                    name="maxQuantity"
                    value={formik.values.maxQuantity}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.maxQuantity &&
                      Boolean(formik.errors.maxQuantity)
                    }
                    helperText={
                      formik.touched.maxQuantity && formik.errors.maxQuantity
                    }
                    fullWidth
                  />
                  <ReactInputMask
                    mask="(99) 99999-9999"
                    maskChar="_"
                    value={formik.values.contact}
                    onChange={formik.handleChange}
                    name="contact"
                  >
                    {() => (
                      <TextField
                        label="Contato"
                        variant="outlined"
                        type="text"
                        name="contact"
                        value={formik.values.contact}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.contact &&
                          Boolean(formik.errors.contact)
                        }
                        helperText={
                          formik.touched.contact && formik.errors.contact
                        }
                        fullWidth
                      />
                    )}
                  </ReactInputMask>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={loading}
                    sx={{
                      justifySelf: "center",
                      height: "40px",
                      width: "50%",
                      color: "white !important",
                    }}
                  >
                    {loading ? "Carregando..." : "Adicionar"}
                  </Button>

                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{
                      gridColumn: "span 2",
                      justifySelf: "center",
                      width: "100%",
                      mx: "auto",
                    }}
                    mb={2}
                  >
                    <Tabs
                      orientation={"horizontal"}
                      value={0}
                      className={classes.tabs}
                    >
                      <Tab label="Produtos em Destaque" />
                    </Tabs>
                  </Grid>
                  {
                    <Destaques
                      rows={rows}
                      fetchData={fetchData}
                      setLoading={setLoading}
                    />
                  }
                </Box>
                {success !== null && (
                  <Box>
                    <MDAlert
                      color={success ? "success" : "error"}
                      mt={4}
                      dismissible
                      key={alertKey}
                    >
                      <MDTypography variant="body2" color="white">
                        {success
                          ? "Destaques atualizados com sucesso!"
                          : "Ocorreu um erro ao atualizar destaques."}
                      </MDTypography>
                    </MDAlert>
                  </Box>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        {/* forms alternativos */}
        {showAddProduto && (
          <MDBox
            pt={"30px"}
            pb={"5%"}
            px={"5%"}
            sx={{
              position: "absolute",
              zIndex: 9999,
              width: "100vw",
              minHeight: "100vh",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.5)",
              backdropFilter: "blur(5px)",
              top: 0,
              right: 0,
              overflow: "auto",
            }}
          >
            <AdicionarProduto
              setShowAddProduto={setShowAddProduto}
              selectParent={(data) => {
                setChildData({
                  ...childData,
                  produto: data,
                });
                setShowAddProduto(false);
                formik.setFieldValue("produto", data.productObject._id);
              }}
              fromParent="dashboard"
              filterByWarehouse={warehouse?.id}
            />
          </MDBox>
        )}
      </MDBox>
    </>
  );
}
