import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import { ROLES, findRole } from "./yupSchema";
import { Icon, Tooltip } from "@mui/material";

export default function data(handleEdit, setLoading = () => {}) {
  const [rows, setRows] = useState([]);

  const fetchData = async () => {
    setLoading(true);

    setRows(
      Object.values(ROLES).map((row, idx) => ({
        id: idx + 1,
        role: findRole(row),
        originalRole: row,
      }))
    );

    setLoading(false);
  };

  const action = (props) => {
    const row = props?.row;

    return (
      <MDBox display="flex" gap={1}>
        <Tooltip title="Editar">
          <MDButton
            variant="gradient"
            color="info"
            fullWidth
            type="submit"
            onClick={() => handleEdit(row.originalRole)}
            iconOnly
          >
            <Icon fontSize="small">edit</Icon>
          </MDButton>
        </Tooltip>
      </MDBox>
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    columns: [
      { headerName: "ID", field: "id", flex: 0.3 },
      { headerName: "Perfil", field: "role", flex: 0.4 },
      { headerName: "Ações", field: "action", flex: 0.3, renderCell: action },
    ],

    rows,
    fetchData,
  };
}
