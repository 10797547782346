// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { DataGrid } from "@mui/x-data-grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// Data
import { useState } from "react";

import EditarDado from "./components/editData";
import tableData from "./components/tableData";


import { ptBR_DataGrid } from "utils/datagrid";

export default function Permissoes() {
  const [showEditState, setShowEditState] = useState(false);
  const [currentIdAction, setCurrentIdAction] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleEdit = (id) => {
    setCurrentIdAction(id);
    setShowEditState(true);
    window.scrollTo(0, 0);
  };

  const { columns, rows, fetchData } = tableData(handleEdit, setLoading);

  return (
    <DashboardLayout>
      <MDBox pt={3} pb={3}>
        {showEditState && (
          <EditarDado
            setShowEditState={setShowEditState}
            currentIdAction={currentIdAction}
            fetchData={fetchData}
          />
        )}
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  Acessos do Usuário
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <Card sx={{ padding: 2 }}>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    rowSelection={false}
                    loading={loading}
                    localeText={ptBR_DataGrid}
                    sx={{
                      ".MuiDataGrid-virtualScroller": {
                        minHeight: "100px",
                      },
                    }}
                  />
                </Card>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
