// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import TimelineItem from "examples/Timeline/TimelineItem";

function Commodities({ commodities }) {
  const validGradients = [
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ];

  return (
    <Card sx={{ height: "100%", maxHeight: "325px" }}>
      <MDBox pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          Commodities
        </MDTypography>
        <MDBox mt={0} mb={2}>
          <MDTypography variant="button" color="text" fontWeight="regular">
            <MDTypography
              display="inline"
              variant="body2"
              verticalAlign="middle"
            >
              <Icon sx={{ color: ({ palette: { success } }) => success.main }}>
                av_timer
              </Icon>
            </MDTypography>
            &nbsp;
            <MDTypography variant="button" color="text" fontWeight="medium">
              Há
            </MDTypography>{" "}
            1 minuto atrás
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox p={2} sx={{ overflow: "auto" }}>
        {commodities.map((commodity, k) => (
          <TimelineItem
            key={k}
            color={validGradients[k]}
            title={commodity.name + " - " + commodity.lastNum}
            dateTime={commodity.chg}
          />
        ))}
      </MDBox>
    </Card>
  );
}

export default Commodities;
