import * as yup from "yup";

export const BILLING_STATUS = {
  ABERTO: "A",
  ATRASADO: "T",
  PAGO: "P",
  CANCELADO: "C",
  PENDENTES: "D",
};

export const findBillingStatus = (status) => {
  switch (status) {
    case BILLING_STATUS.ABERTO:
      return "Aberto";
    case BILLING_STATUS.ATRASADO:
      return "Atrasado";
    case BILLING_STATUS.PAGO:
      return "Pago";
    case BILLING_STATUS.CANCELADO:
      return "Cancelado";
    case BILLING_STATUS.PENDENTES:
      return "Pendente";
    default:
      return "";
  }
};

export const yupSchema = yup.object().shape({
  numPedido: yup.string(),
  descricao: yup.string().required("Esse campo é obrigatório"),
  paymentMethod: yup.string().required("Esse campo é obrigatório"),
  dataPedido: yup.date().nullable(),
  dataVencimento: yup.date().nullable(),
  dataPagamento: yup.date().nullable(),
  valor: yup.string().required("Esse campo é obrigatório"),
  status: yup.string().required("Esse campo é obrigatório"),
  observacoes: yup.string(),
  accountGroup: yup.string().required("Esse campo é obrigatório"),
  accountSubGroup: yup.string().required("Esse campo é obrigatório"),
});

export const initialValues = {
  numPedido: "",
  descricao: "",
  supplier: null,
  paymentMethod: null,
  dataPedido: null,
  dataVencimento: null,
  dataPagamento: null,
  valor: 0,
  status: "",
  account: null,
  observacoes: "",
  partidaContabil: null,
  accountGroup: null,
  accountSubGroup: null,
  todasGenesis: null,
  fromNfe: null,
};
