import { useState, useEffect } from "react";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import BasicLayoutLanding from "layouts/authentication/components/BasicLayoutLanding";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";
import authService from "services/auth-service";
import { Link } from "react-router-dom";
import logoAlpha from "assets/images/logo-alpha.png";
import logoAlphaPlus from "assets/images/primeplus.png";
import { Typography } from "@mui/material";

function ForgotPassword() {
  const [isDemo, setIsDemo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState(false);
  const [input, setEmail] = useState({
    email: "",
  });
  const [error, setError] = useState({
    err: false,
    textError: "",
  });

  useEffect(() => {
    setIsDemo(process.env.REACT_APP_IS_DEMO === "true");
  }, []);

  const changeHandler = (e) => {
    setEmail({
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (
      input.email.trim().length === 0 ||
      !input.email.trim().match(mailFormat)
    ) {
      setError({ err: true, textError: "The email must be valid" });
      return;
    }

    // somthing not right with the data
    const myData = {
      data: {
        type: "password-forgot",
        attributes: {
          redirect_url: `${process.env.REACT_APP_URL}/auth/reset-password`,
          ...input,
        },
      },
    };

    try {
      if (isDemo == false) {
        setLoading(true);
        const response = await authService.forgotPassword(myData);
        setError({ err: false, textError: "" });
        setEmail({ email: "" });
        setLoading(false);
      }
      setNotification(true);
    } catch (err) {
      console.error(err);
      if (err.hasOwnProperty("errors")) {
        if (err.errors.hasOwnProperty("email")) {
          setError({ err: true, textError: err.errors.email[0] });
        } else {
          setError({ err: true, textError: "An error occured" });
        }
      }
      return null;
    }
  };

  return (
    <BasicLayoutLanding bgColor="#2C441C">
      <MDBox
        display="flex"
        width="100%"
        justifyContent="center"
        sx={{ zIndex: "99" }}
      >
        <MDBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          padding="1.5rem"
          width="100%"
        >
          <MDBox
            component="img"
            src={
              process.env.REACT_APP_IS_PRIME_PLUS ? logoAlphaPlus : logoAlpha
            }
            alt="logo"
            width="100%"
            maxWidth="380px"
            sx={{
              margin: "0 auto",
              filter:
                "brightness( 1000% ) contrast( 100% ) saturate( 100% ) blur( 0px ) hue-rotate( 0deg )",
            }}
          />

          <Typography
            variant="body2"
            style={{ color: "white" }}
            margin="0.5rem 0"
          >
            Você receberá um email com um link para redefinir sua senha.
          </Typography>
        </MDBox>
      </MDBox>

      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Redefinir senha
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox
            component="form"
            role="form"
            method="POST"
            onSubmit={handleSubmit}
          >
            <MDBox mb={4}>
              <MDInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                value={input.email}
                name="email"
                onChange={changeHandler}
                error={error.err}
                disabled={loading}
              />
            </MDBox>
            {error.err && (
              <MDTypography variant="caption" color="error" fontWeight="light">
                {error.textError}
              </MDTypography>
            )}
            <MDBox mt={6} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                type="submit"
                disabled={loading}
              >
                Enviar
              </MDButton>
            </MDBox>
          </MDBox>
          <MDBox mt={3} mb={1} textAlign="center">
            <MDTypography variant="button" color="text">
              Voltar para a página de{" "}
              <MDTypography
                component={Link}
                to="/auth/login"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                login
              </MDTypography>
            </MDTypography>
          </MDBox>
        </MDBox>
      </Card>
      {notification && (
        <MDAlert color="info" mt="20px" dismissible>
          <MDTypography variant="body2" color="white">
            {isDemo
              ? "You can't update the password in the demo version"
              : "Por favor verifique seu email para redefinir sua senha."}
          </MDTypography>
        </MDAlert>
      )}
    </BasicLayoutLanding>
  );
}

export default ForgotPassword;
