// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";


// Data

// Dashboard components
import Commodities from "layouts/dashboard/components/Commodities";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import commoditiesService from "services/commodities.service";
import dashboardService from "services/dashboard.service";
import payablesService from "services/payables";
import receivablesService from "services/receivables";
import weatherService from "services/weather.service";
import permissionsService from "services/permission";
import { getMes } from "utils/getMes";

import Carousel from "react-material-ui-carousel";
import { AREAS } from "layouts/permissoes/components/yupSchema";

function Dashboard() {
  const [commodities, setCommodities] = useState([]);
  const [weathers, setWeathers] = useState([
    {
      temperature: "--",
      is_day: true,
      windspeed: "--",
      city: "--",
    },
  ]);
  const [payables, setPayables] = useState({});
  const [receivables, setReceivables] = useState({});
  const [dashboard, setDashboard] = useState({});
  const [lastPurchases, setLastPurchases] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const navigate = useNavigate();

  const fetchCommodities = async () => {
    const commoditiesData = await commoditiesService.get();
    setCommodities(commoditiesData);
  };

  const fetchWeather = async () => {
    const weatherData = await weatherService.get();
    setWeathers(weatherData);
  };

  const fetchPayables = async () => {
    const payablesData = await payablesService.getlastMonths();

    setPayables({
      labels: payablesData.map((item) => {
        return getMes(item._id.month);
      }),
      datasets: {
        label: "R$",
        data: payablesData.map((item) => {
          return item.totalValue;
        }),
      },
    });
  };

  const fetchDashboard = async () => {
    const dashboardData = await dashboardService.get();
    setDashboard(dashboardData);
  };

  const fetchReceivables = async () => {
    const receivablesData = await receivablesService.getlastMonths();

    setReceivables({
      labels: receivablesData.map((item) => {
        return getMes(item._id.month);
      }),
      datasets: {
        label: "R$",
        data: receivablesData.map((item) => {
          return item.totalValue;
        }),
      },
    });
  };

  const fetchLastPurchases = async () => {
    const lastPurchasesData = await dashboardService.getPurchasesLastMonths();

    setLastPurchases({
      labels: lastPurchasesData.map((item) => {
        return getMes(item.month);
      }),
      datasets: {
        label: "Quantidade",
        data: lastPurchasesData.map((item) => {
          return item.count;
        }),
      },
    });
  };

  const fetchPermissions = async () => {
    const permissionsData = await permissionsService.listMy();
    setPermissions(permissionsData);
  };

  const hasDashboardReportsPermission = () => {
    const permissionsFiltered = permissions.filter(
      (permission) => permission.area === AREAS.DASHBOARD_REPORTS
    );

    if (permissionsFiltered.length === 0) {
      return false;
    }

    return permissionsFiltered.shift().visualiza === "1";
  };

  useEffect(() => {
    fetchCommodities();
    fetchWeather();
    fetchPayables();
    fetchReceivables();
    fetchDashboard();
    fetchLastPurchases();
    fetchPermissions();
  }, []);

  return (
    <DashboardLayout>
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <Carousel indicators={false} sx={{ minHeight: "148px" }}>
                {weathers.map((weather) => (
                  <ComplexStatisticsCard
                    color={weather.is_day ? "warning" : "dark"}
                    icon={weather.is_day ? "wb_sunny" : "nightlight"}
                    title={"Tempo em " + weather.city}
                    count={weather.temperature + " °C"}
                    percentage={{
                      color: "success",
                      amount: weather.windspeed + " km/h",
                      label: "vento",
                    }}
                  />
                ))}
              </Carousel>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Aguardando Fechamento"
                count={dashboard.pedidosAguardandoFechamento || 0}
                percentage={{
                  color: "success",
                  label: "Pedidos de Venda Aguardando Fechamento",
                }}
                sx={{
                  cursor: "pointer",
                }}
                onClick={() =>
                  navigate("/pedidos-venda", {
                    state: { pedidosAguardandoFechamento: true },
                  })
                }
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Aguardando Fechamento"
                count={dashboard.pedidosCompraAguardandoFechamento || 0}
                percentage={{
                  color: "success",
                  label: "Pedidos de Compra Aguardando Fechamento",
                }}
                sx={{
                  cursor: "pointer",
                }}
                onClick={() =>
                  navigate("/pedidos-compra", {
                    state: { pedidosCompraAguardandoFechamento: true },
                  })
                }
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person"
                title="Contas a Receber"
                count={dashboard.contasReceberVencendoHoje || 0}
                percentage={{
                  color: "success",
                  label: "Contas a Receber Vencendo Hoje",
                }}
                sx={{
                  cursor: "pointer",
                }}
                onClick={() =>
                  navigate("/contas-receber", {
                    state: { contasReceberVencendoHoje: true },
                  })
                }
              />
            </MDBox>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            sx={{
              display: hasDashboardReportsPermission() ? "block" : "none",
            }}
          >
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="secondary"
                icon="money"
                title="Contas a Receber Vencidas"
                count={dashboard.contasReceberVencidasSempre || 0}
                percentage={{
                  color: "secondary",
                  label: dashboard.contasReceberVencidasSempreValor
                    ? `Valor em aberto: ${new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(dashboard.contasReceberVencidasSempreValor)}`
                    : "Valor em aberto: R$ 0,00",
                }}
                sx={{
                  cursor: "pointer",
                }}
                onClick={() =>
                  navigate("/contas-receber", {
                    state: { contasReceberVencidasSempre: true },
                  })
                }
              />
            </MDBox>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            sx={{
              display: hasDashboardReportsPermission() ? "block" : "none",
            }}
          >
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="money"
                title="Contas a Pagar Vencidas"
                count={dashboard.contasPagarVencidasSempre || 0}
                percentage={{
                  color: "primary",
                  label: dashboard.contasPagarVencidasSempreValor
                    ? `Valor em aberto: ${new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(dashboard.contasPagarVencidasSempreValor)}`
                    : "Valor em aberto: R$ 0,00",
                }}
                sx={{
                  cursor: "pointer",
                }}
                onClick={() =>
                  navigate("/contas-pagar", {
                    state: { contasPagarVencidasSempre: true },
                  })
                }
              />
            </MDBox>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            sx={{
              display: hasDashboardReportsPermission() ? "block" : "none",
            }}
          >
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="warning"
                icon="shop"
                title="Sem Conta a Receber"
                count={dashboard.pedidosVendaSemContasReceber || 0}
                percentage={{
                  color: "warning",
                  label: "Pedidos de Venda Sem Conta a Receber",
                }}
                sx={{
                  cursor: "pointer",
                }}
                onClick={() =>
                  navigate("/pedidos-venda", {
                    state: { pedidosVendaSemContasReceber: true },
                  })
                }
              />
            </MDBox>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            sx={{
              display: hasDashboardReportsPermission() ? "block" : "none",
            }}
          >
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="list"
                title="Sem Conta a Pagar"
                count={dashboard.pedidosCompraSemContasPagar || 0}
                percentage={{
                  color: "success",
                  label: "Pedidos de Compra Sem Conta a Pagar",
                }}
                sx={{
                  cursor: "pointer",
                }}
                onClick={() =>
                  navigate("/pedidos-compra", {
                    state: { pedidosCompraSemContasPagar: true },
                  })
                }
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox
          mt={4.5}
          sx={{
            display: hasDashboardReportsPermission() ? "block" : "none",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="secondary"
                  title="Pedidos"
                  description={
                    <>
                      últimos <strong>12</strong> meses
                    </>
                  }
                  date="atualizado 1 min atrás"
                  chart={lastPurchases}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="Contas a Pagar"
                  description={
                    <>
                      últimos <strong>12</strong> meses
                    </>
                  }
                  date="atualizado 1 min atrás"
                  chart={payables}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="Contas a Receber"
                  description={
                    <>
                      últimos <strong>12</strong> meses
                    </>
                  }
                  date="atualizado 1 min atrás"
                  chart={receivables}
                />
              </MDBox>
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={3}>
                <Commodities commodities={commodities} />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
