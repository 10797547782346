import { Icon, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import Service from "services/suppliers";
import { findTipoPessoa } from "utils/tipoPessoa";

export default function data(
  handleEdit,
  handleDelete,
  handleToggleStatus,
  handleDetail,
  fromParent = false,
  selectParent = null,
  setShowAddFornecedor = null,
  setLoading = () => {}
) {
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [sortStr, setSortStr] = useState(null);
  const [filterStr, setFilterStr] = useState(null);

  const fetchData = async () => {
    setLoading(true);

    const { data, limit, total } = await Service.paginate(
      page,
      perPage,
      sortStr,
      filterStr
    );

    setPerPage(limit);
    setTotalRows(total);

    setRows(
      data.map((row) =>
        Object({
          id: row._id,
          action: row,
          tipoPessoa: findTipoPessoa(row.tipoPessoa),
          cpfCnpj: row.cpfCnpj,
          uf: row.uf,
          nomeFantasia: row.nomeFantasia,
          razaoSocial: row.razaoSocial,
          cidade: row.cidade,
        })
      ).filter((row) => 
          fromParent ? row.action.ativo : true
      )
    );

    setLoading(false);
  };

  const action = (props) => {
    const row = props?.row?.action;

    return (
      <MDBox display="flex" gap={1}>
        {fromParent && (
          <MDButton
            variant="gradient"
            color="primary"
            fullWidth
            type="submit"
            onClick={() => {
              selectParent(row);
              setShowAddFornecedor(false);
            }}
            iconOnly
          >
            <Icon fontSize="small">check</Icon>
          </MDButton>
        )}
        <Tooltip title="Editar">
          <MDButton
            variant="gradient"
            color="info"
            fullWidth
            type="submit"
            onClick={() => handleEdit(row._id)}
            iconOnly
          >
            <Icon fontSize="small">edit</Icon>
          </MDButton>
        </Tooltip>

        <Tooltip title="Detalhes">
          <MDButton
            variant="gradient"
            color="success"
            fullWidth
            type="submit"
            onClick={() => handleDetail(row._id)}
            iconOnly
          >
            <Icon fontSize="small">visibility</Icon>
          </MDButton>
        </Tooltip>

        <Tooltip title="Remover">
          <MDButton
            variant="gradient"
            color="error"
            fullWidth
            type="submit"
            onClick={() => handleDelete(row)}
            iconOnly
          >
            <Icon fontSize="small">delete_forever</Icon>
          </MDButton>
        </Tooltip>

        {!fromParent && (
        <Tooltip title={ row?.ativo? "Inativar" : "Ativar"}>
          <MDButton
            variant="gradient"
            color={ row?.ativo? "info" : "error"}
            fullWidth
            type="submit"
            onClick={() => handleToggleStatus(row)}
            iconOnly
          >
            {
              row?.ativo ? 
            <Icon fontSize="small">toggle_on</Icon>
            :
            <Icon fontSize="small">toggle_off</Icon>
            }
          </MDButton>
        </Tooltip>
        )}
      </MDBox>
    );
  };

  const updatePagination = ({ page: newPage, pageSize: newPerPage }) => {
    setPage(newPage);
    setPerPage(newPerPage);
  };

  const sortBy = (sortArr) => {
    if (sortArr.length === 0) {
      setSortStr(null);
      return;
    }

    const [filter] = sortArr;

    setSortStr(
      `&sortBy=${filter.field}&sortOrder=${filter.sort === "asc" ? 1 : "-1"}`
    );
  };

  const filterBy = ({ items }) => {
    if (items.length === 0) {
      setFilterStr(null);
      return;
    }

    const [filter] = items;

    if (filter.operator === "contains" && filter.value === undefined) {
      return;
    }

    setFilterStr(
      `&filterField=${filter.field}&filterOperator=${filter.operator}&filterValue=${filter.value}`
    );
  };

  useEffect(() => {
    fetchData();
  }, [page, perPage, sortStr, filterStr]);

  return {
    columns: [
      { headerName: "Nome", field: "razaoSocial", flex: 0.16 },
      { headerName: "CPF/CNPJ", field: "cpfCnpj", flex: 0.16 },
      { headerName: "Tipo de Pessoa", field: "tipoPessoa", flex: 0.16 },
      { headerName: "Cidade", field: "cidade", flex: 0.16 },
      { headerName: "UF", field: "uf", flex: 0.16 },
      { headerName: "Ações", field: "action", flex: 0.16, renderCell: action },
    ],

    rows,
    fetchData,
    updatePagination,
    sortBy,
    filterBy,
    pageObject: {
      totalRows,
      perPage,
      page,
    },
  };
}
