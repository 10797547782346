import HttpService from "./http.service";

class PurchasesWarehouseService {
  list = async (sortStr = null, filterStr = null) => {
    const purchasesWarehouseEndpoint = `/purchases-warehouse?limit=-1${sortStr ? sortStr : ""}${filterStr ? filterStr : ""}`;
    return await HttpService.get(purchasesWarehouseEndpoint);
  };

  paginate = async (page = 0, limit = 25, sortStr = null, filterStr = null) => {
    const purchasesWarehouseEndpoint = `/purchases-warehouse?page=${page}&limit=${limit}${sortStr ? sortStr : ""}${filterStr ? filterStr : ""}`;
    return await HttpService.get(purchasesWarehouseEndpoint);
  };

  get = async (id) => {
    const purchasesWarehouseEndpoint = "/purchases-warehouse";
    return await HttpService.get(`${purchasesWarehouseEndpoint}/${id}`);
  };

  getPdf = async (id) => {
    const purchasesWarehouseEndpoint = "/purchases-warehouse";
    return await HttpService.getFile(`${purchasesWarehouseEndpoint}/${id}/pdf`);
  };

  productReport = async (data) => {
    const dataWithoutUndefined = Object.keys(data).reduce((object, key) => {
      if (data[key] !== undefined && data[key] !== "" && data[key] !== null) {
        object[key] = data[key];
      }
      return object;
    }, {});
    const query = new URLSearchParams(dataWithoutUndefined).toString();
    const purchasesWarehouseEndpoint = `/purchases-warehouse/product-report?${query}`;
    return await HttpService.get(purchasesWarehouseEndpoint, data);
  };

  productReportByMonth = async (data) => {
    const dataWithoutUndefined = Object.keys(data).reduce((object, key) => {
      if (data[key] !== undefined && data[key] !== "" && data[key] !== null) {
        object[key] = data[key];
      }
      return object;
    }, {});
    const query = new URLSearchParams(dataWithoutUndefined).toString();
    const purchasesWarehouseEndpoint = `/purchases-warehouse/product-report-by-month?${query}`;
    return await HttpService.get(purchasesWarehouseEndpoint, data);
  };

  productPriceReportByMonthHandler = async (data) => {
    const dataWithoutUndefined = Object.keys(data).reduce((object, key) => {
      if (data[key] !== undefined && data[key] !== "" && data[key] !== null) {
        object[key] = data[key];
      }
      return object;
    }, {});
    const query = new URLSearchParams(dataWithoutUndefined).toString();
    const purchasesWarehouseEndpoint = `/purchases-warehouse/product-price-report-by-month?${query}`;
    return await HttpService.get(purchasesWarehouseEndpoint, data);
  };

  filter = async (data) => {
    const dataWithoutUndefined = Object.keys(data).reduce((object, key) => {
      if (data[key] !== undefined && data[key] !== "" && data[key] !== null) {
        object[key] = data[key];
      }
      return object;
    }, {});
    const query = new URLSearchParams(dataWithoutUndefined).toString();
    const purchasesWarehouseEndpoint = `/purchases-warehouse/filter?${query}`;
    return await HttpService.get(purchasesWarehouseEndpoint, data);
  };

  create = async (data) => {
    const purchasesWarehouseEndpoint = "/purchases-warehouse";
    return await HttpService.post(purchasesWarehouseEndpoint, data);
  };

  update = async (id, data) => {
    const purchasesWarehouseEndpoint = "/purchases-warehouse";
    return await HttpService.put(`${purchasesWarehouseEndpoint}/${id}`, data);
  };

  delete = async (id) => {
    const purchasesWarehouseEndpoint = "/purchases-warehouse";
    return await HttpService.delete(`${purchasesWarehouseEndpoint}/${id}`);
  };
}

export default new PurchasesWarehouseService();
