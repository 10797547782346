import * as yup from "yup";

export const companies = {
  PRIMEMATRIZ: "prime-matriz",
  PRIMESP: "prime-sp",
  PRIMEPLUS: "prime-plus",
};


export const tpagEntries = {
  "01": "01 - Dinheiro",
  "02": "02 - Cheque",
  "03": "03 - Cartão de Crédito",
  "04": "04 - Cartão de Débito",
  "05": "05 - Crédito Loja",
  10: "10 - Vale Alimentação",
  11: "11 - Vale Refeição",
  12: "12 - Vale Presente",
  13: "13 - Vale Combustível",
  14: "14 - Duplicata Mercantil",
  15: "15 - Boleto Bancário",
  16: "16 - Depósito Bancário",
  17: "17 - Pagamento Instantâneo (PIX)",
  18: "18 - Transferência bancária, Carteira Digital",
  19: "19 - Programa de fidelidade, Cashback, Crédito Virtual",
  90: "90 - Sem pagamento",
  99: "99 - Outros",
};

export const findCompany = (company) => {
  switch (company) {
    case companies.PRIMEMATRIZ:
      return "Prime Matriz";
    case companies.PRIMESP:
      return "Prime SP";
    case companies.PRIMEPLUS:
      return "Prime Plus";
    default:
      return "";
  }
};

export const STATUS_PEDIDO = {
  AGUARDANDO: "A",
  PARC_ENTREGUE: "P",
  REALIZAR_AFERICAO: "R",
  ENTREGUE: "E",
  CANCELADO: "C",
  DEVOLVIDO: "D",
};

export const findStatusPedido = (status) => {
  switch (status) {
    case STATUS_PEDIDO.AGUARDANDO:
      return "Aguardando";
    case STATUS_PEDIDO.PARC_ENTREGUE:
      return "Parcialmente entregue";
    case STATUS_PEDIDO.REALIZAR_AFERICAO:
      return "Realizar aferição de compra";
    case STATUS_PEDIDO.ENTREGUE:
      return "Entregue";
    case STATUS_PEDIDO.CANCELADO:
      return "Cancelado";
    case STATUS_PEDIDO.DEVOLVIDO:
      return "Devolvido";
    default:
      return "";
  }
};

export const TIPO_FRETE = {
  FOB: "F",
  CIF: "C",
  TERCEIRO: "T",
  OUTRO: "O",
};

export const findTipoFrete = (tipoFrete) => {
  switch (tipoFrete) {
    case TIPO_FRETE.FOB:
      return "FOB";
    case TIPO_FRETE.CIF:
      return "CIF";
    case TIPO_FRETE.TERCEIRO:
      return "Terceiro";
    case TIPO_FRETE.OUTRO:
      return "Outro";
    default:
      return "";
  }
};

export const yupSchema = yup.object().shape({
  data: yup.date().required("Esse campo é obrigatório"),
  status: yup
    .string()
    .oneOf(Object.values(STATUS_PEDIDO))
    .required("Esse campo é obrigatório"),
  valorTotal: yup.string().required("Esse campo é obrigatório"),
  customer: yup.string().required("Esse campo é obrigatório"),
  shippingCompany: yup.string().required("Esse campo é obrigatório"),
  pesoBruto: yup.string().required("Esse campo é obrigatório"),
  tara: yup.string().required("Esse campo é obrigatório"),
  pesoLiquido: yup.string().required("Esse campo é obrigatório"),
  qtdFrutas: yup.string().required("Esse campo é obrigatório"),
  media: yup.string().required("Esse campo é obrigatório"),
  precoTonelada: yup.string().required("Esse campo é obrigatório"),
  dataDescarga: yup.date().required("Esse campo é obrigatório"),
  valorFrete: yup.string().required("Esse campo é obrigatório"),
  valorIcms: yup.string().required("Esse campo é obrigatório"),
  carregamento: yup.string().required("Esse campo é obrigatório"),
  totalPagarFrete: yup.string().required("Esse campo é obrigatório"),
  tipoFrete: yup
    .string()
    .oneOf(Object.values(TIPO_FRETE))
    .required("Esse campo é obrigatório"),
});

export const initialValues = {
  data: "",
  status: "",
  valorTotal: "",
  customer: "",
  user: "",
  shippingCompany: "",
  agent: null,
  pesoBruto: "",
  tara: "",
  pesoLiquido: "",
  qtdFrutas: "",
  media: "",
  precoTonelada: "",
  dataDescarga: "",
  valorFrete: "",
  valorIcms: "",
  carregamento: "",
  adiantamentoFrete: "",
  totalPagarFrete: "",
  motorista: "",
  tipoFrete: "",
  caminhao: "",
  telefone: "",
  totalFinal: "",
  produtos: [],
  contas: [],
  observacoes: "",
  numPedido: "",
  letterPedido: "",
  productBatch: null,
  valorDescarga: "",
};
