import * as yup from "yup";

export const BILLING_STATUS = {
  ABERTO: "A",
  ATRASADO: "T",
  PAGO: "P",
  CANCELADO: "C",
  PENDENTES: "D",
};

export const findBillingStatus = (status) => {
  switch (status) {
    case BILLING_STATUS.ABERTO:
      return "Aberto";
    case BILLING_STATUS.ATRASADO:
      return "Atrasado";
    case BILLING_STATUS.PAGO:
      return "Pago";
    case BILLING_STATUS.CANCELADO:
      return "Cancelado";
    case BILLING_STATUS.PENDENTES:
      return "Pendente";
    default:
      return "";
  }
};

export const PRAZO_PAGAMENTO = {
  A_VISTA: "A_VISTA",
  A_PRAZO: "A_PRAZO",
  SETE: "SETE",
  DEZ: "DEZ",
  QUINZE: "QUINZE",
  VINTE_UM: "VINTE_UM",
  TRINTA: "TRINTA",
  TRINTA_CINCO: "TRINTA_CINCO",
  QUARENTA: "QUARENTA",
  SESSENTA: "SESSENTA",
  NOVENTA: "NOVENTA",
  CENTO_VINTE: "CENTO_VINTE",
  TRINTA_QUARENTA_CINCO: "TRINTA_QUARENTA_CINCO",
  TRINTA_SESSENTA: "TRINTA_SESSENTA",
  TRINTA_QUARENTA_CINCO_SESSENTA: "TRINTA_QUARENTA_CINCO_SESSENTA",
  TRINTA_SESSENTA_NOVENTA: "TRINTA_SESSENTA_NOVENTA",
};

export const PARCELAS_PRAZO_PAGAMENTO = {
  TRINTA_QUARENTA_CINCO: 2,
  TRINTA_SESSENTA: 2,
  TRINTA_QUARENTA_CINCO_SESSENTA: 3,
  TRINTA_SESSENTA_NOVENTA: 3,
};

export const DIAS_PRAZO_PAGAMENTO = {
  TRINTA_QUARENTA_CINCO: [30, 45],
  TRINTA_SESSENTA: [30, 60],
  TRINTA_QUARENTA_CINCO_SESSENTA: [30, 45, 60],
  TRINTA_SESSENTA_NOVENTA: [30, 60, 90],
};

export const findPrazoPagamento = (prazo) => {
  const prazos = {
    A_VISTA: "À VISTA",
    A_PRAZO: "À PRAZO",
    SETE: "7",
    DEZ: "10",
    QUINZE: "15",
    VINTE_UM: "21",
    TRINTA: "30",
    TRINTA_CINCO: "35",
    QUARENTA: "40",
    SESSENTA: "60",
    NOVENTA: "90",
    CENTO_VINTE: "120",
    TRINTA_QUARENTA_CINCO: "30/45",
    TRINTA_SESSENTA: "30/60",
    TRINTA_QUARENTA_CINCO_SESSENTA: "30/45/60",
    TRINTA_SESSENTA_NOVENTA: "30/60/90",
  };

  return prazos[prazo];
};

export const yupSchema = yup.object().shape({
  descricao: yup.string().required("Esse campo é obrigatório"),
  supplier: yup.string().required("Esse campo é obrigatório"),
  paymentMethod: yup.string().required("Esse campo é obrigatório"),
  dataPedido: yup.date().nullable(),
  dataVencimento: yup.date().nullable(),
  valor: yup.string().required("Esse campo é obrigatório"),
  status: yup.string().required("Esse campo é obrigatório"),
  prazoPagamento: yup.string().required("Esse campo é obrigatório"),
  accountGroup: yup.string().required("Esse campo é obrigatório"),
  accountSubGroup: yup.string().required("Esse campo é obrigatório"),
});

export const initialValues = {
  descricao: "",
  supplier: "",
  paymentMethod: "",
  dataPedido: null,
  dataVencimento: null,
  valor: 0,
  status: "",
  account: "",
  observacoes: "",
  prazoPagamento: "",
  accountGroup: null,
  accountSubGroup: null,
};
