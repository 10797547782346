import { Box, Card, Grid, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";

// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

// forms
import MDButton from "components/MDButton";

//@mui/styles
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#81BF52",
    },
    "& .MuiTab-root": {
      color: "#425d73 !important",
    },
    "& .MuiTab-root.Mui-selected": {
      backgroundColor: "#81bf52ab",
      color: "#FFFFFF !important",
    },
  },
});

export default function DetalhesRecebimento({ setShowAddState, parentFormik, produto, received }) {
  const [loading, setLoading] = useState(false);

  // tabs
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm ? setTabsOrientation("vertical") : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  useEffect(() => {
    const closeOnEscapePressed = (e) => {
      if (e.key === "Escape") {
        setShowAddState(false);
      }
    };
    window.addEventListener("keydown", closeOnEscapePressed);
    return () => window.removeEventListener("keydown", closeOnEscapePressed);
  }, []);

  return (
    <>
      <MDBox
        pt={"30px"}
        pb={"5%"}
        sx={{
          position: "fixed",
          zIndex: 99999,
          width: "100vw",
          minHeight: "100vh",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.1)",
          backdropFilter: "blur(5px)",
          top: 0,
          right: 0,
          overflow: "auto",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Grid container spacing={6}>
          <Grid item xs={6} sm={6} md={6} mx={"auto"}>
            <Card>
              <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" sx={{ display: "flex", justifyContent: "space-between" }}>
                <MDTypography variant="h6" color="white" mt={1}>
                  {loading ? "Carregando..." : "Detalhes do Recebimento"}
                </MDTypography>

                <MDButton
                  variant="contained"
                  type="button"
                  onClick={() => {
                    setShowAddState(false);
                  }}
                >
                  Voltar
                </MDButton>
              </MDBox>

              <MDBox pt={3} mx={2} py={3} px={2}>
                <Box
                  sx={{
                    mb: 2,
                    // display: loading ? "none" : "",
                  }}
                >
                  <Grid
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gap: "1rem",
                    }}
                  >
                    <TextField
                      id="numPedido"
                      name="numPedido"
                      label="Número do Pedido"
                      value={received.numPedido}
                      disabled
                      sx={{
                        width: "100%",
                      }}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      id="codigoInterno"
                      name="codigoInterno"
                      label="Código Interno do Produto"
                      value={received.codigoInterno}
                      disabled
                      sx={{
                        width: "100%",
                      }}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      id="produto"
                      name="produto"
                      label="Descrição do Produto"
                      value={produto.produto}
                      disabled
                      sx={{
                        width: "100%",
                      }}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      id="unidadeMedida"
                      name="unidadeMedida"
                      label="Unidade de Medida"
                      value={produto.unidadeMedida}
                      disabled
                      sx={{
                        width: "100%",
                      }}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      id="localDeRecebimento"
                      name="localDeRecebimento"
                      label="Local de Recebimento"
                      value={received.localDeRecebimento.descricao}
                      disabled
                      sx={{
                        width: "100%",
                      }}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      id="dataRecebimento"
                      name="dataRecebimento"
                      label="Data de Recebimento"
                      type="date"
                      value={received.dataRecebimento?.split("T")[0]}
                      disabled
                      sx={{
                        width: "100%",
                      }}
                      inputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      id="lote"
                      name="lote"
                      label="Lote"
                      value={received.lote}
                      disabled
                      sx={{
                        width: "100%",
                      }}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      id="dataFabricacao"
                      name="dataFabricacao"
                      label="Data de Fabricação"
                      type="date"
                      value={received.dataFabricacao?.split("T")[0]}
                      disabled
                      sx={{
                        width: "100%",
                      }}
                      inputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      id="qtd"
                      name="qtd"
                      label="Quantidade Solicitada"
                      value={received.qtd}
                      disabled
                      sx={{
                        width: "100%",
                      }}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      id="dataVencimento"
                      name="dataVencimento"
                      label="Data de Vencimento"
                      type="date"
                      value={received.dataVencimento?.split("T")[0]}
                      disabled
                      sx={{
                        width: "100%",
                      }}
                      inputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      id="valorTotalSolicitado"
                      name="valorTotalSolicitado"
                      label="Valor Total Solicitado"
                      value={received.valorTotalSolicitado?.toLocaleString("pt-BR", { style: "currency", currency: parentFormik.values.currency })}
                      disabled
                      sx={{
                        width: "100%",
                      }}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      id="qtdRecebida"
                      name="qtdRecebida"
                      label="Quantidade Recebida"
                      value={received.qtdRecebida}
                      disabled
                      sx={{
                        width: "100%",
                      }}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      id="valorRecebido"
                      name="valorRecebido"
                      label="Valor Recebido"
                      value={received.valorRecebido?.toLocaleString("pt-BR", { style: "currency", currency: parentFormik.values.currency })}
                      disabled
                      sx={{
                        width: "100%",
                      }}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </Box>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
