import { Box, Button, Grid, Card, TextField } from "@mui/material";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Service from "services/accounts";
import { yupSchema, initialValues } from "./yupSchema";
import AccountSubgroupService from "services/sub-group-accounts";
import AccountGroupsService from "services/account-groups";

export default function EditarConta({
  setShowEditState,
  currentIdAction,
  fetchData,
}) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [alertKey, setAlertKey] = useState(0);
  const [dataList, setDataList] = useState({
    accountSubgroup: [],
    accountGroups: [],
    accountSubgroupFirst: [],
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yupSchema,
    onSubmit: async (values) => {
      setLoading(true);

      try {
        await Service.update(currentIdAction, values);
        setSuccess(true);
      } catch (error) {
        console.log(error);
        setSuccess(false);
      }
      setAlertKey(alertKey + 1);
      setLoading(false);
    },
  });

  useEffect(() => {
    const fetchList = async () => {
      setLoading(true);
      const fields = await Service.get(currentIdAction);

      const { data: accountSubgroup } = await AccountSubgroupService.list();
      const { data: accountGroups } = await AccountGroupsService.list();
      const accountSubgroupFirst = accountSubgroup;

      setDataList({
        accountSubgroup,
        accountGroups,
        accountSubgroupFirst,
      });

      for (var key in formik.values) {
        formik.setFieldValue(key, fields[key]);

        if (key === "accountGroup" || key === "accountSubGroup") {
          if (fields[key] !== undefined && fields[key] !== null) {
            formik.setFieldValue(key, fields[key]._id);
          }
        }
      }

      setLoading(false);
    };

    fetchList();
  }, [currentIdAction]);

  useEffect(() => {
    const { accountGroup } = formik.values;

    if (accountGroup !== "") {
      const accountSubgroup = dataList.accountSubgroupFirst.filter(
        (item) => item.accountGroup._id === accountGroup
      );

      setDataList((prevState) => ({
        ...prevState,
        accountSubgroup,
      }));
    }
  }, [formik.values.accountGroup]);

  useEffect(() => {
    const closeOnEscapePressed = (e) => {
      if (e.key === "Escape") {
        fetchData();
        setSuccess(null);
        setShowEditState(false);
      }
    };
    window.addEventListener("keydown", closeOnEscapePressed);
    return () => window.removeEventListener("keydown", closeOnEscapePressed);
  }, []);

  return (
    <>
      <MDBox
        pt={"30px"}
        sx={{
          position: "absolute",
          zIndex: 9999,
          width: "100vw",
          minHeight: "100vh",
          top: 0,
          right: 0,
        }}
      >
        <MDBox
          pt={"30px"}
          sx={{
            position: "fixed",
            zIndex: -1,
            width: "100vw",
            height: "110%",
            backgroundColor: "rgba(0,0,0,0.5)",
            backdropFilter: "blur(5px)",
            top: 0,
            right: 0,
          }}
        />
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={8} mx={"auto"}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  Editar Conta
                </MDTypography>
                <MDButton
                  variant="contained"
                  type="button"
                  onClick={() => {
                    fetchData();
                    setSuccess(null);
                    setShowEditState(false);
                  }}
                >
                  Voltar
                </MDButton>
              </MDBox>
              <MDBox pt={3} mx={2} py={3} px={2}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 2,
                    mb: 2,
                  }}
                  component="form"
                  onSubmit={formik.handleSubmit}
                >
                  <TextField
                    id="nome"
                    name="nome"
                    label="Nome"
                    value={formik.values.nome}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.nome && Boolean(formik.errors.nome)}
                    helperText={formik.touched.nome && formik.errors.nome}
                  />

                  <TextField
                    id="tipoConta"
                    name="tipoConta"
                    label="Tipo de Conta"
                    value={formik.values.tipoConta}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.tipoConta &&
                      Boolean(formik.errors.tipoConta)
                    }
                    helperText={
                      formik.touched.tipoConta && formik.errors.tipoConta
                    }
                    SelectProps={{
                      native: true,
                    }}
                    select
                  >
                    <option value=""></option>
                    <option value="E">Entrada</option>
                    <option value="S">Saída</option>
                  </TextField>

                  <TextField
                    id="accountGroup"
                    name="accountGroup"
                    label="Centro de Custo"
                    value={formik.values.accountGroup}
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      formik.handleBlur(e);
                    }}
                    error={
                      formik.touched.accountGroup?.descricao &&
                      Boolean(formik.errors.accountGroup)
                    }
                    helperText={
                      formik.touched.accountGroup?.descricao &&
                      formik.errors.accountGroup
                    }
                    select
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option value="" />
                    {dataList?.accountGroups?.map((option) => (
                      <option key={option._id} value={option._id}>
                        {option.descricao}
                      </option>
                    ))}
                  </TextField>

                  <TextField
                    id="accountSubGroup"
                    name="accountSubGroup"
                    label="Natureza de Lançamento"
                    value={formik.values.accountSubGroup}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.accountSubGroup?.descricao &&
                      Boolean(formik.errors.accountSubGroup)
                    }
                    helperText={
                      formik.touched.accountSubGroup?.descricao &&
                      formik.errors.accountSubGroup
                    }
                    select
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option value="" />
                    {dataList?.accountSubgroup?.map((option) => (
                      <option key={option._id} value={option._id}>
                        {option.nome}
                      </option>
                    ))}
                  </TextField>

                  <Button
                    type="submit"
                    variant="contained"
                    disabled={loading}
                    sx={{
                      gridColumn: "span 2",
                      justifySelf: "center",
                      width: "100%",
                      color: "white !important",
                    }}
                  >
                    {loading ? "Carregando..." : "Salvar"}
                  </Button>
                </Box>

                {success !== null && (
                  <Box>
                    <MDAlert
                      color={success ? "success" : "error"}
                      mt={4}
                      dismissible
                      key={alertKey}
                    >
                      <MDTypography variant="body2" color="white">
                        {success
                          ? "Conta atualizada com sucesso!"
                          : "Ocorreu um erro ao atualizar a conta."}
                      </MDTypography>
                    </MDAlert>
                  </Box>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
