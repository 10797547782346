// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { DataGrid } from "@mui/x-data-grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Data
import MDButton from "components/MDButton";
import { useRef, useState } from "react";

import MDDialog from "components/MDDialog";

import AdicionarDado from "./components/addData";
import EditarDado from "./components/editData";
import tableData from "./components/tableData";
import DetalharDado from "./components/detailData";

import Service from "services/customers";

import { ptBR_DataGrid } from "utils/datagrid";
import { Box, Input, Stack, TextField } from "@mui/material";
import Filter from "./filter";
import { Icon } from "@mui/material";
import { exportToExcel } from "react-json-to-excel";

export default function Clientes({
  fromParent = false,
  selectParent = null,
  setShowAddCliente = null,
}) {
  const [showAddState, setShowAddState] = useState(false);
  const [showEditState, setShowEditState] = useState(false);
  const [showFilterState, setShowFilterState] = useState(false);
  const [showDetailState, setShowDetailState] = useState(false);
  const [dialogRemove, setDialogRemove] = useState(false);
  const [dialogStatus, setDialogStatus] = useState(false);
  const [dialogText, setDialogText] = useState("");
  const [currentIdAction, setCurrentIdAction] = useState(null);
  const [loading, setLoading] = useState(false);
  const obsRef = useRef();

  const [staticPagination, setStaticPagination] = useState({
    page: 0,
    pageSize: 50,
  });

  const [isFiltered, setIsFiltered] = useState(false);
  const [currentFilter, setCurrentFilter] = useState({});

  const handleDetail = (id) => {
    setCurrentIdAction(id);
    setShowDetailState(true);
  };

  const handleEdit = (id) => {
    setCurrentIdAction(id);
    setShowEditState(true);
    window.scrollTo(0, 0);
  };

  const handleDelete = (obj) => {
    setDialogRemove(true);
    setDialogText(obj.nomeFantasia || obj.razaoSocial);
    setCurrentIdAction(obj._id);
  };

  const cancelDelete = () => {
    setDialogRemove(false);
    setDialogText("");
    setCurrentIdAction(null);
  };

  const confirmDelete = async () => {
    await Service.delete(currentIdAction);
    setDialogRemove(false);
    fetchData();
  };

  const handleToggleStatus = async (row) => {
    setCurrentIdAction(row);
    setDialogStatus(true);
    setDialogText(row.nomeFantasia || row.razaoSocial);
    fetchData();
  };

  const cancelToggleStatus = () => {
    setDialogStatus(false);
    setDialogText("");
    setCurrentIdAction(null);
  };

  const confirmToggleStatus = async () => {
    await Service.toggleStatus(currentIdAction._id, {
      ativo_obs: obsRef.current.value,
    });
    setDialogStatus(false);
    fetchData();
  };

  const paginateArray = (array, perPage, page) => {
    const start = page * perPage;
    const end = start + perPage;
    return array.slice(start, end);
  };

  const cleanRawRows = (lines) => {
    const linesToExport = lines.map((line) => ({
      "Nome Fantasia": line.nomeFantasia || "",
      "Razão Social": line.razaoSocial || "",
      "CPF/CNPJ": line.cpfCnpj,
      "Tipo Pessoa": line.tipoPessoa === "F" ? "Física" : "Jurídica",
      Cidade: line.cidade,
      UF: line.uf,
      Telefone: line.telefone,
      Celular: line.celular,
      Email: line.email,
    }));

    return linesToExport;
  };

  const doExportExcel = async () => {
    setLoading(true);

    if (isFiltered) {
      const data = await Service.filter({
        ...currentFilter,
      });

      exportToExcel(cleanRawRows(data), "clientes_" + new Date().getTime());
      setLoading(false);
    } else {
      const { data } = await Service.paginate(0, -1, sortStr, filterStr);
      exportToExcel(cleanRawRows(data), "clientes_" + new Date().getTime());
      setLoading(false);
    }
  };

  const doExportExcelPage = async () => {
    setLoading(true);

    if (isFiltered) {
      const oldData = await Service.filter({
        ...currentFilter,
      });

      const data = paginateArray(
        oldData,
        staticPagination.pageSize,
        staticPagination.page
      );

      exportToExcel(cleanRawRows(data), "clientes_" + new Date().getTime());
      setLoading(false);
    } else {
      const { data } = await Service.paginate(
        pageObject.page,
        pageObject.perPage,
        sortStr,
        filterStr
      );
      exportToExcel(cleanRawRows(data), "clientes_" + new Date().getTime());
      setLoading(false);
    }
  };

  const resetRows = () => {
    updateRows([]);
    setLoading(true);
    setIsFiltered(false);
    setCurrentFilter({});
    fetchData();
  };

  const doCurrentFilter = async () => {
    if (JSON.stringify(currentFilter) !== "{}") {
      setLoading(true);
      const data = await Service.filter(currentFilter);
      updateRows(data);
      setLoading(false);
    } else {
      resetRows();
    }
  };

  const {
    columns,
    rows,
    fetchData,
    updatePagination,
    updateRows,
    sortBy,
    filterBy,
    pageObject,
    sortStr,
    filterStr,
  } = tableData(
    handleEdit,
    handleDelete,
    handleToggleStatus,
    handleDetail,
    fromParent,
    selectParent,
    setShowAddCliente,
    setLoading
  );

  return (
    <DashboardLayout fromParent={fromParent}>
      <MDBox pt={6} pb={3}>
        {showEditState && (
          <EditarDado
            setShowEditState={setShowEditState}
            currentIdAction={currentIdAction}
            fetchData={isFiltered ? doCurrentFilter : fetchData}
            fromParent={fromParent}
          />
        )}
        {showAddState && (
          <AdicionarDado
            setShowAddState={setShowAddState}
            fetchData={isFiltered ? doCurrentFilter : fetchData}
            fromParent={fromParent}
            selectParent={selectParent}
            setShowAddCliente={setShowAddCliente}
          />
        )}
        {showFilterState && (
          <Filter
            Service={Service}
            resetRows={resetRows}
            updateRows={updateRows}
            setIsFiltered={setIsFiltered}
            setShowFilterState={setShowFilterState}
            setCurrentFilter={setCurrentFilter}
            currentFilter={currentFilter}
          />
        )}
        {showDetailState && (
          <DetalharDado
            setShowDetailState={setShowDetailState}
            currentIdAction={currentIdAction}
            fetchData={isFiltered ? doCurrentFilter : fetchData}
            fromParent={fromParent}
          />
        )}
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  Clientes
                </MDTypography>
                <div>
                  {fromParent && setShowAddCliente && (
                    <MDButton
                      variant="contained"
                      type="button"
                      onClick={() => setShowAddCliente(false)}
                      sx={{ mr: 2 }}
                    >
                      Voltar
                    </MDButton>
                  )}

                  {!fromParent && (
                    <MDButton
                      variant="contained"
                      type="button"
                      onClick={() => setShowFilterState(true)}
                      sx={{ mr: 1 }}
                    >
                      Filtrar
                      {isFiltered && (
                        <>
                          {" "}
                          <Icon fontSize="small">filter_alt</Icon>
                        </>
                      )}
                    </MDButton>
                  )}

                  <MDButton
                    variant="contained"
                    type="button"
                    onClick={() => setShowAddState(true)}
                  >
                    Adicionar
                  </MDButton>
                </div>
              </MDBox>
              <MDBox pt={3}>
                <Card sx={{ padding: 2 }}>
                  {isFiltered && "Filtrando Registros"}

                  {isFiltered ? (
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      pageSizeOptions={[50, 100]}
                      rowSelection={false}
                      loading={loading}
                      localeText={ptBR_DataGrid}
                      sx={{
                        ".MuiDataGrid-virtualScroller": {
                          minHeight: "100px",
                        },
                      }}
                      onPaginationModelChange={(model) =>
                        setStaticPagination(model)
                      }
                    />
                  ) : (
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      rowCount={pageObject.totalRows}
                      pageSizeOptions={[50, 100]}
                      paginationModel={{
                        pageSize: pageObject.perPage,
                        page: pageObject.page,
                      }}
                      paginationMode="server"
                      onPaginationModelChange={updatePagination}
                      sortingMode="server"
                      onSortModelChange={sortBy}
                      filterMode="server"
                      onFilterModelChange={filterBy}
                      rowSelection={false}
                      loading={loading}
                      localeText={ptBR_DataGrid}
                      sx={{
                        ".MuiDataGrid-virtualScroller": {
                          minHeight: "100px",
                        },
                      }}
                    />
                  )}
                </Card>
              </MDBox>
            </Card>

            <MDBox
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: 2,
              }}
            >
              <MDButton
                variant="gradient"
                color="success"
                onClick={doExportExcelPage}
                sx={{ mr: 1 }}
              >
                Exportar Página Para Excel{" "}
                <Icon fontSize="small">download</Icon>
              </MDButton>

              <MDButton
                variant="gradient"
                color="success"
                onClick={doExportExcel}
              >
                Exportar Tudo Para Excel <Icon fontSize="small">download</Icon>
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>

        <MDDialog
          open={dialogRemove}
          handleClose={() => setDialogRemove(false)}
          title={"Tem certeza que deseja remover?"}
          actions={
            <>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                type="submit"
                onClick={() => confirmDelete()}
              >
                Confirmar
              </MDButton>
              <MDButton
                variant="gradient"
                color="error"
                fullWidth
                type="submit"
                onClick={() => cancelDelete()}
              >
                Cancelar
              </MDButton>
            </>
          }
        >
          {dialogText}
        </MDDialog>
        <MDDialog
          open={dialogStatus}
          handleClose={() => setDialogStatus(false)}
          title={`Tem certeza que deseja ${
            currentIdAction?.ativo ? "inativar" : "ativar"
          }?`}
          actions={
            <Box width={"100%"}>
              <TextField
                id="ativo_obs"
                name="ativo_obs"
                label="Observação"
                multiline
                placeholder={currentIdAction?.ativo_obs}
                ref={obsRef}
                onChange={(e) => (obsRef.current.value = e.target.value)}
                rows={4}
                sx={{
                  gridColumn: "span 2",
                  justifySelf: "center",
                  width: "100%",
                }}
              />
              <Stack spacing={2} marginTop={2} direction="row">
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  type="submit"
                  onClick={() => confirmToggleStatus()}
                >
                  Confirmar
                </MDButton>
                <MDButton
                  variant="gradient"
                  color="error"
                  fullWidth
                  type="submit"
                  onClick={() => cancelToggleStatus()}
                >
                  Cancelar
                </MDButton>
              </Stack>
            </Box>
          }
        >
          {dialogText}
        </MDDialog>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
