import HttpService from "./http.service";

class LastNfeNumbersService {
  list = async (sortStr = null, filterStr = null) => {
    const lastnfenumberEndpoint = `/lastnfenumbers?limit=-1${
      sortStr ? sortStr : ""
    }${filterStr ? filterStr : ""}`;
    return await HttpService.get(lastnfenumberEndpoint);
  };

  get = async (id) => {
    const lastnfenumberEndpoint = "/lastnfenumbers";
    return await HttpService.get(`${lastnfenumberEndpoint}/${id}`);
  };

  update = async (id, data) => {
    const lastnfenumberEndpoint = "/lastnfenumbers";
    return await HttpService.put(`${lastnfenumberEndpoint}/${id}`, data);
  };

 
}

export default new LastNfeNumbersService();
