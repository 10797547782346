import { Grid, Icon, TextField } from "@mui/material";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import DataTable from "examples/Tables/DataTable";
import { v4 as uuidv4 } from "uuid";
import { useFormik } from "formik";
import * as yup from "yup";

export default function Parcelas({ isDetalhar, formik: parentFormik }) {
  const [rows, setRows] = useState([]);

  const formik = useFormik({
    validationSchema: yup.object().shape({
      parcela: yup.string().required("Campo obrigatório"),
    }),
    initialValues: {
      parcela: "",
    },
    onSubmit: (values) => {
      parentFormik.setFieldValue("parcelas", [
        ...parentFormik.values?.parcelas,
        {
          id: uuidv4(),
          parcela: values.parcela,
        },
      ]);
      formik.resetForm();
    },
  });

  const columns = [
    {
      Header: "Parcela",
      accessor: "parcela",
    },
    { Header: isDetalhar ? "" : "Ações", accessor: "action", align: "center" },
  ];

  useEffect(() => {
    parentFormik.values?.parcelas &&
      setRows(
        parentFormik.values?.parcelas?.map((parcela) => ({
          parcela: parcela?.parcela,
          action: (
            <div
              style={{
                justifyContent: "center",
                display: isDetalhar ? "none" : "flex",
              }}
            >
              <MDButton
                variant="gradient"
                color="error"
                fullWidth
                type="submit"
                onClick={() =>
                  parentFormik.setFieldValue("parcelas", [
                    ...parentFormik.values?.parcelas?.filter(
                      (item) => item !== formik.values?.parcela
                    ),
                  ])
                }
                iconOnly
              >
                <Icon fontSize="small">delete_forever</Icon>
              </MDButton>
            </div>
          ),
        }))
      );
  }, [parentFormik.values]);

  return (
    <>
      <Grid
        item
        spacing={6}
        sx={{
          marginTop: 2,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          display: isDetalhar ? "none" : "flex",
        }}
      >
        <TextField
          type="text"
          name="parcela"
          label="Nome Parcela"
          value={formik.values.parcela}
          onChange={formik.handleChange}
          error={formik.touched.parcela && Boolean(formik.errors.parcela)}
          helperText={formik.touched.parcela && formik.errors.parcela}
          sx={{ width: "50%" }}
        />
        <MDButton
          variant="contained"
          color="info"
          type="submit"
          onClick={formik.handleSubmit}
        >
          Adicionar
        </MDButton>
      </Grid>
      <Grid item>
        <DataTable
          table={{ columns, rows }}
          isSorted={false}
          disablePagination={true}
          noEndBorder
        />
      </Grid>
    </>
  );
}
