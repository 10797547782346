import * as yup from "yup";

export const yupSchema = yup.object().shape({
  codigo: yup.string().required("Esse campo é obrigatório"),
  descricao: yup.string().required("Esse campo é obrigatório"),
  parcelas: yup.array().of(
    yup.object().shape({
      parcela: yup.string(),
    })
  ),
});

export const initialValues = {
  codigo: "",
  descricao: "",
  parcelas: [],
};
