import HttpService from "./http.service";

class SupplierService {
  list = async (sortStr = null, filterStr = null) => {
    const suppliersEndpoint = `/suppliers?limit=-1${sortStr ? sortStr : ""}${
      filterStr ? filterStr : ""
    }`;
    return await HttpService.get(suppliersEndpoint);
  };

  paginate = async (page = 0, limit = 25, sortStr = null, filterStr = null) => {
    const suppliersEndpoint = `/suppliers?page=${page}&limit=${limit}${
      sortStr ? sortStr : ""
    }${filterStr ? filterStr : ""}`;
    return await HttpService.get(suppliersEndpoint);
  };

  get = async (id) => {
    const suppliersEndpoint = "/suppliers";
    return await HttpService.get(`${suppliersEndpoint}/${id}`);
  };

  create = async (data) => {
    const suppliersEndpoint = "/suppliers";
    return await HttpService.post(suppliersEndpoint, data);
  };

  toggleStatus = async (id, data) => {
    const suppliersEndpoint = "/suppliers/toggle-status";
    return await HttpService.put(`${suppliersEndpoint}/${id}`, data);
  };

  update = async (id, data) => {
    const suppliersEndpoint = "/suppliers";
    return await HttpService.put(`${suppliersEndpoint}/${id}`, data);
  };

  delete = async (id) => {
    const suppliersEndpoint = "/suppliers";
    return await HttpService.delete(`${suppliersEndpoint}/${id}`);
  };
}

export default new SupplierService();
