import HttpService from "./http.service";

class GruposProdutoService {
  list = async (sortStr = null, filterStr = null) => {
    const productGroupsEndpoint = `/product-groups?limit=-1${
      sortStr ? sortStr : ""
    }${filterStr ? filterStr : ""}`;
    return await HttpService.get(productGroupsEndpoint);
  };

  paginate = async (page = 0, limit = 25, sortStr = null, filterStr = null) => {
    const productGroupsEndpoint = `/product-groups?page=${page}&limit=${limit}${
      sortStr ? sortStr : ""
    }${filterStr ? filterStr : ""}`;
    return await HttpService.get(productGroupsEndpoint);
  };

  get = async (id) => {
    const productGroupsEndpoint = "/product-groups";
    return await HttpService.get(`${productGroupsEndpoint}/${id}`);
  };

  create = async (data) => {
    const productGroupsEndpoint = "/product-groups";
    return await HttpService.post(productGroupsEndpoint, data);
  };

  update = async (id, data) => {
    const productGroupsEndpoint = "/product-groups";
    return await HttpService.put(`${productGroupsEndpoint}/${id}`, data);
  };

  delete = async (id) => {
    const productGroupsEndpoint = "/product-groups";
    return await HttpService.delete(`${productGroupsEndpoint}/${id}`);
  };
}

export default new GruposProdutoService();
