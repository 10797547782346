import { Icon, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import Service from "services/payment-methods";
import { findTipoDesconto } from "utils/tipoDesconto";

export default function data(
  handleEdit,
  handleDelete,
  handleDetail,
  setLoading = () => {}
) {
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [sortStr, setSortStr] = useState(null);
  const [filterStr, setFilterStr] = useState(null);

  const fetchData = async () => {
    setLoading(true);

    const { data, limit, total } = await Service.paginate(
      page,
      perPage,
      sortStr,
      filterStr
    );

    setPerPage(limit);
    setTotalRows(total);

    setRows(
      data.map((row) =>
        Object({
          id: row._id,
          action: row,
          descricao: row.descricao,
          tipoDesconto: findTipoDesconto(row.tipoDesconto),
          valorDesconto: row.valorDesconto,
        })
      )
    );

    setLoading(false);
  };

  const action = (props) => {
    const row = props?.row?.action;

    return (
      <MDBox display="flex" gap={1}>
        <Tooltip title="Editar">
          <MDButton
            variant="gradient"
            color="info"
            fullWidth
            type="submit"
            onClick={() => handleEdit(row._id)}
            iconOnly
          >
            <Icon fontSize="small">edit</Icon>
          </MDButton>
        </Tooltip>

        <Tooltip title="Detalhes">
          <MDButton
            variant="gradient"
            color="success"
            fullWidth
            type="submit"
            onClick={() => handleDetail(row._id)}
            iconOnly
          >
            <Icon fontSize="small">visibility</Icon>
          </MDButton>
        </Tooltip>

        <Tooltip title="Remover">
          <MDButton
            variant="gradient"
            color="error"
            fullWidth
            type="submit"
            onClick={() => handleDelete(row)}
            iconOnly
          >
            <Icon fontSize="small">delete_forever</Icon>
          </MDButton>
        </Tooltip>
      </MDBox>
    );
  };

  const updatePagination = ({ page: newPage, pageSize: newPerPage }) => {
    setPage(newPage);
    setPerPage(newPerPage);
  };

  const sortBy = (sortArr) => {
    if (sortArr.length === 0) {
      setSortStr(null);
      return;
    }

    const [filter] = sortArr;

    setSortStr(
      `&sortBy=${filter.field}&sortOrder=${filter.sort === "asc" ? 1 : "-1"}`
    );
  };

  const filterBy = ({ items }) => {
    if (items.length === 0) {
      setFilterStr(null);
      return;
    }

    const [filter] = items;

    if (filter.operator === "contains" && filter.value === undefined) {
      return;
    }

    setFilterStr(
      `&filterField=${filter.field}&filterOperator=${filter.operator}&filterValue=${filter.value}`
    );
  };

  useEffect(() => {
    fetchData();
  }, [page, perPage, sortStr, filterStr]);

  return {
    columns: [
      { headerName: "Descrição", field: "descricao", flex: 0.25 },
      { headerName: "Tipo de Desconto", field: "tipoDesconto", flex: 0.25 },
      { headerName: "Valor do Desconto", field: "valorDesconto", flex: 0.25 },
      { headerName: "Ações", field: "action", flex: 0.25, renderCell: action },
    ],

    rows,
    fetchData,
    updatePagination,
    sortBy,
    filterBy,
    pageObject: {
      totalRows,
      perPage,
      page,
    },
  };
}
