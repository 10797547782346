import {
  Box,
  Grid,
  Card,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Service from "services/products";
import { yupSchema, initialValues } from "./yupSchema";

export default function DetalharProduto({
  setShowDetailState,
  currentIdAction,
  fetchData,
}) {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yupSchema,
  });

  useEffect(() => {
    const fetchList = async () => {
      setLoading(true);
      const fields = await Service.get(currentIdAction);

      for (var key in formik.values) {
        formik.setFieldValue(key, fields[key]);
      }

      setLoading(false);
    };

    fetchList();
  }, [currentIdAction]);

  useEffect(() => {
    const closeOnEscapePressed = (e) => {
      if (e.key === "Escape") {
        fetchData();
        setShowDetailState(false);
      }
    };
    window.addEventListener("keydown", closeOnEscapePressed);
    return () => window.removeEventListener("keydown", closeOnEscapePressed);
  }, []);

  return (
    <>
      <MDBox
        pt={"30px"}
        sx={{
          position: "absolute",
          zIndex: 9999,
          width: "100vw",
          minHeight: "100vh",
          top: 0,
          right: 0,
        }}
      >
        <MDBox
          pt={"30px"}
          sx={{
            position: "fixed",
            zIndex: -1,
            width: "100vw",
            height: "110%",
            backgroundColor: "rgba(0,0,0,0.5)",
            backdropFilter: "blur(5px)",
            top: 0,
            right: 0,
          }}
        />
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={8} mx={"auto"}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  Detalhar Produto
                </MDTypography>
                <MDButton
                  variant="contained"
                  type="button"
                  onClick={() => {
                    fetchData();
                    setShowDetailState(false);
                  }}
                >
                  Voltar
                </MDButton>
              </MDBox>
              <MDBox pt={3} mx={2} py={3} px={2}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 2,
                    mb: 2,
                  }}
                >
                  <TextField
                    id="productGroup"
                    name="productGroup"
                    label="Grupo de Produto"
                    value={formik?.values?.productGroup?.descricao}
                    onChange={formik.handleChange}
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  ></TextField>

                  <TextField
                    id="referencia"
                    name="referencia"
                    label="Referência"
                    value={formik.values.referencia}
                    InputProps={{
                      readOnly: true,
                    }}
                  />

                  <TextField
                    id="nomeEstrangeiro"
                    name="nomeEstrangeiro"
                    label="Nome Estrangeiro"
                    value={formik.values.nomeEstrangeiro}
                    InputProps={{
                      readOnly: true,
                    }}
                  />

                  <TextField
                    id="codigoInterno"
                    name="codigoInterno"
                    label="Código Interno"
                    value={formik.values.codigoInterno}
                    InputProps={{
                      readOnly: true,
                    }}
                  />

                  <TextField
                    id="codigoDeBarras"
                    name="codigoDeBarras"
                    label="Código de Barras"
                    value={formik.values.codigoDeBarras}
                    InputProps={{
                      readOnly: true,
                    }}
                  />

                  <TextField
                    id="ncm"
                    name="ncm"
                    label="NCM"
                    value={formik.values.ncm}
                    InputProps={{
                      readOnly: true,
                    }}
                  />

                  <TextField
                    id="descricao"
                    name="descricao"
                    label="Descrição"
                    value={formik.values.descricao}
                    InputProps={{
                      readOnly: true,
                    }}
                  />

                  <TextField
                    id="localizacao"
                    name="localizacao"
                    label="Localização"
                    value={formik.values.localizacao}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    id="precoCompra"
                    name="precoCompra"
                    label="Preço de Compra"
                    type="number"
                    value={formik.values.precoCompra}
                    InputProps={{
                      readOnly: true,
                    }}
                    style={{
                      display:
                        formik?.values?.productGroup?.descricao === "COMERCIO"
                          ? "flex"
                          : "none",
                    }}
                  />

                  <TextField
                    id="margemLucro"
                    name="margemLucro"
                    label="Margem de Lucro"
                    type="number"
                    value={formik.values.margemLucro}
                    InputProps={{
                      readOnly: true,
                    }}
                    style={{
                      display:
                        formik?.values?.productGroup?.descricao === "COMERCIO"
                          ? "flex"
                          : "none",
                    }}
                  />

                  <TextField
                    id="precoVenda"
                    name="precoVenda"
                    label="Preço de Venda"
                    type="number"
                    value={formik.values.precoVenda}
                    InputProps={{
                      readOnly: true,
                    }}
                    style={{
                      display:
                        formik?.values?.productGroup?.descricao === "COMERCIO"
                          ? "flex"
                          : "none",
                    }}
                  />

                  <TextField
                    id="itemGroup"
                    name="itemGroup"
                    label="Grupo de Itens"
                    value={formik.values.itemGroup}
                    InputProps={{
                      readOnly: true,
                    }}
                    style={{
                      display:
                        formik?.values?.productGroup?.descricao ===
                        "ALMOXARIFADO"
                          ? "flex"
                          : "none",
                    }}
                  ></TextField>
                  <TextField
                    id="umGroup"
                    name="umGroup"
                    label="Grupo de UM"
                    value={formik.values.umGroup}
                    InputProps={{
                      readOnly: true,
                    }}
                    style={{
                      display:
                        formik?.values?.productGroup?.descricao ===
                        "ALMOXARIFADO"
                          ? "flex"
                          : "none",
                    }}
                  ></TextField>
                  <TextField
                    id="itemAdministration"
                    name="itemAdministration"
                    label="Forma de administrar o item"
                    value={formik.values.itemAdministration}
                    InputProps={{
                      readOnly: true,
                    }}
                    style={{
                      display:
                        formik?.values?.productGroup?.descricao ===
                        "ALMOXARIFADO"
                          ? "flex"
                          : "none",
                    }}
                  ></TextField>
                  <TextField
                    id="administrationMethod"
                    name="administrationMethod"
                    label="Método de administração"
                    value={formik.values.administrationMethod}
                    InputProps={{
                      readOnly: true,
                    }}
                    style={{
                      display:
                        formik?.values?.productGroup?.descricao ===
                        "ALMOXARIFADO"
                          ? "flex"
                          : "none",
                    }}
                  ></TextField>
                  <TextField
                    id="manufacturer"
                    name="manufacturer"
                    label="Fabricante"
                    value={formik.values.manufacturer}
                    InputProps={{
                      readOnly: true,
                    }}
                    style={{
                      display:
                        formik?.values?.productGroup?.descricao ===
                        "ALMOXARIFADO"
                          ? "flex"
                          : "none",
                    }}
                  />

                  <TextField
                    id="unidade"
                    name="unidade"
                    label="Unidade de preço"
                    value={formik.values.unidade}
                    InputProps={{
                      readOnly: true,
                    }}
                    style={{
                      display:
                        formik?.values?.productGroup?.descricao ===
                        "ALMOXARIFADO"
                          ? "flex"
                          : "none",
                    }}
                  ></TextField>

                  <FormGroup
                    sx={{
                      display:
                        formik?.values?.productGroup?.descricao ===
                        "ALMOXARIFADO"
                          ? "flex"
                          : "none",
                      flexDirection: "row",
                    }}
                  >
                    <FormControlLabel
                      control={<Checkbox />}
                      name="itemDeEstoque"
                      id="itemDeEstoque"
                      label="Item de estoque"
                      value={formik.values.itemDeEstoque}
                      checked={formik.values.itemDeEstoque}
                      disabled
                    />

                    <FormControlLabel
                      control={<Checkbox />}
                      name="itemDeVenda"
                      id="itemDeVenda"
                      label="Item de venda"
                      value={formik.values.itemDeVenda}
                      checked={formik.values.itemDeVenda}
                      disabled
                    />

                    <FormControlLabel
                      control={<Checkbox />}
                      name="itemDeCompra"
                      id="itemDeCompra"
                      label="Item de compra"
                      value={formik.values.itemDeCompra}
                      checked={formik.values.itemDeCompra}
                      disabled
                    />
                  </FormGroup>

                  <TextField
                    id="observacao"
                    name="observacao"
                    label="Observação"
                    value={formik.values.observacao}
                    InputProps={{
                      readOnly: true,
                    }}
                    multiline
                    rows={4}
                    sx={{
                      gridColumn: "span 2",
                      justifySelf: "center",
                      width: "100%",
                    }}
                  />
                </Box>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
