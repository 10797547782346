import { EditRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import { memo } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useNavigate } from "react-router-dom";

const cores = {
  A: "linear-gradient(195deg, #FFD700, #FFA500)",
  P: "linear-gradient(195deg, #FF4500, #FF0000)",
  R: "linear-gradient(195deg, #FF69B4, #FF1493)",
  E: "linear-gradient(195deg, #81BF52, #2C441C)",
  C: "linear-gradient(195deg, #808080, #000000)",
  D: "linear-gradient(195deg, #2b22d0, #2b60d0)",
};

const fonte = {
  A: "#000",
  P: "#fff",
  R: "#fff",
  E: "#fff",
  C: "#fff",
  D: "#fff",
};

const Task = memo(({ task, index }) => {
  const navigate = useNavigate();

  return (
    <Draggable draggableId={task.id} index={index} isDragDisabled={true}>
      {(provided) => (
        <MDBox
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{
            padding: "8px",
            margin: "0 0 8px 0",
            borderRadius: "4px",
            boxShadow: "0 1px 2px rgba(0,0,0,0.2)",
            background: cores[task.status],
            color: fonte[task.status],
          }}
        >
          <IconButton
            color="default"
            onClick={() =>
              navigate("/pedidos-venda", { state: { id: task.id } })
            }
          >
            <EditRounded />
          </IconButton>
          {task.content} - {task.customer?.razaoSocial}
        </MDBox>
      )}
    </Draggable>
  );
});

export default Task;
