import HttpService from "./http.service";

class SalesService {
  list = async (sortStr = null, filterStr = null) => {
    const salesEndpoint = `/sales?limit=-1${sortStr ? sortStr : ""}${
      filterStr ? filterStr : ""
    }`;
    return await HttpService.get(salesEndpoint);
  };

  listSaleAgroupedByStatus = async () => {
    const salesEndpoint = "/sales/status";
    return await HttpService.get(salesEndpoint);
  };

  paginate = async (page = 0, limit = 25, sortStr = null, filterStr = null) => {
    const salesEndpoint = `/sales?page=${page}&limit=${limit}${
      sortStr ? sortStr : ""
    }${filterStr ? filterStr : ""}`;
    return await HttpService.get(salesEndpoint);
  };

  get = async (id) => {
    const salesEndpoint = "/sales";
    return await HttpService.get(`${salesEndpoint}/${id}`);
  };

  getPdf = async (id) => {
    const salesEndpoint = "/sales";
    return await HttpService.getFile(`${salesEndpoint}/${id}/pdf`);
  };

  gerarNfe = async (id, data) => {
    const salesEndpoint = "/sales";
    return await HttpService.post(`${salesEndpoint}/${id}/generate/nfe`, data);
  };

  removerNfe = async (id) => {
    const salesEndpoint = "/sales";
    return await HttpService.post(`${salesEndpoint}/${id}/remove/nfe`);
  };

  filter = async (data) => {
    const dataWithoutUndefined = Object.keys(data).reduce((object, key) => {
      if (data[key] !== undefined && data[key] !== "" && data[key] !== null) {
        object[key] = data[key];
      }
      return object;
    }, {});
    const query = new URLSearchParams(dataWithoutUndefined).toString();
    const salesEndpoint = `/sales/filter?${query}`;
    return await HttpService.get(salesEndpoint, data);
  };

  create = async (data) => {
    const salesEndpoint = "/sales";
    return await HttpService.post(salesEndpoint, data);
  };

  import = async (data) => {
    const salesEndpoint = "/sales/import";
    return await HttpService.post(salesEndpoint, data);
  };

  update = async (id, data) => {
    const salesEndpoint = "/sales";
    return await HttpService.put(`${salesEndpoint}/${id}`, data);
  };

  delete = async (id) => {
    const salesEndpoint = "/sales";
    return await HttpService.delete(`${salesEndpoint}/${id}`);
  };

  cancelNfe = async (id) => {
    const salesEndpoint = `/sales/cancel/nfe/${id}`;
    return await HttpService.post(salesEndpoint);
  };

  fixNfe = async (id, data) => {
    const salesEndpoint = `/sales/fix/nfe/${id}`;
    return await HttpService.post(salesEndpoint, data);
  };
}

export default new SalesService();
