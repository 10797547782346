import HttpService from "./http.service";

class ProductService {
  list = async (sortStr = null, filterStr = null) => {
    const productsEndpoint = `/products?limit=-1${sortStr ? sortStr : ""}${
      filterStr ? filterStr : ""
    }`;
    return await HttpService.get(productsEndpoint);
  };

  listBatches = async (sortStr = null, filterStr = null) => {
    const productsEndpoint = `/products/batch?limit=-1${
      sortStr ? sortStr : ""
    }${filterStr ? filterStr : ""}`;
    return await HttpService.get(productsEndpoint);
  };

  listBatchesPaginate = async (
    page = 0,
    limit = 25,
    sortStr = null,
    filterStr = null
  ) => {
    const productsEndpoint = `/products/batch-paginate?page=${page}&limit=${limit}${
      sortStr ? sortStr : ""
    }${filterStr ? filterStr : ""}`;
    return await HttpService.get(productsEndpoint);
  };

  getBatch = async (id) => {
    const productsEndpoint = "/products";
    return await HttpService.get(`${productsEndpoint}/batch/${id}`);
  };

  updateBatch = async (id, data) => {
    const productsEndpoint = "/products";
    return await HttpService.put(`${productsEndpoint}/batch/${id}`, data);
  };

  paginate = async (page = 0, limit = 25, sortStr = null, filterStr = null) => {
    const productsEndpoint = `/products?page=${page}&limit=${limit}${
      sortStr ? sortStr : ""
    }${filterStr ? filterStr : ""}`;
    return await HttpService.get(productsEndpoint);
  };

  get = async (id) => {
    const productsEndpoint = "/products";
    return await HttpService.get(`${productsEndpoint}/${id}`);
  };

  create = async (data) => {
    const productsEndpoint = "/products";
    return await HttpService.post(productsEndpoint, data);
  };

  update = async (id, data) => {
    const productsEndpoint = "/products";
    return await HttpService.put(`${productsEndpoint}/${id}`, data);
  };

  delete = async (id) => {
    const productsEndpoint = "/products";
    return await HttpService.delete(`${productsEndpoint}/${id}`);
  };
}

export default new ProductService();
