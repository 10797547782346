import HttpService from "./http.service";

class PaymentService {
  list = async (sortStr = null, filterStr = null) => {
    const paymentEndpoint = `/payment-methods?limit=-1${
      sortStr ? sortStr : ""
    }${filterStr ? filterStr : ""}`;
    return await HttpService.get(paymentEndpoint);
  };

  paginate = async (page = 0, limit = 25, sortStr = null, filterStr = null) => {
    const paymentEndpoint = `/payment-methods?page=${page}&limit=${limit}${
      sortStr ? sortStr : ""
    }${filterStr ? filterStr : ""}`;
    return await HttpService.get(paymentEndpoint);
  };

  get = async (id) => {
    const paymentEndpoint = "/payment-methods";
    return await HttpService.get(`${paymentEndpoint}/${id}`);
  };

  create = async (data) => {
    const paymentEndpoint = "/payment-methods";
    return await HttpService.post(paymentEndpoint, data);
  };

  update = async (id, data) => {
    const paymentEndpoint = "/payment-methods";
    return await HttpService.put(`${paymentEndpoint}/${id}`, data);
  };

  delete = async (id) => {
    const paymentEndpoint = "/payment-methods";
    return await HttpService.delete(`${paymentEndpoint}/${id}`);
  };
}

export default new PaymentService();
