import { Box, Card, CardActions, CardContent, CardHeader, Grid, Icon, Tab, Tabs, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import Service from "services/receiveds";

// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

// @mui icons
import InventoryIcon from "@mui/icons-material/Inventory";

// forms
import MDButton from "components/MDButton";
import { format } from "utils/formatDate";

//@mui/styles
import { makeStyles } from "@mui/styles";
import DataTable from "examples/Tables/DataTable";
import DetalhesRecebimento from "./detalhesRecebimento";
const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#81BF52",
    },
    "& .MuiTab-root": {
      color: "#425d73 !important",
    },
    "& .MuiTab-root.Mui-selected": {
      backgroundColor: "#81bf52ab",
      color: "#FFFFFF !important",
    },
  },
});

export default function VisualizarRecebimentos({ setShowAddState, parentFormik, produto, isDetalhar }) {
  const [loading, setLoading] = useState(false);
  const [receiveds, setReceiveds] = useState([]);
  const [rows, setRows] = useState([]);
  const [viewDetails, setViewDetails] = useState(null);
  const [deletingRow, setDeletingRow] = useState(null);

  // tabs
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  const classes = useStyles();

  const columns = [
    {
      Header: "QTD Recebida",
      accessor: "qtdRecebida",
      align: "left",
    },
    {
      Header: "Local de Recebimento",
      accessor: "localDeRecebimento",
      align: "left",
    },
    {
      Header: "Valor Recebido",
      accessor: "valorRecebido",
      align: "left",
    },
    {
      Header: "Data de Vencimento",
      accessor: "dataVencimento",
      align: "left",
    },
    {
      Header: "Ações",
      accessor: "actions",
      align: "center",
    },
  ];

  useEffect(() => {
    setRows(
      receiveds.map((row) =>
        Object({
          ...row,
          id: row._id,
          qtdRecebida: row.qtdRecebida,
          localDeRecebimento: row.localDeRecebimento.descricao || row.localDeRecebimento,
          valorRecebido: row.valorRecebido?.toLocaleString("pt-BR", { style: "currency", currency: parentFormik.values.currency }),
          dataVencimento: format(new Date(row.dataVencimento), "dd/MM/yyyy"),
          actions: (
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                gap: "5px",
              }}
            >
              <MDButton
                variant="gradient"
                color="error"
                fullWidth
                iconOnly
                onClick={() => {
                  setDeletingRow(row);
                }}
                style={{ display: isDetalhar ? "none" : "flex" }}
              >
                <Icon fontSize="small">delete_forever</Icon>
              </MDButton>
              <MDButton
                variant="gradient"
                color="success"
                fullWidth
                iconOnly
                onClick={() => {
                  setViewDetails(row);
                }}
              >
                <Icon fontSize="small">visibility</Icon>
              </MDButton>
            </div>
          ),
        })
      )
    );
  }, [receiveds]);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm ? setTabsOrientation("vertical") : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  useEffect(() => {
    const closeOnEscapePressed = (e) => {
      if (e.key === "Escape") {
        setShowAddState(false);
      }
    };
    window.addEventListener("keydown", closeOnEscapePressed);
    return () => window.removeEventListener("keydown", closeOnEscapePressed);
  }, []);

  useEffect(() => {
    async function fetchReceiveds() {
      setLoading(true);
      try {
        const formattedNumPedido = parentFormik.values.numPedido.replace(/\D/g, "");
        const response = await Service.listFromPayableProduct(0, 25, null, null, formattedNumPedido, produto.codigoInterno);
        setReceiveds(response);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    fetchReceiveds();
  }, [parentFormik.values.numPedido, produto.codigoInterno]);

  const calculateTotal = (receiveds) => {
    return receiveds.reduce((acc, curr) => acc + curr.valorRecebido, 0);
  };

  const handleDeleteReceived = async (id) => {
    try {
      await Service.delete(id);
      setReceiveds(receiveds.filter((received) => received._id !== id));
    } catch (error) {
      console.error(error);
    }
    setDeletingRow(null);
  };

  return (
    <>
      <MDBox
        pt={"30px"}
        pb={"5%"}
        sx={{
          position: "fixed",
          zIndex: 9999,
          width: "100vw",
          minHeight: "100vh",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.1)",
          backdropFilter: "blur(5px)",
          top: 0,
          right: 0,
          overflow: "auto",
          justifyContent: "center",
          display: "flex",
        }}
      >
        {viewDetails && <DetalhesRecebimento setShowAddState={setViewDetails} parentFormik={parentFormik} produto={produto} received={viewDetails} />}
        {deletingRow && (
          <MDBox
            pt={"30px"}
            pb={"5%"}
            sx={{
              position: "fixed",
              zIndex: 999999,
              width: "100vw",
              minHeight: "100vh",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.1)",
              backdropFilter: "blur(5px)",
              top: 0,
              right: 0,
              overflow: "auto",
              justifyContent: "center",
              display: deletingRow ? "flex" : "none",
              transition: "all 0.3s",
            }}
          >
            <Card open={deletingRow} handleClose={() => setDeletingRow(null)} sx={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "100%", maxWidth: "400px" }}>
              <CardHeader title="Tem certeza que deseja remover este recebimento?" />
              <CardContent>
                <p>{deletingRow?.qtdRecebida + " " + produto.unidadeMedida + " de " + produto.produto}</p>
              </CardContent>
              <CardActions>
                <MDButton variant="gradient" color="info" fullWidth type="submit" onClick={() => handleDeleteReceived(deletingRow._id)} disabled={loading}>
                  Confirmar
                </MDButton>
                <MDButton variant="gradient" color="error" fullWidth type="submit" onClick={() => setDeletingRow(null)} disabled={loading}>
                  Cancelar
                </MDButton>
              </CardActions>
            </Card>
          </MDBox>
        )}
        <Grid container spacing={6}>
          <Grid item xs={8} sm={8} md={8} mx={"auto"}>
            <Card>
              <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" sx={{ display: "flex", justifyContent: "space-between" }}>
                <MDTypography variant="h6" color="white" mt={1}>
                  {loading ? "Carregando..." : "Listar Recebimentos"}
                </MDTypography>

                <MDButton
                  variant="contained"
                  type="button"
                  onClick={() => {
                    setShowAddState(false);
                  }}
                >
                  Voltar
                </MDButton>
              </MDBox>

              <MDBox pt={3} mx={2} py={3} px={2}>
                <Box
                  sx={{
                    mb: 2,
                    // display: loading ? "none" : "",
                  }}
                >
                  <Grid
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gap: "1rem",
                    }}
                  >
                    <TextField
                      id="numPedido"
                      name="numPedido"
                      label="Número do Pedido"
                      value={receiveds.length > 0 ? receiveds[0].numPedido : parentFormik.values.numPedido}
                      disabled
                      sx={{
                        width: "100%",
                      }}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      id="codigoInterno"
                      name="codigoInterno"
                      label="Código Interno do Produto"
                      value={receiveds.length > 0 ? receiveds[0].codigoInterno : produto.codigoInterno}
                      disabled
                      sx={{
                        width: "100%",
                      }}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      id="produto"
                      name="produto"
                      label="Descrição do Produto"
                      value={receiveds.length > 0 ? receiveds[0].produto : produto.produto}
                      disabled
                      sx={{
                        width: "100%",
                      }}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      id="unidadeMedida"
                      name="unidadeMedida"
                      label="Unidade de medida"
                      value={receiveds.length > 0 ? receiveds[0].unidadeMedida : produto.unidadeMedida}
                      disabled
                      sx={{
                        width: "100%",
                      }}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      id="qtd"
                      name="qtd"
                      label="Quantidade Solicitada"
                      value={receiveds.length > 0 ? receiveds[0].qtd : produto.qtd}
                      disabled
                      sx={{
                        width: "100%",
                      }}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      id="valorTotalSolicitado"
                      name="valorTotalSolicitado"
                      label="Custo Total Solicitado"
                      value={
                        receiveds.length > 0
                          ? receiveds[0].valorTotalSolicitado?.toLocaleString("pt-BR", { style: "currency", currency: parentFormik.values.currency })
                          : (produto.qtd * produto.valorUnitario)?.toLocaleString("pt-BR", { style: "currency", currency: parentFormik.values.currency })
                      }
                      disabled
                      sx={{
                        width: "100%",
                      }}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      id="valorRecebido"
                      name="valorRecebido"
                      label="Total Recebido"
                      value={receiveds.length > 0 ? calculateTotal(receiveds)?.toLocaleString("pt-BR", { style: "currency", currency: parentFormik.values.currency }) : ""}
                      disabled
                      sx={{
                        width: "100%",
                        columnSpan: 2,
                      }}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12} lg={12} sx={{ mx: "auto" }} mb={2}>
                    <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue} className={classes.tabs}>
                      <Tab label="Recebimentos" icon={<InventoryIcon />} />
                    </Tabs>

                    {tabValue === 0 && receiveds?.length > 0 ? (
                      <div style={{ marginTop: "-40px" }}>
                        <DataTable table={{ columns, rows }} isSorted={false} disablePagination={true} noEndBorder />
                      </div>
                    ) : (
                      <h3
                        style={{
                          textAlign: "center",
                          marginTop: "25px",
                        }}
                      >
                        Nenhum recebimento acusado para este produto neste pedido.
                      </h3>
                    )}
                  </Grid>
                </Box>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
