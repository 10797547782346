import { Box, Button, Card, CardActions, CardContent, CardHeader, Grid, TextField } from "@mui/material";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Service from "services/receiveds";
import WarehouseService from "services/warehouses";

// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

import * as yup from "yup";

// forms
import MDButton from "components/MDButton";

//@mui/styles
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#81BF52",
    },
    "& .MuiTab-root": {
      color: "#425d73 !important",
    },
    "& .MuiTab-root.Mui-selected": {
      backgroundColor: "#81bf52ab",
      color: "#FFFFFF !important",
    },
  },
});

export default function AcusarRecebimento({ setShowAddState, parentFormik, produto }) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [alertKey, setAlertKey] = useState(0);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const [warehouses, setWarehouses] = useState([]);
  // tabs
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm ? setTabsOrientation("vertical") : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const formik = useFormik({
    initialValues: {
      qtd: produto?.qtd,
      unidadeMedida: produto?.unidadeMedida,
      produto: produto?.produto,
      numPedido: parentFormik.values.numPedido,
      codigoInterno: produto?.codigoInterno,
      valorTotalSolicitado: (produto?.qtd * produto?.valorUnitario).toLocaleString("pt-BR", { style: "currency", currency: parentFormik.values.currency || "BRL" }),
      dataRecebimento: new Date().toISOString().split("T")[0],
      dataFabricacao: "",
      dataVencimento: "",
      lote: "",
      qtdRecebida: 0,
      valorRecebido: "",
      localDeRecebimento: "",
    },
    validationSchema: yup.object().shape({
      qtd: yup.number(),
      produto: yup.string(),
      unidadeMedida: yup.string(),
      numPedido: yup.string(),
      codigoInterno: yup.string(),
      valorTotalSolicitado: yup.string(),
      lote: yup.string(),
      dataRecebimento: yup.date(),
      dataFabricacao: yup.date(),
      dataVencimento: yup.date(),
      qtdRecebida: yup.number().min(1, "A quantidade deve ser maior que 0").max(produto?.qtd, "A quantidade recebida não pode ser maior que a quantidade solicitada"),
      valorRecebido: yup.string(),
      localDeRecebimento: yup.string().required("Campo obrigatório"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      setSuccess(null);
      const formattedValues = {
        ...values,
        valorRecebido: parseFloat(values.valorRecebido.replace("R$", "").replace(".", "").replace(",", ".")),
        valorTotalSolicitado: parseFloat(values.valorTotalSolicitado.replace("R$", "").replace(".", "").replace(",", ".")),
        productObject: produto?._id,
      };

      try {
        const request = {
          ...formattedValues,
        };
        await Service.create(request);
        formik.resetForm();
        setSuccess(true);
        setShowAddState(false);
      } catch (error) {
        console.log(error);
        setSuccess(false);
      }

      setAlertKey(alertKey + 1);

      setLoading(false);
    },
  });

  useEffect(() => {
    const fetchWarehouses = async () => {
      const response = await WarehouseService.list();
      setWarehouses(response.data);
    };

    fetchWarehouses();
  }, []);

  useEffect(() => {
    const closeOnEscapePressed = (e) => {
      if (e.key === "Escape") {
        setShowAddState(false);
      }
    };
    window.addEventListener("keydown", closeOnEscapePressed);
    return () => window.removeEventListener("keydown", closeOnEscapePressed);
  }, []);

  useEffect(() => {
    formik.setFieldValue("valorRecebido", (formik.values.qtdRecebida * produto?.valorUnitario).toLocaleString("pt-BR", { style: "currency", currency: parentFormik.values.currency || "BRL" }));
  }, [formik.values.qtdRecebida, produto?.valorUnitario]);

  return (
    <>
      <MDBox
        pt={"30px"}
        pb={"5%"}
        sx={{
          position: "fixed",
          zIndex: 9999,
          width: "100vw",
          minHeight: "100vh",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.1)",
          backdropFilter: "blur(5px)",
          top: 0,
          right: 0,
          overflow: "auto",
          justifyContent: "center",
          display: "flex",
        }}
      >
        {confirmDialogOpen && (
          <MDBox
            pt={"30px"}
            pb={"5%"}
            sx={{
              position: "fixed",
              zIndex: 999999,
              width: "100vw",
              minHeight: "100vh",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.1)",
              backdropFilter: "blur(5px)",
              top: 0,
              right: 0,
              overflow: "auto",
              justifyContent: "center",
              display: confirmDialogOpen ? "flex" : "none",
              transition: "all 0.3s",
            }}
          >
            <Card sx={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "100%", maxWidth: "400px" }}>
              <CardHeader title="Confirmar Recebimento de:" />
              <CardContent>
                <p>{formik.values?.qtdRecebida + " " + formik.values?.unidadeMedida + " de " + formik.values?.produto}</p>
              </CardContent>
              <CardActions>
                <MDButton variant="gradient" color="info" fullWidth type="submit" onClick={() => (setConfirmDialogOpen(false), formik.handleSubmit())} disabled={loading}>
                  Confirmar
                </MDButton>
                <MDButton variant="gradient" color="error" fullWidth type="submit" onClick={() => setConfirmDialogOpen(false)} disabled={loading}>
                  Cancelar
                </MDButton>
              </CardActions>
            </Card>
          </MDBox>
        )}

        <Grid container spacing={6}>
          <Grid item xs={6} sm={6} md={6} mx={"auto"}>
            <Card>
              <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" sx={{ display: "flex", justifyContent: "space-between" }}>
                <MDTypography variant="h6" color="white" mt={1}>
                  {loading ? "Carregando..." : "Acusar Recebimento"}
                </MDTypography>

                <MDButton
                  variant="contained"
                  type="button"
                  onClick={() => {
                    setSuccess(null);
                    setShowAddState(false);
                  }}
                >
                  Voltar
                </MDButton>
              </MDBox>

              <MDBox pt={3} mx={2} py={3} px={2}>
                <Box
                  sx={{
                    mb: 2,
                    display: loading ? "none" : "",
                  }}
                >
                  <Grid
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gap: "1rem",
                    }}
                  >
                    <TextField
                      label="Número do Pedido"
                      value={formik.values.numPedido}
                      sx={{
                        width: "100%",
                      }}
                      disabled
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      id="codigoInterno"
                      name="codigoInterno"
                      label="Código Interno do Produto"
                      value={formik.values?.codigoInterno}
                      sx={{
                        width: "100%",
                      }}
                      disabled
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      id="produto"
                      name="produto"
                      label="Descrição do Produto"
                      value={formik.values?.produto}
                      sx={{
                        width: "100%",
                      }}
                      disabled
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      id="unidadeMedida"
                      name="unidadeMedida"
                      label="Unidade de Medida"
                      value={formik.values?.unidadeMedida}
                      sx={{
                        width: "100%",
                      }}
                      disabled
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      id="localDeRecebimento"
                      name="localDeRecebimento"
                      label="Local de Recebimento do Produto"
                      value={formik.localDeRecebimento}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={formik.touched.localDeRecebimento && Boolean(formik.errors.localDeRecebimento)}
                      helperText={formik.touched.localDeRecebimento && formik.errors.localDeRecebimento}
                      sx={{
                        width: "100%",
                      }}
                      select
                      SelectProps={{
                        native: true,
                      }}
                    >
                      <option value=""></option>
                      {warehouses.map((warehouse) => (
                        <option key={warehouse._id} value={warehouse._id}>
                          {warehouse?.descricao}
                        </option>
                      ))}
                    </TextField>
                    <TextField
                      id="dataRecebimento"
                      name="dataRecebimento"
                      label="Data de Recebimento"
                      type="date"
                      value={formik.values.dataRecebimento}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={loading}
                      error={formik.touched.dataRecebimento && Boolean(formik.errors.dataRecebimento)}
                      helperText={formik.touched.dataRecebimento && formik.errors.dataRecebimento}
                      sx={{
                        width: "100%",
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      id="lote"
                      name="lote"
                      label="Número do Lote"
                      value={formik.values.lote}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={loading}
                      error={formik.touched.lote && Boolean(formik.errors.lote)}
                      helperText={formik.touched.lote && formik.errors.lote}
                      sx={{
                        width: "100%",
                      }}
                    />
                    <TextField
                      id="dataFabricacao"
                      name="dataFabricacao"
                      label="Data de Fabricação"
                      type="date"
                      value={formik.values.dataFabricacao}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={loading}
                      error={formik.touched.dataFabricacao && Boolean(formik.errors.dataFabricacao)}
                      helperText={formik.touched.dataFabricacao && formik.errors.dataFabricacao}
                      sx={{
                        width: "100%",
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                    <TextField
                      id="qtd"
                      name="qtd"
                      label="Quantidade Solicitada"
                      value={formik.values?.qtd}
                      sx={{
                        width: "100%",
                      }}
                      disabled
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      id="dataVencimento"
                      name="dataVencimento"
                      label="Data de Vencimento"
                      type="date"
                      value={formik.values.dataVencimento}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={loading}
                      error={formik.touched.dataVencimento && Boolean(formik.errors.dataVencimento)}
                      helperText={formik.touched.dataVencimento && formik.errors.dataVencimento}
                      sx={{
                        width: "100%",
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      id="valorTotalSolicitado"
                      name="valorTotalSolicitado"
                      label="Custo do total solicitado"
                      value={formik.values.valorTotalSolicitado}
                      sx={{
                        width: "100%",
                      }}
                      disabled
                      inputProps={{
                        readOnly: true,
                      }}
                    />

                    <TextField
                      id="qtdRecebida"
                      name="qtdRecebida"
                      label="Quantidade Recebida"
                      value={formik.values.qtdRecebida}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={loading}
                      type="number"
                      error={formik.touched.qtdRecebida && Boolean(formik.errors.qtdRecebida)}
                      helperText={formik.touched.qtdRecebida && formik.errors.qtdRecebida}
                      sx={{
                        width: "100%",
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      id="valorRecebido"
                      name="valorRecebido"
                      label="Valor Recebido"
                      value={formik.values.valorRecebido}
                      sx={{
                        width: "100%",
                      }}
                      disabled
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>

                  <Button
                    variant="contained"
                    disabled={loading}
                    sx={{
                      gridColumn: "span 2",
                      justifySelf: "center",
                      width: "100%",
                      color: "white !important",
                      marginTop: "1rem",
                    }}
                    onClick={() => setConfirmDialogOpen(true)}
                  >
                    {loading ? "Carregando..." : "Salvar"}
                  </Button>
                </Box>

                {formik.errors.submit && (
                  <Box>
                    <MDAlert color="error" mt={4} dismissible key={alertKey}>
                      <MDTypography variant="body2" color="white">
                        {formik.errors.submit}
                      </MDTypography>
                    </MDAlert>
                  </Box>
                )}

                {success !== null && (
                  <Box>
                    <MDAlert color={success ? "success" : "error"} mt={4} dismissible key={alertKey}>
                      <MDTypography variant="body2" color="white">
                        {success ? "Recebimento acusado com sucesso!" : "Ocorreu um erro ao acusar recebimento."}
                      </MDTypography>
                    </MDAlert>
                  </Box>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
